import React, { useEffect, useState } from 'react';
import { TrackInputError } from 'util/google_optimize/optimize_helper';

export interface InputErrorProps {
    inputElement: React.MutableRefObject<null>;
    touched?: boolean;
    label?: string | object;
    errors?: string;
    showErrors?: boolean;
    className?: string;
}

const InputError = ({ inputElement, touched, label, errors, showErrors, className }: InputErrorProps) => {
    const [currentError, setCurrentError] = useState('');
    if (showErrors === undefined) {
        showErrors = true;
    }

    useEffect(() => {
        if (touched && errors) {
            if (errors.toString() !== currentError) {
                setCurrentError(errors.toString());
                TrackInputError(inputElement.current, label, errors);
            }
        } else {
            setCurrentError('');
        }
    }, [touched, errors]);

    if (touched && errors && showErrors) {
        return <div className={className}>{errors}</div>;
    } else {
        return <></>;
    }
};

export default InputError;
