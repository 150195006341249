import { GATSBY_API_BASE_URL } from 'gatsby-env-variables';

import { RegistrationFormValues } from 'components/registration-form/registration-form.component';

import { BaseService } from 'types/service';

import axiosClient from 'util/axiosClient';

import { ValidateInviteMembershipPayload, ValidateInviteMembershipResponse } from './membership-registration.helpers';

export interface MembershipGendersPayload {
    genders: {
        genderCode: string;
        genderDesc: string;
        genderId: number;
    }[];
    messageStatus: boolean;
    messageText: string;
}

export interface MembershipRacesPayload {
    races: {
        raceId: number;
        raceDesc: string;
        active: string;
    }[];
    messageStatus: boolean;
    messageText: string;
}

export interface MembershipEthnicitiesPayload {
    ethnicities: {
        ethnicityId: number;
        ethnicityDesc: string;
        active: string;
    }[];
    messageStatus: boolean;
    messageText: string;
}

export interface PatientRegistrationPayload {
    FirstName?: string;
    MiddleName: string;
    LastName?: string;
    BirthDate: string;
    GenderCode?: string;
    epostPatientNum: number;
    FamilyId: number;
    AccountId: number;
    PatientStatusCode: string;
    PatientStatus: string;
    CardNumber: string;
    CoverageTypeId: number;
    CoverageType: string;
    RelationshipNumber: number;
    Relationship: string;
    Payor: string;
    PayorNum: number;
    PlanAlias: string;
    GroupId: string;
    GroupName: string;
    EmailAddress: string;
    PhoneSeqNum: number;
    PhoneTypeNum: number;
    PhoneType: string;
    AreaCode: string;
    PhoneNumber?: string;
    Ext: string;
    AddressSeqNumb: number;
    AddressTypeNum: number;
    Address1: string;
    Address2: string;
    Address3: string;
    City: string;
    StateCode: string;
    StateNumber: number;
    ZipCode: string;
    Zip4: number;
    Country: string;
    CountryNumber: number;
    RecordLocation: string;
    HasUserProfile: number;
    UserName: string;
    WebId: string;
    IsMID: number;
    CoverageStartDate: string;
    CoverageEndDate: string;
    ReadHIPAA: boolean;
    AcceptedHIPAA: boolean;
    CacheId: string;
    RegCode: number;
    messageErrorText: string;
    messageStatus: boolean;
    messageText: string;
    Email?: string;
    Password?: string;
    PrimaryCareProvider?: string;
    AutoRefill?: boolean;
    RaceId?: string;
    EthnicityId?: string;
    PasswordConfirm?: string;
    patientPlanAutoRefillFlag?: boolean;
}

export interface InsuranceLookupPayload {
    messageStatus: boolean;
    messageText: string;
}

export interface MembershipEmailZipcodePayload {
    messageStatus: boolean;
    messageText: string;
    validate?: 'registered' | 'valid' | 'invalid';
}

export class MembershipRegistrationService {
    static getMembershipGenders() {
        return {
            get: async (): Promise<MembershipGendersPayload> => {
                const response = await axiosClient.get(`${GATSBY_API_BASE_URL}/api/Membership/Genders`);
                return response.data;
            }
        };
    }

    static getMembershipRaces() {
        return {
            get: async (): Promise<MembershipRacesPayload> => {
                const response = await axiosClient.get(`${GATSBY_API_BASE_URL}/api/Membership/Races`);
                return response.data;
            }
        };
    }

    static getMembershipEthnicities() {
        return {
            get: async (): Promise<MembershipEthnicitiesPayload> => {
                const response = await axiosClient.get(`${GATSBY_API_BASE_URL}/api/Membership/Ethnicities`);
                return response.data;
            }
        };
    }

    /**
     * Retrieves the email and zipcode lookup data from the API.
     */
    static getEmailZipcodeLookup(email: string, zipcode: string) {
        return {
            get: async (): Promise<MembershipEmailZipcodePayload> => {
                return await axiosClient
                    .get(`${GATSBY_API_BASE_URL}/api/Membership/EmailZipcodeLookup`, {
                        params: {
                            email,
                            zipcode
                        }
                    })
                    .then((response) => response.data);
            }
        };
    }

    static validateInsuranceId(insuranceId?: string): BaseService<RegistrationFormValues> {
        return {
            get: (payload?: RegistrationFormValues): Promise<PatientRegistrationPayload> => {
                const dobString = `${payload?.dobMonth}-${payload?.dobDay}-${payload?.dobYear}`;

                const parameters = `${payload?.firstName}/${payload?.lastName}/${dobString}/${insuranceId}`;

                return axiosClient
                    .get(`${GATSBY_API_BASE_URL}/api/Registration/Validate/${parameters}`)
                    .then((response) => response.data);
            }
        };
    }

    static validateInvite(): BaseService<ValidateInviteMembershipPayload> {
        return {
            get: async (payload?: ValidateInviteMembershipPayload): Promise<ValidateInviteMembershipResponse> => {
                const email = encodeURIComponent(payload!.email.replaceAll('%20', '+'));
                const parameters = `firstName=${payload?.firstName}&lastName=${payload?.lastName}&birthDate=${payload?.birthDate}&email=${email}`;

                const response = await axiosClient.get(
                    `${GATSBY_API_BASE_URL}/api/Registration/ValidateInviteMembership?${parameters}`
                );

                return response.data;
            }
        };
    }

    static insuranceLookup(insuranceId?: string): BaseService<RegistrationFormValues> {
        return {
            post: (): Promise<InsuranceLookupPayload> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/Membership/InsuranceLookup`, { InsuranceId: insuranceId })
                    .then((response) => response.data);
            }
        };
    }

    static planDetails(promoCode?: string) {
        return {
            get: async (): Promise<any> => {
                const response = await axiosClient.get(
                    `${GATSBY_API_BASE_URL}/api/Membership/GetPlanDetails${
                        promoCode ? `?DiscountCode=${promoCode}` : ''
                    }`
                );
                return response.data;
            }
        };
    }

    static confirmPayment() {
        return {
            post: (email?: string): Promise<any> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/Membership/ConfirmPaymentMembership`, {
                        email
                    })
                    .then((response) => response.data);
            }
        };
    }

    static UnauthorizedPayments() {
        return {
            getPaymetricCredentials: async (): Promise<any> => {
                const response = await axiosClient.get(
                    `${GATSBY_API_BASE_URL}/api/UnauthorizedPayments/PaymetricCredentials`
                );
                return response.data;
            },
            getTokenizedCardNumber: async (accessToken: string): Promise<any> => {
                const response = await axiosClient.get(
                    `${GATSBY_API_BASE_URL}/api/UnauthorizedPayments/GetTokenizedCardNumber?accessToken=${accessToken}`
                );
                return response.data;
            }
        };
    }
}
