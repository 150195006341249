import React from 'react';

const LinkedIn = ({ label }: { label: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <title>{label}</title>
            <g clipPath="url(#clip0_27738_113)">
                <path
                    d="M22.5616 0H2.10492C1.63504 0 1.18441 0.186657 0.852161 0.518909C0.519909 0.851161 0.333252 1.30179 0.333252 1.77167V22.2283C0.333252 22.6982 0.519909 23.1488 0.852161 23.4811C1.18441 23.8133 1.63504 24 2.10492 24H22.5616C23.0315 24 23.4821 23.8133 23.8143 23.4811C24.1466 23.1488 24.3333 22.6982 24.3333 22.2283V1.77167C24.3333 1.30179 24.1466 0.851161 23.8143 0.518909C23.4821 0.186657 23.0315 0 22.5616 0ZM7.48659 20.445H3.87825V8.98333H7.48659V20.445ZM5.67992 7.395C5.27062 7.3927 4.87117 7.2692 4.53199 7.04009C4.19281 6.81098 3.92909 6.48653 3.77413 6.10769C3.61917 5.72885 3.5799 5.31259 3.66128 4.91145C3.74267 4.51032 3.94105 4.14228 4.23141 3.85378C4.52176 3.56529 4.89107 3.36927 5.29272 3.29046C5.69437 3.21165 6.11036 3.25359 6.4882 3.41099C6.86604 3.56838 7.18879 3.83417 7.41572 4.17481C7.64264 4.51546 7.76358 4.91569 7.76325 5.325C7.76711 5.59903 7.71576 5.87104 7.61226 6.1248C7.50876 6.37857 7.35523 6.6089 7.16082 6.80207C6.96641 6.99523 6.7351 7.14728 6.48068 7.24915C6.22625 7.35102 5.95392 7.40062 5.67992 7.395ZM20.7866 20.455H17.1799V14.1933C17.1799 12.3467 16.3949 11.7767 15.3816 11.7767C14.3116 11.7767 13.2616 12.5833 13.2616 14.24V20.455H9.65325V8.99167H13.1233V10.58H13.1699C13.5183 9.875 14.7383 8.67 16.5999 8.67C18.6133 8.67 20.7883 9.865 20.7883 13.365L20.7866 20.455Z"
                    fill="#1A497F"
                />
            </g>
            <defs>
                <clipPath id="clip0_27738_113">
                    <rect width="24" height="24" fill="white" transform="translate(0.333252)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default LinkedIn;
