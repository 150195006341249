import { Allergies, MedicalConditions } from 'types/medical-conditions';

export const threatHealthConditionsPayload = (payload: MedicalConditions | Allergies) => {
    const noneTrue = payload.None === true;
    const filterBooleansInOBject = Object.values(payload).filter((value) => typeof value === 'boolean');
    const onlyNoneIsTrue = noneTrue && filterBooleansInOBject.filter((value) => value === true).length === 1;
    if (payload.Other === null) payload.Other = '';

    return onlyNoneIsTrue ? payload : { ...payload, None: false };
};
