import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

import type { PrescriberSearchProps } from 'types/prescriber';
import type { PhysicianDetailLookupObjectPayload, PhysicianContact, PhysicianInfoObjectPayload } from 'types/physician';
import { physicianInfoRoutine, physicianDetailLookUpRoutine } from './physician.routines';

export interface PhysicianState {
    lastPhysicianSearchFormValues?: PrescriberSearchProps;
    physicianDetailLookupResult?: PhysicianDetailLookupObjectPayload;
    physicianInfoResult?: PhysicianInfoObjectPayload[];
    isSearchingPhysicians?: boolean;
    isPhysicianDetailLoading?: boolean;
    isPhysicianLocationFlow?: boolean;
    isPhysicianSearchFlow?: boolean;
    isPastPrescriberFlow?: boolean;
    selectedPhysician: PhysicianInfoObjectPayload | null;
    selectedPhysicianLocation: PhysicianContact | null;
}

export const initialState: PhysicianState = {
    lastPhysicianSearchFormValues: undefined,
    physicianDetailLookupResult: undefined,
    physicianInfoResult: [],
    isSearchingPhysicians: false,
    isPhysicianDetailLoading: false,
    isPhysicianLocationFlow: false,
    isPhysicianSearchFlow: true,
    isPastPrescriberFlow: false,
    selectedPhysician: null,
    selectedPhysicianLocation: null
};

const physicianSlice = createSlice({
    name: 'physician',
    initialState,
    reducers: {
        clearPhysicianSearchResults(state) {
            state.physicianDetailLookupResult = initialState.physicianDetailLookupResult;
            state.physicianInfoResult = initialState.physicianInfoResult;
            state.selectedPhysician = initialState.selectedPhysician;
            state.selectedPhysicianLocation = initialState.selectedPhysicianLocation;
        },
        resetPhysicianSearch(state) {
            state.isPastPrescriberFlow = initialState.isPastPrescriberFlow;
            state.isPhysicianLocationFlow = initialState.isPhysicianLocationFlow;
            state.isPhysicianSearchFlow = initialState.isPhysicianSearchFlow;
            state.lastPhysicianSearchFormValues = initialState.lastPhysicianSearchFormValues;
            state.physicianDetailLookupResult = initialState.physicianDetailLookupResult;
            state.physicianInfoResult = initialState.physicianInfoResult;
            state.selectedPhysician = initialState.selectedPhysician;
            state.selectedPhysicianLocation = initialState.selectedPhysicianLocation;
        },
        setIsPhysicianLocationFlow(state, action: PayloadAction<boolean>) {
            state.isPhysicianLocationFlow = action.payload;
        },
        setIsPhysicianSearchFlow(state, action: PayloadAction<boolean>) {
            state.isPhysicianSearchFlow = action.payload;
        },
        setIsPastPrescriberFlow(state, action: PayloadAction<boolean>) {
            state.isPastPrescriberFlow = action.payload;
        },
        setLastPhysicianSearchFormValues(state, action: PayloadAction<PrescriberSearchProps | undefined>) {
            state.lastPhysicianSearchFormValues = action.payload;
        },
        setSelectedPhysician(state, action: PayloadAction<PhysicianInfoObjectPayload | null>) {
            state.selectedPhysician = action.payload;
        },
        setSelectedPhysicianLocation(state, action: PayloadAction<PhysicianContact | null>) {
            state.selectedPhysicianLocation = action.payload;
        }
    },
    extraReducers: ({ addCase }) => {
        /**
         * Individual Physician Detail Lookup
         */
        addCase(physicianDetailLookUpRoutine.TRIGGER, (state) => {
            return produce(state, (draftState) => {
                draftState.isPhysicianDetailLoading = true;
                draftState.physicianDetailLookupResult = initialState.physicianDetailLookupResult;
            });
        });
        addCase(
            physicianDetailLookUpRoutine.SUCCESS,
            (state, action: PayloadAction<PhysicianDetailLookupObjectPayload>) => {
                return produce(state, (draftState) => {
                    draftState.isPhysicianDetailLoading = false;
                    draftState.physicianDetailLookupResult = action.payload;
                });
            }
        );
        addCase(physicianDetailLookUpRoutine.FAILURE, (state) => {
            return produce(state, (draftState) => {
                draftState.isPhysicianDetailLoading = false;
                draftState.physicianDetailLookupResult = initialState.physicianDetailLookupResult;
            });
        });

        /**
         * Physician search/information
         */
        addCase(physicianInfoRoutine.TRIGGER, (state) => {
            return produce(state, (draftState) => {
                draftState.isSearchingPhysicians = true;
                draftState.physicianInfoResult = [];
            });
        });
        addCase(physicianInfoRoutine.SUCCESS, (state, { payload }: PayloadAction<PhysicianInfoObjectPayload[]>) => {
            return produce(state, (draftState) => {
                draftState.isSearchingPhysicians = false;
                draftState.physicianInfoResult = payload;
            });
        });
        addCase(physicianInfoRoutine.FAILURE, (state) => {
            return produce(state, (draftState) => {
                draftState.isSearchingPhysicians = false;
                draftState.physicianInfoResult = [];
            });
        });
    }
});

export const {
    clearPhysicianSearchResults,
    resetPhysicianSearch,
    setIsPastPrescriberFlow,
    setIsPhysicianLocationFlow,
    setIsPhysicianSearchFlow,
    setLastPhysicianSearchFormValues,
    setSelectedPhysician,
    setSelectedPhysicianLocation
} = physicianSlice.actions;

export default physicianSlice.reducer;
