import React, { ReactElement } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { paragraphToComponent } from 'providers/paragraphs/paragraphs';
import { useDispatch } from 'react-redux';
import { openModal } from 'state/birdi-modal/birdi-modal.reducers';
import Button from 'ui-kit/button/button';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

interface BirdiPriceProps {
    insuredText?: string;
    showIfInsured?: boolean;
    accountHasInsurance: boolean;
}

const BirdiPrice = ({ insuredText, showIfInsured, accountHasInsurance }: BirdiPriceProps): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const pageData = useStaticQuery(graphql`
        query {
            modalContent: nodeLandingPage(path: { alias: { eq: "/what_is_the_birdi_price" } }) {
                title
                relationships {
                    field_landing_page_content {
                        ...supportedParagraphs
                    }
                }
            }
        }
    `);

    const handleLinkClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'scroll-modal',
                bodyContent: (
                    <BirdiModalContent
                        icon={'birdi-price'}
                        body={pageData.modalContent.relationships?.field_landing_page_content.map(
                            (paragraph: any, index: number) => (
                                <React.Fragment key={`acknowledgement_${index}`}>
                                    {paragraphToComponent(paragraph?.internal.type, paragraph, index + 10)}
                                </React.Fragment>
                            )
                        )}
                    />
                ),
                ctas: []
            })
        );
    };

    if (!accountHasInsurance || showIfInsured) {
        return (
            <Button
                type="button"
                variant="text"
                className="p-0 mb-1 left-n1 float-lg-right btn-birdi-price text-capitalize text-decoration-none no-min-width"
                label={t('components.birdiPrice.label')}
                disabled={!pageData.modalContent}
                onClick={handleLinkClick}
                dataGAFormName="addMorePrescriptions"
                dataGALocation="addMorePrescriptions"
                birdipriceIcon
            />
        );
    } else {
        return <>{insuredText}</>;
    }
};

export default BirdiPrice;
