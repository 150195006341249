import React from 'react';
import { ExternalProps } from './external-icon.props';
import './external-icon.style.scss';

const ExternalIcon = ({ style }: ExternalProps) => {
    return (
        <div className={`external-icon`} style={style}>
            <svg width="100%" height="100%" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.37673 3.81398H2.51586C2.23096 3.81398 2 4.04494 2 4.32984V18.2579C2 18.5428 2.23096 18.7738 2.51586 18.7738H16.3924C16.6773 18.7738 16.9082 18.5428 16.9082 18.2579V12.7383"
                    stroke="currentColor"
                    strokeWidth="2.06342"
                    strokeLinecap="round"
                />
                <path
                    d="M8.1388 12.7379L17.4516 5.11086"
                    stroke="currentColor"
                    strokeWidth="2.06342"
                    strokeLinecap="round"
                />
                <path
                    d="M18.3327 9.54519L19.2717 4.11707C19.3271 3.79685 19.0762 3.50579 18.7513 3.51342L13.2031 3.64377"
                    stroke="currentColor"
                    strokeWidth="2.06342"
                    strokeLinecap="round"
                />
            </svg>
        </div>
    );
};

export default ExternalIcon;
