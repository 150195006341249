import { put, takeLatest, call } from 'redux-saga/effects';
import { AWS_CONFIG_REGION, AWS_CONFIG_POOLID } from 'gatsby-env-variables';
import { PayloadAction } from '@reduxjs/toolkit';
import { baseEffectHandler } from 'util/sagas/sagas';
import AccountService from '../account/account.services';
import { physicianDetailLookUpRoutine, physicianInfoRoutine } from './physician.routines';
import PhysicianService, { PhysicianDetailLookupPayload, PhysicianInfoPayload } from './physician.service';

export default function* physicianSaga() {
    yield takeLatest(
        physicianDetailLookUpRoutine.TRIGGER,
        function* (action: PayloadAction<PhysicianDetailLookupPayload>) {
            const { npi, state, onSuccess, onFailure } = action.payload;
            yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

            yield baseEffectHandler({
                service: PhysicianService.physicianDetailLookup().get,
                data: { npi, state },
                *onResponse(response) {
                    yield put(physicianDetailLookUpRoutine.success(response));
                    if (onSuccess) onSuccess(response);
                },
                *onError() {
                    yield put(physicianDetailLookUpRoutine.failure());
                    if (onFailure) onFailure();
                }
            });
        }
    );

    yield takeLatest(physicianInfoRoutine.TRIGGER, function* (action: PayloadAction<PhysicianInfoPayload>) {
        const { firstName, lastName, state, onSuccess, onFailure } = action.payload;
        yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

        yield baseEffectHandler({
            service: PhysicianService.physicianInfo().get,
            data: {
                firstName,
                lastName,
                state
            },
            *onResponse(response) {
                yield put(physicianInfoRoutine.success(response));
                if (onSuccess) onSuccess(response);
            },
            *onError() {
                yield put(physicianInfoRoutine.failure());
                if (onFailure) onFailure();
            }
        });
    });
}
