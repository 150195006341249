import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Field } from 'formik';
import { PrescriptionInfoDetailsProps } from './prescription-info-details.props';
import TextSetValue from 'ui-kit/text/textSetValue';
import FormSelect from 'ui-kit/form-select/form-select';
import Text from 'ui-kit/text/text';
import {
    setDrugDetailValues,
    DrugOption,
    StrengthOption
} from 'state/add-transfer-prescription/add-transfer-prescription.reducers';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { addTransferPrescriptionDrugDetailsSelector } from 'state/add-transfer-prescription/add-transfer-prescription.selectors';
import { noop } from 'util/function';
import { useGlobalLink } from 'hooks/useGlobalLink';
export const PrescriptionInfoDetails = ({ formik, extractStrengths, selectOptions }: PrescriptionInfoDetailsProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    let strengthOptions = null;
    if (selectOptions) {
        const initalFormOption = selectOptions.find((format) => format.value === formik?.values?.drugForm);
        strengthOptions = extractStrengths(initalFormOption.strengths);
    }
    const requestFormValues = useSelector(addTransferPrescriptionDrugDetailsSelector);
    const handleDrugFormChange = (drugForm: { key: string; option: DrugOption }, form: any) => {
        const drugStrengths = extractStrengths(drugForm.option.strengths);

        // If there is only one drug strength available, set it as the selected
        // value.
        if (drugStrengths.length === 1) {
            form.setFieldValue('strength', drugStrengths[0].value);
            form.setFieldValue('ndc', drugStrengths[0].drugCode);
            form.setFieldValue('gpi', drugStrengths[0].gpi);
            form.setFieldValue('qty', drugStrengths[0].quantity90days);
        } else {
            form.setFieldValue('strength', '');
            form.setFieldValue('ndc', '');
            form.setFieldValue('gpi', '');
            form.setFieldValue('qty', '');
        }

        dispatch(
            setDrugDetailValues({
                ...requestFormValues,
                drugStrengthOptions: drugStrengths
            })
        );
    };

    const handleDrugStrengthChange = (drugStrength: { key: string; option: StrengthOption }, form: any) => {
        form.setFieldValue('gpi', drugStrength.option.gpi);
        form.setFieldValue('ndc', drugStrength.option.drugCode);
        if (drugStrength.option.quantity90days) {
            form.setFieldValue('qty', drugStrength.option.quantity90days);
        }
    };

    const globalLink = useGlobalLink();

    return (
        <>
            <Row xs={1}>
                <Col>
                    <Field
                        id="drugForm"
                        name="drugForm"
                        options={selectOptions ? selectOptions : requestFormValues.drugFormOptions}
                        component={FormSelect}
                        showSelectPlaceholder={true}
                        placeholder={t('prescriptionInfoForm.inputs.drugForm')}
                        touched={formik?.touched?.drugForm}
                        value={formik?.values?.drugForm}
                        errors={
                            formik?.errors?.drugForm
                                ? t('forms.errorMessages.requiredField', {
                                      label: t('prescriptionInfoForm.inputs.drugForm')
                                  })
                                : undefined
                        }
                        onSelectionChanged={handleDrugFormChange}
                        disabled={requestFormValues.drugFormOptions && requestFormValues.drugFormOptions.length === 0}
                        defaultValue={formik?.initialValues.drugForm}
                        formikControlled={true}
                        singleOptionComponent={
                            <TextSetValue
                                name="drugForm"
                                label={t('prescriptionInfoForm.inputs.drugForm')}
                                value={formik?.values.drugForm ? formik?.values.drugForm : ''}
                                onChange={noop}
                                readOnly={true}
                            />
                        }
                    />
                </Col>
            </Row>
            <Row xs={1} lg={2}>
                <Col>
                    <Text
                        className={'d-none'}
                        type={'hidden'}
                        name="ndc"
                        onChange={formik.handleChange}
                        touched={formik.touched.ndc}
                        value={formik.values?.ndc}
                        defaultValue={formik.initialValues.ndc}
                    />
                    <Field
                        id="strength"
                        name="strength"
                        options={strengthOptions ? strengthOptions : requestFormValues.drugStrengthOptions}
                        component={FormSelect}
                        showSelectPlaceholder={true}
                        placeholder={t('prescriptionInfoForm.inputs.strength')}
                        touched={formik?.touched.strength}
                        value={formik?.values?.strength}
                        errors={
                            formik?.errors?.strength
                                ? t('forms.errorMessages.requiredField', {
                                      label: t('prescriptionInfoForm.inputs.strength')
                                  })
                                : undefined
                        }
                        disabled={
                            requestFormValues.drugStrengthOptions && requestFormValues.drugStrengthOptions.length === 0
                        }
                        onSelectionChanged={handleDrugStrengthChange}
                        defaultValue={formik?.initialValues.strength}
                        formikControlled={true}
                        singleOptionComponent={
                            <TextSetValue
                                name="strength"
                                label={t('prescriptionInfoForm.inputs.strength')}
                                value={formik?.values.strength ? formik?.values.strength : ''}
                                onChange={noop}
                                readOnly={true}
                                onFocus={() => globalLink.handleFieldFocus(t('prescriptionInfoForm.inputs.strength'))}
                            />
                        }
                    />
                </Col>
                <Col>
                    <TextSetValue
                        name="qty"
                        label={t('prescriptionInfoForm.inputs.qty')}
                        onChange={formik?.handleChange}
                        errors={
                            formik?.errors?.qty
                                ? formik.values.qty === ''
                                    ? t('forms.errorMessages.requiredField', {
                                          label: t('prescriptionInfoForm.inputs.qty')
                                      })
                                    : formik.errors.qty === 'drug-qty-max'
                                    ? t('forms.errorMessages.maxCharacters', {
                                          field: t('prescriptionInfoForm.inputs.qty'),

                                          max: '6'
                                      })
                                    : t('forms.errorMessages.nonNumericalValue', {
                                          field: t('prescriptionInfoForm.inputs.qty')
                                      })
                                : formik?.errors?.qty
                        }
                        touched={formik?.touched.qty}
                        value={formik.values.qty ? formik.values.qty : ''}
                        disabled={formik?.values?.strength === ''}
                        autocomplete="off"
                        onFocus={() => globalLink.handleFieldFocus(t('prescriptionInfoForm.inputs.qty'))}
                    />
                </Col>
            </Row>
        </>
    );
};
