import React from 'react';
import { graphql } from 'gatsby';
import { ResourcesFileListProps } from 'components/resources-file-list/resources-file-list.props';
import FileListFile from 'components/resources-file-list/FileListFile/FileListFile';
import ResourcesFileList from 'components/resources-file-list/resources-file-list.component';
import ResourceIcon from 'components/resources-file-list/ResourceIcon/ResourceIcon';

export default function ResourcesFileListProvider({
    paragraph,
    sectionIndex
}: {
    paragraph: GatsbyTypes.ResourcesFileListProviderFragment;
    sectionIndex: number;
}) {
    const resourcesFileListProps: ResourcesFileListProps = {
        sectionIndex: sectionIndex,
        backgroundColor: paragraph.field_background_color,
        eyebrowText: paragraph.field_rfl_eyebrow,
        title: paragraph.field_rfl_title,
        bodyUpperText: paragraph.field_rfl_upper_body?.processed,
        bodyText: paragraph.field_rfl_body?.processed,
        icons: paragraph.relationships?.field_rfl_icons
            ? paragraph.relationships?.field_rfl_icons?.map((icons, index) => (
                  <ResourceIcon
                      key={`resources-icon-${sectionIndex}-${index}`}
                      icon={
                          icons?.relationships?.field_ri_icon ? (
                              <img
                                  src={
                                      icons?.relationships?.field_ri_icon?.relationships?.field_media_svg?.localFile
                                          ?.publicURL
                                  }
                                  alt={icons?.relationships?.field_ri_icon?.field_media_svg?.alt}
                              />
                          ) : (
                              ''
                          )
                      }
                      titleText={icons?.field_ri_title}
                      bodyClassName={icons?.field_ri_html_body?.processed ? 'bodyAsHTML' : ''}
                      bodyText={
                          icons?.field_ri_html_body?.processed
                              ? icons?.field_ri_html_body?.processed
                              : icons?.field_ri_body
                      }
                  />
              ))
            : [],
        downloads: paragraph.relationships?.field_rfl_file_list
            ? paragraph.relationships?.field_rfl_file_list.map((downloads, index) => (
                  <FileListFile
                      key={`filelist-file-${sectionIndex}-${index}`}
                      file={downloads?.relationships?.field_media_document?.localFile?.publicURL}
                      title={downloads?.field_media_document_link_text}
                  />
              ))
            : []
    };
    return <ResourcesFileList {...resourcesFileListProps} />;
}

export const query = graphql`
    fragment ResourcesFileListProvider on paragraph__resources_file_list {
        id
        field_background_color
        field_rfl_eyebrow
        field_rfl_title
        field_rfl_upper_body {
            processed
        }
        field_rfl_body {
            processed
        }
        relationships {
            field_rfl_icons {
                field_ri_body
                field_ri_html_body {
                    processed
                }
                field_ri_title
                relationships {
                    field_ri_icon {
                        field_media_svg {
                            alt
                        }
                        relationships {
                            field_media_svg {
                                localFile {
                                    publicURL
                                }
                            }
                        }
                    }
                }
            }
            field_rfl_file_list {
                field_media_document_link_text
                relationships {
                    field_media_document {
                        localFile {
                            publicURL
                        }
                    }
                }
            }
        }
    }
`;
