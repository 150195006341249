import React, { FC, ReactElement } from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import { SideBySideProps } from 'components/side-by-side-paragraphs/side-by-side.props';
import { paragraphToComponent } from 'providers/paragraphs/paragraphs';

const SideBySide: FC<SideBySideProps> = ({ paragraphs, sectionIndex }: SideBySideProps): ReactElement => {
    return (
        <Container
            fluid
            className={`side-by-side-component`}
            data-ga-index={`${sectionIndex > -1 ? 1 + sectionIndex : 0}`}
            data-ga-location={'Side By Side Contaner'}
        >
            <Row xs={1} lg={paragraphs.length}>
                {paragraphs.map((paragraph: any, index: number) => (
                    <Col className={'mb-4 mb-lg-0'} key={`paragraph_${index}`}>
                        {paragraphToComponent(paragraph?.internal.type, paragraph, index + 10)}
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default SideBySide;
