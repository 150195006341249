import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './GridIcon.style.scss';

export interface GridIconProps {
    icon: JSX.Element;
    subtitle: string;
    bodyText: string;
    sectionIndex: number;
}

export default function GridIcon({ icon, subtitle, bodyText, sectionIndex }: GridIconProps) {
    return (
        <Col className="grid-icon p-0">
            <Row className="d-flex justify-content-center">
                <Col xs={12} className="grid-icon__icon">
                    {icon}
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col xs={8} className="text-center my-3">
                    {sectionIndex === 0 && <h2 className="h4 title m-0">{subtitle}</h2>}
                    {sectionIndex > 0 && <h3 className="h4 title m-0">{subtitle}</h3>}
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col xs={10} className="text-center">
                    <p className="body-text">{bodyText}</p>
                </Col>
            </Row>
        </Col>
    );
}
