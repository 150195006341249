import * as yup from 'yup';

export const ADDRESS_SCHEMA = yup.object().shape({
    addressTypeDesc: yup.string().required(),
    address1: yup.string().required(),
    address2: yup.string(),
    city: yup.string().required(),
    state: yup.string().required(),
    zipcode: yup.string().required()
});

export const ADDRESS_VALIDATION_CONFIRMATION_SCHEMA = yup.object().shape({
    addressChoice: yup.string().required()
});
