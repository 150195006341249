/**
 * Returns a dynamic greeting based on the current time of day.
 *
 * @param {string} morningGreeting - The greeting to use in the morning.
 * @param {string} afternoonGreeting - The greeting to use in the afternoon.
 * @param {string} eveningGreeting - The greeting to use in the evening.
 * @returns {string} The appropriate greeting based on the current time of day.
 */
const dynamicGreeting = (morningGreeting: string, afternoonGreeting: string, eveningGreeting: string): string => {
    const hour = new Date().getHours();
    if (hour >= 0 && hour < 12) {
        return morningGreeting;
    } else if (hour >= 12 && hour < 17) {
        return afternoonGreeting;
    }
    return eveningGreeting;
};

export default dynamicGreeting;
