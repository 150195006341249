import React from 'react';

const QuestionTooltipIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="100%"
            height="100%"
            viewBox="0 0 20 20"
        >
            <g>
                <ellipse
                    id="ellipse-2"
                    cx="10"
                    cy="10"
                    rx="8.5"
                    ry="8.5"
                    fill="none"
                    stroke="rgb(172,185,200)"
                    strokeWidth="3"
                />
                <path
                    fill="rgb(172,185,200)"
                    fillRule="evenodd"
                    d="M10.62151109 11.88066716c-.02364445.15672111-.0945778.2290554-.21280003.2290554H8.9545778c-.14186668 0-.21280003-.08438873-.21280003-.24111175.03546668-.9282775.35466671-1.65161086.72115565-2.0373888.55564427-.56661082 1.20586657-.96444417 1.20586657-1.73600007 0-.42194414-.30737781-.74744415-.76844453-.74744415-.46106649 0-.73297763.20494461-.80391098.60277748-.02364444.13261128-.09457778.19288922-.2009778.18083382l-1.6196444-.13261128c-.10640001-.0120554-.16551113-.08438873-.16551113-.2290554.05911112-.7715559.34284448-1.38638926.87484455-1.85655594.53200006-.47016644 1.19404435-.69922209 1.9861331-.69922209q1.40093326 0 2.09253359.7956667c.47288894.53044438.70933342 1.18144464.70933342 1.96505546s-.30737782 1.42255545-.72115565 1.84450007c-.0810666.08266687-.16623497.16459512-.25295448.24705124l-.13092327.12423706c-.48197937.4581461-.97230006.95137787-1.04661131 1.69021225zm.0472889 2.12177705c0 .59072304-.48471118 1.085001-1.06400014 1.085001-.9221332.07233238-1.43048882-1.24172402-.75662207-1.85655594.60293317-.68716765 1.89155555-.16877795 1.8206222.77155494z"
                />
            </g>
        </svg>
    );
};

export default QuestionTooltipIcon;
