import React, { ReactElement } from 'react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

const UpdateCartModalContent = ({ area }: { area: string }): ReactElement => {
    const { t } = useTranslation();
    return (
        <BirdiModalContent
            icon={'success'}
            title={t('modals.updateCart.title')}
            body={t('modals.updateCart.body', {
                area
            })}
        />
    );
};

export const FailureUpdateCartModalContent = ({ area }: { area: string }): ReactElement => {
    const { t } = useTranslation();
    return (
        <BirdiModalContent
            icon={'alert'}
            title={t('modals.updateCart.error')}
            body={t('modals.updateCart.failure', {
                area
            })}
        />
    );
};

export default UpdateCartModalContent;
