import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { VerifyAddressRequest, VerifyZipRequest } from 'state/usps/usps.reducers';
import { verifyAddressRoutine, verifyZipRoutine } from 'state/usps/usps.routines';
import USPSService from 'state/usps/usps.services';

import { baseEffectHandler } from 'util/sagas/sagas';
import { USPSAddressValidateResponse, USPSZipValidateResponse } from 'util/usps';

export default function* uspsSaga() {
    yield takeLatest(verifyAddressRoutine.TRIGGER, (action: PayloadAction<VerifyAddressRequest>) =>
        baseEffectHandler<VerifyAddressRequest>({
            service: USPSService.verify().post,
            data: action.payload,
            *onResponse(response: USPSAddressValidateResponse) {
                const { onSuccess, onFailure } = action.payload;
                const { AddressValidateResponse } = response;
                if (AddressValidateResponse?.responseCode !== 'invalid') {
                    yield put(verifyAddressRoutine.success(AddressValidateResponse));
                    if (onSuccess) onSuccess(AddressValidateResponse);
                } else {
                    yield put(verifyAddressRoutine.failure(AddressValidateResponse));
                    if (onFailure) onFailure(AddressValidateResponse);
                }
            },
            *onError(err: any) {
                const { onFailure } = action.payload;
                yield put(verifyAddressRoutine.failure(err));
                if (onFailure) onFailure(err);
            }
        })
    );

    yield takeLatest(verifyZipRoutine.TRIGGER, (action: PayloadAction<VerifyZipRequest>) =>
        baseEffectHandler<VerifyZipRequest>({
            service: USPSService.verifyZip().post,
            data: action.payload,
            *onResponse(response: USPSZipValidateResponse) {
                const { onSuccess, onFailure } = action.payload;
                const { ZipValidateResponse } = response;
                if (ZipValidateResponse?.responseCode !== 'invalid') {
                    yield put(verifyZipRoutine.success(ZipValidateResponse));
                    if (onSuccess) onSuccess(ZipValidateResponse);
                } else {
                    yield put(verifyZipRoutine.failure(ZipValidateResponse));
                    if (onFailure) onFailure(ZipValidateResponse);
                }
            },
            *onError(err: any) {
                const { onFailure } = action.payload;
                yield put(verifyZipRoutine.failure(err));
                if (onFailure) onFailure(err);
            }
        })
    );
}
