import { CaregiversAcceptOrDeclinePayload, EasyCaregiverGetCaregivers } from './../../types/family-account.d';
import { put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import {
    familyAccountAuthenticateUserRoutine,
    familyAccountVerifyUserRoutine,
    familyAccountGetSecretKeyHashRoutine,
    familyAccountGetCaregiversRoutine,
    familyAccountPostChangeCaregiversAccessRoutine
} from './family-account.reducer';
import {
    AuthenticateUserRequest,
    AuthenticateUserResponse,
    VerifyUserRequest,
    VerifyUserResponse
} from 'types/family-account';
import { FamilyAccountService } from './family-account.services';
import { baseEffectHandler } from 'util/sagas/sagas';
import { TrackError } from 'util/google_optimize/optimize_helper';
import {
    familyAccountSecretKeyHashSelector,
    familyAccountSecurityTokenSelector,
    familyAccountUserBearerTokenSelector
} from './family-account.selectors';

function* generateSecretKeySaga(
    action: PayloadAction<{
        onSuccess?: () => void;
        onFailure?: (response: string) => void;
    }>
) {
    try {
        const securityToken: string = yield select(familyAccountSecurityTokenSelector);

        yield baseEffectHandler({
            service: FamilyAccountService.getSecretKeyHash().get,
            isAuthenticatedService: false,
            data: securityToken,
            *onResponse(response: AuthenticateUserResponse) {
                yield put(familyAccountGetSecretKeyHashRoutine.success(response));
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
            },
            *onError() {
                yield put(familyAccountGetSecretKeyHashRoutine.failure('The security token is invalid'));
                const { onFailure } = action.payload;
                if (onFailure) {
                    onFailure('The security token is invalid');
                }
            }
        });
    } catch (error) {
        yield put(familyAccountGetSecretKeyHashRoutine.failure(error));
    }
}

function* authenticateUserSaga() {
    try {
        const securityToken: string = yield select(familyAccountSecurityTokenSelector);
        const secretKeyHash: string = yield select(familyAccountSecretKeyHashSelector);
        // if successful verification - authenticate user
        const authenticateData: AuthenticateUserRequest = {
            securityToken,
            secretKeyHash,
            authenticationType: 'EasyCaregiver'
        };

        yield baseEffectHandler({
            service: FamilyAccountService.authenticateUser().post,
            isAuthenticatedService: false,
            data: authenticateData,
            *onResponse(response: AuthenticateUserResponse) {
                if (response && response.messageText !== 'Authenticated') {
                    yield put(familyAccountAuthenticateUserRoutine.failure(response));
                    TrackError('family-account.sagas.ts', 'authenticateUserSaga', response.messageText);
                } else {
                    yield put(familyAccountAuthenticateUserRoutine.success(response));
                }
            },
            *onError(error) {
                yield put(familyAccountAuthenticateUserRoutine.failure({ messageText: 'An error has occured' }));
            }
        });
    } catch (_) {
        yield put(familyAccountAuthenticateUserRoutine.failure({ messageText: 'An error has occured' }));
    }
}

function* verifyUserSaga(
    action: PayloadAction<{
        dateOfBirth: string;
        zipcode: string;
        onSuccess?: () => void;
        onFailure?: (response: VerifyUserResponse) => void;
        onMaxNumAttemptsReached?: (response: VerifyUserResponse) => void;
    }>
) {
    try {
        const securityToken: string = yield select(familyAccountSecurityTokenSelector);
        const { dateOfBirth, zipcode, onFailure, onMaxNumAttemptsReached, onSuccess } = action.payload;
        const data: VerifyUserRequest = {
            dateOfBirth,
            zipcode,
            securityToken,
            authenticationType: 'EasyCaregiver'
        };

        yield baseEffectHandler({
            service: FamilyAccountService.verifyUser().post,
            isAuthenticatedService: false,
            data: data,
            *onResponse(response: VerifyUserResponse) {
                if (response && response.verificationStatus === 'MaxAttemptsReached') {
                    yield put(familyAccountVerifyUserRoutine.failure(response));
                    if (onMaxNumAttemptsReached) onMaxNumAttemptsReached(response);
                    if (onFailure) onFailure(response);
                } else if (
                    response &&
                    response.verificationStatus !== 'UserVerified' &&
                    response.verificationStatus !== 'TokenValid'
                ) {
                    yield put(familyAccountVerifyUserRoutine.failure(response));
                    TrackError('family-account.sagas.ts', 'verifyUserSaga', response.messageText);
                    if (onFailure) onFailure(response);
                } else {
                    yield put(familyAccountVerifyUserRoutine.success(response));
                    yield put(familyAccountAuthenticateUserRoutine.trigger());
                    if (onSuccess) onSuccess();
                }
            },
            *onError(error) {
                yield put(familyAccountVerifyUserRoutine.failure({ messageText: 'An error has occured' }));
                if (onFailure)
                    onFailure({
                        messageText: 'An error has occured',
                        verificationStatus: 'UserNotVerified',
                        firstName: '',
                        lastName: '',
                        epostPatientNum: '',
                        messageStatus: false
                    });
            }
        });
    } catch (error: any) {
        const { onFailure } = action.payload;
        yield put(familyAccountVerifyUserRoutine.failure({ messageText: 'An error has occured' }));
        if (onFailure)
            onFailure({
                messageText: 'An error has occured',
                verificationStatus: 'UserNotVerified',
                firstName: '',
                lastName: '',
                epostPatientNum: '',
                messageStatus: false
            });
    }
}

function* getCaregiversSaga() {
    const bearerToken: string = yield select(familyAccountUserBearerTokenSelector);
    yield baseEffectHandler({
        service: FamilyAccountService.getCaregivers().get,
        isAuthenticatedService: false,
        data: bearerToken,
        *onResponse(response: EasyCaregiverGetCaregivers) {
            yield put(familyAccountGetCaregiversRoutine.success(response.caregivers));
        },
        *onError(error) {
            yield put(familyAccountGetCaregiversRoutine.failure({ messageText: error }));
        }
    });
}

function* changeCaregiversAccessSaga(
    action: PayloadAction<{
        data: CaregiversAcceptOrDeclinePayload;
        onSuccess?: () => void;
        onFailure?: (responseMessage: string) => void;
    }>
) {
    const bearerToken: string = yield select(familyAccountUserBearerTokenSelector);

    const { data, onFailure, onSuccess } = action.payload;

    yield baseEffectHandler({
        service: FamilyAccountService.changeCaregiversAccess().post,
        isAuthenticatedService: false,
        data: { ...data, bearerToken: bearerToken },
        *onResponse(response) {
            if (response.messageStatus !== false) {
                yield put(familyAccountPostChangeCaregiversAccessRoutine.success(response));
                if (onSuccess) onSuccess();
            } else {
                put(familyAccountPostChangeCaregiversAccessRoutine.failure({ messageText: response.messageText }));
                if (onFailure) onFailure(response.messageText);
            }
        },
        *onError(error) {
            yield put(familyAccountPostChangeCaregiversAccessRoutine.failure({ messageText: error }));
            if (onFailure) onFailure(error.messageText);
        }
    });
}

function* familyAccountSaga() {
    yield takeLatest(familyAccountVerifyUserRoutine.TRIGGER, verifyUserSaga);
    yield takeLatest(familyAccountAuthenticateUserRoutine.TRIGGER, authenticateUserSaga);
    yield takeLatest(familyAccountGetSecretKeyHashRoutine.TRIGGER, generateSecretKeySaga);
    yield takeLatest(familyAccountGetCaregiversRoutine.TRIGGER, getCaregiversSaga);
    yield takeLatest(familyAccountPostChangeCaregiversAccessRoutine.TRIGGER, changeCaregiversAccessSaga);
}

export default familyAccountSaga;
