import React from 'react';

const Facebook = ({ label }: { label: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <title>{label}</title>

            <g clipPath="url(#clip0_27738_107)">
                <path
                    d="M24.3333 12C24.3333 5.37264 18.9606 0 12.3333 0C5.70589 0 0.333252 5.37264 0.333252 12C0.333252 17.6275 4.20781 22.3498 9.43453 23.6467V15.6672H6.96013V12H9.43453V10.4198C9.43453 6.33552 11.283 4.4424 15.2929 4.4424C16.0533 4.4424 17.3651 4.59168 17.9017 4.74048V8.06448C17.6185 8.03472 17.1265 8.01984 16.5155 8.01984C14.548 8.01984 13.7877 8.76528 13.7877 10.703V12H17.7073L17.0339 15.6672H13.7877V23.9122C19.7296 23.1946 24.3337 18.1354 24.3337 12H24.3333Z"
                    fill="#1A497F"
                />
            </g>
            <defs>
                <clipPath id="clip0_27738_107">
                    <rect width="24" height="24" fill="white" transform="translate(0.333252)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Facebook;
