import { createRoutine } from 'redux-saga-routines';

export const familyProfileGetDependentsRoutine = createRoutine('FAMILY_PROFILE/GET_DEPENDENTS');
export const familyProfileAddDependentRoutine = createRoutine('FAMILY_PROFILE/ADD_DEPENDENT');
export const familyProfileVerifyDependentRoutine = createRoutine('FAMILY_PROFILE/VERIFY_DEPENDENT');
export const familyProfileRemoveDependentRoutine = createRoutine('FAMILY_PROFILE/REMOVE_DEPENDENT');
export const familyProfileSendDependentInvitationRoutine = createRoutine('FAMILY_PROFILE/SEND_INVITATION');
export const familyProfileGetCaregiversRoutine = createRoutine('FAMILY_PROFILE/GET_CAREGIVERS');
export const familyProfilePostChangeCaregiversAccessRoutine = createRoutine(
    'FAMILY_PROFILE/POST_CHANGE_CAREGIVERS_ACCESS'
);
