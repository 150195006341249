import { GATSBY_API_BASE_URL } from 'gatsby-env-variables';

import {
    ExtendedRefillRxs,
    Images,
    NewRxs,
    Note,
    OrderBillShip,
    OrderHeader,
    OTCs,
    RefillRxs
} from 'types/order-prescription';

import axiosClient from 'util/axiosClient';

export interface OrderV2Payload {
    BirdiOrderNumber?: string;
    FirstName?: string;
    LastName?: string;
    type?: string;
    EpostPatientNum?: string;
    messageErrorText?: string;
    messageStatus?: boolean;
    messageText?: string;
    Order: {
        doNotSubmitToWorkflow: boolean;
        originationNum: string;
        orderTotal: string;
        cartId: string | null;
        messageErrorText: string;
        messageStatus: true;
        messageText: string;
        otcs: OTCs;
        images: Images[];
        orderHeader: OrderHeader;
        note: Note[];
        newRxs: NewRxs[];
        refillRxs: RefillRxs[];
        orderBillShip: OrderBillShip;
    };
}

export type StartOrderV2Payload = OrderV2Payload;

export interface CartObjectV2Payload {
    isCaregiver: boolean;
    epostPatientNum: string;
    cart: StartOrderV2Payload[];
}

export interface CartObjectPayload {
    doNotSubmitToWorkflow: boolean;
    originationNum: string;
    orderTotal: string;
    cartId: string | null;
    messageErrorText: string;
    messageStatus: true;
    messageText: string;
    otcs: OTCs;
    images: Images[];
    orderHeader: OrderHeader;
    note: Note[];
    newRxs: NewRxs[];
    refillRxs: RefillRxs[];
    orderBillShip: OrderBillShip;
}

export interface ExtendedCartObjectPayload extends CartObjectPayload {
    BirdiOrderNumber: string;
    orderTotalCalculated: number;
    itemHasUnknownPrice: boolean;
    extendedRefillRxs: ExtendedRefillRxs[];
    FirstName?: string;
    LastName?: string;
}

export interface CancelOrderLinePayload {
    rxNumber: string;
    lineId: string; // lineId is actually epostRxScriptId in the order object
    orderNum: string;
    epostNumPatient?: string;
}

/**
 * Anything related to Cart in the system.
 */
export default class CartService {
    static startCart() {
        return {
            getAll: (): Promise<any> => {
                return axiosClient.get(`${GATSBY_API_BASE_URL}/api/Order/GetCart`).then((response) => {
                    const { refillRxs, orderHeader, ...params } = response.data;

                    return {
                        Order: {
                            orderHeader: {
                                ...orderHeader
                            },
                            refillRxs: refillRxs,
                            ...params
                        }
                    };
                });
            },
            getAllV2: (): Promise<any> => {
                return axiosClient.get(`${GATSBY_API_BASE_URL}/api/v2/Order/GetCart`).then((response) => {
                    return response.data;
                });
            },
            post: (payload: { epostPatientNum: string; updatedCartObject: StartOrderV2Payload }): Promise<any> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/Order/StartOrder`, payload.updatedCartObject?.Order)
                    .then((response) => {
                        return {
                            EpostPatientNum: payload.updatedCartObject?.EpostPatientNum,
                            Order: response.data
                        };
                    });
            },
            postV2: (payload: { epostPatientNum: string; updatedCartObject: StartOrderV2Payload }): Promise<any> => {
                return axiosClient
                    .post(
                        `${GATSBY_API_BASE_URL}/api/v2/Order/StartOrder?epostPatientNum=${payload.epostPatientNum}`,
                        payload.updatedCartObject
                    )
                    .then((response) => response.data);
            }
        };
    }
    static completeOrder() {
        return {
            post: (payload?: OrderV2Payload[]): Promise<any> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/Order/CompleteOrder`, payload && payload[0].Order)
                    .then((response) => {
                        return [{ Order: response.data }];
                    });
            },
            postV2: (payload?: OrderV2Payload[]): Promise<any> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/v2/Order/CompleteOrder`, payload)
                    .then((response) => response.data);
            }
        };
    }
    static updateRefillLines() {
        return {
            post: (payload?: CartObjectPayload): Promise<any> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/Order/updateRefillLines`, payload)
                    .then((response) => response.data);
            }
        };
    }
    static cancelOrderLine() {
        return {
            post: (payload?: CartObjectPayload): Promise<any> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/Order/CancelOrderLine`, payload)
                    .then((response) => response.data);
            }
        };
    }
    static cancelOrder() {
        return {
            post: (payload?: CancelOrderLinePayload): Promise<any> => {
                return axiosClient
                    .post(
                        `${GATSBY_API_BASE_URL}/api/Order/CancelOrderLine?rxNumber=${payload?.rxNumber}&lineId=${payload?.lineId}&orderNum=${payload?.orderNum}`
                    )
                    .then((response) => response.data);
            },
            postV2: (payload?: CancelOrderLinePayload): Promise<any> => {
                return axiosClient
                    .post(
                        `${GATSBY_API_BASE_URL}/api/v2/Order/CancelOrderLine?rxNumber=${payload?.rxNumber}&lineId=${payload?.lineId}&orderNum=${payload?.orderNum}&epostNumPatient=${payload?.epostNumPatient}`
                    )
                    .then((response) => response.data);
            }
        };
    }
    static updateCart() {
        return {
            post: (payload?: CartObjectPayload): Promise<any> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/v2/Order/UpdateCart/false`, payload)
                    .then((response) => {
                        return response.data;
                    });
            }
        };
    }
}
