import type { AxiosResponse } from 'axios';
import axiosClientAws from 'util/axiosClientAws';
import { PharmacyGroupSearchResponse, PharmacySearchGroup, PharmacySearchItem } from 'types/pharmacy';

export interface PharmacyLookupPayload {
    pharmacyName: string;
    onSuccess?: (response: PharmacySearchItem) => void;
    onFailure?: () => void;
}

export interface PharmacySearchPayload {
    pharmacyName: string;
    city?: string; //required if zipCode is not provided
    state?: string; //required if zipCode is not provided
    zipCode?: string; // only required if city/state not provided
    maxNumber?: string;
    onSuccess?: (response: PharmacySearchGroup[]) => void;
    onFailure?: () => void;
}

export interface PharmacyGroupByPayload extends PharmacySearchPayload {
    groupBy: 'name' | 'zip';
}

/**
 * Anything related to pharmacy lookup, search, group etc.
 */
export default class PharmacyService {
    /**
     * Pharmacy name lookup
     */
    static pharmacyLookup() {
        return {
            get: (payload: PharmacyLookupPayload): Promise<[]> => {
                const request = axiosClientAws(
                    `pharmacy/lookup?pharmacy-name=${encodeURIComponent(payload.pharmacyName)}`
                );
                return request.then((response: AxiosResponse<[]>) => response.data);
            }
        };
    }

    /**
     * Pharmacy search
     */
    static pharmacySearch() {
        const buildPath = (path: string, payload: PharmacySearchPayload | PharmacyGroupByPayload): string => {
            if (payload.city) {
                path += `&city-name=${encodeURIComponent(payload.city)}`;
            }
            if (payload.state) {
                path += `&state-code=${encodeURIComponent(payload.state)}`;
            }
            if (payload.zipCode) {
                path += `&zip-code=${encodeURIComponent(payload.zipCode)}`;
            }
            if (payload.maxNumber) {
                path += `&max-number=${encodeURIComponent(payload.maxNumber)}`;
            }
            return path;
        };
        return {
            get: (payload: PharmacySearchPayload): Promise<PharmacySearchItem[]> => {
                let path = `pharmacy/search?pharmacy-name=${encodeURIComponent(payload.pharmacyName)}`;
                const request = axiosClientAws(buildPath(path, payload));
                return request.then((response: any) => response.data);
            },
            groupBy: (payload: PharmacyGroupByPayload): Promise<PharmacyGroupSearchResponse> => {
                let path = `pharmacy/group/${payload.groupBy}?pharmacy-name=${encodeURIComponent(
                    payload.pharmacyName
                )}`;
                const request = axiosClientAws(buildPath(path, payload));
                return request.then((response: any) => response.data);
            }
        };
    }
}
