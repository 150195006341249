import BirdiModalIcon from './BirdiModalIcon';
import { BirdiModalIconType } from 'types/birdi-modal-icon';

export interface BirdiModalHeaderProps {
    children: React.ReactNode;
    note?: React.ReactNode;
    title?: React.ReactNode;
}

export interface BirdiModalHeaderBaseProps {
    headerText: string;
    icon?: BirdiModalIconType;
}

interface BirdiModalHeaderWithBackgroundColorProps extends BirdiModalHeaderBaseProps {
    type?: 'danger' | 'warning' | 'success' | 'info';
}

export const BirdiModalHeaderWithBackgroundColor: React.FunctionComponent<BirdiModalHeaderWithBackgroundColorProps> = ({
    headerText,
    type,
    icon
}) => {
    return (
        <div className={`birdi-modal-header-container ${type}`}>
            {icon && (
                <div className="icon-container">
                    <BirdiModalIcon icon={icon} />
                </div>
            )}
            <h2>{headerText}</h2>
        </div>
    );
};

export const BirdiModalHeaderDanger: React.FunctionComponent<BirdiModalHeaderBaseProps> = ({ headerText, icon }) => {
    return <BirdiModalHeaderWithBackgroundColor headerText={headerText} icon={icon} type="danger" />;
};

const BirdiModalHeader: React.FunctionComponent<BirdiModalHeaderProps> = ({ children, note, title }) => {
    const modalHeaderClasses =
        title && note
            ? 'd-flex flex-column align-items-center text-center w-100'
            : 'd-flex flex-column align-items-center text-center w-100 pb-3 pb-md-4';

    return (
        <div className={modalHeaderClasses}>
            <h2 className="mt-5 mb-3">{children}</h2>
            {!note && !title && <div className="spacer" />}
            {title && <>{title}</>}
            {note && <>{note}</>}
        </div>
    );
};

export default BirdiModalHeader;
