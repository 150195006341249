import EnTranslations from './src/locales/en/translation.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// DRX-1680: This file was necessary to provide translations through the provider.

const resources = {
    en: {
        translation: EnTranslations
    }
};

i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    debug: false, // When to debug translations in a React component using the i18n Provider.
    detection: {
        order: ['localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
        caches: ['localStorage']
    },
    interpolation: {
        escapeValue: false
    },
    resources,
    lng: 'en'
});

export default i18n;
