import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FC, ReactElement } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import sanitizeHtml from 'sanitize-html';

import Link from 'ui-kit/link/link';

import { HeroProps } from 'components/hero/hero.props';

import withBackgroundImage from 'hoc/withBackgroundImage';

import './hero.style.scss';

const Hero: FC<HeroProps> = ({
    backgroundColor,
    backgroundImage,
    eyebrowText,
    eyebrowColor = 'light',
    image,
    imagePosition,
    style,
    imageAltText,
    linkLabel,
    linkTo,
    size = 'large',
    text,
    title,
    sectionIndex
}: HeroProps): ReactElement => {
    const allowedTags = ['br', 's', 'strong', 'em', 'u'];
    const sanitizedTitle = sanitizeHtml(title, {
        allowedTags
    });
    const sanitizedText = sanitizeHtml(text, {
        allowedTags: allowedTags.concat(['p', 'ul', 'li'])
    });

    return (
        <Container
            fluid
            className={`hero ${backgroundColor ?? ''} ${size ?? ''} ${imagePosition ?? ''} ${
                backgroundImage ? 'has-background-image' : ''
            } ${style}`}
            data-ga-index={`${sectionIndex > -1 ? 1 + sectionIndex : 0}`}
            data-ga-location={backgroundImage ? 'Multiple Image Hero' : 'Rounded Hero'}
            data-ga-title={title}
        >
            <Row>
                <Col xs="12" md="12" lg="6" className={`d-flex align-items-center`}>
                    <div className="hero-content">
                        {eyebrowText && (
                            <div className={`h5 hero-eyebrow-text ${eyebrowColor ? eyebrowColor : ''}`}>
                                {eyebrowText}
                            </div>
                        )}
                        {sectionIndex === 0 && (
                            <h1 className="hero-title" dangerouslySetInnerHTML={{ __html: sanitizedTitle }} />
                        )}
                        {sectionIndex > 0 && (
                            <h2 className="hero-title" dangerouslySetInnerHTML={{ __html: sanitizedTitle }} />
                        )}
                        {sectionIndex === 0 && (
                            <div className="h3 hero-text" dangerouslySetInnerHTML={{ __html: sanitizedText }} />
                        )}
                        {sectionIndex > 0 && (
                            <div className="h4 hero-title" dangerouslySetInnerHTML={{ __html: sanitizedText }} />
                        )}
                        {
                            <Link
                                to={linkTo}
                                label={linkLabel}
                                variant="cta-button"
                                dataGALocation={backgroundImage ? 'Multiple Image Hero' : 'Rounded Hero'}
                            />
                        }
                    </div>
                </Col>

                {image && (
                    <Col xs="12" md="12" lg="6" className={`d-flex hero-image ${imagePosition ?? ''}`}>
                        <div className="hero-image-container">
                            <GatsbyImage image={getImage(image)} alt={imageAltText} />
                        </div>
                    </Col>
                )}
            </Row>
        </Container>
    );
};

export const HeroWithBackgroundImage = withBackgroundImage(Hero, 'heroBackground');
export default Hero;
