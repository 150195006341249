import { v4 as uuidV4 } from 'uuid';

const tabIdKey = 'tab:id';

// Creating a Tab Broadcast class as a singleton
// which will allow us to communicate across different browser tabs
// See more at: https://developer.mozilla.org/en-US/docs/Web/API/BroadcastChannel
class TabBroadcast {
    private _id: string;
    private _active: boolean;
    private _channel: BroadcastChannel;
    private _callback: () => void;
    private static _instance: TabBroadcast | null;

    // Store callback and id when an instance is created
    // A BroadcastChannel is set to allow communication between tabs
    // An empty callback is set by default
    private constructor(id: string) {
        this._id = id;
        this._active = false;
        this._callback = () => {};
        this._channel = new BroadcastChannel('tab');
        this._channel.addEventListener('message', (msg) => {
            // Allow triggering the event just once.
            if (!this._active) {
                this._callback();
                this._active = true;
            }
        });
        sessionStorage.setItem(tabIdKey, id);
    }

    // Get broadcast tab id
    get id(): string {
        return this._id;
    }

    // Setter to define callback
    set callback(callback: () => void) {
        this._callback = callback;
    }

    // Send a message to all the tabs
    public sendMessage(message: string): void {
        this._channel.postMessage(message);
    }

    // This method creates the singleton instance.
    public static getInstance(): TabBroadcast {
        const tabId = sessionStorage.getItem(tabIdKey);
        if (!TabBroadcast._instance) {
            TabBroadcast._instance = new TabBroadcast(tabId ? tabId : uuidV4());
        }
        return TabBroadcast._instance;
    }
}
export default TabBroadcast;
