import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

export interface ChatbotState {
    lexRuntimeService: unknown | undefined;
}

const initialState: ChatbotState = {
    lexRuntimeService: undefined
};
const chatbotSlice = createSlice({
    name: 'chatbot',
    initialState,
    reducers: {
        setLexRuntime: (state, { payload }: PayloadAction<{ lexRuntimeService: unknown }>) =>
            produce(state, (draftState) => {
                draftState.lexRuntimeService = payload.lexRuntimeService;
            })
    }
});

export const { setLexRuntime } = chatbotSlice.actions;
export default chatbotSlice.reducer;
