import classNames from 'classnames';
import React from 'react';

import { ArrowIconProps } from './arrow-icon.props';
import './arrow-icon.styles.scss';

const ArrowIcon = ({ className, style, colorHex = '#1A497F', direction = 'left' }: ArrowIconProps) => {
    const classes = classNames('arrow-icon', className);
    const positionClass = classNames(
        {
            left: direction === 'left',
            right: direction === 'right',
            down: direction === 'down',
            up: direction === 'up'
        },
        className
    );

    return (
        <div className={classes} style={style}>
            <svg
                className={positionClass}
                width={32}
                height={32}
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12.8571 23.9999L5 15.9999M5 15.9999L12.8571 7.99988M5 15.9999L27 15.9999"
                    stroke={colorHex}
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default ArrowIcon;
