import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { produce } from 'immer';
import { drupalFetchCSRFRoutine } from 'state/drupal/drupal.routines';

export interface DrupalState {
    csrfToken: string | undefined;
}

export const initialState: DrupalState = {
    csrfToken: undefined
};

const drupalSlice = createSlice({
    name: 'drupal',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(drupalFetchCSRFRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.csrfToken = payload;
            })
        );
        addCase(drupalFetchCSRFRoutine.FAILURE, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.csrfToken = undefined;
            })
        );
    }
});

export default drupalSlice.reducer;
