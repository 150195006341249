import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

import Button from 'ui-kit/button/button';
import ArrowIcon from 'ui-kit/icons/arrow/arrow-icon';
import ModalCloseIcon from 'ui-kit/icons/modal-close-icon/modal-close-icon';

import { modalComponentSelector } from 'state/modal/modal.selector';

import { ModalComponentProps } from './modal.props';
import './modal.styles.scss';

/**
 * Base Modal component implementation
 * @returns Jsx.Element
 */
export default function ModalComponent() {
    const modalState = useSelector(modalComponentSelector);

    return <ModalComponentRender {...modalState} />;
}

/**
 * Used to maintain the default layout when the default modal configuration is not used
 * Ideal for use around content with customized forms and actions
 * @param props Children component
 * @returns Jsx.Element
 */
export const ModalComponentContent = (props: PropsWithChildren<any>) => {
    return <div className="modal-body-inner-content">{props.children}</div>;
};

/**
 * Used to maintain the default layout when the default modal configuration is not used
 * Maintains the same layout settings as Modal.footer, leaving the component standardized
 * new properties can be added to complement the component
 * @param props Partial ModalComponentProps
 * @returns Jsx.Element
 */
export const ModalComponentFooter = (
    props: Pick<
        ModalComponentProps,
        | 'cancelButtonLabel'
        | 'continueButtonLabel'
        | 'onCancel'
        | 'onContinue'
        | 'footerLayout'
        | 'isCTABusy'
        | 'isCTADisabled'
    >
) => {
    const modalFooterClass = classNames({ 'vertical-footer': props.footerLayout === 'vertical' });

    const handleCancel = () => {
        if (props.onCancel) props.onCancel();
    };

    const handleContinue = () => {
        if (props.onContinue) props.onContinue();
    };

    return (
        <Modal.Footer className={modalFooterClass}>
            <Button
                type="button"
                label={props.cancelButtonLabel as string}
                variant="link"
                onClick={handleCancel}
                disabled={props.isCTADisabled}
                isBusy={props.isCTABusy}
            />
            <Button
                async={props.isCTABusy}
                type="button"
                label={props.continueButtonLabel as string}
                variant="primary"
                onClick={handleContinue}
                isBusy={props.isCTABusy}
                disabled={props.isCTADisabled}
            />
        </Modal.Footer>
    );
};

export const ModalComponentRender = ({
    isCloseable = true,
    footerLayout = 'horizontal',
    continueButtonLabel = 'Save',
    cancelButtonLabel = 'Cancel',
    hasTitleNavigation = false,
    hasDefaultFooter = true,
    hasCustomContent = false,
    hasDefaultTitle = true,
    hasModalHeader = true,
    onClose,
    onNavigate,
    customDialogClassName,
    ...props
}: ModalComponentProps) => {
    const modalBodyClass = classNames({ 'with-custom-content': hasCustomContent });
    const modalHeaderClass = classNames({ 'only-close-icon': !hasDefaultTitle });

    const getModalSize = (size: ModalComponentProps['variation']) => {
        if (customDialogClassName) return undefined;

        if (size === 'small') return 'sm';
        return 'lg';
    };

    const handleClose = () => {
        onClose();
    };

    const handleNavigate = () => {
        if (onNavigate) onNavigate();
    };

    return (
        <Modal
            size={getModalSize(props.variation)}
            centered={props.isCentered}
            show={props.show}
            data-ga-type="modal"
            backdrop={props.backdrop}
            dialogClassName={
                customDialogClassName ? `custom-modal-component ${customDialogClassName}` : 'custom-modal-component'
            }
            contentClassName="custom-modal-component-content"
            backdropClassName="custom-modal-component-backdrop"
            onHide={handleClose}
        >
            {/* Modal Title */}
            {hasModalHeader && (
                <Modal.Header className={modalHeaderClass} closeButton={false}>
                    {hasDefaultTitle && (
                        <Modal.Title>
                            {hasTitleNavigation && (
                                <div className="goBack-icon">
                                    <button type="button" onClick={handleNavigate}>
                                        <ArrowIcon />
                                    </button>
                                </div>
                            )}
                            {props.title}
                        </Modal.Title>
                    )}
                    {isCloseable && (
                        <div className="close-icon">
                            <button type="button" onClick={handleClose}>
                                <ModalCloseIcon />
                            </button>
                        </div>
                    )}
                </Modal.Header>
            )}
            {/* End Modal Title */}

            {/* Modal Content */}
            {props.content && <Modal.Body className={modalBodyClass}>{props.content}</Modal.Body>}
            {/* End Modal Content */}

            {/* Modal Footer */}
            {hasDefaultFooter && (
                <ModalComponentFooter
                    {...props}
                    continueButtonLabel={continueButtonLabel}
                    cancelButtonLabel={cancelButtonLabel}
                    footerLayout={footerLayout}
                />
            )}
            {/* End Modal Footer */}
        </Modal>
    );
};
