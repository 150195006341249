import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import AddTransferPrescriptionService, {
    TransferPrescriptionPayload,
    CancelPrescriptionPayload
} from 'state/add-transfer-prescription/add-transfer-prescription.services';
import {
    addPrescriptionRoutine,
    transferPrescriptionRoutine,
    addTransferPrescriptionRoutine,
    getFilePrescriptionRoutine,
    photoUploadRoutine,
    getFilePrescriberRoutine,
    getFilePharmacyRoutine,
    cancelPrescriptionRoutine
} from 'state/add-transfer-prescription/add-transfer-prescription.routines';
import { ProfileObjectPayload } from 'state/account/account.services';
import {
    accountProfileSelector,
    accountIsLoggedInSelector,
    accountHasInsuranceSelector
} from 'state/account/account.selectors';
import { addTransferPrescriptionSelector } from 'state/add-transfer-prescription/add-transfer-prescription.selectors';
import { baseEffectHandler } from 'util/sagas/sagas';
import { TrackFormSuccess, TrackError, TrackTransferObjectFlowComplete } from 'util/google_optimize/optimize_helper';

const delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

export default function* addTransferPrescriptionSaga() {
    yield takeLatest(addPrescriptionRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        yield put(addTransferPrescriptionRoutine(action.payload));
    });

    yield takeLatest(transferPrescriptionRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        yield put(transferPrescriptionRoutine(action.payload));
    });

    yield takeLatest(addTransferPrescriptionRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const profileObject: ProfileObjectPayload = yield select(accountProfileSelector);
        const transferObject: TransferPrescriptionPayload = yield select(addTransferPrescriptionSelector);

        const patientId =
            transferObject.DependentsEpostPatientNum !== undefined &&
            transferObject.DependentsEpostPatientNum?.length > 0
                ? transferObject.DependentsEpostPatientNum
                : profileObject.epostPatientNum;
        const isLoggedIn = yield select(accountIsLoggedInSelector);
        const accountHasInsurance: boolean = yield select(accountHasInsuranceSelector);

        // DRX-755: Pass along the drug name with the generic if part of the drugDisplayName.
        // The following code makes a "transferPayload" variable, removing DrugDisplayName, and
        // copying all of the transferObject with an updated DrugName if it's different than DrugDisplayName.
        let { DrugDisplayName, StrengthWithPackage, ...transferPayload } = {
            ...transferObject,
            DrugName:
                transferObject?.DrugDisplayName &&
                transferObject.DrugName.toUpperCase() !== transferObject.DrugDisplayName.toUpperCase()
                    ? transferObject.DrugDisplayName
                    : transferObject.DrugName,
            Strength: transferObject?.StrengthWithPackage ? transferObject.StrengthWithPackage : transferObject.Strength
        };
        const addTransferObject = { patientId: patientId, request: transferPayload };
        if (isLoggedIn) {
            yield baseEffectHandler({
                service: AddTransferPrescriptionService.transferRx().post,
                data: addTransferObject,
                *onResponse(data) {
                    yield put(addTransferPrescriptionRoutine.success(data));

                    const formName = transferObject.RxNumber ? 'TransferPrescription' : 'RequestPrescription';

                    TrackFormSuccess(formName);
                    TrackTransferObjectFlowComplete({
                        flowName: formName,
                        prescriptionName: transferObject.DrugDisplayName,
                        form: transferObject.DrugForm,
                        strength: transferObject.Strength,
                        quantity: transferObject.Qty,
                        accountHasInsurance: accountHasInsurance
                    });

                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess(transferPayload);
                },
                *onError(data) {
                    yield put(addTransferPrescriptionRoutine.failure(data));
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure(data);
                }
            });
        }
    });
    yield takeLatest(photoUploadRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn = yield select(accountIsLoggedInSelector);
        const {
            payload: { imageUrl, onSuccess, onFailure }
        } = action;
        if (isLoggedIn) {
            yield baseEffectHandler<any>({
                service: AddTransferPrescriptionService.photoUpload().post,
                data: imageUrl,
                *onResponse(data) {
                    yield call(delay, 3000);
                    yield baseEffectHandler<any>({
                        service: AddTransferPrescriptionService.getFileFetch().get,
                        data: data.data.body.replace(/"/g, '') + '.json',
                        *onResponse(result) {
                            const { imageUrl, medicationData, pharmacyData, prescriberData } = result;
                            if (medicationData) {
                                medicationData.imageUrl = imageUrl;
                            }
                            yield put(getFilePrescriptionRoutine.success(medicationData));
                            yield put(getFilePharmacyRoutine.success(pharmacyData));
                            yield put(getFilePrescriberRoutine.success(prescriberData));
                            if (onSuccess) onSuccess(data);
                        },
                        *onError(result) {
                            yield put(getFilePrescriptionRoutine.failure(result));
                        }
                    });
                },
                *onError(data) {
                    yield put(photoUploadRoutine.failure(data));
                    if (onFailure) onFailure();
                }
            });
        }
    });
    yield takeLatest(cancelPrescriptionRoutine.TRIGGER, function* (action: PayloadAction<CancelPrescriptionPayload>) {
        yield baseEffectHandler({
            service: AddTransferPrescriptionService.cancelRx().post,
            data: action.payload.rxNumber,
            *onResponse(response) {
                yield put(cancelPrescriptionRoutine.success(response));
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
            },
            *onError(error) {
                if (error.response?.data?.messageErrorText) {
                    // This is an attempt to catch errors passed back from the API.
                    TrackError(
                        'add-transfer-prescription.sagas.ts',
                        'cancelPrescriptionRoutine',
                        error.response.data.messageErrorText
                    );
                } else if (error.message) {
                    // This is an attempt to catch basic network errors.
                    TrackError('add-transfer-prescription.ts', 'cancelPrescriptionRoutine', error.message);
                }

                yield put(cancelPrescriptionRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });
}
