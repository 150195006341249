import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'ui-kit/checkbox';
import './fsa-hsa-checkbox.style.scss';

interface FsaHsaCheckboxProps {
    id: string;
    onClick: (checkboxValue: boolean) => void;
}

const FsaHsaCheckbox = ({ id, onClick }: FsaHsaCheckboxProps): ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="payment-type-box">
            <Checkbox id={id} label={t('modals.addPaymentModal.fsaHsaCard')} onCheckChanged={onClick} />
        </div>
    );
};

export default FsaHsaCheckbox;
