import 'styles/birdi-theme.scss';
import wrapWithProvider from './src/hoc/wrap-with-provider';
import { defineCustomElements } from '@duetds/date-picker/dist/loader';

export const wrapRootElement = wrapWithProvider;

export const onClientEntry = () => {
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (!(`IntersectionObserver` in window)) {
        import(`intersection-observer`);
        console.log(`# IntersectionObserver is polyfilled!`);
    }
    // Register Duet Date Picker
    defineCustomElements(window);
};

export const onRouteUpdate = () => {
    if (window.document) {
        window.document.body.classList.remove('scroll-lock');
    }
};
