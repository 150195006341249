import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import Button from 'ui-kit/button/button';
import './DashboardCtaBlock.style.scss';
import pillBottle from 'assets/icons/pillBottle.svg';
import dr from 'assets/icons/dr.svg';
import plane from 'assets/icons/plane.svg';
import sanitizeHtml from 'sanitize-html';

export interface DashboardCtaBlockButton {
    label: string;
    variant: 'primary' | 'outline-smoke' | 'secondary' | 'ghost' | 'dark' | undefined;
    onClick: () => void;
    dataGALocation?: string;
}

const mapIconSrc = (icon: DashboardCtaBlockProps['icon']) => {
    switch (icon) {
        case 'pill-bottle':
            return pillBottle;
        case 'dr':
            return dr;
        case 'plane':
            return plane;
    }
};

export interface DashboardCtaBlockProps {
    icon: 'pill-bottle' | 'dr' | 'plane';
    title: string;
    bodyText: string;
    ctas?: DashboardCtaBlockButton[];
    open?: boolean;
    onClick?: () => void;
}

const MobileCtaBlock: React.FC<DashboardCtaBlockProps> = ({ icon, title, bodyText, onClick }) => {
    return (
        <Container
            className={`dashboard-cta-block d-block d-sm-none d-xl-none d-xxl-none mobile-cta-block`}
            onClick={onClick}
        >
            <Row>
                <Col xs="12">
                    <div className="dashboard-cta-block__content">
                        <div className="d-flex flex-row">
                            <div className="dashboard-cta-block__icon mobile-icon">
                                <img src={mapIconSrc(icon)} alt={`Dashboard CTA Icon ${icon}`} />
                            </div>
                            <div className="d-flex flex-row">
                                <div
                                    className="dashboard-cta-block__header d-flex justify-content-between align-items-center"
                                    data-ga-location="Medicine Cabinet CTA Block"
                                >
                                    <h2 className="mt-2 h3 dashboard-cta-block__title font-weight-bold">{title}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

const DesktopCtaBlock: React.FC<DashboardCtaBlockProps> = ({ icon, title, bodyText, ctas, open = false }) => {
    return (
        <div className={`dashboard-cta-block d-none d-sm-block d-lg-block p-4 open`}>
            <Row className="">
                <Col xs="12">
                    <div className="dashboard-cta-block__content d-flex flex-column">
                        <div className="dashboard-cta-block__wrapper">
                            <div className="dashboard-cta-block__icon">
                                <img src={mapIconSrc(icon)} alt={`Dashboard CTA Icon ${icon}`} />
                            </div>
                            <div className={`dashboard-cta-block__body open`}>
                                <div className="dashboard-cta-block__body-text">
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: sanitizeHtml(bodyText, {
                                                allowedTags: ['strong']
                                            })
                                        }}
                                    />
                                </div>
                                <div className="dashboard-cta-block__ctas d-lg-none">
                                    {ctas?.map((cta, idx) => (
                                        <Button
                                            key={`dashboard-cta-block-${idx}`}
                                            {...cta}
                                            type={'button'}
                                            className="btn-bold"
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-cta-block__ctas mt-auto align-self-start d-none d-lg-block">
                            {ctas?.map((cta, idx) => (
                                <Button
                                    key={`dashboard-cta-block-${idx}`}
                                    {...cta}
                                    type={'button'}
                                    className="btn-bold"
                                />
                            ))}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default function DashboardCtaBlock({ icon, title, bodyText, ctas = [], open = false }: DashboardCtaBlockProps) {
    return (
        <>
            <MobileCtaBlock icon={icon} title={title} bodyText={bodyText} onClick={ctas[0].onClick} />
            <DesktopCtaBlock icon={icon} title={title} bodyText={bodyText} ctas={ctas} open={open} />
        </>
    );
}
