import { DRUPAL_BASE_URL } from 'gatsby-env-variables';
import axiosClient from 'util/axiosClient';

/**
 * Anything related to Drupal in the system.
 */
export default class DrupalService {
    static fetchCSRF(): any {
        return {
            get: () => {
                return axiosClient.get(`${DRUPAL_BASE_URL}/session/token`).then((response) => response.data);
            }
        };
    }
}
