import { cartState } from './cart.reducers';

/**
 * Returns the main cart from a list of carts based on the EpostPatientNum.
 *
 * @param {Array<cartState['cart']>} carts - The list of carts to search through.
 * @param {string | undefined} EpostPatientNum - The EpostPatientNum to match against.
 * @return {cartState['cart'] | undefined} The main cart that matches the EpostPatientNum, or undefined if no match is found.
 */
export const getMainCart = (carts: cartState['cart'], EpostPatientNum: string | undefined) => {
    const mainCart = carts?.filter((cart) => {
        return EpostPatientNum === cart.EpostPatientNum;
    });

    return mainCart && mainCart[0];
};

/**
 * Calculates the total order value for all items in the given carts.
 *
 * @param {cartState['cart']} carts - The array of carts containing the items.
 * @return {number} The total order value.
 */
export const getAllOrderTotal = (carts: cartState['cart']) => {
    return carts?.reduce((total, cart) => {
        return total + Number(cart?.Order?.orderTotal);
    }, 0);
};

/**
 * Calculates the total number of items in all the carts.
 *
 * @param {cartState['cart'][]} carts - The list of carts.
 * @return {number} The total number of items in all the carts.
 */
export const getAllItemsTotal = (carts: cartState['cart']) => {
    return carts?.reduce((total, cart) => {
        return total + cart?.Order.refillRxs?.length;
    }, 0);
};

export const getAllEpostsCarts = (carts: cartState['cart'], eposts: string[]) => {
    return carts?.filter((cart) => {
        return eposts.includes(cart.EpostPatientNum);
    });
};
