import sanitizeHtml from 'sanitize-html';
import AlertIcon from 'ui-kit/icons/alert/alert-icon';

import { DisclaimerProps } from './disclaimer.props';

import './disclaimer.style.scss';

const Disclaimer = ({ disclaimerText }: DisclaimerProps) => {
    return (
        <div className="disclaimer-container text-center">
            <div className="disclaimer-icon-container">
                <AlertIcon />
            </div>
            <p
                className="disclaimer"
                dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(disclaimerText, {
                        allowedTags: ['span', 'strong'],
                        allowedAttributes: {
                            span: ['class']
                        }
                    })
                }}
            />
        </div>
    );
};

export default Disclaimer;
