import { ReactElement } from 'react';

import PageSectionHeader from 'ui-kit/page-section-header/page-section-header';

import FeatureCard from 'components/feature-card';
import { FeatureCardProps } from 'components/feature-card/feature-card.props';

import './how-it-works-section.styles.scss';

interface HowItWorksSectionProps {
    featureCards: FeatureCardProps[];
    title: string;
    subtitle: string;
}

const HowItWorksSection = (props: HowItWorksSectionProps): ReactElement => {
    const { title, subtitle, featureCards } = props;

    return (
        <div className="section-how-it-works">
            <div className="section-how-it-works-header">
                <PageSectionHeader title={title} subTitle={subtitle} />
            </div>
            <div className="section-how-it-works-cards">
                {featureCards.map((cards) => (
                    <FeatureCard {...cards} key={cards.title} />
                ))}
            </div>
        </div>
    );
};

export default HowItWorksSection;
