import { SpinnerProps } from './spinner.props';
import './spinner.style.scss';

const Spinner = ({
    isVisible,
    t,
    fullOverlay = true,
    isSmall = false,
    className,
    isDotsSpinner = false
}: SpinnerProps) => {
    if (isDotsSpinner) {
        return (
            <div className={`spinner-container ${isVisible ? '' : 'd-none'}`}>
                <div className={`spinner-dots ${className ? className : ''}`}>{t('components.spinner.loading')}</div>
            </div>
        );
    }

    return (
        <div className={`${isVisible ? '' : 'd-none'} ${fullOverlay ? 'fullOverlay' : ''}`}>
            <div className={`spinner-border ${isSmall ? 'spinner-border-sm' : ''} ${className}`} role="status">
                <span className="sr-only">{t('components.spinner.loading')}</span>
            </div>
        </div>
    );
};

export default Spinner;
