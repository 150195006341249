import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

// Ui-
import Button from 'ui-kit/button/button';
import { Checkbox } from 'ui-kit/checkbox';
import AlertIcon from 'ui-kit/icons/alert/alert-icon';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import { accountFetchProfileRoutine, accountUpdateAutoRefill } from 'state/account/account.routines';
import { accountProfileSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

import { getPhoneNumber } from 'util/globalVariables';
import storageHelper from 'util/storageHelper';

import './auto-refill-body.styles.scss';

interface AutoRefillBodyProps {
    isModal?: boolean;
    onClose?: () => void;
}

const AutoRefillBody = ({ isModal = false, onClose }: AutoRefillBodyProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const profileObject = useSelector(accountProfileSelector);

    const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [isShowMore, setIsShowMore] = useState<boolean>(false);
    const [showError, setShowError] = useState<string>('');

    // DRX-3278: Integrate global autorefill state since accept terms
    // checkbox won't display for accounts with auto-refill true
    useEffect(() => {
        if (profileObject?.autoRefill) {
            setAcceptTerms(true);
        }
    }, [profileObject]);

    useEffect(() => {
        if (acceptTerms === true) {
            setShowError('');
        }
    }, [acceptTerms]);

    const handleShowMore = () => {
        setIsShowMore(!isShowMore);
    };

    const handleSubmit = () => {
        setIsBusy(true);
        if (!acceptTerms) {
            setIsBusy(false);
            setShowError('Error');
            return;
        }
        dispatch(
            accountUpdateAutoRefill.trigger({
                epostPatientNumber: profileObject?.epostPatientNum,
                autoRefillFlag: !profileObject?.autoRefill,
                onSuccess: () => {
                    dispatch(accountFetchProfileRoutine.trigger());
                    setIsBusy(false);
                    setAcceptTerms(false);

                    // only apply this logic for the modal prompts
                    if (isModal) {
                        storageHelper.local.setAutoRefillToggleFlag();
                    }

                    // only display success modal for toggling OFF auto refill
                    if (!profileObject?.autoRefill) {
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: (
                                    <BirdiModalContent
                                        title={t('pages.profile.autoRefill.successModal')}
                                        body={
                                            <div className="success-content-container">
                                                <p className="success-content-container-subtitle">
                                                    {t('pages.profile.autoRefill.successToggleTitle')}
                                                </p>
                                            </div>
                                        }
                                        icon={'default'}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t('pages.profile.autoRefill.successDoneBtn'),
                                        variant: 'primary',
                                        className: 'auto-refill-success-btn',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        },
                                        dataGALocation: 'AutoRefillToggleModalSuccess'
                                    }
                                ]
                            })
                        );
                    }
                },
                onFailure: (error: any) => {
                    setIsBusy(false);
                    setAcceptTerms(false);

                    dispatch(
                        openModal({
                            showClose: false,
                            type: 'danger',
                            size: 'lg',
                            onClose: () => dispatch(closeModal({})),
                            headerContent: (
                                <BirdiModalHeaderDanger
                                    headerText={t('components.membershipModals.errorTitle')}
                                    icon="alert"
                                />
                            ),
                            bodyContent: (
                                <BirdiModalContentAlt
                                    subTitle={t(`pages.medicineCabinet.messages.callbacks.autoRefillErrorMessage`)}
                                    note={t(`pages.medicineCabinet.messages.callbacks.errorMessageNote`, {
                                        phoneNumber: getPhoneNumber({ isEnd: true })
                                    })}
                                />
                            ),
                            ctas: [
                                {
                                    label: t(`pages.medicineCabinet.messages.labels.gotIt`),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    return (
        <div className="manage-auto-refill-container">
            <section>
                <p>{t('pages.profile.autoRefill.description')}</p>
                <Button
                    label={t('pages.profile.autoRefill.learnMore.label')}
                    type="button"
                    chevron={isShowMore ? 'up' : 'down'}
                    variant={'text'}
                    onClick={handleShowMore}
                    className="learn-more-dropdown"
                />
            </section>

            {isShowMore && (
                <section className="manage-auto-refill-container-show-more">
                    <p>{t('pages.profile.autoRefill.learnMore.description')}</p>

                    <ul>
                        <li>{t('pages.profile.autoRefill.learnMore.pointOne')}</li>
                        <li>{t('pages.profile.autoRefill.learnMore.pointTwo')}</li>
                        <li>{t('pages.profile.autoRefill.learnMore.pointThree')}</li>
                        <li>{t('pages.profile.autoRefill.learnMore.pointFour')}</li>
                        <li>{t('pages.profile.autoRefill.learnMore.pointFive')}</li>
                    </ul>
                    <p>{t('pages.profile.autoRefill.learnMore.pleaseNote')}</p>
                    <ul>
                        <li>{t('pages.profile.autoRefill.learnMore.note')}</li>
                    </ul>
                </section>
            )}
            <section className="manage-auto-refill-container-terms">
                <p>{t('pages.profile.autoRefill.autoRefillTC.label')}</p>
                <ol>
                    <li>{t('pages.profile.autoRefill.autoRefillTC.termsOne')}</li>
                    <li>{t('pages.profile.autoRefill.autoRefillTC.termsTwo')}</li>
                    <li>{t('pages.profile.autoRefill.autoRefillTC.termsThree')}</li>
                    <li>{t('pages.profile.autoRefill.autoRefillTC.termsFour')}</li>
                    <li>{t('pages.profile.autoRefill.autoRefillTC.termsFive')}</li>
                    <li
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                                t('pages.profile.autoRefill.autoRefillTC.termsSix', {
                                    phoneNumber: getPhoneNumber({ isEnd: true })
                                })
                            )
                        }}
                    />
                </ol>

                {profileObject?.autoRefill === false && (
                    <>
                        <Checkbox
                            label={t('pages.profile.autoRefill.autoRefillTC.checkboxLabel')}
                            id="manageAutoRefill"
                            name="manageAutoRefill"
                            onClick={(isChecked: boolean) => {}}
                            onCheckChanged={(isChecked: boolean) => {
                                setAcceptTerms(isChecked);
                            }}
                            className="checkbox-auto-refill"
                            defaultValue={acceptTerms}
                            hasError={showError !== ''}
                        />
                        {showError && (
                            <p className="autorefill__error">
                                {t('pages.profile.autoRefill.autoRefillTC.checkboxError')}
                            </p>
                        )}
                    </>
                )}
            </section>
            <section className="manage-auto-refill-container-alert">
                <AlertIcon variant="warning" />
                <p>{t('pages.profile.autoRefill.alert')}</p>
            </section>
            <div className={`d-flex autorefill-btn-container justify-content-center`}>
                <Button
                    async
                    isBusy={isBusy}
                    disabled={isBusy}
                    type={'button'}
                    label={
                        profileObject?.autoRefill
                            ? t('pages.profile.autoRefill.button.off')
                            : t('pages.profile.autoRefill.button.on')
                    }
                    onClick={handleSubmit}
                    variant={`${profileObject?.autoRefill ? 'outline-primary' : 'primary'}`}
                />
            </div>
            {isModal && (
                <div className={'d-flex justify-content-center'}>
                    <Button
                        label={t('pages.profile.autoRefill.cancelModal')}
                        type="button"
                        variant={'text'}
                        onClick={onClose}
                        className="cancel-button-auto-refill"
                    />
                </div>
            )}
        </div>
    );
};

export default AutoRefillBody;
