import React from 'react';
import sanitizeHtml from 'sanitize-html';

import './ListIcon.style.scss';

export interface ListIconProps {
    icon: JSX.Element;
    subtitle: string;
    bodyText: string;
    bodyClassName: string;
}

export default function ListIcon({ icon, subtitle, bodyText, bodyClassName }: ListIconProps) {
    return (
        <div className="list-icon justify-content-center mb-2">
            <div className="list-icon__icon">{icon}</div>
            <div className="h6 list-icon__title">{subtitle}</div>
            <p
                className={`body-text ${bodyClassName ? bodyClassName : ''}`}
                dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(bodyText, {
                        allowedAttributes: {
                            a: ['id', 'href', 'name', 'rel', 'target', 'data-*'],
                            '*': ['class']
                        }
                    })
                }}
            />
        </div>
    );
}
