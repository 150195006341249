import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

import { isRxOnHold, isRxOrderedInProgress } from 'util/prescription';

export const MedicineCabinetSelector = (state: RootState) => state.medicineCabinetReducer;

export const medicineCabinetPrescriptionsSelector = createSelector(MedicineCabinetSelector, (medicineCabinet) =>
    medicineCabinet.currentPrescriptions ? medicineCabinet.currentPrescriptions : []
);

export const medicineShowNewPrescriptionModalSelector = createSelector(
    MedicineCabinetSelector,
    (medicineCabinet) => medicineCabinet.showNewPrescriptionModal
);

export const medicineCabinetFilteredRxsForStatusSelector = createSelector(
    medicineCabinetPrescriptionsSelector,
    (currentPrescriptions) => {
        return currentPrescriptions.filter((rx) => !isRxOnHold(rx) && isRxOrderedInProgress(rx));
    }
);

export const medicineCabinetAutoRefillPlanEligibleSelector = createSelector(
    medicineCabinetPrescriptionsSelector,
    (currentPrescriptions) => {
        return currentPrescriptions.some((prescription) => prescription.autorefillPlanEligible);
    }
);

export const medicineCabinetShowToggleAutoRefillAllSelector = createSelector(
    [medicineCabinetAutoRefillPlanEligibleSelector, medicineCabinetPrescriptionsSelector],
    (autoRefillPlanEligible, currentPrescriptions) => {
        return autoRefillPlanEligible && currentPrescriptions.some((prescription) => prescription.autoRefillEligible);
    }
);

export const medicineCabinetAutoRefillRxsSelector = createSelector(
    medicineCabinetPrescriptionsSelector,
    (currentPrescriptions) => {
        const eligibleRxs = currentPrescriptions.filter((prescription) => prescription.autoRefillEligible);
        return eligibleRxs.map((rx) => rx.rxNumber);
    }
);

export const medicineCabinetAutoRefillToggleAllSelector = createSelector(
    medicineCabinetPrescriptionsSelector,
    (currentPrescriptions) => {
        const eligibleRxs = currentPrescriptions.filter((prescription) => prescription.autoRefillEligible);
        const rxsAutoRefillEnabled = eligibleRxs.filter((rx) => rx.autoRefillEnabled);
        return rxsAutoRefillEnabled.length === eligibleRxs.length;
    }
);

export const medicineCabinetAutoRefillToggleDisabledSelector = createSelector(
    [medicineCabinetAutoRefillPlanEligibleSelector, medicineCabinetPrescriptionsSelector],
    (autoRefillPlanEligible, currentPrescriptions) => {
        return autoRefillPlanEligible && currentPrescriptions.every((prescription) => !prescription.autoRefillEligible);
    }
);

export const medicineCabinetAutoRefillToggleBusySelector = createSelector(
    MedicineCabinetSelector,
    (medicineCabinet) => medicineCabinet.autoRefillToggleBusy
);

export const medicineCabinetPopulatedSelector = createSelector(
    MedicineCabinetSelector,
    (medicineCabinet) => medicineCabinet.medicineCabinetLoaded
);

export const medicineCabinetRxLoadedSelector = createSelector(
    MedicineCabinetSelector,
    (medicineCabinet) => medicineCabinet.showNewPrescriptionModal.isRxLoaded
);

export const medicineCabinetActiveTabSelector = createSelector(
    MedicineCabinetSelector,
    (medicineCabinet) => medicineCabinet.medicineCabinetActiveTab || ''
);

export const medicineCabinetFilterTabSelector = createSelector(
    MedicineCabinetSelector,
    (medicineCabinet) => medicineCabinet.medicineCabinetFilterTab || 'all'
);

export const medicineCabinetIsBusySelector = createSelector(
    MedicineCabinetSelector,
    (medicineCabinet) => medicineCabinet.isBusy || false
);

/**
 * Selects the prescriptions that are eligible for discount price.
 *
 * @param {object} medicineCabinetPrescriptionsSelector The state of the medicine cabinet prescriptions.
 * @return {array} The prescriptions that are eligible for auto refill but not enabled.
 */
export const medicineCabinetDiscountPriceEligiblePrescriptionsSelector = createSelector(
    medicineCabinetPrescriptionsSelector,
    (currentPrescriptions) => {
        const result = currentPrescriptions
            .filter(function (rx) {
                return (
                    rx.inOrderCart === true ||
                    rx.webEligibilityStatus === 'ELIGIBLE' ||
                    rx.webEligibilityStatus === 'AUTH_REQ'
                );
            })
            .filter(function (rx) {
                return !!(rx.dispensedProductNumber || rx.writtenProductNumber);
            });
        return result;
    }
);
