import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { TooltipProps } from 'ui-kit/tooltip/tooltip.props';

import './tooltip.style.scss';
import QuestionTooltipIcon from 'ui-kit/icons/question-tooltip-icon/question-tooltip-icon';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ReactTooltip from 'react-bootstrap/Tooltip';

const Tooltip = ({ className, tip, style, id = 'tooltip' }: TooltipProps) => {
    const classes = classNames('myb-tooltip', className);
    const { t } = useTranslation();

    return (
        <div className={classes} style={style}>
            <OverlayTrigger placement="auto" overlay={<ReactTooltip id={id}>{tip}</ReactTooltip>}>
                <button type="button" aria-label={t('components.tooltip.buttonText')}>
                    <QuestionTooltipIcon />
                </button>
            </OverlayTrigger>
        </div>
    );
};

export default Tooltip;
