import React from 'react';
import { graphql } from 'gatsby';
import { IconGridProps } from 'components/icon-grid/IconGrid';
import IconGrid from 'components/icon-grid/IconGrid';
import GridIcon from 'components/icon-grid/GridIcon/GridIcon';

export default function IconGridComponentProvider({
    paragraph,
    sectionIndex
}: {
    paragraph: GatsbyTypes.IconGridComponentProviderFragment;
    sectionIndex: number;
}) {
    const iconGridProps: IconGridProps = {
        sectionIndex: sectionIndex,
        eyebrowText: paragraph.field_ig_eyebrow,
        title: paragraph.field_ig_title,
        subtitle: paragraph.field_ig_subtitle,
        cta: {
            linkText: paragraph.field_ig_cta_link?.title,
            linkUri: paragraph.field_ig_cta_link?.uri
        },
        image: paragraph.relationships?.field_ig_image?.relationships?.field_media_image?.localFile,
        imageAltText: paragraph.relationships?.field_ig_image?.field_media_image?.alt,
        children: paragraph.relationships?.field_ig_grid_icons
            ? paragraph.relationships?.field_ig_grid_icons?.map((icons, index) => {
                  return (
                      <GridIcon
                          key={`icongrid-icon-${sectionIndex}-${index}`}
                          icon={
                              icons?.relationships?.field_ig_icon ? (
                                  <img
                                      src={
                                          icons?.relationships?.field_ig_icon?.relationships?.field_media_svg?.localFile
                                              ?.publicURL
                                      }
                                      alt={icons?.relationships?.field_ig_icon?.field_media_svg?.alt}
                                  />
                              ) : (
                                  ''
                              )
                          }
                          subtitle={icons?.field_ig_icon_subtitle}
                          bodyText={icons?.field_ig_icon_body}
                          sectionIndex={sectionIndex}
                      />
                  );
              })
            : []
    };
    return <IconGrid {...iconGridProps} />;
}

export const query = graphql`
    fragment IconGridComponentProvider on paragraph__icon_grid_component {
        field_ig_title
        field_ig_subtitle
        field_ig_eyebrow
        field_ig_background_color
        field_ig_cta_link {
            title
            uri: url
        }
        relationships {
            field_ig_image {
                relationships {
                    field_media_image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(formats: [AUTO, WEBP])
                            }
                        }
                    }
                }
                field_media_image {
                    alt
                }
            }
            field_ig_grid_icons {
                field_ig_icon_body
                field_ig_icon_subtitle
                relationships {
                    field_ig_icon {
                        relationships {
                            field_media_svg {
                                localFile {
                                    publicURL
                                }
                            }
                        }
                        field_media_svg {
                            alt
                        }
                    }
                }
            }
        }
    }
`;
