import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

import { Allergies, MedicalConditions } from 'types/medical-conditions';

import {
    medicalConditionsAddOrUpdateRoutine,
    medicalConditionsAllergiesAddOrUpdateRoutine,
    medicalConditionsAllergiesDetailsRoutine,
    medicalConditionsDetailsRoutine
} from './medical-conditions.routines';

export type MedicalConditionsResponse = MedicalConditions;
export type AllergiesResponse = Allergies;

export type MedicalConditionsState = {
    healthConditions: {
        medicalConditions: MedicalConditionsResponse;
        allergies: AllergiesResponse;
    };
    medicalConditions?: MedicalConditionsResponse;
    allergies?: AllergiesResponse;
    isLoadingHealthConditions: boolean;
    isLoadingAllergies: boolean;
};

const initialState: MedicalConditionsState = {
    isLoadingHealthConditions: false,
    isLoadingAllergies: false,
    healthConditions: {
        medicalConditions: {
            Asthma: false,
            COPD: false,
            Cholesterol: false,
            Diabetes: false,
            Glaucoma: false,
            Heart: false,
            Hypertension: false,
            Kidney: false,
            Stroke: false,
            Thyroid: false,
            None: false,
            Other: '',
            TemporaryOther: '',
            ePostPatientNum: ''
        },
        allergies: {
            None: false,
            Tetracyclines: false,
            Penicillin: false,
            Cephalosporins: false,
            Ampicillin: false,
            Sulfa: false,
            Erythromycin: false,
            Aspirin: false,
            Codeine: false,
            NSAIDs: false,
            Quinolones: false,
            Other: '',
            TemporaryOther: '',
            ePostPatientNum: ''
        }
    }
};

const medicalConditionsSlice = createSlice({
    name: 'medicalConditions',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(medicalConditionsDetailsRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingHealthConditions = true;
            })
        );
        addCase(
            medicalConditionsDetailsRoutine.SUCCESS,
            (state, { payload }: PayloadAction<MedicalConditionsResponse>) =>
                produce(state, (draftState) => {
                    draftState.healthConditions.medicalConditions = payload;
                    draftState.isLoadingHealthConditions = false;
                })
        );
        addCase(medicalConditionsDetailsRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.medicalConditions = undefined;
                draftState.isLoadingHealthConditions = false;
            })
        );
        addCase(medicalConditionsAddOrUpdateRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.medicalConditions = undefined;
                draftState.isLoadingHealthConditions = true;
            })
        );
        addCase(medicalConditionsAddOrUpdateRoutine.SUCCESS, (state, { payload }: PayloadAction<MedicalConditions>) =>
            produce(state, (draftState) => {
                draftState.healthConditions.medicalConditions = payload;
                draftState.isLoadingHealthConditions = false;
            })
        );
        addCase(medicalConditionsAddOrUpdateRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.medicalConditions = undefined;
                draftState.isLoadingHealthConditions = false;
            })
        );
        addCase(medicalConditionsAllergiesDetailsRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.allergies = undefined;
                draftState.isLoadingHealthConditions = true;
            })
        );
        addCase(
            medicalConditionsAllergiesDetailsRoutine.SUCCESS,
            (state, { payload }: PayloadAction<AllergiesResponse>) =>
                produce(state, (draftState) => {
                    draftState.healthConditions.allergies = payload;
                    draftState.isLoadingAllergies = false;
                })
        );
        addCase(medicalConditionsAllergiesDetailsRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.allergies = undefined;
                draftState.isLoadingAllergies = false;
            })
        );
        addCase(medicalConditionsAllergiesAddOrUpdateRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.allergies = undefined;
                draftState.isLoadingAllergies = true;
            })
        );
        addCase(medicalConditionsAllergiesAddOrUpdateRoutine.SUCCESS, (state, { payload }: PayloadAction<Allergies>) =>
            produce(state, (draftState) => {
                draftState.healthConditions.allergies = payload;
                draftState.isLoadingAllergies = false;
            })
        );
        addCase(medicalConditionsAllergiesAddOrUpdateRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.allergies = undefined;
                draftState.isLoadingAllergies = false;
            })
        );
    }
});

export const medicalConditionsActions = medicalConditionsSlice.actions;
export default medicalConditionsSlice.reducer;
