import { caregiverStatusMap } from 'state/family-profile/family-profile-helpers';
import { UnauthorizedCaregiver } from 'types/family-account';
import format from 'date-fns/format';

// DRX-1809: This function returns the specific date based on the account status for the unauth flow.
export function getLastCaregiverUpdateDate(caregiver: UnauthorizedCaregiver): string {
    const accountStatus =
        caregiverStatusMap.get(caregiver.releventDependentInformation.accountStatus) ?? 'responsePending';
    let lastUpdateDate;

    if (accountStatus === 'responsePending' || accountStatus === 'expired' || accountStatus === 'declined') {
        lastUpdateDate = format(new Date(caregiver.releventDependentInformation.dateRequested), 'MM/dd/yyyy');
    } else {
        lastUpdateDate = format(new Date(caregiver.caregiver.dateGrantedAccess), 'MM/dd/yyyy');
    }

    return lastUpdateDate;
}
