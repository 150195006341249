import axios from 'axios';
import aws4 from 'aws4';
import AWS from 'aws-sdk/global';
import { DRUG_LOOKUP_API_BASE_URL, DRUG_LOOKUP_API_PATH } from 'gatsby-env-variables';

const buildRequest = (path: string) => {
    const credentials = AWS.config.credentials;
    const credentials_keys = {
        secretAccessKey: credentials?.secretAccessKey,
        accessKeyId: credentials?.accessKeyId,
        sessionToken: credentials?.sessionToken
    };

    let opts = {
        host: DRUG_LOOKUP_API_BASE_URL,
        method: 'GET',
        url: `https://${DRUG_LOOKUP_API_BASE_URL}/${DRUG_LOOKUP_API_PATH}/${path}`,
        path: `/${DRUG_LOOKUP_API_PATH}/${path}`
    };

    let signedRequest = aws4.sign(opts, credentials_keys);

    // Remove unsafe Host parameter
    delete signedRequest.headers.Host;
    return signedRequest;
};

export const axiosClientAws = (path: string) => {
    return axios(buildRequest(path));
};

export default axiosClientAws;
