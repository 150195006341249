import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

import {
    AuthenticateUserResponse,
    JoinMembershipPlanInvitationData,
    JoinMembershipPlanInvitationResponse,
    VerifyUserResponse
} from 'types/join-membership-plan';
import {
    joinMembershipPlanAuthenticateUserRoutine,
    joinMembershipPlanGetInvitesRoutine,
    joinMembershipPlanGetSecretKeyHashRoutine,
    joinMembershipPlanVerifyUserRoutine
} from './join-membership-plan.routines';

export interface JoinMembershipPlanState {
    invitationList: JoinMembershipPlanInvitationData[];
    userVerified: 'UserNotVerified' | 'UserVerified' | 'MaxAttemptsReached' | 'TokenExpired' | 'TokenValid';
    securityToken: string;
    secretKeyHash: string;
    bearerToken: string;
    isLoading: boolean;
    error: {
        messageText?: string;
    };
}

const initialState: JoinMembershipPlanState = {
    invitationList: [],
    userVerified: 'UserNotVerified',
    securityToken: '',
    secretKeyHash: '',
    bearerToken: '',
    isLoading: true,
    error: {
        messageText: undefined
    }
};

const joinMembershipPlanSlice = createSlice({
    name: 'family-account',
    initialState,
    reducers: {
        setSecurityToken: (state, action: PayloadAction<{ securityToken: string }>) => {
            state.securityToken = action.payload.securityToken;
        },
        resetBearerToken: (state) => {
            state.bearerToken = initialState.bearerToken;
        }
    },
    extraReducers: ({ addCase }) => {
        /**
         * Join Membership Plan reducers
         */
        // Verify User
        addCase(joinMembershipPlanVerifyUserRoutine.SUCCESS, (state, { payload }: PayloadAction<VerifyUserResponse>) =>
            produce(state, (draftState) => {
                draftState.userVerified = payload.verificationStatus;
            })
        );
        addCase(
            joinMembershipPlanVerifyUserRoutine.FAILURE,
            (state, { payload }: PayloadAction<{ messageText: string }>) =>
                produce(state, (draftState) => {
                    draftState.error.messageText = payload.messageText;
                })
        );
        // Getting user secret key hash for authentication
        addCase(joinMembershipPlanGetSecretKeyHashRoutine.SUCCESS, (state, { payload }: PayloadAction<string>) =>
            produce(state, (draftState) => {
                draftState.secretKeyHash = payload;
            })
        );
        addCase(joinMembershipPlanGetSecretKeyHashRoutine.FAILURE, (state, { payload }: PayloadAction<string>) =>
            produce(state, (draftState) => {
                draftState.error.messageText = payload;
            })
        );
        // authenticate user
        addCase(
            joinMembershipPlanAuthenticateUserRoutine.SUCCESS,
            (state, { payload }: PayloadAction<AuthenticateUserResponse>) =>
                produce(state, (draftState) => {
                    draftState.bearerToken = payload.bearerToken;
                })
        );
        addCase(
            joinMembershipPlanAuthenticateUserRoutine.FAILURE,
            (state, { payload }: PayloadAction<AuthenticateUserResponse>) =>
                produce(state, (draftState) => {
                    draftState.bearerToken = initialState.bearerToken;
                    draftState.error.messageText = payload.messageText;
                })
        );
        // Get Invites
        addCase(joinMembershipPlanGetInvitesRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoading = true;
            })
        );
        addCase(
            joinMembershipPlanGetInvitesRoutine.SUCCESS,
            (state, { payload }: PayloadAction<JoinMembershipPlanInvitationResponse>) =>
                produce(state, (draftState) => {
                    draftState.isLoading = false;
                    draftState.invitationList = payload.invites;
                })
        );
        addCase(
            joinMembershipPlanGetInvitesRoutine.FAILURE,
            (state, { payload }: PayloadAction<{ messageText: string }>) =>
                produce(state, (draftState) => {
                    draftState.isLoading = false;
                    draftState.error.messageText = payload.messageText;
                })
        );
    }
});

export const joinMembershipPlanActions = joinMembershipPlanSlice.actions;

export default joinMembershipPlanSlice.reducer;
