import * as yup from 'yup';

import { validatePhoneNumber } from 'schema/validation/phone-number';

export const CONTACT_US_SCHEMA = (countryCode: 'US' | 'CA') =>
    yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        email: yup.string().email('invalid').required('required'),
        phoneNumber: yup
            .string()
            .test(
                'Phone Number valiation',
                (value: string | undefined, context: yup.TestContext<Record<string, any>>) =>
                    validatePhoneNumber(value, context, countryCode)
            ),
        message: yup.string().required()
    });
