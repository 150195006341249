import classNames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import ChevronThinIcon from 'ui-kit/icons/chevron-thin-icon.tsx/chevron-thin-icon';

import { accountProfilEPostPatientNumSelector, accountProfileSelector } from 'state/account/account.selectors';
import { Dependent } from 'state/family-profile/family-profile.reducers';

import { lowercaseAndCapitalize } from 'util/cart';

import './family-tabs.style.scss';

type Dependents = {
    ePostPatientNum: string;
    familyMemberFirstName: string;
};

type FamilyTabsProps = {
    activeTab: string | undefined;
    onTabItemChange: (dependent: string) => void;
    dependents: Dependent[];
};

const FamilyTabs: React.FC<FamilyTabsProps> = ({ activeTab, onTabItemChange, dependents }) => {
    const caregiverEpostPatientNum = useSelector(accountProfilEPostPatientNumSelector);
    const profileObject = useSelector(accountProfileSelector);
    const [positionCarousel, setPositionCarousel] = useState(0);
    const carouselSplit = positionCarousel === 0 ? positionCarousel + 3 : positionCarousel + 4;
    const carouselSplitMobile = positionCarousel === 0 ? positionCarousel + 1 : positionCarousel + 2;

    if (!dependents) return;

    const caregiverMember: Dependents = {
        familyMemberFirstName: `${profileObject?.patientFirstName} (You)`,
        ePostPatientNum: caregiverEpostPatientNum || ''
    };

    const updatedDependents = [caregiverMember, ...dependents];

    const prevAction = () => {
        setPositionCarousel(positionCarousel > 0 ? positionCarousel - 1 : 0);
    };

    const nextActionDesktop = () => {
        setPositionCarousel(positionCarousel < dependents.length - 3 ? positionCarousel + 1 : dependents.length - 3);
    };

    const nextActionMobile = () => {
        setPositionCarousel(positionCarousel < dependents.length - 2 ? positionCarousel + 1 : dependents.length - 2);
    };

    return (
        <div className="family-tabs">
            <ul
                className={classNames(
                    'tab-list d-none d-md-flex',
                    dependents.length > 3
                        ? 'tab-list-carousel'
                        : dependents.length > 0
                        ? 'tab-list-carousel-disabled'
                        : ''
                )}
                role="tablist"
            >
                {positionCarousel > 0 ? (
                    <li
                        key="tab-item-prev"
                        className="tab-item chevron-tab-icon tab-arrow-prev"
                        role="tab"
                        aria-hidden="true"
                        onClick={prevAction}
                    >
                        <ChevronThinIcon direction={'left'} />
                    </li>
                ) : (
                    <li
                        key={`tab-item-everyone`}
                        className={classNames('tab-item', activeTab ? 'inactive' : 'active')}
                        role="tab"
                        aria-hidden="true"
                        onClick={() => onTabItemChange('')}
                    >
                        Everyone
                    </li>
                )}
                {updatedDependents.slice(positionCarousel, carouselSplit).map((dependent, index) => (
                    <li
                        key={`tab-item-${index}`}
                        className={classNames(
                            'tab-item',
                            activeTab && activeTab === dependent.ePostPatientNum ? 'active' : 'inactive'
                        )}
                        role="tab"
                        aria-hidden="true"
                        onClick={() => onTabItemChange(dependent.ePostPatientNum)}
                    >
                        {lowercaseAndCapitalize(dependent.familyMemberFirstName)}
                    </li>
                ))}
                {positionCarousel < dependents.length - 3 && dependents.length > 3 ? (
                    <li
                        key="tab-item-next"
                        className="tab-item chevron-tab-icon tab-arrow-next"
                        role="tab"
                        aria-hidden="true"
                        onClick={nextActionDesktop}
                    >
                        <ChevronThinIcon direction={'right'} />
                    </li>
                ) : (
                    <li className="last-tab"></li>
                )}
            </ul>
            <ul
                className={classNames(
                    'tab-list d-flex d-md-none',
                    dependents.length > 2
                        ? 'tab-list-carousel'
                        : dependents.length > 0
                        ? 'tab-list-carousel-disabled'
                        : '',
                    positionCarousel > 0 ? 'has-arrow' : ''
                )}
                role="tablist"
            >
                {positionCarousel > 0 ? (
                    <li
                        key="tab-item-prev-1"
                        className="tab-item chevron-tab-icon tab-arrow-prev-mobile"
                        role="tab"
                        aria-hidden="true"
                        onClick={prevAction}
                    >
                        <ChevronThinIcon direction={'left'} />
                    </li>
                ) : (
                    <li
                        key={`tab-item-everyone`}
                        className={classNames('tab-item', activeTab ? 'inactive' : 'active')}
                        role="tab"
                        aria-hidden="true"
                        onClick={() => onTabItemChange('')}
                    >
                        Everyone
                    </li>
                )}
                {updatedDependents.slice(positionCarousel, carouselSplitMobile).map((dependent, index) => (
                    <li
                        key={`tab-item-mobile-${index}`}
                        className={classNames(
                            'tab-item',
                            activeTab && activeTab === dependent.ePostPatientNum ? 'active' : 'inactive',
                            positionCarousel > 0 ? 'has-arrow' : 'first-row'
                        )}
                        role="tab"
                        aria-hidden="true"
                        onClick={() => onTabItemChange(dependent.ePostPatientNum)}
                    >
                        {lowercaseAndCapitalize(dependent.familyMemberFirstName)}
                    </li>
                ))}
                {positionCarousel < dependents.length - 2 && dependents.length > 2 ? (
                    <li
                        key="tab-item-next-1"
                        className="tab-item chevron-tab-icon tab-arrow-next"
                        role="tab"
                        aria-hidden="true"
                        onClick={nextActionMobile}
                    >
                        <ChevronThinIcon direction={'right'} />
                    </li>
                ) : (
                    <li key="tab-item-last" className="last-tab"></li>
                )}
            </ul>
        </div>
    );
};

export default FamilyTabs;
