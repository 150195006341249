import withOnKeyDown from 'hoc/withOnKeyDown';
import React, { ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import './index.style.scss';
import { CheckboxIconProps, CheckboxProps } from './checkbox.props';
import { TrackInputFocus } from 'util/google_optimize/optimize_helper';
import { useField } from 'formik';

const CheckboxSvg = withOnKeyDown(({ defaultChecked, disabled, onFocus, onKeyDown }: CheckboxIconProps<boolean>) => {
    const [isChecked, setIsChecked] = useState<boolean | undefined>(false);
    const classes = classNames('checkbox-symbol', { checked: isChecked });

    const handleClick = () => {
        if (disabled) return;
        setIsChecked(!isChecked);
    };

    useEffect(() => {
        setIsChecked(defaultChecked);
    }, [defaultChecked]);

    return (
        <svg
            className={classes}
            onClick={handleClick}
            onFocus={onFocus}
            onKeyDown={(e: React.KeyboardEvent<HTMLOrSVGElement>) =>
                onKeyDown ? onKeyDown(e, handleClick) : undefined
            }
            role="button"
            tabIndex={0}
        >
            <symbol id="check" className="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </symbol>
        </svg>
    );
});

export const Checkbox = ({ id, className, defaultValue, disabled, onCheckChanged, label, hasError }: CheckboxProps) => {
    const [isChecked, setIsChecked] = useState<boolean | undefined>(defaultValue);
    const classes = classNames('checkbox-container', className, { disabled }, { checked: isChecked });
    const handleClick = (checked?: boolean) => {
        if (disabled) return;
        setIsChecked(checked);
    };
    const handleFocus = (event: FocusEvent) => {
        TrackInputFocus(event.target, label);
    };

    useEffect(() => {
        setIsChecked(defaultValue);
    }, [defaultValue]);

    return (
        <>
            <CheckboxSvg id={id} onClick={handleClick} onCheckChanged={onCheckChanged} onFocus={handleFocus} />
            <div className={classes}>
                <input
                    className="checkbox-input"
                    id={id}
                    type="checkbox"
                    disabled={disabled}
                    checked={isChecked}
                    onChange={(val) => onCheckChanged && onCheckChanged(val.target.checked)}
                    onClick={(event) => event.stopPropagation()}
                />
                <label className={`checkbox ${hasError ? 'form-error' : ''}`} htmlFor={id}>
                    <span>
                        <svg width="12px" height="10px">
                            <use xlinkHref="#check"></use>
                        </svg>
                    </span>
                    <span>{label}</span>
                </label>
            </div>
        </>
    );
};

export const CustomFormCheckbox = (props: any): ReactElement => {
    const [field, meta] = useField(props);

    return (
        <>
            <div className="custom-checkbox">
                <div className={`${meta.touched && meta.error ? 'error' : ''}`}>
                    <label className="checkbox-label">
                        <input {...field} {...props} type="checkbox" />
                        <span>{props.label}</span>
                    </label>
                </div>
            </div>
            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
        </>
    );
};
