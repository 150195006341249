/* eslint-disable no-unused-vars */
import { Dispatch } from '@reduxjs/toolkit';
import { TFunction } from 'gatsby-plugin-react-i18next';

export enum ConfirmationStatuses {
    NoChange = 0,
    Expired = 1,
    Confirmed = 2,
    Denied = 3
}

export interface VerifySmsModalProps {
    valuesToSubmit: any;
    t: TFunction<'translation'>;
    dispatch: Dispatch;
    resetContactPreferences: () => void;
}
