import classNames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import sanitizeHtml from 'sanitize-html';

import StoreBadge from 'ui-kit/bagde/badge-stores/badge-store';
import Button from 'ui-kit/button/button';
import SocialMediaIcon from 'ui-kit/icons/social-media-icon/social-media-icon';
import Link from 'ui-kit/link/link';
import Logo from 'ui-kit/logo/logo';

import { FooterProps } from 'components/footer/footer.props';
import { NavPath } from 'components/navigation/navigation.props';

import useWindowDimensions from 'hooks/useWindowDimensions';

import './footer.style.scss';

interface FooterNavPathLinkProps {
    path: NavPath;
}

interface FooterSectionProps {
    className?: string;
    contentClassName?: string;
    title?: string;
    withAccordion?: boolean;
}

interface FooterAddressProps {
    mailingAddress?: string;
    physicalAddress?: string;
    addressTitle?: string;
}

interface FooterNavPathSectionProps {
    paths?: ReadonlyArray<NavPath>;
    title?: string;
    withAccordion?: boolean;
}

// --- Helper Components ---
const FooterNavPathLink: React.FC<FooterNavPathLinkProps> = (props) => {
    const { path } = props;
    const { link, label, field_menu_body } = path;

    if (link?.url) {
        return <Link className="link-demi" dataGALocation="FooterNav" label={label} to={link.url} variant="footer" />;
    }

    return (
        <div className="footer-contact">
            <div className="footer-col-title">{label}</div>
            <div
                className="footer-col-content"
                dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(field_menu_body?.processed ? field_menu_body.processed : label, {
                        allowedAttributes: {
                            a: ['id', 'href', 'name', 'rel', 'target', 'data-*'],
                            '*': ['class', 'data-*']
                        }
                    })
                }}
            />
        </div>
    );
};

const FooterSection: React.FC<FooterSectionProps> = (props) => {
    const { children, className = '', contentClassName = '', title, withAccordion = true } = props;
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleAccordion = () => {
        setIsExpanded(!isExpanded);
    };

    const { width } = useWindowDimensions();

    useEffect(() => {
        if (width >= 768) {
            setIsExpanded(true);
        } else {
            setIsExpanded(false);
        }
    }, [width]);

    return withAccordion ? (
        <div className="col-section">
            <div className={classNames('withAccordion', { expanded: isExpanded })}>
                {title && <div className={classNames('col-section-title', className)}>{title}</div>}
                <Button
                    className="col-section-btn btn-demi no-min-width"
                    variant="text-blue-light"
                    type="button"
                    chevron={isExpanded ? 'up' : 'down'}
                    label=""
                    onClick={toggleAccordion}
                />
            </div>
            {isExpanded && (
                <div className={classNames(contentClassName ? contentClassName : 'col-section-content')}>
                    {children}
                </div>
            )}
        </div>
    ) : (
        <div className="col-section">
            {title && <div className={classNames('col-section-title', className)}>{title}</div>}

            <div className={classNames(contentClassName ? contentClassName : 'col-section-content')}>{children}</div>
        </div>
    );
};

const FooterAddressLine: React.FC<FooterAddressProps> = (props) => {
    const { t } = useTranslation();
    const { mailingAddress = '', physicalAddress = '', addressTitle = '' } = props;

    return (
        <div className="legal-section-addresses-container">
            <div className="legal-section-addresses-container-title">{addressTitle} &nbsp;</div>
            <div className="legal-section-addresses-container-addresses">
                <span>{`${t('menus.footer.addresses.mailing.title')} ${mailingAddress}`}</span>
                <span> &nbsp;{` | `}&nbsp;</span>
                <span>{`${t('menus.footer.addresses.physical.title')} ${physicalAddress}`}</span>
            </div>
        </div>
    );
};

const FooterNavPathSection: React.FC<FooterNavPathSectionProps> = (props) => {
    const { paths = [], title, withAccordion } = props;

    if (!Array.isArray(paths) || !paths.length) {
        return null;
    }

    return (
        <FooterSection title={title} withAccordion={withAccordion}>
            <>
                {paths.map((path: NavPath, key) => (
                    <FooterNavPathLink key={`footer-company-path-${path.label}-${key}`} path={path} />
                ))}
            </>
        </FooterSection>
    );
};

const Footer = ({
    copyrightYear,
    aboutUsPaths,
    howCanWeHelpPaths,
    socialMediaPaths,
    legalPaths,
    solutionsPaths,
    getAppPaths,
    contactPaths,
    groupsPaths,
    mailingAddress,
    physicalAddress,
    specialtyPhysicalAddress,
    specialtyMailingAddress,
    isShortFooter = false
}: FooterProps) => {
    const { t } = useTranslation();
    const hasSocialMediaPaths = Array.isArray(socialMediaPaths) && socialMediaPaths.length > 0;
    const hasGetAppPaths = Array.isArray(getAppPaths) && getAppPaths.length > 0;

    return (
        <footer>
            <Container fluid="xl" className="content">
                <div className="brand-section">
                    <Logo variant="primary" />
                </div>

                {!isShortFooter && (
                    <div className="links-section d-print-none">
                        <div className="contact">
                            <FooterNavPathSection withAccordion={false} paths={contactPaths} />
                        </div>

                        <>
                            <FooterNavPathSection
                                withAccordion={true}
                                paths={howCanWeHelpPaths}
                                title={t('menus.footer.support.title')}
                            />
                            <div className="solutions">
                                <FooterNavPathSection
                                    withAccordion={true}
                                    paths={solutionsPaths}
                                    title={t('menus.footer.company.title')}
                                />
                                <FooterNavPathSection
                                    withAccordion={true}
                                    paths={groupsPaths}
                                    title={t('menus.footer.business.title')}
                                />
                            </div>
                            <div className="about">
                                <FooterNavPathSection
                                    withAccordion={true}
                                    paths={aboutUsPaths}
                                    title={t('menus.footer.main.title')}
                                />
                                {hasSocialMediaPaths && (
                                    <FooterSection
                                        withAccordion={false}
                                        contentClassName="social-media"
                                        title={t('menus.footer.social.title')}
                                    >
                                        <>
                                            {socialMediaPaths.map(({ link, label }, key) => (
                                                <SocialMediaIcon
                                                    key={`footer-socialmedia-${label}-${key}`}
                                                    to={link?.url as string}
                                                    label={label}
                                                    dataGALocation="FooterNav"
                                                    iconType={label.toLocaleLowerCase()}
                                                />
                                            ))}
                                        </>
                                    </FooterSection>
                                )}
                                {hasGetAppPaths && (
                                    <FooterSection
                                        withAccordion={false}
                                        contentClassName="stores"
                                        title={t('menus.footer.app.title')}
                                    >
                                        <>
                                            {getAppPaths.map(({ link, label, badgeType }, key) => (
                                                <StoreBadge
                                                    key={`footer-getapp-path-${label}-${key}`}
                                                    to={link?.url as string}
                                                    label={label}
                                                    dataGALocation="FooterNav"
                                                    badgeType={badgeType}
                                                />
                                            ))}
                                        </>
                                    </FooterSection>
                                )}
                            </div>
                        </>
                    </div>
                )}

                <div className="legal-section">
                    <div className="legal-section-content">
                        <div className="copyright">{t('menus.footer.legal.title', { year: copyrightYear })}</div>
                        <div className="links">
                            {legalPaths.map(({ link, label }, key) => (
                                <div className="link-container" key={`footer-path-${label}-${key}`}>
                                    <Link
                                        to={link?.url as string}
                                        label={label}
                                        variant="footer-legal"
                                        dataGALocation="FooterNav"
                                    />
                                    <span>|</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="legal-section-addresses">
                        {mailingAddress && physicalAddress && (
                            <FooterAddressLine
                                mailingAddress={mailingAddress}
                                physicalAddress={physicalAddress}
                                addressTitle={t('menus.footer.addresses.mailOrder')}
                            />
                        )}
                        {specialtyMailingAddress && specialtyPhysicalAddress && (
                            <FooterAddressLine
                                mailingAddress={specialtyMailingAddress}
                                physicalAddress={specialtyPhysicalAddress}
                                addressTitle={t('menus.footer.addresses.specialty')}
                            />
                        )}
                    </div>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
