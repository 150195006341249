import React, { useState, useRef } from 'react';

import Form from 'react-bootstrap/Form';
import CurrencyInput from 'react-currency-input-field';
import { TrackInputFocus } from 'util/google_optimize/optimize_helper';

import Tooltip from 'ui-kit/tooltip/tooltip';
import InputError from 'ui-kit/input-error/input-error';

import { TextProps } from './text.props';

import classNames from 'classnames';

import './text.style.scss';

const Text = ({
    className,
    defaultValue,
    errors,
    footNote,
    icon,
    label,
    name,
    onChange,
    onBlur,
    onFocus,
    onKeyDown,
    showErrorsText = true,
    style,
    touched,
    tooltipText,
    type = 'text',
    value,
    disabled = false,
    maxLength = undefined,
    rows = undefined,
    ariaExpanded,
    inputRef,
    autocomplete = Date.now().toString(),
    formik,
    required = undefined,
    readOnly = false,
    title
}: TextProps) => {
    const localInputRef = useRef(null);
    const inputEl = inputRef ? inputRef : localInputRef;
    const [isFocused, setIsFocused] = useState(false);
    const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);
        TrackInputFocus(event.target, label);
        onFocus !== undefined && onFocus(event);
    };

    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(false);
        onBlur !== undefined && onBlur(event);
    };

    const handleCurrencyOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (formik) {
            formik.setFieldTouched(name, true);
        }
        setIsFocused(false);
        onBlur !== undefined && onBlur(event);
    };

    const handleCurrencyOnChange = (value: any) => {
        if (formik) {
            formik.setFieldValue(name, value);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        onKeyDown !== undefined && onKeyDown(event);
    };

    const classes = classNames(
        'text',
        { focused: isFocused },
        { 'has-value': value || inputEl?.current?.value || defaultValue },
        { 'has-errors': touched && errors },
        className
    );

    return (
        <div className={classes} style={style}>
            <div className={`text-input-container type-input`}>
                {label && (
                    <Form.Label className="text-label" htmlFor={name}>
                        {label}
                    </Form.Label>
                )}
                {type === 'currency' && (
                    <CurrencyInput
                        ref={inputEl}
                        className={`form-control text-input ${className} ${disabled ? 'disabled' : ''} ${
                            readOnly ? 'readonly' : ''
                        }`}
                        name={name}
                        id={name}
                        onValueChange={handleCurrencyOnChange}
                        onFocus={handleOnFocus}
                        onBlur={handleCurrencyOnBlur}
                        onKeyDown={handleKeyDown}
                        defaultValue={defaultValue}
                        disabled={disabled}
                        prefix="$"
                        decimalsLimit={2}
                        required={required}
                        readOnly={readOnly}
                        title={title}
                    />
                )}
                {type !== 'currency' && (
                    <Form.Control
                        ref={inputEl}
                        as={'input'}
                        className={`text-input ${className} ${disabled ? 'disabled' : ''} ${
                            readOnly ? 'readonly' : ''
                        }`}
                        type={type}
                        name={name}
                        id={name}
                        onChange={onChange}
                        onFocus={handleOnFocus}
                        onBlur={handleOnBlur}
                        onKeyDown={handleKeyDown}
                        value={value}
                        defaultValue={defaultValue}
                        disabled={disabled}
                        maxLength={maxLength ? maxLength : 128}
                        aria-expanded={ariaExpanded}
                        autoComplete={autocomplete}
                        required={required}
                        readOnly={readOnly}
                        title={title}
                    />
                )}
                {tooltipText && <Tooltip tip={tooltipText} />}
                {icon}
            </div>
            <InputError
                inputElement={inputEl}
                touched={touched}
                label={label}
                errors={errors}
                showErrors={showErrorsText}
                className="text-errors"
            />
            {footNote && <div className="text-footnote">{footNote}</div>}
        </div>
    );
};

export default Text;
