import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';

// UI Kit
import Button from 'ui-kit/button/button';
import PlanBadge from 'ui-kit/icons/plan-badge/plan-badge';

// Assets
import BirdiPrice from '../../assets/icons/birdi-price.svg';
// Types
import { MembershipPlanAttributes } from '../../types/membership';
// Styles
import './membership-available-plan.style.scss';

export interface MembershipAvailablePlanDetails {
    planId: number;
    planName: string;
    planPrice: number | string;
    savingPercentage?: number;
    isBilledAnually: boolean;
    strikethroughInfo?: string;
    attributes: MembershipPlanAttributes[];
}

const MembershipAvailablePlan = ({
    planId,
    planPrice,
    savingPercentage,
    isBilledAnually,
    attributes
}: MembershipAvailablePlanDetails) => {
    const { t } = useTranslation();

    let isPaidPerVisit = true;

    const isCarePlan = [1, 2].includes(planId);

    let strikethroughInfo = ' ';
    let planPeriod = 'visit';

    // String for Includes list
    const memberQuantityString =
        attributes[0].membershipMembers > 1
            ? t('components.membership.plans.includesList.memberCarePlus', { amount: attributes[0].membershipMembers })
            : t('components.membership.plans.includesList.member', { amount: attributes[0].membershipMembers });
    const telemedicineVisitsString = t('components.membership.plans.includesList.telemedicine', {
        amount: attributes[0].membershipIncludedvisits,
        visits: attributes[0].membershipIncludedvisits > 1 ? 'visits' : 'visit'
    });
    const fastShippingString = t('components.membership.plans.includesList.fastShipping');
    const prescriptionDiscountString = t('components.membership.plans.includesList.prescriptionDescount', {
        discount: attributes[0].membershipOthermeds.charAt(0).toUpperCase() + attributes[0].membershipOthermeds.slice(1)
    });
    const prescriptionsStartingAtString = t('components.membership.plans.includesList.prescriptionsStartingAt');

    let planIncludes: Array<React.ReactNode | string> = [
        prescriptionsStartingAtString,
        prescriptionDiscountString,
        fastShippingString
    ];

    const birdiSelect = (
        <>
            <span
                dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(
                        t('components.membership.plans.includesList.birdiSelectLine1', {
                            amount: attributes[0].membershipIncludedrx
                        }),
                        { allowedTags: ['strong'] }
                    )
                }}
            ></span>
            <PlanBadge variant={'birdi-select'} />
            <span>{t('components.membership.plans.includesList.birdiSelectLine2')}</span>
        </>
    );
    if (isCarePlan) {
        planIncludes = [
            memberQuantityString,
            telemedicineVisitsString,
            birdiSelect,
            prescriptionDiscountString,
            fastShippingString
        ];
        isPaidPerVisit = false;
        strikethroughInfo = t('components.membership.plans.oneTimeJoinFee', { amount: attributes[0].joinFeeAmount });
        planPeriod = 'mo';
    }

    let badge = <>On Demand Visit</>;
    let nameOnCta = 'On Demand Visit';

    if (planId === 1) {
        badge = <PlanBadge variant={'care'} />;
        nameOnCta = 'Care';
    } else if (planId === 2) {
        badge = <PlanBadge variant={'care-plus'} />;
        nameOnCta = 'Care Plus';
    }

    return (
        <div className="membership-available-plan">
            <div className="upper-card">
                <div className="badge">{badge}</div>
                <div
                    className="paragraph"
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(
                            t(`components.membership.availablePlan.descriptions.description-${planId}`),
                            {
                                allowedTags: ['strong']
                            }
                        )
                    }}
                ></div>
                <div className="price">
                    <div className="billing">
                        <span className="price_dollar_sign">$</span>
                        <span className="price_amount">{planPrice}</span>
                        <span className="price_slash">/</span>
                        <span className="price_period">{planPeriod}</span>
                        {savingPercentage && <span className="saving_percentage">Save {savingPercentage}%</span>}
                    </div>
                    <div className="billing__frequency">{isBilledAnually ? 'Billed anually' : ' '}</div>
                    <div className="paid_per_telemedicine">{isPaidPerVisit ? 'Paid per telemedicine visit' : ' '}</div>
                    <div className="price__strikethrough">{strikethroughInfo}</div>
                </div>
                <div className="cta">
                    <Button
                        label={`Select ${nameOnCta}`}
                        type="button"
                        variant="primary"
                        className="btn-bold w-100"
                        onClick={() => navigate(`/get-started/membership?plan=${planId}`)}
                    ></Button>
                </div>
            </div>
            <div className="includes-box">
                <div className="includes">{t('components.membership.plans.includes')}</div>
                <div className="includes-list">
                    {planIncludes.map((planInclude, index) => (
                        <div key={index}>
                            <img alt="Bullet" src={BirdiPrice} />
                            {typeof planInclude === 'string' ? (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeHtml(planInclude, { allowedTags: ['strong'] })
                                    }}
                                ></span>
                            ) : (
                                <div className="d-block">{planInclude}</div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MembershipAvailablePlan;
