import axios from 'axios';

// In DRX-760, we increased the timeout to 60 seconds to allow for retrieving
// large order histories. This should probably be bumped back down to 20 seconds
// once the PatientOrders endpoint is reworked.
const axiosClient = axios.create({
    timeout: 120000
});
axiosClient.defaults.withCredentials = false;
const sessionStorage = (typeof window !== 'undefined' && window.sessionStorage) || undefined;
axiosClient.defaults.headers = {
    Authorization: `Bearer ${sessionStorage?.getItem('Authorization')}`,
    'Content-Type': 'application/json'
};

//  request interceptor to prevent requests from being made if the user is logged out
// axiosClient.interceptors

const setLoggedOutInterceptor = (store: any) => {
    axiosClient.interceptors.response.use(
        (response) => response,
        (error) => {
            //	force the user to log out of the system if an API response indicates that their token is no longer authorized
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                //  dispatch a user logged out event via store.dispatch(event);
            }
        }
    );
};

//	header helpers
const setDefaultHeader = (name: string, value: any) => {
    axiosClient.defaults.headers[name] = value.toString();
};
const unsetDefaultHeader = (name: string) => {
    delete axiosClient.defaults.headers[name];
};
const getDefaultHeader = (name: string) => axiosClient.defaults.headers[name];

const isAxiosError = (error: any) => axios.isAxiosError(error);

export default axiosClient;

export { getDefaultHeader, setLoggedOutInterceptor, setDefaultHeader, unsetDefaultHeader, isAxiosError };
