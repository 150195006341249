import { ReactElement } from 'react';

import Facebook from './facebook/facebook';
import Instagram from './instagram/instagram';
import LinkedIn from './linkedin/linkedin';
import Twitter from './twitter/twitter';

export interface SocialMediaIconProps {
    className?: string;
    label: string;
    to: string;
    dataGALocation: string;
    iconType: string;
}

function getSvg(iconType: string, label: string) {
    const iconComponents: { [key: string]: React.ReactNode } = {
        facebook: <Facebook label={label} />,
        instagram: <Instagram label={label} />,
        linkedin: <LinkedIn label={label} />,
        twitter: <Twitter label={label} />
    };

    return iconComponents[iconType] || null;
}

const SocialMediaIcon = ({ label, to, dataGALocation, iconType }: SocialMediaIconProps): ReactElement => {
    const svgImage = getSvg(iconType, label);

    return (
        <a
            href={to}
            rel="noopener noreferrer"
            target="_blank"
            data-tagtype="anchorlink"
            data-ga-location={dataGALocation}
        >
            {svgImage}
        </a>
    );
};

export default SocialMediaIcon;
