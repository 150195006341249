import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Button from 'ui-kit/button/button';
import DropFilesIcon from 'ui-kit/icons/drop-files-icon/drop-files-icon';
import UploadFilesIcon from 'ui-kit/icons/upload-files-icon/upload-files-icon';
import './attach-files.style.scss';
import { MAX_FILE_SIZE, fileSizeDisplay } from 'util/file';

const AttachFiles = ({
    setFieldValue,
    fieldName,
    value,
    disabled
}: {
    setFieldValue: any;
    fieldName: string;
    value: any[];
    disabled: boolean;
}) => {
    const { t } = useTranslation();
    const maxFileSize = MAX_FILE_SIZE;
    const maxNumberOfFiles = 10;
    const maxFileSizeForDisplay = fileSizeDisplay(maxFileSize, 0);

    const onDrop = useCallback(
        (acceptedFiles) => {
            let updatedAcceptedFiles = value;
            acceptedFiles.forEach((file: File) => {
                if (!updatedAcceptedFiles.includes(file)) {
                    updatedAcceptedFiles.push(file);
                }
                setFieldValue(fieldName, updatedAcceptedFiles);
            });
            acceptedFiles = [];
            setFieldValue(fieldName, updatedAcceptedFiles);
        },
        [value]
    );

    const { fileRejections, getRootProps, getInputProps, open, isDragActive } = useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true,
        useFsAccessApi: false,
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'image/gif': ['.gif'],
            'image/heic': ['.heic'],
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
        },
        maxFiles: maxNumberOfFiles,
        maxSize: maxFileSize,
        multiple: true,
        disabled: disabled
    });

    const removeFile = (file) => () => {
        const newFiles = [...value];
        newFiles.splice(newFiles.indexOf(file), 1);
        setFieldValue(fieldName, newFiles);
    };

    const isDragAndDropSupported = () => {
        if (typeof document !== 'undefined') {
            return 'draggable' in document.createElement('div');
        } else {
            return false;
        }
    };

    const rejectedFilesItems = fileRejections.map(({ file, errors }) => (
        <p className="error pb-0" data-error={errors[0].code} key={file.path}>
            {t(`components.attachFiles.errors.${errors[0].code}`, {
                fileName: file.name,
                sizeLimit: maxFileSizeForDisplay,
                maxFiles: maxNumberOfFiles
            })}
        </p>
    ));

    const acceptedFilesItems = value?.map((file, i) => {
        const fileName = file.path;
        const fileSize = fileSizeDisplay(file.size);

        return (
            <div className="attachedFile" key={`attach-${i}`}>
                {`${fileName} (${fileSize})`}
                <Button
                    className="delete no-min-width p-0 ml-1 border-0 rounded-0 d-inline-block"
                    variant={'detach'}
                    onClick={removeFile(file)}
                    label=""
                    type="button"
                />
            </div>
        );
    });

    return (
        <div className={'attachFiles'}>
            <div className="accepted-files">{acceptedFilesItems}</div>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className={`dropZone text-center ${isDragActive ? 'active' : ''}`}>
                    {isDragActive ? (
                        <div className={'font-weight-bold'}>
                            <DropFilesIcon className="d-inline-block mr-3" />
                            {t('components.attachFiles.labels.dragAndDropActive')}
                        </div>
                    ) : (
                        <div className={'text-ebony-clay'}>
                            <UploadFilesIcon className="d-inline-block mr-3" />
                            {isDragAndDropSupported() ? `${t('components.attachFiles.labels.dragAndDrop')} ` : ``}
                            <Button
                                className={'p-0 border-0 no-min-width font-weight-bold'}
                                variant="text"
                                disabled={disabled}
                                label={t(
                                    isDragAndDropSupported()
                                        ? 'components.attachFiles.labels.browseFiles'
                                        : 'components.attachFiles.labels.browseFilesLeading'
                                )}
                                onClick={open}
                                type="button"
                            />
                            {!isDragAndDropSupported() ? ` ${t('components.attachFiles.labels.toUpload')} ` : ``}
                        </div>
                    )}
                </div>
            </div>
            {fileRejections?.length > 0 && <div className="rejected-files error">{rejectedFilesItems}</div>}
            <p className="attach-message">
                {t('components.attachFiles.labels.acceptedFiles', {
                    sizeLimit: maxFileSizeForDisplay
                })}
            </p>
        </div>
    );
};

export default AttachFiles;
