import React from 'react';

import { CartIconProps } from 'ui-kit/icons/cart-icon/cart-icon.props';

import './cart-icon.style.scss';

const CartIcon = ({ className, itemCount, style, color = 'blue' }: CartIconProps) => {
    const colors = {
        blue: '#039FD3',
        black: '#222A3F'
    };

    const selectedColor = colors[color] || color;

    return (
        <div className={`cart-icon ${className ?? ''}`} style={style}>
            {itemCount && itemCount > 0 ? <div className={`cart-icon__count`}>{itemCount}</div> : <></>}
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2 3H5.08632L5.51145 6.07692M5.51145 6.07692L6.54956 15.916C6.6361 16.7363 7.32941 17.359 8.15615 17.359H18.3643C19.1056 17.359 19.7518 16.8556 19.9316 16.1382L21.951 8.07955C22.2059 7.06231 21.4347 6.07692 20.3836 6.07692H5.51145ZM17.9229 23C17.4769 23 17.1152 22.6391 17.1152 22.1941C17.1152 21.7491 17.4769 21.3883 17.9229 21.3883C18.3691 21.3883 18.7307 21.7491 18.7307 22.1941C18.7307 22.6391 18.3691 23 17.9229 23ZM7.42237 23C6.97627 23 6.61463 22.6391 6.61463 22.1941C6.61463 21.7491 6.97627 21.3883 7.42237 21.3883C7.86848 21.3883 8.23011 21.7491 8.23011 22.1941C8.23011 22.6391 7.86848 23 7.42237 23Z"
                    stroke={selectedColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default CartIcon;
