import classNames from 'classnames';
import { CSSProperties } from 'react';

interface PlanBadgeProps {
    className?: string;
    style?: CSSProperties;
    variant?: 'care' | 'care-plus' | 'birdi-select' | 'birdi-select-blue';
}

const PlanBadge = ({ className, style, variant }: PlanBadgeProps) => {
    const classes = classNames('plan-badge', className);

    return (
        <>
            {variant === 'care' && (
                <svg
                    className={classes}
                    style={style}
                    width="80"
                    height="24"
                    viewBox="0 0 80 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="80" height="24" rx="12" fill="#222A3F" />
                    <path
                        d="M52.2594 17.2114L54.0789 6.78857C54.1809 6.15429 54.385 6 55.0142 6H61.323C61.9352 6 62.0713 6.15429 61.9692 6.78857L61.9012 7.2C61.7822 7.81714 61.5781 7.98857 60.9659 7.98857H56.0515L55.5413 10.92H59.4185C60.0477 10.92 60.1837 11.0743 60.0647 11.7086L59.9967 12.1029C59.8946 12.7371 59.7076 12.8914 59.0784 12.8914H55.2012L54.6571 16.0114H59.7586C60.3708 16.0114 60.5068 16.1829 60.4048 16.8L60.3368 17.2114C60.2177 17.8457 60.0307 18 59.4185 18H52.9226C52.2934 18 52.1403 17.8457 52.2594 17.2114Z"
                        fill="white"
                    />
                    <path
                        d="M41.5016 17.2114L43.2137 6.78857C43.3097 6.15429 43.5017 6 44.0938 6H47.0539C50.1261 6 51.6142 7.14857 51.1661 9.73714C50.8621 11.5029 49.8221 12.5486 48.126 12.9771L50.2381 17.3314C50.4621 17.8457 50.3661 18 49.8061 18H49.118C48.526 18 48.334 17.8629 48.126 17.3143L46.1419 13.2H44.2378L43.5817 17.2114C43.4697 17.8457 43.3097 18 42.7017 18H42.1257C41.5336 18 41.3896 17.8457 41.5016 17.2114ZM46.7819 7.98857H45.0858L44.5578 11.2286H46.2539C47.966 11.2286 48.814 10.7829 49.006 9.61714C49.198 8.46857 48.51 7.98857 46.7819 7.98857Z"
                        fill="white"
                    />
                    <path
                        d="M37.388 17.28L36.989 15.4114H32.1059L31.1325 17.28C30.8772 17.8629 30.6538 18 30.0952 18H29.2335C28.7867 18 28.6271 17.7257 28.8665 17.28L34.4996 6.72C34.8028 6.13714 35.0103 6 35.5847 6H36.3507C36.9252 6 37.1007 6.13714 37.1965 6.72L39.4944 17.28C39.5742 17.7257 39.3667 18 38.904 18H38.2337C37.6593 18 37.4837 17.8629 37.388 17.28ZM35.6167 8.72571L33.1591 13.4229H36.5901L35.6167 8.72571Z"
                        fill="white"
                    />
                    <path
                        d="M23.5643 18C19.3803 18 17.4323 15.6467 18.1438 11.758C18.8044 8.10292 21.5486 6 25.3091 6C27.3587 6 28.6969 6.51739 29.5439 7.40195C29.6963 7.56885 29.7472 7.73574 29.7302 7.88595C29.6794 8.08623 29.5269 8.26982 29.2559 8.45341L28.7477 8.80389C28.4598 8.98748 28.2565 9.05424 28.1041 9.05424C27.8669 9.05424 27.7653 8.92072 27.6636 8.83727C27.003 8.26982 26.2069 7.95271 25.1058 7.95271C22.6496 7.95271 20.871 9.53825 20.4475 11.9583C19.9732 14.5452 21.3792 16.0473 23.7676 16.0473C24.8856 16.0473 25.7664 15.7802 26.6303 15.2462C26.9352 15.0626 27.1216 14.9791 27.291 14.9791C27.4434 14.9791 27.5789 15.0459 27.7314 15.2128L28.1549 15.63C28.3412 15.8303 28.392 15.9805 28.3751 16.114C28.3412 16.2976 28.1718 16.4312 28.0532 16.5313C26.9691 17.4325 25.4615 18 23.5643 18Z"
                        fill="white"
                    />
                </svg>
            )}

            {variant === 'care-plus' && (
                <svg
                    className={classes}
                    style={style}
                    width="80"
                    height="24"
                    viewBox="0 0 80 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="80" height="24" rx="12" fill="#03A4DC" />
                    <path
                        d="M46.2594 17.2114L48.0789 6.78857C48.1809 6.15429 48.385 6 49.0142 6H55.323C55.9352 6 56.0713 6.15429 55.9692 6.78857L55.9012 7.2C55.7822 7.81714 55.5781 7.98857 54.9659 7.98857H50.0515L49.5413 10.92H53.4185C54.0477 10.92 54.1837 11.0743 54.0647 11.7086L53.9967 12.1029C53.8946 12.7371 53.7076 12.8914 53.0784 12.8914H49.2012L48.6571 16.0114H53.7586C54.3708 16.0114 54.5068 16.1829 54.4048 16.8L54.3368 17.2114C54.2177 17.8457 54.0307 18 53.4185 18H46.9226C46.2934 18 46.1403 17.8457 46.2594 17.2114Z"
                        fill="white"
                    />
                    <path
                        d="M35.5016 17.2114L37.2137 6.78857C37.3097 6.15429 37.5017 6 38.0938 6H41.0539C44.1261 6 45.6142 7.14857 45.1661 9.73714C44.8621 11.5029 43.8221 12.5486 42.126 12.9771L44.2381 17.3314C44.4621 17.8457 44.3661 18 43.8061 18H43.118C42.526 18 42.334 17.8629 42.126 17.3143L40.1419 13.2H38.2378L37.5817 17.2114C37.4697 17.8457 37.3097 18 36.7017 18H36.1257C35.5336 18 35.3896 17.8457 35.5016 17.2114ZM40.7819 7.98857H39.0858L38.5578 11.2286H40.2539C41.966 11.2286 42.814 10.7829 43.006 9.61714C43.198 8.46857 42.51 7.98857 40.7819 7.98857Z"
                        fill="white"
                    />
                    <path
                        d="M31.388 17.28L30.989 15.4114H26.1059L25.1325 17.28C24.8772 17.8629 24.6538 18 24.0952 18H23.2335C22.7867 18 22.6271 17.7257 22.8665 17.28L28.4996 6.72C28.8028 6.13714 29.0103 6 29.5847 6H30.3507C30.9252 6 31.1007 6.13714 31.1965 6.72L33.4944 17.28C33.5742 17.7257 33.3667 18 32.904 18H32.2337C31.6593 18 31.4837 17.8629 31.388 17.28ZM29.6167 8.72571L27.1591 13.4229H30.5901L29.6167 8.72571Z"
                        fill="white"
                    />
                    <path
                        d="M17.5643 18C13.3803 18 11.4323 15.6467 12.1438 11.758C12.8044 8.10292 15.5486 6 19.3091 6C21.3587 6 22.6969 6.51739 23.5439 7.40195C23.6963 7.56885 23.7472 7.73574 23.7302 7.88595C23.6794 8.08623 23.5269 8.26982 23.2559 8.45341L22.7477 8.80389C22.4598 8.98748 22.2565 9.05424 22.1041 9.05424C21.8669 9.05424 21.7653 8.92072 21.6636 8.83727C21.003 8.26982 20.2069 7.95271 19.1058 7.95271C16.6496 7.95271 14.871 9.53825 14.4475 11.9583C13.9732 14.5452 15.3792 16.0473 17.7676 16.0473C18.8856 16.0473 19.7664 15.7802 20.6303 15.2462C20.9352 15.0626 21.1216 14.9791 21.291 14.9791C21.4434 14.9791 21.5789 15.0459 21.7314 15.2128L22.1549 15.63C22.3412 15.8303 22.392 15.9805 22.3751 16.114C22.3412 16.2976 22.1718 16.4312 22.0532 16.5313C20.9691 17.4325 19.4615 18 17.5643 18Z"
                        fill="white"
                    />
                    <path
                        d="M64.9565 7.17647L64.5013 9.86189H67.0048C67.8899 9.86189 68.0922 10.0921 67.9657 10.9616C67.814 11.9079 67.5105 12.1381 66.6002 12.1381H64.0967L63.6163 14.8235C63.4645 15.7442 63.1864 16 62.2507 16H61.9979C61.0622 16 60.8852 15.7442 61.0369 14.8235L61.5174 12.1381H59.0139C58.1289 12.1381 57.876 11.9335 58.053 11.0384C58.2047 10.0921 58.5082 9.86189 59.4185 9.86189H61.922L62.3772 7.17647C62.5542 6.23018 62.8071 6 63.7427 6H63.9956C64.9312 6 65.1335 6.23018 64.9565 7.17647Z"
                        fill="white"
                    />
                </svg>
            )}

            {variant === 'birdi-select' && (
                <svg
                    className={classNames('birdi-select', className)}
                    style={style}
                    width="96"
                    height="18"
                    viewBox="0 0 96 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="95.2654" height="18" rx="9" fill="white" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.8557 6.93985C11.4591 6.93985 11.0787 7.01038 10.7246 7.1387V5.38487H10.7236C10.7129 4.61827 10.1079 4 9.36231 4C8.61655 4 8.01164 4.61827 8.00087 5.38487H8.00012V5.40045C8.00012 5.40206 8 5.40362 8 5.40535C8 5.40702 8.00012 5.40851 8.00012 5.41018V12.5898C8.00012 12.5915 8 12.5931 8 12.5947C8 12.5964 8.00012 12.5979 8.00012 12.5996V12.6728H8.00382C8.04324 13.4124 8.63548 14 9.36231 14C9.73197 14 10.0664 13.8475 10.3113 13.6008C10.7752 13.8441 11.2997 13.9821 11.8557 13.9821C13.7409 13.9821 15.2691 12.4056 15.2691 10.461C15.2691 8.51628 13.7409 6.93985 11.8557 6.93985ZM11.8557 11.6432C11.2227 11.6432 10.7096 11.1139 10.7096 10.461C10.7096 9.80799 11.2227 9.2787 11.8557 9.2787C12.4887 9.2787 13.0018 9.80799 13.0018 10.461C13.0018 11.1139 12.4887 11.6432 11.8557 11.6432Z"
                        fill="#03A4DC"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M34.2763 8.66032H34.2756V8.67584C34.2756 8.67751 34.2754 8.67907 34.2754 8.6808C34.2754 8.68241 34.2756 8.68396 34.2756 8.68564V12.5898C34.2756 12.5914 34.2754 12.5931 34.2754 12.5947C34.2754 12.5964 34.2756 12.5979 34.2756 12.5995V12.6727H34.2793C34.3186 13.4123 34.9109 14 35.6378 14C36.3646 14 36.9568 13.4123 36.9963 12.6727H37V8.66032H36.999C36.9884 7.89372 36.3833 7.27545 35.6378 7.27545C34.8921 7.27545 34.2871 7.89372 34.2763 8.66032Z"
                        fill="#03A4DC"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M24.0177 7.29366C23.4784 7.30298 22.9514 7.40915 22.4565 7.59993C22.2207 7.39756 21.9179 7.27545 21.5871 7.27545C20.8414 7.27545 20.2364 7.89372 20.2257 8.66032H20.2249V8.6759C20.2249 8.67751 20.2248 8.67907 20.2248 8.6808C20.2248 8.68247 20.2249 8.68402 20.2249 8.68564V12.5898C20.2249 12.5915 20.2248 12.5931 20.2248 12.5947C20.2248 12.5964 20.2249 12.5979 20.2249 12.5995V12.6728H20.2287C20.268 13.4123 20.8603 14 21.5871 14C22.314 14 22.9061 13.4123 22.9456 12.6728H22.9494V10.8402C22.9494 10.5022 23.1579 10.1978 23.4701 10.0953C23.6703 10.0297 23.8809 9.9952 24.0955 9.9952C24.8286 9.9952 25.4211 9.37371 25.4049 8.61356C25.3889 7.86488 24.7435 7.28106 24.0177 7.29366Z"
                        fill="#03A4DC"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.3352 11.17V12.5898C16.3352 12.5915 16.335 12.5931 16.335 12.5947C16.335 12.5963 16.3352 12.5979 16.3352 12.5996V12.6728H16.3389C16.3783 13.4123 16.9706 14 17.6973 14C18.4243 14 19.0163 13.4123 19.0559 12.6728H19.0596V11.17H16.3352Z"
                        fill="#03A4DC"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.3352 10.1055H19.0596V8.66038H19.0587C19.0479 7.89372 18.443 7.27551 17.6973 7.27551C16.9516 7.27551 16.3467 7.89372 16.336 8.66038H16.3352V8.6759C16.3352 8.67758 16.335 8.67913 16.335 8.68086C16.335 8.68247 16.3352 8.68403 16.3352 8.6857V10.1055Z"
                        fill="#03A4DC"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M25.7642 10.461C25.7642 12.4056 27.2924 13.9821 29.1776 13.9821C29.7336 13.9821 30.2581 13.8441 30.7219 13.6008C30.9669 13.8475 31.3013 14 31.671 14C32.3978 14 32.9901 13.4124 33.0295 12.6728H33.0332V12.5996C33.0332 12.5979 33.0334 12.5964 33.0334 12.5947C33.0334 12.5931 33.0332 12.5915 33.0332 12.5898V5.41018C33.0332 5.40851 33.0334 5.40702 33.0334 5.40535C33.0334 5.40362 33.0332 5.40206 33.0332 5.40045V5.38487H33.0324C33.0217 4.61827 32.4168 4 31.671 4C30.9254 4 30.3204 4.61827 30.3097 5.38487H30.3087V7.1387C29.9546 7.01038 29.5742 6.93985 29.1776 6.93985C27.2924 6.93985 25.7642 8.51628 25.7642 10.461ZM28.0315 10.461C28.0315 9.80799 28.5446 9.2787 29.1776 9.2787C29.8105 9.2787 30.3237 9.80799 30.3237 10.461C30.3237 11.1139 29.8105 11.6432 29.1776 11.6432C28.5446 11.6432 28.0315 11.1139 28.0315 10.461Z"
                        fill="#03A4DC"
                    />
                    <path
                        d="M80.7608 5.99445C80.1514 5.99445 80.0267 5.8421 80.1237 5.23268L80.1791 4.92797C80.2899 4.31855 80.4699 4.1662 81.0793 4.1662H86.6195C87.2151 4.1662 87.3397 4.31855 87.2289 4.92797L87.1735 5.23268C87.0766 5.8421 86.8965 5.99445 86.3009 5.99445H84.5973L83.337 13.0997C83.2262 13.7091 83.06 13.8615 82.4505 13.8615H81.9935C81.3841 13.8615 81.2455 13.7091 81.3563 13.0997L82.6167 5.99445H80.7608Z"
                        fill="#222A3F"
                    />
                    <path
                        d="M74.4909 14C71.1252 14 69.8094 11.964 70.3634 8.80611C70.9036 5.82827 72.9673 4.04156 75.9175 4.04156C77.3994 4.04156 78.4936 4.41553 79.1308 5.13575C79.2693 5.3158 79.3385 5.45431 79.3108 5.60666C79.2693 5.80057 79.103 5.98062 78.8399 6.17453L78.4382 6.47924C78.1751 6.67314 77.9812 6.7424 77.8288 6.7424C77.6072 6.7424 77.4964 6.59004 77.4133 6.52079C76.9701 6.11913 76.4161 5.89752 75.6958 5.89752C73.9784 5.89752 72.7596 7.18561 72.4549 8.97231C72.1086 10.9252 73.0504 12.1441 74.7402 12.1441C75.3911 12.1441 76.0144 11.9778 76.61 11.6039C76.9147 11.4238 77.0947 11.3269 77.2609 11.3269C77.3995 11.3269 77.5241 11.3961 77.6765 11.5623L78.0089 11.9363C78.2028 12.1302 78.2305 12.2964 78.2166 12.4211C78.1889 12.6011 78.0227 12.7119 77.9258 12.8089C77.0393 13.5983 75.8205 14 74.4909 14Z"
                        fill="#222A3F"
                    />
                    <path
                        d="M62.7066 13.0997L64.1471 4.92797C64.2579 4.31855 64.4379 4.1662 65.0473 4.1662H69.5487C70.1581 4.1662 70.2828 4.31855 70.172 4.92797L70.1166 5.23268C70.0196 5.8421 69.8396 5.99445 69.2302 5.99445H65.9338L65.5737 8.03046H68.2191C68.8285 8.03046 68.9532 8.18282 68.8424 8.79224L68.787 9.09694C68.6761 9.70636 68.4961 9.85872 67.8867 9.85872H65.2412L64.8673 12.0332H68.3022C68.9116 12.0332 69.0363 12.1856 68.9255 12.795L68.8701 13.0997C68.7592 13.7091 68.593 13.8615 67.9836 13.8615H63.3437C62.7343 13.8615 62.5958 13.7091 62.7066 13.0997Z"
                        fill="#222A3F"
                    />
                    <path
                        d="M55.6764 13.0997L57.1168 4.92797C57.2277 4.31855 57.4077 4.1662 58.0171 4.1662H58.4603C59.0698 4.1662 59.1944 4.31855 59.0836 4.92797L57.8371 12.0332H60.9811C61.5905 12.0332 61.7152 12.1856 61.6044 12.795L61.549 13.0997C61.4382 13.7091 61.272 13.8615 60.6626 13.8615H56.3135C55.7041 13.8615 55.5656 13.7091 55.6764 13.0997Z"
                        fill="#222A3F"
                    />
                    <path
                        d="M48.0106 13.0997L49.451 4.92797C49.5618 4.31855 49.7419 4.1662 50.3513 4.1662H54.8527C55.4621 4.1662 55.5867 4.31855 55.4759 4.92797L55.4205 5.23268C55.3236 5.8421 55.1435 5.99445 54.5341 5.99445H51.2377L50.8776 8.03046H53.523C54.1325 8.03046 54.2571 8.18282 54.1463 8.79224L54.0909 9.09694C53.9801 9.70636 53.8 9.85872 53.1906 9.85872H50.5452L50.1712 12.0332H53.6061C54.2156 12.0332 54.3402 12.1856 54.2294 12.795L54.174 13.0997C54.0632 13.7091 53.897 13.8615 53.2876 13.8615H48.6477C48.0383 13.8615 47.8998 13.7091 48.0106 13.0997Z"
                        fill="#222A3F"
                    />
                    <path
                        d="M44.7033 5.80055C43.7892 5.80055 43.3321 6.11911 43.2213 6.74238C42.9581 8.34903 47.5011 7.86426 46.9332 11.1607C46.6147 12.9335 45.3404 14 43.2906 14C41.6424 14 40.6313 13.5291 40.1742 13.0305C40.0219 12.8504 39.9803 12.6704 40.008 12.4903C40.0357 12.2964 40.1742 12.1163 40.382 11.9501L40.6451 11.7562C40.8944 11.5485 41.116 11.4515 41.3099 11.4515C41.5038 11.4515 41.6839 11.5485 41.864 11.7147C42.2102 12.0748 42.6673 12.2687 43.3183 12.2687C44.1631 12.2687 44.8556 11.7839 44.9665 11.1607C45.2712 9.38781 40.6451 10.2465 41.2407 6.75623C41.5315 5.02493 42.7919 4 44.7725 4C45.9221 4 46.8086 4.30471 47.4734 4.88643C47.5703 4.96953 47.7365 5.12188 47.695 5.35734C47.6673 5.50969 47.5426 5.7036 47.2518 5.91136L46.9471 6.13296C46.6562 6.34072 46.4484 6.40997 46.2961 6.40997C46.0883 6.40997 45.9775 6.29917 45.8944 6.22992C45.5897 5.98061 45.2296 5.80055 44.7033 5.80055Z"
                        fill="#222A3F"
                    />
                </svg>
            )}

            {variant === 'birdi-select-blue' && (
                <svg
                    className={classes}
                    style={style}
                    width="99"
                    height="20"
                    viewBox="0 0 99 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="98.0987" height="20" rx="10" fill="#F2F9FC" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M35.6722 9.66031H35.6715V9.67584C35.6715 9.67751 35.6713 9.67906 35.6713 9.6808C35.6713 9.68241 35.6715 9.68396 35.6715 9.68563V13.5898C35.6715 13.5914 35.6713 13.5931 35.6713 13.5947C35.6713 13.5964 35.6715 13.5979 35.6715 13.5995V13.6727H35.6752C35.7136 14.4123 36.2923 15 37.0024 15C37.7125 15 38.2911 14.4123 38.3297 13.6727H38.3333V9.66031H38.3323C38.3219 8.89372 37.7308 8.27545 37.0024 8.27545C36.2738 8.27545 35.6828 8.89372 35.6722 9.66031Z"
                        fill="#03A4DC"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M27.3558 11.4609C27.3558 13.4056 28.8489 14.982 30.6908 14.982C31.234 14.982 31.7464 14.844 32.1996 14.6008C32.439 14.8474 32.7656 15 33.1269 15C33.837 15 34.4156 14.4124 34.4541 13.6728H34.4577V13.5996C34.4577 13.5979 34.4579 13.5963 34.4579 13.5947C34.4579 13.5931 34.4577 13.5914 34.4577 13.5898V6.41018C34.4577 6.40851 34.4579 6.40702 34.4579 6.40535C34.4579 6.40361 34.4577 6.40206 34.4577 6.40045V6.38486H34.457C34.4465 5.61827 33.8555 5 33.1269 5C32.3984 5 31.8073 5.61827 31.7968 6.38486H31.7959V8.13869C31.45 8.01037 31.0782 7.93985 30.6908 7.93985C28.8489 7.93985 27.3558 9.51627 27.3558 11.4609ZM29.5711 11.4609C29.5711 10.808 30.0723 10.2787 30.6908 10.2787C31.3091 10.2787 31.8105 10.808 31.8105 11.4609C31.8105 12.1139 31.3091 12.6432 30.6908 12.6432C30.0723 12.6432 29.5711 12.1139 29.5711 11.4609Z"
                        fill="#03A4DC"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M25.6495 8.29366C25.1226 8.30298 24.6077 8.40915 24.1242 8.59993C23.8938 8.39756 23.598 8.27545 23.2748 8.27545C22.5462 8.27545 21.9552 8.89372 21.9447 9.66031H21.9439V9.6759C21.9439 9.67751 21.9438 9.67906 21.9438 9.6808C21.9438 9.68247 21.9439 9.68402 21.9439 9.68563V13.5898C21.9439 13.5915 21.9438 13.5931 21.9438 13.5947C21.9438 13.5964 21.9439 13.5979 21.9439 13.5995V13.6728H21.9476C21.986 14.4123 22.5647 15 23.2748 15C23.985 15 24.5635 14.4123 24.6021 13.6728H24.6058V11.8402C24.6058 11.5022 24.8094 11.1978 25.1145 11.0953C25.3101 11.0297 25.5159 10.9952 25.7255 10.9952C26.4418 10.9952 27.0207 10.3737 27.0048 9.61356C26.9892 8.86488 26.3586 8.28106 25.6495 8.29366Z"
                        fill="#03A4DC"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.1436 12.17V13.5899C18.1436 13.5915 18.1434 13.5931 18.1434 13.5948C18.1434 13.5964 18.1436 13.598 18.1436 13.5996V13.6728H18.1472C18.1857 14.4124 18.7644 15 19.4744 15C20.1847 15 20.7631 14.4124 20.8018 13.6728H20.8054V12.17H18.1436Z"
                        fill="#03A4DC"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.1436 11.1054H20.8054V9.66032H20.8045C20.794 8.89366 20.2029 8.27545 19.4744 8.27545C18.7459 8.27545 18.1549 8.89366 18.1444 9.66032H18.1436V9.67584C18.1436 9.67751 18.1434 9.67907 18.1434 9.6808C18.1434 9.68241 18.1436 9.68396 18.1436 9.68563V11.1054Z"
                        fill="#03A4DC"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.7671 7.93985C13.3796 7.93985 13.0079 8.01037 12.6619 8.13869V6.38486H12.661C12.6506 5.61827 12.0595 5 11.331 5C10.6024 5 10.0114 5.61827 10.0008 6.38486H10.0001V6.40045C10.0001 6.40206 10 6.40361 10 6.40534C10 6.40702 10.0001 6.40851 10.0001 6.41018V13.5898C10.0001 13.5914 10 13.5931 10 13.5947C10 13.5963 10.0001 13.5979 10.0001 13.5996V13.6728H10.0037C10.0422 14.4124 10.6209 15 11.331 15C11.6922 15 12.0189 14.8474 12.2582 14.6008C12.7114 14.844 13.2239 14.982 13.7671 14.982C15.6089 14.982 17.102 13.4056 17.102 11.4609C17.102 9.51627 15.6089 7.93985 13.7671 7.93985ZM13.7671 12.6432C13.1486 12.6432 12.6473 12.1139 12.6473 11.4609C12.6473 10.808 13.1486 10.2787 13.7671 10.2787C14.3855 10.2787 14.8868 10.808 14.8868 11.4609C14.8868 12.1139 14.3855 12.6432 13.7671 12.6432Z"
                        fill="#03A4DC"
                    />
                    <path
                        d="M81.5941 6.99445C80.9847 6.99445 80.86 6.8421 80.957 6.23268L81.0124 5.92797C81.1232 5.31855 81.3032 5.1662 81.9127 5.1662H87.4528C88.0484 5.1662 88.173 5.31855 88.0622 5.92797L88.0068 6.23268C87.9099 6.8421 87.7298 6.99445 87.1343 6.99445H85.4307L84.1703 14.0997C84.0595 14.7091 83.8933 14.8615 83.2838 14.8615H82.8268C82.2174 14.8615 82.0789 14.7091 82.1897 14.0997L83.45 6.99445H81.5941Z"
                        fill="#222A3F"
                    />
                    <path
                        d="M75.3242 15C71.9585 15 70.6427 12.964 71.1968 9.8061C71.7369 6.82827 73.8006 5.04156 76.7508 5.04156C78.2328 5.04156 79.3269 5.41553 79.9641 6.13575C80.1026 6.3158 80.1718 6.45431 80.1441 6.60666C80.1026 6.80057 79.9364 6.98062 79.6732 7.17453L79.2715 7.47923C79.0084 7.67314 78.8145 7.74239 78.6621 7.74239C78.4405 7.74239 78.3297 7.59004 78.2466 7.52079C77.8034 7.11913 77.2494 6.89752 76.5292 6.89752C74.8117 6.89752 73.5929 8.18561 73.2882 9.97231C72.9419 11.9252 73.8837 13.144 75.5735 13.144C76.2244 13.144 76.8477 12.9778 77.4433 12.6039C77.748 12.4238 77.928 12.3269 78.0943 12.3269C78.2328 12.3269 78.3574 12.3961 78.5098 12.5623L78.8422 12.9363C79.0361 13.1302 79.0638 13.2964 79.0499 13.4211C79.0222 13.6011 78.856 13.7119 78.7591 13.8089C77.8726 14.5983 76.6538 15 75.3242 15Z"
                        fill="#222A3F"
                    />
                    <path
                        d="M63.5399 14.0997L64.9803 5.92797C65.0911 5.31855 65.2712 5.1662 65.8806 5.1662H70.382C70.9914 5.1662 71.1161 5.31855 71.0053 5.92797L70.9499 6.23268C70.8529 6.8421 70.6729 6.99445 70.0634 6.99445H66.767L66.4069 9.03046H69.0524C69.6618 9.03046 69.7864 9.18281 69.6756 9.79223L69.6202 10.0969C69.5094 10.7064 69.3294 10.8587 68.72 10.8587H66.0745L65.7006 13.0332H69.1355C69.7449 13.0332 69.8695 13.1856 69.7587 13.795L69.7033 14.0997C69.5925 14.7091 69.4263 14.8615 68.8169 14.8615H64.177C63.5676 14.8615 63.4291 14.7091 63.5399 14.0997Z"
                        fill="#222A3F"
                    />
                    <path
                        d="M56.5097 14.0997L57.9502 5.92797C58.061 5.31855 58.241 5.1662 58.8504 5.1662H59.2936C59.9031 5.1662 60.0277 5.31855 59.9169 5.92797L58.6704 13.0332H61.8144C62.4238 13.0332 62.5485 13.1856 62.4377 13.795L62.3823 14.0997C62.2715 14.7091 62.1053 14.8615 61.4959 14.8615H57.1468C56.5374 14.8615 56.3989 14.7091 56.5097 14.0997Z"
                        fill="#222A3F"
                    />
                    <path
                        d="M48.8439 14.0997L50.2843 5.92797C50.3951 5.31855 50.5752 5.1662 51.1846 5.1662H55.686C56.2954 5.1662 56.42 5.31855 56.3092 5.92797L56.2538 6.23268C56.1569 6.8421 55.9768 6.99445 55.3674 6.99445H52.071L51.7109 9.03046H54.3563C54.9657 9.03046 55.0904 9.18281 54.9796 9.79223L54.9242 10.0969C54.8134 10.7064 54.6333 10.8587 54.0239 10.8587H51.3785L51.0045 13.0332H54.4394C55.0488 13.0332 55.1735 13.1856 55.0627 13.795L55.0073 14.0997C54.8965 14.7091 54.7303 14.8615 54.1209 14.8615H49.481C48.8716 14.8615 48.7331 14.7091 48.8439 14.0997Z"
                        fill="#222A3F"
                    />
                    <path
                        d="M45.5366 6.80055C44.6225 6.80055 44.1654 7.11911 44.0546 7.74238C43.7915 9.34902 48.3344 8.86426 47.7665 12.1607C47.448 13.9335 46.1738 15 44.1239 15C42.4757 15 41.4646 14.5291 41.0076 14.0305C40.8552 13.8504 40.8136 13.6703 40.8413 13.4903C40.869 13.2964 41.0076 13.1163 41.2153 12.9501L41.4785 12.7562C41.7278 12.5485 41.9494 12.4515 42.1433 12.4515C42.3372 12.4515 42.5172 12.5485 42.6973 12.7147C43.0436 13.0748 43.5006 13.2687 44.1516 13.2687C44.9965 13.2687 45.689 12.7839 45.7998 12.1607C46.1045 10.3878 41.4785 11.2465 42.074 7.75623C42.3649 6.02493 43.6253 5 45.6059 5C46.7555 5 47.6419 5.30471 48.3067 5.88643C48.4037 5.96953 48.5699 6.12188 48.5283 6.35734C48.5006 6.50969 48.376 6.7036 48.0851 6.91135L47.7804 7.13296C47.4895 7.34072 47.2818 7.40997 47.1294 7.40997C46.9217 7.40997 46.8109 7.29917 46.7278 7.22991C46.4231 6.98061 46.0629 6.80055 45.5366 6.80055Z"
                        fill="#222A3F"
                    />
                </svg>
            )}
        </>
    );
};

export default PlanBadge;
