import React from 'react';

import { PageSectionHeaderProps } from 'ui-kit/page-section-header/page-section-header.props';

import './page-section-header.style.scss';

const PageSectionHeader = ({ eyebrowText, title, subTitle, icon }: PageSectionHeaderProps) => {
    return (
        <div className="page-section-header">
            {icon && <div className="icon">{icon}</div>}
            <div className="h5 eyebrow-text">{eyebrowText}</div>
            <h1 className="h2 title">{title}</h1>
            <div className="spacer" />
            {subTitle && <div className="subTitle">{subTitle}</div>}
        </div>
    );
};

export default PageSectionHeader;
