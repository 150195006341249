import type { AxiosResponse } from 'axios';
import axiosClientAws from 'util/axiosClientAws';
import { PhysicianInfoObjectPayload, PhysicianDetailLookupObjectPayload } from 'types/physician';

export interface PhysicianInfoPayload {
    firstName: string;
    lastName: string;
    state: string;
    onSuccess?: (response: PhysicianInfoObjectPayload[]) => void;
    onFailure?: () => void;
}

export interface PhysicianDetailLookupPayload {
    npi: number;
    /** 2 character abbr for state */
    state: string;
    onFailure?: () => void;
    onSuccess?: (physicianDetail: PhysicianDetailLookupObjectPayload) => void;
}

/**
 * Anything related to physician/prescriber lookup, details etc.
 */
export default class PhysicianService {
    /**
     * Physician detail lookup
     */
    static physicianDetailLookup() {
        return {
            get: (payload: PhysicianDetailLookupPayload): Promise<PhysicianDetailLookupObjectPayload> => {
                const request = axiosClientAws(
                    `physician/details?npi=${encodeURIComponent(payload.npi)}&state-code=${encodeURIComponent(
                        payload.state
                    )}`
                );
                return request.then((response: AxiosResponse<PhysicianDetailLookupObjectPayload>) => response.data);
            }
        };
    }

    /**
     * Physician information
     */
    static physicianInfo() {
        return {
            get: (payload: PhysicianInfoPayload): Promise<any> => {
                let path = `physician/information?first-name=${encodeURIComponent(
                    payload.firstName
                )}&last-name=${encodeURIComponent(payload.lastName)}&state-code=${encodeURIComponent(payload.state)}`;
                const request = axiosClientAws(path);
                return request.then((response: any) => response.data);
            }
        };
    }
}
