import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// UI Kit & components
import Button from 'ui-kit/button/button';
import { ButtonProps, ButtonVariantProps } from 'ui-kit/button/button.props';

// Components
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import EmptyAlertBox from 'components/medicine-cabinet-cart/empty-alert-box/empty-alert-box';
import ColumnSectionEditModeToggle from 'components/sidebar-column/column-section-toggle/column-section-toggle.component';

// Pages
import { FailureUpdateCartModalContent } from 'pages/secure/cart/intra-page-items/_cart-update-modal-item';
import PaymentForm from 'pages/secure/profile/intra-page-items/_payment.item';
import UpdateProfileModalContent, {
    FailureUpdateProfileModalContent
} from 'pages/secure/profile/intra-page-items/_profile-update-modal.item';

// State
import { accountGetAllCreditCardsRoutine, accountUpdateCreditCardRoutine } from 'state/account/account.routines';
import { accountProfilIsCaregiverSelector } from 'state/account/account.selectors';
import { CreditCardPayload } from 'state/account/account.services';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { cartUpdatePaymentRoutine, getCartRoutine } from 'state/cart/cart.routines';
import { isCartWithoutPaymentMethodSelector } from 'state/cart/cart.selectors';

// Types
import { OrderBillShip } from 'types/order-prescription';
import { PaymentCardProps } from 'types/payment';

// Utils
import { noop } from 'util/function';
import { getCreditCardEnding } from 'util/payments';

// Hooks
import useWindowDimensions from 'hooks/useWindowDimensions';

// Payment
import PaymentItem, { PaymentItemProps } from './payment-item/payment-item.component';
import './payment-method.style.scss';
import SelectedPayment from './selected-payment/selected-payment.component';

export interface PaymentMethodsProps extends Pick<PaymentItemProps, 'showSetDefaultLink' | 'showSelectCardRadioInput'> {
    addNewPaymentButtonLabel?: string;
    buttonVariant?: typeof ButtonVariantProps;
    orderBillShip: OrderBillShip;
    paymentData: CreditCardPayload[];
    paymentRequiredMessage?: string;
    selectedCardSeqNum?: string;
    selectedPaymentMethod?: CreditCardPayload;
    onCardSelectionChange?: (newSelectedCard: CreditCardPayload) => void;
    onDefaultCardChanged?: (newDefaultCard: CreditCardPayload) => void;
}

interface UpdatePrimaryCreditCardOptions {
    showPrimaryPaymentUpdatedModal?: boolean;
}

const PaymentMethod: React.FC<PaymentMethodsProps> = (props) => {
    const {
        addNewPaymentButtonLabel,
        buttonVariant = 'primary',
        onDefaultCardChanged = noop,
        paymentData,
        paymentRequiredMessage,
        showSelectCardRadioInput = false,
        showSetDefaultLink = false,
        selectedPaymentMethod,
        orderBillShip
    } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const isCaregiver = useSelector(accountProfilIsCaregiverSelector);
    const isCartWithoutPayment = useSelector(isCartWithoutPaymentMethodSelector);
    const hasPaymentData = paymentData.length > 0;
    const isInnerWidthViewportRef = React.useRef<boolean>(false);

    const handleCardSelectionChange = React.useCallback<NonNullable<PaymentMethodsProps['onCardSelectionChange']>>(
        (newSelectedCard) => {
            if (!newSelectedCard || !orderBillShip) {
                return;
            }

            const updatedOrderBillShip: OrderBillShip = {
                ...orderBillShip,
                paymentCardSeqNum: newSelectedCard.cardSeqNum,
                dependentPaymentSeqNum: isCaregiver ? orderBillShip.dependentPaymentSeqNum : newSelectedCard.cardSeqNum
            };

            dispatch(
                cartUpdatePaymentRoutine.trigger({
                    ...updatedOrderBillShip,
                    onSuccess: () => {
                        dispatch(getCartRoutine.trigger());
                        dispatch(
                            openModal({
                                showClose: true,
                                className: 'prescription-modal',
                                bodyContent: (
                                    <BirdiModalContent
                                        icon={'success'}
                                        title={t('modals.paymentMethods.success.title')}
                                        body={t('modals.paymentMethods.success.description')}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.paymentMethods.success.cta'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        },
                                        dataGALocation: 'ReviewOrderUpdateCart'
                                    }
                                ]
                            })
                        );
                    },
                    onFailure: () => {
                        dispatch(
                            openModal({
                                showClose: true,
                                className: 'prescription-modal',
                                bodyContent: (
                                    <FailureUpdateCartModalContent area={t('modals.updateCart.areas.payment')} />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.updateCart.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        },
                                        dataGALocation: 'ReviewOrderUpdateCartError'
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        },
        [dispatch, orderBillShip, t]
    );

    const handleCardSelected = React.useCallback(
        (card: CreditCardPayload): PaymentCardProps['onSelectCardRadioInputChange'] =>
            () => {
                handleCardSelectionChange(card);
            },
        [handleCardSelectionChange]
    );

    const handleUpdatePrimaryCreditCard = React.useCallback(
        (newPrimaryCard: CreditCardPayload, opts: UpdatePrimaryCreditCardOptions = {}) => {
            const { showPrimaryPaymentUpdatedModal = false } = opts;

            if (!newPrimaryCard) {
                return;
            }

            dispatch(
                accountUpdateCreditCardRoutine.trigger({
                    ...newPrimaryCard,
                    onSuccess: () => {
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: showPrimaryPaymentUpdatedModal ? (
                                    <BirdiModalContent
                                        icon={'success'}
                                        title={t('modals.updateProfile.title')}
                                        body={t('modals.primaryPaymentMethodUpdated.body', {
                                            paymentMethod: `<strong>${t('pages.profile.payment.cardTypeAndNum', {
                                                cardNumber: getCreditCardEnding(newPrimaryCard.secureCardNumber),
                                                cardType: newPrimaryCard?.cardType
                                            })}</strong>`
                                        })}
                                    />
                                ) : (
                                    <UpdateProfileModalContent area={t('modals.updateProfile.areas.payment')} />
                                ),
                                onClose: () => {
                                    dispatch(accountGetAllCreditCardsRoutine.trigger());
                                },
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                            dispatch(accountGetAllCreditCardsRoutine.trigger());
                                        }
                                    }
                                ]
                            })
                        );

                        onDefaultCardChanged(newPrimaryCard);
                    },
                    onFailure: () => {
                        dispatch(
                            openModal({
                                showClose: true,
                                type: 'danger',
                                size: 'lg',
                                headerContent: (
                                    <BirdiModalHeaderDanger icon="alert" headerText={t('modals.updateProfile.error')} />
                                ),
                                bodyContent: (
                                    <FailureUpdateProfileModalContent area={t('modals.updateProfile.areas.payment')} />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        }
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        },
        [dispatch, onDefaultCardChanged, t]
    );

    const handleAddNewPaymentClick = React.useCallback<NonNullable<ButtonProps['onClick']>>(() => {
        dispatch(
            openModal({
                showClose: true,
                contentClassName: 'prescription-add-payment-modal',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        title={t('modals.addPaymentModal.title')}
                        body={
                            <PaymentForm
                                centerFormSubmit={true}
                                isInModal={true}
                                onCancel={() => {
                                    dispatch(closeModal({}));
                                }}
                                hasPaymentData={hasPaymentData}
                            />
                        }
                    />
                ),
                ctas: []
            })
        );
    }, [dispatch, t, hasPaymentData]);

    const handleSetDefaultClick = React.useCallback(
        (card: CreditCardPayload): PaymentCardProps['onSetDefaultClick'] =>
            () => {
                handleUpdatePrimaryCreditCard(card);
            },
        [handleUpdatePrimaryCreditCard]
    );

    // DRX-3353: This ticket adds validation for invitee users and new insured users.
    // These users can create and place an order without a registered payment method.
    // The validation checks if the current cart lacks a cardSeqNum. When the user adds
    // a new payment method, the order will be updated to select the new payment method.
    useEffect(() => {
        if (isCartWithoutPayment && paymentData.length === 1 && !isCaregiver) {
            handleCardSelectionChange(paymentData[0]);
        }
    }, [handleCardSelectionChange, isCartWithoutPayment, paymentData, isCaregiver]);

    useEffect(() => {
        if (!hasPaymentData) {
            dispatch(accountGetAllCreditCardsRoutine.trigger());
        }
    }, []);

    useEffect(() => {
        if (width < 1200) {
            isInnerWidthViewportRef.current = true;
        } else {
            isInnerWidthViewportRef.current = false;
        }
    }, [width]);

    return (
        <ColumnSectionEditModeToggle
            className="payment-method"
            headerClassName="payment-method__header"
            title={t('components.medicineCabinetCart.paymentMethod.title')}
            isToggleDisabled={!hasPaymentData}
            editModeContent={
                <section>
                    {paymentData.length > 0 ? (
                        paymentData
                            .filter((filterCard) => filterCard.cardActive && filterCard.cardIsExpired !== true)
                            .map((card, index) => (
                                <React.Fragment key={`payment-card-${card.secureCardNumber}-${index}`}>
                                    <PaymentItem
                                        index={index}
                                        cardHolder={card.cardName}
                                        cardType={card.cardType}
                                        endingDigits={getCreditCardEnding(card.secureCardNumber)}
                                        expiryMonth={card.cardMonthNum}
                                        expiryYear={card.cardYear.slice(-2)}
                                        isDefaultCard={Boolean(card.defaultCard)}
                                        isSelectCardRadioInputChecked={
                                            card.cardSeqNum === selectedPaymentMethod?.cardSeqNum
                                        }
                                        key={`payment-card-${card.secureCardNumber}-${index}`}
                                        onSelectCardRadioInputChange={handleCardSelected(card)}
                                        onSetDefaultClick={handleSetDefaultClick(card)}
                                        showSelectCardRadioInput={showSelectCardRadioInput}
                                        showSetDefaultLink={showSetDefaultLink}
                                        isInnerWidthViewport={isInnerWidthViewportRef}
                                    />

                                    {index === paymentData.length - 1 && addNewPaymentButtonLabel && (
                                        <Button
                                            plusIcon
                                            IconType="secondary"
                                            className={`${buttonVariant ? ' text-cerulean sm-full' : 'p-0'}`}
                                            dataGAFormName="Payments"
                                            label={addNewPaymentButtonLabel}
                                            onClick={handleAddNewPaymentClick}
                                            type="button"
                                            variant={buttonVariant}
                                        />
                                    )}
                                </React.Fragment>
                            ))
                    ) : (
                        <div className="empty-section">
                            {paymentRequiredMessage && addNewPaymentButtonLabel && (
                                <>
                                    <EmptyAlertBox text={paymentRequiredMessage} />
                                    <Button
                                        plusIcon
                                        IconType="secondary"
                                        className={`${buttonVariant ? ' text-cerulean sm-full' : 'p-0'}`}
                                        dataGAFormName="Payments"
                                        label={addNewPaymentButtonLabel}
                                        onClick={handleAddNewPaymentClick}
                                        type="button"
                                        variant={buttonVariant}
                                    />
                                </>
                            )}
                        </div>
                    )}
                </section>
            }
        >
            <>
                <SelectedPayment
                    isInnerWidthViewport={isInnerWidthViewportRef}
                    selectedPaymentMethod={selectedPaymentMethod}
                    creditCardsData={paymentData}
                />
                {!hasPaymentData && addNewPaymentButtonLabel && (
                    <Button
                        plusIcon
                        IconType="secondary"
                        className={`${buttonVariant ? ' text-cerulean sm-full' : 'p-0'} pt-2`}
                        dataGAFormName="Payments"
                        label={addNewPaymentButtonLabel}
                        onClick={handleAddNewPaymentClick}
                        type="button"
                        variant={buttonVariant}
                    />
                )}
            </>
        </ColumnSectionEditModeToggle>
    );
};

export default PaymentMethod;
