import { useDispatch, useSelector } from 'react-redux';
import { AddressParts } from 'state/usps/usps.reducers';
import { verifyAddressRoutine, verifyZipRoutine } from 'state/usps/usps.routines';

import { uspsStateSelector } from 'state/usps/usps.selectors';
import { AddressValidateResponse, ZipValidateResponse } from 'util/usps';

export interface VerifyAddressProps {
    address: AddressParts;
    onSuccess?: (validationResponse: AddressValidateResponse) => void;
    onFailure?: (validationResponse: AddressValidateResponse) => void;
}

export interface VerifyZipProps {
    zip: string;
    onSuccess?: (validationResponse: ZipValidateResponse) => void;
    onFailure?: (validationResponse: ZipValidateResponse) => void;
}

export const useAddressVerification = () => {
    const { isBusy, verifiedAddress } = useSelector(uspsStateSelector);

    const dispatch = useDispatch();

    const verifyAddress = ({ address, onSuccess, onFailure }: VerifyAddressProps) => {
        if (isBusy) return;
        dispatch(verifyAddressRoutine({ address, onSuccess, onFailure }));
    };

    const verifyZip = ({ zip, onSuccess, onFailure }: VerifyZipProps) => {
        if (isBusy) return;
        dispatch(verifyZipRoutine({ zip, onSuccess, onFailure }));
    };

    return {
        isBusy,
        verifiedAddress,
        verifyAddress,
        verifyZip
    };
};
