import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

export const membershipRegistrationSelector = (state: RootState) => state.membershipRegistrationReducer;

export const membershipRegistrationInitialValuesSelector = createSelector(
    membershipRegistrationSelector,
    (membershipRegistration) => {
        return membershipRegistration.formValues;
    }
);

export const membershipRegistrationGendersListSelector = createSelector(
    membershipRegistrationSelector,
    (membershipRegistration) => {
        // DRX-2857: Hide Unknown gender from the UI
        return membershipRegistration.gendersResult.filter((gender) => gender.genderCode !== 'U');
    }
);

export const membershipRegistrationRacesListSelector = createSelector(
    membershipRegistrationSelector,
    (membershipRegistration) => {
        return membershipRegistration.racesResult;
    }
);

export const membershipRegistrationEthnicitiesListSelector = createSelector(
    membershipRegistrationSelector,
    (membershipRegistration) => {
        return membershipRegistration.ethnicitiesResult;
    }
);

export const membershipRegistrationValidateInsuranceSelector = createSelector(
    membershipRegistrationSelector,
    (membershipRegistration) => {
        return membershipRegistration.insuranceResult;
    }
);

export const membershipRegistrationFormValuesSelector = createSelector(
    membershipRegistrationSelector,
    (membershipRegistration) => {
        return membershipRegistration.formValues;
    }
);

export const membershipRegistrationIsCaliforniaUserSelector = createSelector(
    membershipRegistrationSelector,
    (membershipRegistration) => {
        return membershipRegistration.isCaliforniaUser;
    }
);

export const membershipRegistrationSelectPlanListSelector = createSelector(
    membershipRegistrationSelector,
    (membershipRegistration) => {
        return membershipRegistration.selectPlanResult;
    }
);

export const membershipRegistrationPaymentModelSelector = createSelector(
    membershipRegistrationSelector,
    (membershipRegistration) => {
        return membershipRegistration.paymentModel;
    }
);

export const membershipRegistrationValidPromoCodeSelector = createSelector(
    membershipRegistrationSelector,
    (membershipRegistration) => {
        return membershipRegistration.isPromoCodeValid;
    }
);

export const membershipRegistrationResponseErrorPayloadMembershipSelector = createSelector(
    membershipRegistrationSelector,
    (membershipRegistration) => {
        return membershipRegistration.responseErrorPayload;
    }
);

export const membershipRegistrationSelectedPlanId = createSelector(
    membershipRegistrationSelector,
    (membershipRegistration) => {
        return membershipRegistration.selectedPlanId;
    }
);

export const membershipRegistrationIsValidatingInviteSelector = createSelector(
    membershipRegistrationSelector,
    (membershipRegistration) => {
        return membershipRegistration.isValidatingInvite;
    }
);

export const membershipRegistrationValidateInviteSelector = createSelector(
    membershipRegistrationSelector,
    (membershipRegistration) => {
        return membershipRegistration.validateInviteData;
    }
);

export const membershipRegistrationAutoRefillSelector = createSelector(
    membershipRegistrationSelector,
    (membershipRegistration) => {
        return membershipRegistration.patientPlanAutoRefillFlag && !membershipRegistration.AutoRefill;
    }
);
