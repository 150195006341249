import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ImageState {
    imageSrc: string;
    isPhotoComponentDisabled: boolean;
}

const initialState: ImageState = {
    imageSrc: '',
    isPhotoComponentDisabled: false
};

const imageSlice = createSlice({
    name: 'image',
    initialState,
    reducers: {
        setImageSrc: (state, action: PayloadAction<string>) => {
            state.imageSrc = action.payload;
        },
        setIsPhotoComponentEnabled: (state, action: PayloadAction<boolean>) => {
            state.isPhotoComponentDisabled = action.payload;
        }
    }
});

export const { setImageSrc, setIsPhotoComponentEnabled } = imageSlice.actions;

export default imageSlice.reducer;
