import './spinner.style.scss';

const SpinnerInline = () => {
    return (
        <div className="spinner-inline">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="60px" height="15px" viewBox="0 0 128 32">
                <rect x="0" y="0" width="100%" height="100%" fill="#FFFFFF" />
                <circle fill="#cccccc" cx="0" cy="0" r="11" transform="translate(16 16)">
                    <animateTransform
                        attributeName="transform"
                        type="scale"
                        additive="sum"
                        values="1;1.42;1;1;1;1;1;1;1;1"
                        dur="1050ms"
                        repeatCount="indefinite"
                    ></animateTransform>
                </circle>
                <circle fill="#cccccc" cx="0" cy="0" r="11" transform="translate(64 16)">
                    <animateTransform
                        attributeName="transform"
                        type="scale"
                        additive="sum"
                        values="1;1;1;1;1.42;1;1;1;1;1"
                        dur="1050ms"
                        repeatCount="indefinite"
                    ></animateTransform>
                </circle>
                <circle fill="#cccccc" cx="0" cy="0" r="11" transform="translate(112 16)">
                    <animateTransform
                        attributeName="transform"
                        type="scale"
                        additive="sum"
                        values="1;1;1;1;1;1;1;1.42;1;1"
                        dur="1050ms"
                        repeatCount="indefinite"
                    ></animateTransform>
                </circle>
            </svg>
        </div>
    );
};

export default SpinnerInline;
