import React from 'react';
import classNames from 'classnames';

import { DrugInfoProps } from './drug-info.props';
import sanitizeHtml from 'sanitize-html';

import './drug-info.style.scss';

const DrugInfo = ({ title, error, details, className = '' }: DrugInfoProps) => {
    const classes = classNames(className, 'drug-info');

    return (
        <div className={classes}>
            {title && <h3 className="drug-info__section-title">{title}</h3>}
            {error && <div>{error}</div>}
            {details && (
                <div
                    className="drug-info__info mb-5"
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(details, {
                            allowedAttributes: {
                                ...sanitizeHtml.defaults.allowedAttributes,
                                '*': ['class']
                            }
                        })
                    }}
                />
            )}
        </div>
    );
};

export default DrugInfo;
