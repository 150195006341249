import React, { ReactElement } from 'react';

const UploadFilesIcon = ({ className, style }: { className?: string; style?: CSSProperties }): ReactElement => {
    return (
        <div className={`upload-files-icon ${className ?? ''}`} style={style}>
            <svg width="34px" height="34px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <circle fill="#1A497F" cx="15" cy="15" r="15"></circle>
                <g strokeLinecap="round" strokeLinejoin="round" stroke="#FFFFFF" strokeWidth="1.5" fill="none">
                    <g transform="translate(8.75,8)">
                        <g transform="translate(1.861455, 0.000000)">
                            <path d="M5.79914861,5.15479876 L8.37654799,5.15479876 L4.18827399,0 L0,5.15479876 L2.57739938,5.15479876 L2.57739938,8.05437307 C2.57739938,8.9442202 3.29842686,9.66524768 4.18827399,9.66524768 C5.07812113,9.66524768 5.79914861,8.9442202 5.79914861,8.05437307 C5.79914861,7.82128618 5.79914861,7.82334186 5.79914861,7.66401757 C5.79914861,7.53391899 5.79914861,5.44174293 5.79914861,5.15479876 Z"></path>
                        </g>
                        <polyline points="12.6578947 11.1687307 12.6578947 13.406276 0 13.406276 0 11.2020172"></polyline>
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default UploadFilesIcon;
