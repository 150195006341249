import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

import type { PharmacySearchGroup, PharmacySearchProps } from 'types/pharmacy';

import { pharmacyGroupByRoutine, pharmacyLookUpRoutine } from './pharmacy.routines';

export interface PharmacyState {
    isPastPharmacyFlow?: boolean;
    lastPharmacySearchFormValues?: PharmacySearchProps;
    pharmacyLookupResult?: [];
    pharmacyGroupByResult?: PharmacySearchGroup[];
    isPharmacyDetailLoading?: boolean;
    isSearchingPharmacies?: boolean;
    isPharmacySearchFlow?: boolean;
    showPharmacyList?: boolean;
}

export const initialState: PharmacyState = {
    isPastPharmacyFlow: false,
    lastPharmacySearchFormValues: undefined,
    pharmacyLookupResult: [],
    pharmacyGroupByResult: [],
    isPharmacyDetailLoading: false,
    isSearchingPharmacies: false,
    isPharmacySearchFlow: true,
    showPharmacyList: false
};

const pharmacySlice = createSlice({
    name: 'pharmacy',
    initialState,
    reducers: {
        clearPharmacySearchResults(state) {
            state.pharmacyLookupResult = initialState.pharmacyLookupResult;
            state.pharmacyGroupByResult = initialState.pharmacyGroupByResult;
        },
        resetPharmacySearch(state) {
            state.isPastPharmacyFlow = initialState.isPastPharmacyFlow;
            state.isPharmacySearchFlow = initialState.isPharmacySearchFlow;
            state.lastPharmacySearchFormValues = initialState.lastPharmacySearchFormValues;
            state.pharmacyLookupResult = initialState.pharmacyLookupResult;
            state.pharmacyGroupByResult = initialState.pharmacyGroupByResult;
        },
        setIsPharmacySearchFlow(state, action: PayloadAction<boolean>) {
            state.isPharmacySearchFlow = action.payload;
        },
        setIsPastPharmacyFlow(state, action: PayloadAction<boolean>) {
            state.isPastPharmacyFlow = action.payload;
        },
        setLastPharmacySearchFormValues(state, action: PayloadAction<PharmacySearchProps | undefined>) {
            state.lastPharmacySearchFormValues = action.payload;
        },
        setPharmacyListVisibility: (state, action: PayloadAction<boolean>) => {
            state.showPharmacyList = action.payload;
        }
    },
    extraReducers: ({ addCase }) => {
        /**
         * Pharmacy search/GroupBy
         */
        addCase(pharmacyGroupByRoutine.TRIGGER, (state) => {
            return produce(state, (draftState) => {
                draftState.isSearchingPharmacies = true;
                draftState.pharmacyGroupByResult = [];
            });
        });
        addCase(pharmacyGroupByRoutine.SUCCESS, (state, { payload }: PayloadAction<PharmacySearchGroup[]>) => {
            return produce(state, (draftState) => {
                draftState.isSearchingPharmacies = false;
                draftState.pharmacyGroupByResult = payload;
            });
        });
        addCase(pharmacyGroupByRoutine.FAILURE, (state) => {
            return produce(state, (draftState) => {
                draftState.isSearchingPharmacies = false;
                draftState.pharmacyGroupByResult = [];
            });
        });

        /**
         * Pharmacy lookup
         */
        addCase(pharmacyLookUpRoutine.TRIGGER, (state) => {
            return produce(state, (draftState) => {
                draftState.pharmacyLookupResult = [];
                draftState.showPharmacyList = initialState.showPharmacyList;
            });
        });
        addCase(pharmacyLookUpRoutine.SUCCESS, (state, { payload }: PayloadAction<[]>) => {
            return produce(state, (draftState) => {
                draftState.pharmacyLookupResult = payload;
                draftState.showPharmacyList = true;
            });
        });
        addCase(pharmacyLookUpRoutine.FAILURE, (state) => {
            return produce(state, (draftState) => {
                draftState.pharmacyLookupResult = [];
                draftState.showPharmacyList = initialState.showPharmacyList;
            });
        });
    }
});

export const {
    clearPharmacySearchResults,
    resetPharmacySearch,
    setIsPharmacySearchFlow,
    setIsPastPharmacyFlow,
    setLastPharmacySearchFormValues,
    setPharmacyListVisibility
} = pharmacySlice.actions;

export default pharmacySlice.reducer;
