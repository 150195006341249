import React from 'react';

import { MobileToggleProps } from 'ui-kit/mobile-toggle/mobile-toggle.props';

import './mobile-toggle.style.scss';

const MobileToggle = ({ onClick, style, variant }: MobileToggleProps) => {
    const getVariantStyle = (variant: string | undefined) => {
        switch (variant) {
            case 'light': {
                return {
                    stroke: '#FFFFFF'
                };
            }
            default: {
                return {
                    stroke: '#039fd3'
                };
            }
        }
    };

    const variantStyle = getVariantStyle(variant);

    return (
        <div className="mobile-toggle-container" style={style}>
            <svg
                className="mobile-toggle"
                fill="none"
                viewBox="0 0 30 30"
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
                onClick={onClick ?? undefined}
            >
                <g stroke={variantStyle.stroke}>
                    <circle cx="15" cy="15" r="13.5" strokeWidth="2" />
                    <g strokeLinecap="round" strokeWidth="3">
                        <path d="m10.2656 15h9.4667" />
                        <path d="m10.2656 10.3333h4.1334" />
                        <path d="m10.2656 19.6666h9.4667" />
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default MobileToggle;
