import { graphql } from 'gatsby';

import Hero from 'components/hero/hero.component';
import { HeroProps } from 'components/hero/hero.props';

// backgroundColor?: string;
// backgroundImage?: FluidObject;
// eyebrowText?: string;
// title: string;
// text: string;
// image?: FluidObject;
// imagePosition?: 'top-right' | 'top-right-breakout' | 'bottom-right' | 'bottom-right-breakout';
// linkTo: string;
// linkLabel: string;
// size?: string;

export default function RoundedHeroProvider({
    paragraph,
    sectionIndex
}: {
    paragraph: GatsbyTypes.RoundedHeroProviderFragment;
    sectionIndex: number;
}) {
    const formattedTitle = paragraph.field_rhc_formatted_title ? paragraph.field_rhc_formatted_title.processed : null;
    const formattedBody = paragraph.field_rhc_formatted_body ? paragraph.field_rhc_formatted_body.processed : null;
    const title = paragraph.field_rhc_title ? paragraph.field_rhc_title : 'ERROR: Title not found';
    const body = paragraph.field_rhc_body ? paragraph.field_rhc_body : 'ERROR: Text not found';

    const heroProps: HeroProps = {
        eyebrowText: paragraph.field_rhc_eyebrow,
        title: formattedTitle || title,
        text: formattedBody || body,
        linkTo: paragraph.field_rhc_cta_button?.uri ? paragraph.field_rhc_cta_button?.uri : '/404',
        linkLabel: paragraph.field_rhc_cta_button?.title
            ? paragraph.field_rhc_cta_button?.title
            : 'ERROR: Link Title not found',
        image: paragraph.relationships?.field_rhc_hero_image?.localFile,
        style: paragraph.field_rhc_style === 'regular' ? undefined : paragraph.field_rhc_style,
        imagePosition:
            paragraph.field_rhc_image_placement === 'center' ? undefined : paragraph.field_rhc_image_placement,
        imageAltText: paragraph?.field_rhc_hero_image?.alt,
        backgroundColor:
            paragraph.field_rhc_background_color === 'white' ? undefined : paragraph.field_rhc_background_color,
        sectionIndex: sectionIndex
    };
    return <Hero {...heroProps} />;
}

export const query = graphql`
    fragment RoundedHeroProvider on paragraph__rounded_hero_component {
        id
        field_rhc_eyebrow
        field_rhc_title
        field_rhc_formatted_title {
            processed
        }
        field_rhc_body
        field_rhc_formatted_body {
            processed
        }
        field_rhc_cta_button {
            title
            uri: url
        }
        field_rhc_style
        field_rhc_background_color
        field_rhc_image_placement
        relationships {
            field_rhc_hero_image {
                localFile {
                    childImageSharp {
                        gatsbyImageData(formats: [AUTO, WEBP])
                    }
                }
            }
        }
        field_rhc_hero_image {
            alt
        }
    }
`;
