import { FC } from 'react';
import classNames from 'classnames';
import './column-section.style.scss';

export interface ColumnSectionProps {
    children?: React.ReactNode;
    headerAction?: React.ReactNode;
    title: string;
    className?: string;
    headerClassName?: string;
    bodyClassName?: string;
    titleClassName?: string;
}

const ColumnSection: FC<ColumnSectionProps> = ({
    children,
    className,
    title,
    headerClassName,
    titleClassName,
    bodyClassName,
    headerAction
}) => {
    const hasChildren = !!children;
    return (
        <div className={classNames('column-section', className)}>
            <div className={classNames('column-section__header', headerClassName)}>
                <h4 className={titleClassName}>{title}</h4>
                {headerAction}
            </div>
            {hasChildren && <div className={classNames('column-section__body', bodyClassName)}>{children}</div>}
        </div>
    );
};

export default ColumnSection;
