import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import HealthProfileDisplay from 'components/health-profile/health-profile.component';
import { HealthProfileBubbleUpdateEvent } from 'components/health-profile/health-profile.props';
import ProfileLayout from 'components/layouts/profile/profile.layout';

import { accountFetchHealthConditionsRoutine } from 'state/account/account.routines';
import { accountProfileSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    medicalConditionsAllergiesDetailsRoutine,
    medicalConditionsDetailsRoutine
} from 'state/medical-conditions/medical-conditions.routines';

import { getPhoneNumber } from 'util/globalVariables';

import { useHealthConditions } from 'hooks/useHealthConditions';

export const HealthConditionsWarningModalContent = ({ translation }: { translation: TFunction<'translation'> }) => {
    return (
        <BirdiModalContentAlt
            subTitle={translation('modals.prescription.addAllergy.subTitle', {
                phoneNumber: getPhoneNumber({})
            })}
        />
    );
};

const HealthProfile = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const profileObject = useSelector(accountProfileSelector);
    const isMembershipHealthConditions = profileObject?.hasMembership;

    const healthConditions = useHealthConditions(isMembershipHealthConditions);

    const handleUpdateConditionsClick = ({ action, update }: HealthProfileBubbleUpdateEvent) => {
        dispatch(
            openModal({
                showClose: false,
                type: 'primary',
                size: 'lg',
                className: 'modal-health-profile-warning',
                headerContent: <BirdiModalHeaderDanger headerText={t('modals.healthConditions.title')} />,
                bodyContent: <HealthConditionsWarningModalContent translation={t} />,
                ctas: [
                    {
                        label: t('modals.healthConditions.submit'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                            dispatch(action(update));
                        },
                        dataGALocation: 'HealthProfileWarning'
                    }
                ]
            })
        );
    };

    useEffect(() => {
        if (profileObject) {
            if (profileObject?.hasMembership) {
                dispatch(
                    medicalConditionsDetailsRoutine.trigger({
                        epostPatientNumFamily: profileObject?.epostPatientNum
                    })
                );
                dispatch(
                    medicalConditionsAllergiesDetailsRoutine.trigger({
                        epostPatientNumFamily: profileObject?.epostPatientNum
                    })
                );
            } else {
                dispatch(accountFetchHealthConditionsRoutine.trigger());
            }
        }
    }, [dispatch, profileObject]);

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.healthProfile.eyebrowText`)}
            title={t(`pages.profile.healthProfile.title`)}
            heading={t(`pages.profile.healthProfile.heading`)}
            subhead={
                <p
                    className="small"
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(
                            t('pages.profile.healthProfile.subHeading') + getPhoneNumber({ isEnd: true })
                        )
                    }}
                />
            }
        >
            <HealthProfileDisplay
                onUpdateHealthConditions={handleUpdateConditionsClick}
                healthConditions={healthConditions}
                isMembershipHealthConditions={isMembershipHealthConditions}
            />
        </ProfileLayout>
    );
};

export default HealthProfile;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
