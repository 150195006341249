import { CheckCircleIconProps } from './check-circle.props';

const CheckCircle = ({ className, style, variant }: CheckCircleIconProps) => {
    const getVariantStyle = (variant: string | undefined) => {
        switch (variant) {
            case 'light': {
                return { fill: '#FFFFFF', height: '24', width: '24' };
            }
            case 'dark': {
                return { fill: 'rgb(34,42,63)', height: '24', width: '24' };
            }
            case 'alert': {
                return { fill: '#F04C34', height: '24', width: '24' };
            }
            case 'info': {
                return { fill: '#1A497F', height: '24', width: '24' };
            }
            case 'warning': {
                return { fill: '#EF7B4B', height: '24', width: '24' };
            }
            case 'success': {
                return { fill: '#54cda0', height: '24', width: '24' };
            }
            default: {
                return { fill: '#FFFFFF', height: '100%', width: '100%' };
            }
        }
    };

    const variantStyle = getVariantStyle(variant);

    return (
        <div className={className} style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_16840_8223)">
                    <path
                        d="M16.9367 8.19238L10.1675 16.6539L6.78284 14.1155M12 23C18.0752 23 23 18.0752 23 12C23 5.92487 18.0752 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0752 5.92487 23 12 23Z"
                        stroke={variantStyle.fill}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_16840_8223">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default CheckCircle;
