import React from 'react';
import { Provider } from 'react-redux';
import { GlobalLinkProvider } from 'hooks/useGlobalLink';

import createStore from 'state/store';
import MenuWrapper from 'components/menu-wrapper/menu.wrapper';

// Translations
import { I18nextProvider } from 'react-i18next';
import i18n from '../../i18n';

export default ({ element, props }) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is a fresh store for each SSR page
    //  - it will be called only once in the browser when React mounts
    const store = createStore();

    // DRX-1680: Since this file could provide translations to all components,
    // we need to decide whether the chatbot will also have translations within
    // the app or if it will be maintained separately.
    return (
        <Provider store={store}>
            <GlobalLinkProvider>
                <I18nextProvider i18n={i18n}>
                    <MenuWrapper {...props}>{element}</MenuWrapper>
                </I18nextProvider>
            </GlobalLinkProvider>
        </Provider>
    );
};
