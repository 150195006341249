import classNames from 'classnames';
import React from 'react';

import { MapPinIconProps } from './map-pin-icon.props';

const MapPinIcon = ({ className, style, colorHex = '#1A497F' }: MapPinIconProps) => {
    const classes = classNames('modal-close-icon', className);

    return (
        <div className={classes} style={style}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10 9C10 9.26265 10.0517 9.52271 10.1522 9.76537C10.2528 10.008 10.4001 10.2285 10.5858 10.4142C10.7715 10.5999 10.992 10.7472 11.2346 10.8478C11.4773 10.9483 11.7373 11 12 11C12.2627 11 12.5227 10.9483 12.7654 10.8478C13.008 10.7472 13.2285 10.5999 13.4142 10.4142C13.5999 10.2285 13.7473 10.008 13.8477 9.76537C13.9483 9.52271 14 9.26265 14 9C14 8.73735 13.9483 8.47729 13.8477 8.23463C13.7473 7.99198 13.5999 7.77151 13.4142 7.58579C13.2285 7.40007 13.008 7.25275 12.7654 7.15224C12.5227 7.05173 12.2627 7 12 7C11.7373 7 11.4773 7.05173 11.2346 7.15224C10.992 7.25275 10.7715 7.40007 10.5858 7.58579C10.4001 7.77151 10.2528 7.99198 10.1522 8.23463C10.0517 8.47729 10 8.73735 10 9Z"
                    stroke={colorHex}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12 2C13.9891 2 15.8968 2.78741 17.3033 4.18902C18.7099 5.59063 19.5 7.49162 19.5 9.47379C19.5 12.8021 14.625 19.2657 12.735 21.6449C12.6473 21.7556 12.5355 21.845 12.4081 21.9065C12.2806 21.968 12.141 22 11.9994 22C11.8579 22 11.7181 21.968 11.5906 21.9065C11.4632 21.845 11.3515 21.7556 11.2638 21.6449C9.375 19.2645 4.5 12.8021 4.5 9.47379C4.5 7.49162 5.29018 5.59063 6.6967 4.18902C8.10322 2.78741 10.0109 2 12 2Z"
                    stroke={colorHex}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default MapPinIcon;
