import { createSelector } from '@reduxjs/toolkit';
import { ChatbotState } from './chatbot.reducers';
import { RootState } from 'state/store';

export const chatbotSelector = (state: RootState) => state.chatbotReducer;

export const chatbotLexRuntimeSelector = createSelector(
    chatbotSelector,
    (state: ChatbotState) => state.lexRuntimeService
);
