/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import { REACT_APP_IDENTITY_POOL_ID, REACT_APP_BOT_NAME, REACT_APP_REGION } from 'gatsby-env-variables';

const awsmobile = {
    aws_project_region: `${REACT_APP_REGION}`,
    aws_cognito_identity_pool_id: `${REACT_APP_IDENTITY_POOL_ID}`,
    aws_cognito_region: `${REACT_APP_REGION}`,
    oauth: {},
    aws_bots: 'enable',
    aws_bots_config: [
        {
            name: `${REACT_APP_BOT_NAME}`,
            alias: '$LATEST',
            region: `${REACT_APP_REGION}`
        }
    ],
    aws_mobile_analytics_app_id: 'a67396f0bc8e46c381245caafcbf601b',
    aws_mobile_analytics_app_region: `${REACT_APP_REGION}`,
    region: `${REACT_APP_REGION}`
};

export default awsmobile;
