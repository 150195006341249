import React, { useCallback } from 'react';
import classNames from 'classnames';

import { SelectOptionValue } from 'types/select';
import Link from 'ui-kit/link/link';

import NavSelect from 'ui-kit/nav-select/nav-select';
import { SelectChangeEvent } from 'ui-kit/select/select.props';

import {
    IntraPageMenuProps,
    IntraPageMenuItemProps
} from 'components/navigation/intra-page-menu/intra-page-menu.props';

import './intra-page-menu.style.scss';

const IntraPageMenu = <T extends SelectOptionValue>({
    className,
    allMenuItems,
    filteredMenuItems,
    onClick
}: IntraPageMenuProps<T>) => {
    const classes = classNames('', className);

    // Remove the trailing slashes from the pathname. This is only an issue in
    // local environments and prevents from proper matching against menu item
    // paths.
    function cleanPath(location: string) {
        const last = location[location.length - 1];

        if (last === '/') {
            return location.slice(0, -1);
        }
        return location;
    }

    const handleNavSelectChange = ({ option: { value } }: SelectChangeEvent<T>) => {
        const pathname = typeof window !== 'undefined' ? window && cleanPath(window.location.pathname) : '';
        if (pathname !== value.toString()) {
            onClick(value.toString());
        }
    };

    const getDefaultValue = useCallback(() => {
        const pathname = typeof window !== 'undefined' ? window && cleanPath(window.location.pathname) : '';
        const exactMatch = allMenuItems.find((opt) => pathname === opt.value);
        if (exactMatch) {
            return exactMatch;
        } else {
            // We can have a partial match for 'Messages' sub-pages, such as "/secure/profile/messages/contact-us"
            return (
                allMenuItems.find(
                    (opt) => pathname.substring(0, opt.value.toString().length) === opt.value && opt.value !== ''
                ) || allMenuItems[0]
            );
        }
    }, [typeof window !== 'undefined' ? window.location : null]);
    const defaultValue = getDefaultValue();

    return (
        <div className={classes}>
            <NavSelect
                name="intra-page-navSelect"
                className="d-lg-none"
                options={filteredMenuItems}
                onChange={handleNavSelectChange}
                defaultValue={defaultValue}
            />
            <div className="intra-page-menu d-none d-lg-flex" data-ga-location="LeftNav">
                {filteredMenuItems &&
                    filteredMenuItems.map((item: IntraPageMenuItemProps<T>) => (
                        <React.Fragment key={`site-intra-page-${item.label}`}>
                            {item.value && (
                                <Link
                                    to={item.value.toString()}
                                    label={item.label}
                                    partiallyActive={item.partiallyActive}
                                    variant={'nav-sidebar'}
                                    className={item.isChild ? 'nav-sidebar-child' : ''}
                                    dataGALocation={'LeftNav'}
                                />
                            )}
                            {!item.value && (
                                <div className="link" data-variant={'nav-sidebar'}>
                                    <div className="nav-sidebar-parent">{item.label}</div>
                                </div>
                            )}
                        </React.Fragment>
                    ))}
            </div>
        </div>
    );
};

export default IntraPageMenu;
