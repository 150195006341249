import './circle-info-icon.style.scss';

export interface CircleInfoProps {
    color?: string;
    alt?: string;
    variant?: 'primary' | 'secondary';
    className?: string;
}

const CircleInfo = ({ color, alt, className, variant = 'primary' }: CircleInfoProps) => {
    return (
        <>
            {variant === 'primary' ? (
                <svg
                    height="100%"
                    viewBox="0 0 24 24"
                    width="100%"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <path
                        d="m12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1 18h-2v-8h2zm-1-12.25c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25z"
                        fill={color ? color : 'currentColor'}
                    />
                    {alt && <title>{alt}</title>}
                </svg>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68" fill="none">
                    <path
                        d="M34.864 38C36.1771 38 36.5054 37.7201 36.571 36.6007L36.9978 24.3993C37.0306 23.2799 36.7024 23 35.3892 23H33.5837C32.2705 23 31.9422 23.2799 32.0079 24.3993L32.4018 36.6007C32.4675 37.7201 32.7958 38 34.1089 38H34.864ZM34.5 45C36.1234 45 37 43.9474 37 42.5329C37 41.1842 36.1234 40 34.5 40C32.8442 40 32 41.1842 32 42.5329C32 43.9474 32.8442 45 34.5 45Z"
                        fill="#03A4DC"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M34.0015 58C20.7673 58 10 47.2327 10 33.9985C10 20.7673 20.7673 10 34.0015 10C47.2357 10 58.0031 20.7673 58.0031 33.9985C58.0031 47.2327 47.2357 58 34.0015 58ZM34.0015 13.0729C22.4599 13.0729 13.0729 22.4629 13.0729 33.9985C13.0729 45.5401 22.4599 54.9271 34.0015 54.9271C45.5432 54.9271 54.9302 45.5401 54.9302 33.9985C54.9302 22.4629 45.5432 13.0729 34.0015 13.0729Z"
                        fill="#03A4DC"
                    />
                </svg>
            )}
        </>
    );
};

export default CircleInfo;
