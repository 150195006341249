import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'state/store';

export const joinMembershipPlanSelector = (state: RootState) => state.joinMembershipPlanReducer;

export const joinMembershipPlanSecurityTokenSelector = createSelector(
    joinMembershipPlanSelector,
    (joinMembershipPlan) => {
        return joinMembershipPlan.securityToken;
    }
);

export const joinMembershipPlanSecretKeyHashSelector = createSelector(
    joinMembershipPlanSelector,
    (joinMembershipPlan) => {
        return joinMembershipPlan.secretKeyHash;
    }
);

export const joinMembershipPlanUserBearerTokenSelector = createSelector(
    joinMembershipPlanSelector,
    (joinMembershipPlan) => {
        return joinMembershipPlan.bearerToken;
    }
);

export const joinMembershipPlanIsLoadingSelector = createSelector(joinMembershipPlanSelector, (joinMembershipPlan) => {
    return joinMembershipPlan.isLoading;
});

export const joinMembershipPlanInviteListSelector = createSelector(joinMembershipPlanSelector, (joinMembershipPlan) => {
    return joinMembershipPlan.invitationList;
});
