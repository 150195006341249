import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import AttachFiles from 'ui-kit/attach-files/attach-files';
import Button from 'ui-kit/button/button';
import { ButtonProps } from 'ui-kit/button/button.props';
import FormSelect from 'ui-kit/form-select/form-select';
import LoadingMessage from 'ui-kit/loading-message/loading-message';
import Text from 'ui-kit/text/text';
import TextArea from 'ui-kit/text/textarea';

import { accountFetchComposeMessageRoutine, accountPostComposeMessageRoutine } from 'state/account/account.routines';
import { accountMessagesSelector, accountProfileSelector } from 'state/account/account.selectors';
import { ProfileObjectPayload } from 'state/account/account.services';
import { familyProfileGetDependentsRoutine } from 'state/family-profile/family-profile.routines';
import {
    familyProfileDependentsSelector,
    familyProfileIsLoadingSelector
} from 'state/family-profile/family-profile.selectors';

import { messageOptions } from 'const/options';

import { MESSAGE_SCHEMA } from 'schema/messages';

import { Messages } from 'types/messages';

import { mapCaregiverAndFamilyMembers } from 'util/depentent';

export const MessageForm = (props: {
    callbacks: any;
    onMessagesFormCancel?: any;
    centeredButtons: boolean;
    type: 'helpPage' | 'messagesPage';
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const messages = useSelector(accountMessagesSelector);
    const profileObject = useSelector(accountProfileSelector);
    const formName = 'MessageForm';
    const isCaregiver = profileObject?.isCaregiver;
    const familyMembersData = useSelector(familyProfileDependentsSelector);
    const familyMembers = mapCaregiverAndFamilyMembers(profileObject as ProfileObjectPayload, familyMembersData, false);
    const isLoadingFamilyMembers = useSelector(familyProfileIsLoadingSelector);

    useEffect(() => {
        dispatch(accountFetchComposeMessageRoutine.trigger());
        if (isCaregiver) dispatch(familyProfileGetDependentsRoutine.trigger());
    }, [dispatch, profileObject]);

    const submissionCallbacks = (setSubmitting: Function) => ({
        onSuccess: () => {
            const { onSuccess } = props.callbacks;
            if (onSuccess) onSuccess();
            setSubmitting(false);
        },
        onFailure: () => {
            const { onFailure } = props.callbacks;
            if (onFailure) onFailure();
            setSubmitting(false);
        }
    });

    const defineFamilyMemberId = (isCaregiver: boolean, FamilyMemberId: string, profileEposPatientNum: string) => {
        return isCaregiver && FamilyMemberId !== profileEposPatientNum ? { FamilyMemberId } : {};
    };

    const handleFormSubmit = (values: Partial<Messages>, { setSubmitting }: { setSubmitting: Function }) => {
        let messageTemplate = {};

        const patientName = values.patientName as [string];

        const [PersonFirstName, PersonLastName] = isCaregiver
            ? patientName
            : [profileObject?.patientFirstName, profileObject?.patientLastName];

        const FamilyMemberId = isCaregiver ? patientName[patientName.length - 1] : profileObject?.epostPatientNum;

        if (values.messageType === 'Contact Us') {
            messageTemplate = {
                ...messages?.composeMessage.contactUs,
                messageType: 'Contact Us',
                HeaderID: 1,
                MessageID: 1,
                Subject: values.subject,
                CreateDate: new Date().toISOString(),
                Body: values.message,
                Action: 'NEW',
                AttachedFiles: values.attachedFiles,
                WebUserID: profileObject?.epostPatientNum,
                PersonFirstName,
                PersonLastName,
                ...defineFamilyMemberId(
                    isCaregiver as boolean,
                    FamilyMemberId as string,
                    profileObject?.epostPatientNum as string
                ),
                ...submissionCallbacks(setSubmitting)
            };
        } else {
            messageTemplate = {
                ...messages?.composeMessage.askThePharm,
                messageType: 'Ask The Pharmacist',
                Subject: values.subject,
                CreateDate: new Date().toISOString(),
                Body: values.message,
                Action: 'NEW',
                AttachedFiles: values.attachedFiles,
                WebUserID: profileObject?.epostPatientNum,
                PersonFirstName,
                PersonLastName,
                ...defineFamilyMemberId(
                    isCaregiver as boolean,
                    FamilyMemberId as string,
                    profileObject?.epostPatientNum as string
                ),
                Email: profileObject?.patientEmailAddress,
                ...submissionCallbacks(setSubmitting)
            };
        }
        dispatch(accountPostComposeMessageRoutine.trigger(messageTemplate));
    };

    const renderCancelButton = (formik: any) => {
        const commonProps: ButtonProps = {
            label: t('pages.profile.messages.labels.cancel'),
            type: 'button',
            dataGAFormName: formName
        };

        if (props.type === 'messagesPage') {
            return (
                <Button
                    {...commonProps}
                    className="justify-content-center"
                    variant="text-blue-light"
                    onClick={props.onMessagesFormCancel}
                />
            );
        } else {
            return (
                <Button
                    {...commonProps}
                    className="justify-content-center btn-text-dark"
                    variant="link"
                    onClick={() => {
                        formik.resetForm();
                    }}
                />
            );
        }
    };

    return (
        <Formik<Partial<Messages>>
            onSubmit={handleFormSubmit}
            validationSchema={MESSAGE_SCHEMA}
            initialValues={{
                messageType: '',
                subject: '',
                message: '',
                attachedFiles: [],
                patientName: undefined
            }}
        >
            {(formik: any) => (
                <Form
                    id="messages-form"
                    onSubmit={formik.handleSubmit}
                    autoComplete="off"
                    className="text-left"
                    data-ga-form-name={formName}
                >
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <Field
                                name="messageType"
                                component={FormSelect}
                                options={messageOptions}
                                placeholder={t('pages.profile.messages.labels.messageType')}
                                errors={
                                    formik.errors?.messageType
                                        ? t('forms.errorMessages.requiredField', {
                                              label: t('pages.profile.messages.labels.messageType')
                                          })
                                        : undefined
                                }
                                touched={formik.touched.messageType}
                            />
                        </Col>
                        {formik.values.messageType && isCaregiver && (
                            <>
                                <Col xs={12} md={12} lg={12}>
                                    <LoadingMessage
                                        isVisible={isLoadingFamilyMembers}
                                        text={t('pages.profile.messages.loadingFamilyMembers')}
                                    />
                                    {!isLoadingFamilyMembers && familyMembers?.length > 1 && (
                                        <Field
                                            name="patientName"
                                            component={FormSelect}
                                            options={familyMembers}
                                            placeholder={t('pages.profile.messages.labels.patientName')}
                                            errors={
                                                formik.errors?.patientName
                                                    ? t('forms.errorMessages.requiredField', {
                                                          label: t('pages.profile.messages.labels.patientName')
                                                      })
                                                    : undefined
                                            }
                                            touched={formik.touched.patientName}
                                            value={formik.values?.patientName}
                                            defaultValue={formik?.initialValues.patientName}
                                            formikControlled={true}
                                        />
                                    )}
                                </Col>
                            </>
                        )}
                    </Row>
                    {formik.values.messageType && (
                        <>
                            <Row>
                                <Col>
                                    <p className="pt-2 message-label">
                                        {formik.values.messageType === 'Contact Us'
                                            ? t('pages.profile.messages.labels.toGeneral')
                                            : t('pages.profile.messages.labels.toPharmacist')}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Text
                                        label={t('pages.profile.messages.labels.subject')}
                                        name="subject"
                                        onChange={formik.handleChange}
                                        errors={
                                            formik.errors?.subject
                                                ? t('forms.errorMessages.requiredField', {
                                                      label: t('pages.profile.messages.labels.subject')
                                                  })
                                                : undefined
                                        }
                                        touched={formik.touched.subject}
                                        value={formik.values?.subject}
                                        autocomplete="off"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextArea
                                        label={t('pages.profile.messages.labels.message')}
                                        name="message"
                                        rows={5}
                                        onChange={formik.handleChange}
                                        errors={
                                            formik.errors?.message
                                                ? t('forms.errorMessages.requiredField', {
                                                      label: t('pages.profile.messages.labels.message')
                                                  })
                                                : undefined
                                        }
                                        touched={formik.touched.message}
                                        value={formik.values?.message}
                                        maxLength={2000}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Field
                                        fieldName="attachedFiles"
                                        setFieldValue={formik.setFieldValue}
                                        component={AttachFiles}
                                        value={formik.values?.attachedFiles}
                                        disabled={formik.isSubmitting}
                                    />
                                </Col>
                            </Row>

                            <Row className={`mt-3 ` + (props.centeredButtons ? ` text-center` : ``)}>
                                <Col className="d-flex flex-column flex-md-row flex-md-row-reverse">
                                    <Button
                                        async
                                        className="sm-full"
                                        label={t('pages.profile.messages.labels.submit')}
                                        variant="primary"
                                        type="submit"
                                        disabled={
                                            !formik.values.subject.length ||
                                            !formik.values.message.length ||
                                            (isCaregiver &&
                                                isCaregiver &&
                                                familyMembers &&
                                                familyMembers?.length > 1 &&
                                                !formik.values.patientName) ||
                                            !formik.dirty ||
                                            formik.isSubmitting
                                        }
                                        onClick={formik.handleSubmit}
                                        dataGAFormName={formName}
                                        isBusy={formik.isSubmitting}
                                    />

                                    {renderCancelButton(formik)}
                                </Col>
                            </Row>
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
};
