import React from 'react';
import classNames from 'classnames';
import { TrashIconProps } from 'ui-kit/icons/trash/trash-icon.props';
import './trash-icon.style.scss';

const TrashIcon = ({ className, style }: TrashIconProps) => {
    const classes = classNames('trash-icon', className);

    return (
        <div className={classes} style={style}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="0 0 20.102121 22.970169"
                height="22.970169mm"
                width="20.102121mm"
            >
                <g transform="translate(-95.782273,-136.68158)">
                    <g transform="matrix(0.26458333,0,0,0.26458333,94.231979,158.46319)">
                        <path
                            fill="currentColor"
                            d="m 26.1719,4.49219 h 35.3515 q 4.6387,0 7.3731,-2.58789 2.7344,-2.587894 2.9785,-7.22657 l 2.4902,-54.39453 h 3.7598 q 1.5625,0 2.6367,-1.0742 1.0742,-1.0742 1.0742,-2.6367 0,-1.5137 -1.0742,-2.6123 -1.0742,-1.0987 -2.6367,-1.0987 H 9.57031 q -1.51367,0 -2.6123,1.0987 -1.09863,1.0986 -1.09863,2.6123 0,1.5625 1.09863,2.6367 1.09863,1.0742 2.6123,1.0742 h 3.75979 l 2.539,54.39453 q 0.1954,4.687504 2.9053,7.25098 2.71,2.56348 7.3975,2.56348 z m 0.8789,-7.47071 q -1.4649,0 -2.4414,-1.00097 -0.9766,-1.00098 -1.0742,-2.61231 l -2.4903,-53.125 h 45.459 l -2.3437,53.125 q -0.0489,1.61133 -1.0254,2.61231 -0.9766,1.00097 -2.5391,1.00097 z M 26.2207,-64.3066 h 7.8125 v -7.9102 q 0,-1.3672 0.8789,-2.1973 0.8789,-0.83 2.3438,-0.83 h 13.1347 q 1.416,0 2.2949,0.83 0.879,0.8301 0.879,2.1973 v 7.9102 h 7.8613 v -8.3496 q 0,-4.5411 -2.7588,-7.1045 -2.7588,-2.5635 -7.6904,-2.5635 H 36.6211 q -4.8828,0 -7.6416,2.5635 -2.7588,2.5634 -2.7588,7.1045 z m 17.627,55.56637 q 1.3671,0 2.1728,-0.75684 0.8057,-0.75683 0.8057,-1.97753 v -39.3555 q 0,-1.2207 -0.8057,-1.9775 -0.8057,-0.7569 -2.1728,-0.7569 -1.3184,0 -2.1485,0.7569 -0.8301,0.7568 -0.8301,1.9775 v 39.3555 q 0,1.2207 0.8301,1.97753 0.8301,0.75684 2.1485,0.75684 z m -11.2305,0 q 1.3183,0 2.124,-0.75684 0.8057,-0.75683 0.7568,-2.02633 l -1.123,-39.3067 q 0,-1.2695 -0.8057,-2.0019 -0.8056,-0.7325 -2.0752,-0.7325 -1.3183,0 -2.0996,0.7569 -0.7812,0.7568 -0.7812,1.9775 l 1.123,39.3555 q 0.0489,1.2695 0.8301,2.00194 0.7813,0.73243 2.0508,0.73243 z m 22.5098,0 q 1.2207,0 2.0263,-0.73243 0.8057,-0.73244 0.8545,-2.00194 l 1.0742,-39.3555 q 0.0489,-1.2207 -0.7568,-1.9775 -0.8057,-0.7569 -2.124,-0.7569 -1.2696,0 -2.0508,0.7325 -0.7813,0.7324 -0.8301,2.0019 l -1.0742,39.3067 q -0.0488,1.2695 0.7324,2.02633 0.7813,0.75684 2.1485,0.75684 z"
                        />
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default TrashIcon;
