import React from 'react';
import { graphql } from 'gatsby';

import { RichTextProps } from 'components/rich-text/rich-text.props';
import RichText from 'components/rich-text/rich-text.component';

export default function RichTextComponentProvider({
    paragraph,
    sectionIndex
}: {
    paragraph: GatsbyTypes.RichTextComponentProviderFragment;
    sectionIndex: number;
}) {
    const richTextProps: RichTextProps = {
        richText: paragraph.field_rt_rich_text?.processed
            ? paragraph.field_rt_rich_text?.processed
            : paragraph.field_rt_rich_text?.value,
        desktopFullWidth: paragraph.field_rt_destkop_full_width ? paragraph.field_rt_destkop_full_width : false,
        sectionIndex: sectionIndex
    };
    return <RichText {...richTextProps} />;
}

export const query = graphql`
    fragment RichTextComponentProvider on paragraph__rich_text_component {
        id
        field_rt_rich_text {
            processed
        }
        field_rt_destkop_full_width
    }
`;
