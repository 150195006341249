import React, { ReactElement } from 'react';
import Link from 'ui-kit/link/link';
import { Col, Container, Row } from 'react-bootstrap';
import BackgroundImage from 'ui-kit/background-image/background-image';
import './centered-text-hero.style.scss';
import { GatsbyImageType } from 'types/image';
import sanitizeHtml from 'sanitize-html';

export interface CenteredHeroProps {
    eyebrowText: string;
    title: string;
    subtitle: string;
    body: string;
    linkLabel: string;
    linkTo: string;
    backgroundColor?: string;
    image?: GatsbyImageType;
    classNames?: string;
    sectionIndex: number;
    dataGALocation: string;
}

export function CenteredHero({
    eyebrowText,
    title,
    subtitle,
    body,
    image,
    linkLabel,
    linkTo,
    backgroundColor,
    classNames,
    sectionIndex,
    dataGALocation = ''
}: CenteredHeroProps) {
    const allowedTags = ['strong', 'p', 'br'];

    return (
        <div
            className={`centered-hero ${backgroundColor ? backgroundColor : 'light_blue'} ${
                classNames ? classNames : ''
            }`}
        >
            <div className="centered-hero__backgroundColor"></div>
            {image && <BackgroundImage image={image} className={`centered-hero__background`} />}
            <Container fluid="xl">
                <Row className="justify-content-lg-center">
                    <Col xs={12} md={12} lg={6}>
                        <div className="centered-hero__content">
                            <div className={'h5 centered-hero__eyebrowText'}>{eyebrowText}</div>
                            {sectionIndex === 0 && <h1 className={'centered-hero__title'}>{title}</h1>}
                            {sectionIndex > 0 && <h2 className={'h1 centered-hero__title'}>{title}</h2>}
                            {sectionIndex === 0 && subtitle && (
                                <h3
                                    className={'centered-hero__sub-title'}
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeHtml(subtitle, {
                                            allowedTags
                                        })
                                    }}
                                />
                            )}
                            {sectionIndex > 0 && subtitle && (
                                <h3 className={'h3 centered-hero__sub-title'}>{subtitle}</h3>
                            )}
                            <div className={'spacer'}></div>
                            {sectionIndex === 0 && (
                                <h2
                                    className={'h3 centered-hero__text'}
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeHtml(body, {
                                            allowedTags
                                        })
                                    }}
                                />
                            )}
                            {sectionIndex > 0 && (
                                <h3
                                    className={'centered-hero__text'}
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeHtml(body, {
                                            allowedTags
                                        })
                                    }}
                                />
                            )}
                            {linkTo && (
                                <Link
                                    to={linkTo}
                                    label={linkLabel}
                                    variant="cta-button"
                                    dataGALocation={dataGALocation}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
