import { Location } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import SidebarColumnMobile from 'components/sidebar-column/sidebar-column-mobile.component';

// Components
import ChatBot from '../chatbot/chatbot.component';

// Used within gatsby-browser.js to keep the expanded state across pages
const MenuWrapper = ({ children }: any) => {
    const userId = uuidv4();
    const { t } = useTranslation();

    if (typeof window === 'undefined') {
        return null;
    }

    return (
        <Location>
            {({ location }) => {
                const isInMedicineCabinet = location.pathname.includes('/medicine-cabinet');
                const isInEasyRefill = location.pathname.includes('/easy-refill');

                const cartLabel = t('components.medicineCabinetCart.responsiveCart.cartButtonLabel');

                return (
                    <>
                        {children}
                        {isInMedicineCabinet ? (
                            <SidebarColumnMobile cartLabel={cartLabel} />
                        ) : isInEasyRefill ? (
                            <></>
                        ) : (
                            <ChatBot uniqueID={userId} />
                        )}
                    </>
                );
            }}
        </Location>
    );
};

export default MenuWrapper;
