import React, { ReactElement, useState, useEffect } from 'react';
import classNames from 'classnames';

import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';

import withOnKeyDown from 'hoc/withOnKeyDown';
import { SelectOptionValue } from 'types/select';

import { NavSelectProps } from 'ui-kit/nav-select/nav-select.props';
import { SelectOptionProps, SelectDisplayProps, SelectChangeEvent } from 'ui-kit/select/select.props';

import './nav-select.style.scss';

const NavSelectOption = withOnKeyDown(<T extends SelectOptionValue>(opt: SelectOptionProps<T>) => {
    const { label, onClick, onKeyDown } = opt;
    return (
        <div
            role="button"
            tabIndex={0}
            onClick={(e) => (onClick ? onClick(opt) : undefined)}
            onKeyDown={onKeyDown}
            className="nav-select-option"
        >
            {label}
        </div>
    );
});

const NavSelectDisplay = withOnKeyDown(
    <T extends SelectOptionValue>({ isMenuOpen, onClick, onKeyDown, placeholder, value }: SelectDisplayProps<T>) => {
        return (
            <div className="nav-select-display" role="button" tabIndex={0} onClick={onClick} onKeyDown={onKeyDown}>
                <div className="nav-select-display-text">
                    {!value && <div className="nav-select-placeholder">{placeholder}</div>}
                    {value && <div className="nav-select-value">{value}</div>}
                </div>
                <ChevronIcon direction={isMenuOpen ? 'up' : 'down'} />
            </div>
        );
    }
);

const NavSelect = <T extends SelectOptionValue>(props: NavSelectProps<T>): ReactElement => {
    const { className, defaultValue, placeholder, onChange, options = [] } = props;

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [availableOptions, setAvailableOptions] = useState(options.filter((opt) => opt.value !== ''));
    const [selectedOption, setSelectedOption] = useState<SelectOptionProps<T> | undefined>();

    const classes = classNames('nav-select', className, { 'is-open': isMenuOpen }, { 'has-value': selectedOption });

    const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
        const currentTarget = e.currentTarget;

        // Check the newly focused element in the next tick of the event loop
        setTimeout(() => {
            // Check if the new activeElement is a child of the original container
            if (!currentTarget.contains(document.activeElement)) {
                // You can invoke a callback or add custom logic here
                setIsMenuOpen(false);
            }
        }, 0);
    };

    const handleChange = (e: SelectChangeEvent<T>): void => {
        const { option } = e;
        setSelectedOption(option);
        setIsMenuOpen(false);
        if (onChange) onChange(e);
    };

    useEffect(() => {
        if (onChange && defaultValue && !selectedOption) {
            setSelectedOption(defaultValue);
        }
        return function cleanup() {
            setIsMenuOpen(false);
        };
    }, [defaultValue]);

    useEffect(() => {
        if (selectedOption) {
            const nextAvailableOptions = options.filter((opt) => opt.value !== '' && opt.key !== selectedOption.key);
            setAvailableOptions(nextAvailableOptions);
        }
    }, [selectedOption]);

    return (
        <div className={classes} onBlur={handleBlur}>
            <NavSelectDisplay
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                placeholder={placeholder}
                value={selectedOption?.label}
                isMenuOpen={isMenuOpen}
            />
            <div className="nav-select-menu">
                {availableOptions &&
                    availableOptions.map(
                        (opt: SelectOptionProps<T>) =>
                            !opt.hidden &&
                            opt.value && (
                                <NavSelectOption {...opt} onClick={() => handleChange({ key: opt.key, option: opt })} />
                            )
                    )}
            </div>
        </div>
    );
};

export default NavSelect;
