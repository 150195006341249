import { Formik, FormikHelpers } from 'formik';
import { NEW_PRESCRIPTION_SCHEMA, BIRDI_PRICE_SCHEMA } from 'schema/prescription.schema';
import { PrescriptionTypes } from 'types/prescription';
import { ReactElement } from 'react';
import useBirdiPrice from 'hooks/useBirdiPrice';

export interface DrugFormWrapperProps {
    initialSelectValues?: any;
    renderFormikFunction: Function;
    handlePriceSuccess?: Function;
    handleDescriptionSuccess?: Function;
    resetSelectedDrug?: Function;
    isControlled?: boolean;
    isDrugNameEditable?: boolean;
    handleSelectedDrug?: Function;
}

export const DrugFormWrapper = ({
    initialSelectValues,
    renderFormikFunction,
    handlePriceSuccess,
    handleDescriptionSuccess,
    resetSelectedDrug,
    isControlled = false,
    isDrugNameEditable,
    handleSelectedDrug
}: DrugFormWrapperProps): ReactElement => {
    const { getPrice } = useBirdiPrice();

    const initialValues = initialSelectValues
        ? initialSelectValues
        : {
              drugName: '',
              drugDisplayName: '',
              drugForm: '',
              strength: '',
              gpi: '',
              ndc: '',
              qty: '',
              rxNumber: '',
              imageUrl: '',
              genericProductCode: ''
          };

    // Handle the form submit.
    const handleSubmit = (values: Partial<PrescriptionTypes>, actions: FormikHelpers<Partial<PrescriptionTypes>>) => {
        resetSelectedDrug && resetSelectedDrug(values);
        handleSelectedDrug && handleSelectedDrug(values);
        getPrice(values, actions, handlePriceSuccess, handleDescriptionSuccess);
    };
    return (
        <Formik<Partial<PrescriptionTypes>>
            onSubmit={handleSubmit}
            validateOnChange
            validateOnBlur
            validationSchema={isDrugNameEditable ? NEW_PRESCRIPTION_SCHEMA(isControlled) : BIRDI_PRICE_SCHEMA()}
            initialValues={initialValues}
        >
            {(formik) => renderFormikFunction(formik)}
        </Formik>
    );
};
