import { createRoutine } from 'redux-saga-routines';

export const medicalConditionsDetailsRoutine = createRoutine('MEDICAL_CONDITIONS/GET_MEDICAL_CONDITIONS_DETAILS');
export const medicalConditionsAddOrUpdateRoutine = createRoutine(
    'MEDICAL_CONDITIONS/ADD_OR_UPDATE_MEDICAL_CONDITIONS_DETAILS'
);
export const medicalConditionsAllergiesDetailsRoutine = createRoutine('MEDICAL_CONDITIONS/GET_ALLERGIES_DETAILS');
export const medicalConditionsAllergiesAddOrUpdateRoutine = createRoutine(
    'MEDICAL_CONDITIONS/ADD_OR_UPDATE_ALLERGIES_DETAILS'
);
