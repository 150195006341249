import React, { CSSProperties, ReactElement } from 'react';

const SearchIcon = ({ className, style }: { className?: string; style?: CSSProperties }): ReactElement => {
    return (
        <div className={`search-icon ${className ?? ''}`} style={style}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Bounding box" clipPath="url(#clip0_1533_5347)">
                    <path
                        id="Vector"
                        d="M2.05767 11.2379C2.41722 12.0839 2.93988 12.8509 3.59582 13.4949C4.25175 14.1389 5.02812 14.6474 5.88059 14.9914C6.73305 15.3354 7.64493 15.5081 8.56414 15.4997C9.48336 15.4913 10.3919 15.3019 11.2379 14.9423C12.084 14.5828 12.8509 14.0601 13.4949 13.4042C14.1389 12.7482 14.6474 11.9719 14.9914 11.1194C15.3354 10.2669 15.5081 9.35508 15.4997 8.43586C15.4913 7.51665 15.3019 6.6081 14.9423 5.76208C14.5828 4.91606 14.0601 4.14914 13.4042 3.50511C12.7482 2.86108 11.9719 2.35256 11.1194 2.00857C10.2669 1.66458 9.35507 1.49187 8.43586 1.50029C7.51664 1.50872 6.60809 1.69811 5.76207 2.05766C4.91605 2.41721 4.14913 2.93987 3.5051 3.59581C2.86107 4.25175 2.35255 5.02812 2.00857 5.88058C1.66458 6.73305 1.49187 7.64492 1.50029 8.56414C1.50872 9.48335 1.69811 10.3919 2.05767 11.2379Z"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_2"
                        d="M13.5 13.5L18.5 18.5"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1533_5347">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default SearchIcon;
