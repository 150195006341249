import './empty-cart.style.scss';

interface EmptyCartProps {
    title: string;
    body: string;
}

const EmptyCart: React.FC<EmptyCartProps> = ({ title, body }) => (
    <div className="empty-cart">
        <h4 className="empty-cart__title">{title}</h4>
        <p className="empty-cart__body">{body}</p>
    </div>
);
export default EmptyCart;
