import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeLatest } from 'redux-saga/effects';

import { accountIsLoggedInSelector } from 'state/account/account.selectors';

import { Allergies, MedicalConditions } from 'types/medical-conditions';

import { TrackFlowComplete, TrackFormSuccess } from 'util/google_optimize/optimize_helper';
import { baseEffectHandler } from 'util/sagas/sagas';

import {
    medicalConditionsAddOrUpdateRoutine,
    medicalConditionsAllergiesAddOrUpdateRoutine,
    medicalConditionsAllergiesDetailsRoutine,
    medicalConditionsDetailsRoutine
} from './medical-conditions.routines';
import MedicalConditionsService from './medical-conditions.services';

const FORM_NAME = {
    Allergies: 'Allergies',
    HealthConditions: 'HealthConditions'
};

type GetMedicalConditionDetailsSaga = {
    epostPatientNumFamily: string;
    onSuccess?: (response: any) => void;
    onError?: (error: any) => void;
};

type AddOrUpdateMedicalConditionsSaga = {
    medicalConditions: MedicalConditions;
    onSuccess?: (response: any) => void;
    onError?: (error: any) => void;
};

type AddOrUpdateAllergiesSaga = {
    allergies: Allergies;
    onSuccess?: (response: any) => void;
    onError?: (error: any) => void;
};

function* getMedicalConditionDetailsSaga(action: PayloadAction<GetMedicalConditionDetailsSaga>) {
    const { onSuccess } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

    yield baseEffectHandler({
        service: MedicalConditionsService.medicalConditions().get,
        isAuthenticatedService: true,
        isLoggedIn,
        data: action.payload,
        *onResponse(response) {
            yield put(medicalConditionsDetailsRoutine.success(response));
            onSuccess && onSuccess(response);
        },
        *onError(error) {
            yield put(medicalConditionsDetailsRoutine.failure({ messageText: error.response.response.data.Errors }));
        }
    });
}

function* updateMedicalConditionDetailsSaga(action: PayloadAction<AddOrUpdateMedicalConditionsSaga>) {
    const { onSuccess } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
    yield baseEffectHandler({
        service: MedicalConditionsService.medicalConditions().addOrUpdate,
        isAuthenticatedService: true,
        isLoggedIn,
        data: action.payload,
        *onResponse(response) {
            yield put(medicalConditionsAddOrUpdateRoutine.success(action.payload));
            TrackFormSuccess(FORM_NAME.HealthConditions);
            TrackFlowComplete(FORM_NAME.HealthConditions);
            onSuccess && onSuccess(response);
        },
        *onError(error) {
            yield put(
                medicalConditionsAddOrUpdateRoutine.failure({ messageText: error.response.response.data.Errors })
            );
        }
    });
}

function* getAllergiesDetailsSaga(action: PayloadAction<GetMedicalConditionDetailsSaga>) {
    const { onSuccess } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

    yield baseEffectHandler({
        service: MedicalConditionsService.allergies().get,
        isAuthenticatedService: true,
        isLoggedIn,
        data: action.payload,
        *onResponse(response) {
            yield put(medicalConditionsAllergiesDetailsRoutine.success(response));
            onSuccess && onSuccess(response);
        },
        *onError(error) {
            yield put(
                medicalConditionsAllergiesDetailsRoutine.failure({ messageText: error.response.response.data.Errors })
            );
        }
    });
}

function* updateAllergiesDetailsSaga(action: PayloadAction<AddOrUpdateAllergiesSaga>) {
    const { onSuccess } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
    yield baseEffectHandler({
        service: MedicalConditionsService.allergies().addOrUpdate,
        isAuthenticatedService: true,
        isLoggedIn,
        data: action.payload,
        *onResponse(response) {
            yield put(medicalConditionsAllergiesAddOrUpdateRoutine.success(action.payload));
            TrackFormSuccess(FORM_NAME.Allergies);
            TrackFlowComplete(FORM_NAME.Allergies);
            onSuccess && onSuccess(response);
        },
        *onError(error) {
            yield put(
                medicalConditionsAllergiesAddOrUpdateRoutine.failure({
                    messageText: error.response.response.data.Errors
                })
            );
        }
    });
}
function* medicalConditionsSaga() {
    yield takeLatest(medicalConditionsDetailsRoutine.TRIGGER, getMedicalConditionDetailsSaga);
    yield takeLatest(medicalConditionsAddOrUpdateRoutine.TRIGGER, updateMedicalConditionDetailsSaga);
    yield takeLatest(medicalConditionsAllergiesDetailsRoutine.TRIGGER, getAllergiesDetailsSaga);
    yield takeLatest(medicalConditionsAllergiesAddOrUpdateRoutine.TRIGGER, updateAllergiesDetailsSaga);
}

export default medicalConditionsSaga;
