// General
import { ALLOW_INSURED_BIRDI_PRICE } from 'gatsby-env-variables';
import React, { useEffect } from 'react';
// Hooks
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Components
import ColumnSectionEditModeToggle from 'components/sidebar-column/column-section-toggle/column-section-toggle.component';

import { FailureUpdateCartModalContent } from 'pages/secure/cart/intra-page-items/_cart-update-modal-item';

import {
    accountFamilyPlansMapSelector,
    accountProfileSelector,
    accountProfilIsCaregiverSelector
} from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { cancelOrderLine } from 'state/cart/cart.routines';
import { cartOrderShippingAddressSelector } from 'state/cart/cart.selectors';
// States
import { ExtendedCartObjectPayload, StartOrderV2Payload } from 'state/cart/cart.services';
import { drugDiscountPriceRoutine } from 'state/drug/drug.routines';
import { drugSelector } from 'state/drug/drug.selectors';
import { setMedicineCabinetIsBusy } from 'state/medicine-cabinet/medicine-cabinet.reducers';
import { medicineCabinetGetAllPrescriptions } from 'state/medicine-cabinet/medicine-cabinet.routines';
import { medicineCabinetActiveTabSelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';
import { PrescriptionObjectPayload } from 'state/medicine-cabinet/medicine-cabinet.services';
import { membershipIsOnDemandSelector } from 'state/membership/membership.selector';

// Types and Interfaces
import { ExtendedRefillRxs } from 'types/order-prescription';

//Utils
import { EXPEDITED_SHIPPING_COST } from 'util/cart';
import { noop } from 'util/function';
import { TrackCheckoutStep } from 'util/google_optimize/optimize_helper';

// Styles
import './prescription-information.style.scss';
import PrescriptionItem, { PrescriptionItemProps } from './prescription-item/prescription-item.component';

// Main component interface
export interface PrescriptionInformationProps {
    prescriptions: PrescriptionObjectPayload[];
    isInsuranceAccount: boolean;
    cartObject?: StartOrderV2Payload[];
    onPrescriptionRemoved?: (removedCard: ExtendedRefillRxs) => void;
    isToggleDisabled?: boolean;
}

// Main component
const PrescriptionInformation: React.FC<PrescriptionInformationProps> = (props) => {
    const {
        onPrescriptionRemoved = noop,
        prescriptions,
        isInsuranceAccount,
        isToggleDisabled = true,
        cartObject
    } = props;
    // Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // Selectors
    const profileObject = useSelector(accountProfileSelector);
    const isCaregiver = useSelector(accountProfilIsCaregiverSelector);
    const { drugDiscountPrices } = useSelector(drugSelector);
    const activeDependentTab = useSelector(medicineCabinetActiveTabSelector);
    const cartOrderShippingAddress = useSelector(cartOrderShippingAddressSelector);
    const isOnDemandPlan = useSelector(membershipIsOnDemandSelector);
    const familyPlansMap: Record<string, string> = useSelector(accountFamilyPlansMapSelector);

    // Get discount drug prices after the profile and all prescriptions are loaded.
    // As this is mandatory for get the prices, we need this logic be called first
    useEffect(() => {
        if (profileObject !== undefined && prescriptions?.length > 0) {
            let birdiPricePrescriptions: PrescriptionObjectPayload[] = [];
            // DRX-583 - get prices for Insured users to pre-fetch for cart. We only need to get
            // prices for items in cart.

            if (isInsuranceAccount && ALLOW_INSURED_BIRDI_PRICE) {
                // DRX-996 - Since we have to show a different message when an item is added to the cart,
                // we need to get all Rxs that are in the cart, or could be added to the cart.
                birdiPricePrescriptions = prescriptions.filter((rx) => {
                    return (
                        rx.inOrderCart === true ||
                        rx.webEligibilityStatus === 'ELIGIBLE' ||
                        rx.webEligibilityStatus === 'AUTH_REQ'
                    );
                });
            } else {
                // No need to get "pending" prescriptions for Birdi price users
                if (!isInsuranceAccount) {
                    birdiPricePrescriptions = prescriptions.filter(function (rx) {
                        return !!(rx.dispensedProductNumber || rx.writtenProductNumber);
                    });
                }
            }

            dispatch(
                drugDiscountPriceRoutine.trigger({
                    prescriptions: birdiPricePrescriptions,
                    forceBirdiInsurance: !!(isInsuranceAccount && ALLOW_INSURED_BIRDI_PRICE),
                    location: 'Cart'
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, profileObject?.epostPatientNum, prescriptions?.length]);

    const handlePrescriptionsError = React.useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.cart')} />,
                ctas: [
                    {
                        label: t('modals.updateCart.labels.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'UpdateCartError'
                    }
                ]
            })
        );
    }, [dispatch, t]);

    const handleRemovePrescription = React.useCallback(
        (
                prescriptionToRemove: ExtendedRefillRxs,
                cartObject: ExtendedCartObjectPayload
            ): PrescriptionItemProps['onRemovePrescriptionClick'] =>
            () => {
                if (!prescriptionToRemove) {
                    return;
                }

                dispatch(setMedicineCabinetIsBusy(true));
                dispatch(
                    cancelOrderLine.trigger({
                        rxNumber: prescriptionToRemove.rxNumber,
                        onSuccess: () => {
                            TrackCheckoutStep({
                                stepName: 'remove',
                                step: '1',
                                carts: [cartObject],
                                prescriptions: prescriptions,
                                t: t,
                                shippingCost: cartObject?.orderHeader?.orderHighPriority
                                    ? `${EXPEDITED_SHIPPING_COST}`
                                    : '0',
                                accountHasInsurance: isInsuranceAccount
                            });
                            onPrescriptionRemoved(prescriptionToRemove);
                            dispatch(
                                medicineCabinetGetAllPrescriptions({
                                    showNewRxModal: false,
                                    epostNumFamilyMember: activeDependentTab,
                                    onSuccess: () => {
                                        dispatch(setMedicineCabinetIsBusy(false));
                                    },
                                    onFailure: () => {
                                        dispatch(setMedicineCabinetIsBusy(false));
                                    }
                                })
                            );
                        },
                        onFailure: () => {
                            handlePrescriptionsError();
                            dispatch(setMedicineCabinetIsBusy(false));
                        }
                    })
                );
            },
        [
            dispatch,
            prescriptions,
            t,
            isInsuranceAccount,
            onPrescriptionRemoved,
            activeDependentTab,
            handlePrescriptionsError
        ]
    );

    const getPatientName = (item: ExtendedRefillRxs): string => {
        return item && item.FirstName && item.LastName ? `${item.FirstName} ${item.LastName}` : '';
    };

    /**
     * The Birdi Select Badge requires specific validation based on the user's plan to ensure it
     * is displayed correctly according to the prescription response, not discount prices.
     * Additional logic needs to be implemented for this purpose.
     */
    const handleIsBirdiSelectVisible = (item: ExtendedRefillRxs) => {
        const userPlanAlias = profileObject?.planAlias;
        const epostPatientNum: string = item?.prescriptionDetail?.epostPatientNum;

        const isMembershipPlanAlias = isCaregiver
            ? Object.keys(familyPlansMap).length > 0 && familyPlansMap[epostPatientNum] === 'BRD02' && !isOnDemandPlan
            : userPlanAlias === 'BRD02' && !isOnDemandPlan;

        return isMembershipPlanAlias && item?.prescriptionDetail?.isBirdiSelect;
    };

    return (
        <>
            <ColumnSectionEditModeToggle
                bodyClassName="prescription-information__content"
                className="prescription-information"
                editModeContent={<></>}
                headerClassName="prescription-information__header"
                title="Prescription Information"
                isToggleDisabled={isToggleDisabled}
            >
                <>
                    {cartObject && (
                        <div className="cart-review">
                            <div className="cart-review--border-bottom">
                                {cartObject.map((cart: any) => {
                                    return cart?.extendedRefillRxs?.map((item: ExtendedRefillRxs) => {
                                        const discountPrice = drugDiscountPrices.find((dc) => {
                                            return (
                                                dc.rxNumber === item.rxNumber &&
                                                dc.zipCode === cartOrderShippingAddress?.zipcode
                                            );
                                        });

                                        const isBirdiSelectVisible = handleIsBirdiSelectVisible(item);
                                        return (
                                            <PrescriptionItem
                                                isLoading={false}
                                                isCaregiver={isCaregiver}
                                                isBirdiSelect={isBirdiSelectVisible}
                                                patientName={getPatientName(cart)}
                                                key={item.rxNumber}
                                                onRemovePrescriptionClick={handleRemovePrescription(item, cart)}
                                                prescription={item}
                                                currentPrescription={item.prescriptionDetail}
                                                discountPrice={discountPrice}
                                                isInsuranceAccount={isInsuranceAccount}
                                            />
                                        );
                                    });
                                })}
                            </div>
                        </div>
                    )}
                </>
            </ColumnSectionEditModeToggle>
        </>
    );
};

export default PrescriptionInformation;
