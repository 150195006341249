import React from 'react';
import { FluidObject } from 'gatsby-image';
import { Col, Container, Row } from 'react-bootstrap';

import { GridIconProps } from './GridIcon/GridIcon';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import CtaStack from 'ui-kit/cta-stack/cta-stack';

import './IconGrid.style.scss';

export interface IconGridProps {
    backgroundColor?: 'white' | 'dark_blue' | 'light_blue' | 'smoke';
    eyebrowText?: string;
    title: string;
    subtitle: string;
    cta: {
        linkText: string;
        linkUri: string;
    };
    image: FluidObject;
    imageAltText?: string;
    children?: React.ReactElement<GridIconProps> | React.ReactElement<GridIconProps>[];
    sectionIndex: number;
}

export default function IconGrid({
    backgroundColor = 'dark_blue',
    eyebrowText,
    title,
    subtitle,
    cta: { linkText, linkUri },
    image,
    imageAltText,
    children,
    sectionIndex
}: IconGridProps) {
    return (
        <Container
            fluid
            className={`icon-grid ${backgroundColor}`}
            data-ga-location="Icon Grid"
            data-ga-index={`${sectionIndex > -1 ? 1 + sectionIndex : 0}`}
            data-ga-title={title}
        >
            <Row className="d-flex justify-content-center icon-grid__header">
                <Col xs={12} className="text-center">
                    <div className="h5 eyebrow-text">{eyebrowText}</div>
                </Col>
                <Col xs={12} lg={6} className="text-center">
                    {sectionIndex === 0 && <h1 className="title">{title}</h1>}
                    {sectionIndex > 0 && <h2 className="h1 title">{title}</h2>}
                </Col>
                <Col xs={12} lg={12} className="d-flex justify-content-center">
                    <div className="spacer" />
                </Col>
                <Col xs={12} lg={6} className="text-center font-weight-normal">
                    <p className="body">{subtitle}</p>
                </Col>
                <Col xs={12} className="text-center mb-5">
                    <CtaStack to={linkUri} label={linkText} />
                </Col>
            </Row>
            <Row className="icon-grid__grid-icons">
                <Col
                    md={{
                        order: 1,
                        span: 4
                    }}
                    lg={{
                        order: 1,
                        span: 3
                    }}
                    className={'icon-grid__grid-icons__left-gutter'}
                >
                    {React.Children.map(children, (child, index) => (
                        <>{children && Array.isArray(children) && children.length / 2 > index && child}</>
                    ))}
                </Col>
                <Col
                    xs={{
                        span: 10,
                        offset: 1
                    }}
                    md={{
                        order: 2,
                        offset: 0,
                        span: 4
                    }}
                    lg={{
                        order: 2,
                        offset: 1,
                        span: 4
                    }}
                    className={'icon-grid__grid-icons__center px-s-5 px-md-0'}
                >
                    <GatsbyImage image={getImage(image)} alt={imageAltText} className="icon-grid__grid-icons-image" />
                </Col>
                <Col
                    md={{
                        span: 4,
                        offset: 0,
                        order: 3
                    }}
                    lg={{
                        span: 3,
                        offset: 1,
                        order: 3
                    }}
                    className={'icon-grid__grid-icons__right-gutter'}
                >
                    {React.Children.map(children, (child, index) => (
                        <>{children && Array.isArray(children) && children.length / 2 <= index && child}</>
                    ))}
                </Col>
            </Row>
        </Container>
    );
}
