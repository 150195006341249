import axiosClient from 'util/axiosClient';
import { GATSBY_API_BASE_URL } from 'gatsby-env-variables';

import {
    FamilyAccountGetDependentsPayload,
    FamilyAccountAddDependentPayload,
    FamilyAccountAddDependentResponse,
    FamilyAccountVerifyDependentPayload,
    FamilyAccountVerifyDependentResponse,
    CaregiversChangeAccessPayload,
    CaregiversChangeAccessResponse,
    CaregiversResponse,
    FamilyAccountRemoveDependentPayload,
    FamilyAccountRemoveDependentResponse,
    FamilyAccountSendDependentInvitationPayload,
    FamilyAccountSendDependentInvitationResponse
} from 'types/family-management';

export interface DependentsProfile {
    ID: number;
    FirstName: string;
    LastName: string;
    Age: number;
    AccountStatus: number;
    AccountType: number;
    DOB: string;
    InsuranceID?: string;
    EPostPatientNum: string;
    Success?: boolean;
}

export interface GetDependentsPayload {
    Data: DependentsProfile[];
    Message: string;
    Success: boolean;
}

export default class FamilyProfileService {
    static dependents() {
        return {
            get: async (): Promise<FamilyAccountGetDependentsPayload> => {
                const response = await axiosClient.get(`${GATSBY_API_BASE_URL}/api/caregiver/dependents`);
                return response.data;
            },
            add: async (payload: FamilyAccountAddDependentPayload): Promise<FamilyAccountAddDependentResponse> => {
                const response = await axiosClient.post(`${GATSBY_API_BASE_URL}/api/caregiver/dependents`, payload);
                return response.data;
            },
            verify: async (
                payload: FamilyAccountVerifyDependentPayload
            ): Promise<FamilyAccountVerifyDependentResponse> => {
                const response = await axiosClient.post(`${GATSBY_API_BASE_URL}/api/caregiver`, payload);
                return response.data;
            },
            remove: async (
                payload: FamilyAccountRemoveDependentPayload
            ): Promise<FamilyAccountRemoveDependentResponse> => {
                const response = await axiosClient.post(`${GATSBY_API_BASE_URL}/api/caregiver/remove`, payload);
                return response.data;
            },
            sendInvitation: async (
                payload: FamilyAccountSendDependentInvitationPayload
            ): Promise<FamilyAccountSendDependentInvitationResponse> => {
                const response = await axiosClient.post(`${GATSBY_API_BASE_URL}/api/caregiver/SendInvite`, payload);
                return response.data;
            }
        };
    }

    static getCaregivers() {
        return {
            get: async (): Promise<CaregiversResponse> => {
                const response = await axiosClient.get(`${GATSBY_API_BASE_URL}/api/caregivers`);
                return response.data;
            }
        };
    }

    static changeCaregiversAccess() {
        return {
            post: async (payload: CaregiversChangeAccessPayload): Promise<CaregiversChangeAccessResponse> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/caregiver/ChangeAccess`, payload)
                    .then((response) => response.data);
            }
        };
    }
}
