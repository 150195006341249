/**
 * parseFloat helper that always returns a number regardless of input. Will
 * not return NaN values like the standard parseFloat
 */
export function safeParseFloat(valueString?: string): number {
    if (!valueString) {
        return 0;
    }

    const result = Number(valueString);
    return isNaN(result) ? 0 : result;
}

// If number is 1 to 9, adds a zero in front of that number

export function addZero(value: number | string) {
    return String(value).padStart(2, '0');
}
