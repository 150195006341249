import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import classNames from 'classnames';
import { accountAcCodeSelector } from 'state/account/account.selectors';
import { DrugLookupObjectPayload } from 'state/drug/drug.services';
import { Typeahead } from 'react-bootstrap-typeahead';
import { formatDrugName } from 'util/drug';
import { drugListRoutine } from 'state/drug/drug.routines';
import { drugFormularySelector } from 'state/drug/drug.selectors';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './drug-lookup-alt.style.scss';

interface DrugSelectObjectProps extends DrugLookupObjectPayload {
    label: string;
    searchName: string;
}

const DrugLookupAlt = ({ field, form, formError, ...rest }: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isFocused, setIsFocused] = useState(false);
    const drugFormularies = useSelector(drugFormularySelector);

    const [formularyData, setFormularyData] = useState([]);
    const [formularyInitialized, setFormularyInitialized] = useState(false);
    const [selectedDrugName, setSelectedDrugName] = useState(rest.defaultValue);
    const [selectedDrugGPC, setSelectedDrugGPC] = useState('');
    const insuranceName: string = useSelector(accountAcCodeSelector);

    const cleanOptions = (options: any[]) => {
        const selectOptions: Record<string, DrugSelectObjectProps>[] = [];
        options.map(function (option) {
            const cleanOption: DrugSelectObjectProps = { ...option, label: '', searchName: '' };
            cleanOption.label = formatDrugName(t, option);
            cleanOption.searchName = formatDrugName(t, option);
            selectOptions.push(cleanOption);
            if (option.brandDrugNames && option.brandDrugNames.length > 0) {
                option.brandDrugNames.map(function (brandDrugName: string) {
                    const brandOption: DrugSelectObjectProps = { ...option, label: '', searchName: '' };
                    if (brandOption.onSaleDrugName !== brandDrugName) {
                        option.brandDrugName = brandDrugName;
                        brandOption.label = formatDrugName(t, option);
                        brandOption.searchName = brandDrugName;
                        selectOptions.push(brandOption);
                    }
                });
            }
        });
        return selectOptions;
    };

    const initialDrugOption = null;
    const [selectedDrug, setSelectedDrug] = useState(initialDrugOption ? [initialDrugOption] : []);
    const typeAheadEl = useRef(null);

    const handleChange = (drug: any) => {
        form.setFieldValue(field.name, drug.onSaleDrugName);
        if (selectedDrugName !== drug.onSaleDrugName || selectedDrugGPC !== drug.genericProductCode) {
            setSelectedDrugName(drug.onSaleDrugName);
            setSelectedDrugGPC(drug.genericProductCode);

            // Call the onChange handler from the parent, passing back the Formik
            // bag (ie, the form variable) so that it can be used to reset drugForm
            // and strength dropdowns.
            rest.onDrugChange(drug, form);
        }
    };

    const handleOnBlur = () => {
        setIsFocused(false);

        if (selectedDrugName) {
            form.setFieldValue(field.name, selectedDrugName);
        }

        if (typeAheadEl.current) {
            typeAheadEl.current.setState({
                text: selectedDrugName
            });
        }

        rest.onDrugBlur(form);
    };

    const classes = classNames('text drug-lookup mb-0', { focused: isFocused });

    useEffect(() => {
        const activeFormulary = drugFormularies.find((formulary) => formulary.insuranceName === insuranceName);
        if (!activeFormulary) {
            dispatch(
                drugListRoutine.trigger({
                    acCode: insuranceName
                })
            );
        }
    }, []);

    useEffect(() => {
        if (drugFormularies.length > 0) {
            const activeFormulary = drugFormularies.find((formulary) => formulary.insuranceName === insuranceName);
            if (activeFormulary) {
                setFormularyData(cleanOptions(activeFormulary.formulary));
                setFormularyInitialized(true);
                if (selectedDrugName) {
                    handleOnBlur();
                }
                return () => {
                    setIsFocused(false);
                    setSelectedDrugName('');
                };
            }
        }
    }, [drugFormularies]);

    useEffect(() => {
        if (formError && selectedDrugName) {
            if (typeAheadEl.current) {
                typeAheadEl.current.focus();
            }
        }
    }, [formError]);

    return (
        <>
            <div className={classes}>
                <div className="text-input-container">
                    <Typeahead
                        id="druglookup"
                        ref={typeAheadEl}
                        onChange={(selected) => {
                            if (selected.length > 0) {
                                handleChange(selected[0]);
                            }
                            setSelectedDrug(selected);
                        }}
                        onInputChange={(text, event) => form.setFieldValue(field.name, text)}
                        onBlur={handleOnBlur}
                        options={formularyData}
                        labelKey={'label'}
                        filterBy={(option, props) => {
                            return option.searchName.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
                        }}
                        inputProps={{ className: 'text-input text-uppercase' }}
                        minLength={2}
                        selected={selectedDrug}
                        isLoading={!formularyInitialized}
                        allowNew={false}
                        caseSensitive={false}
                        defaultInputValue={''}
                        defaultOpen={false}
                        defaultSelected={[]}
                        highlightOnlyResult={false}
                        ignoreDiacritics={true}
                        maxResults={0}
                        multiple={false}
                        paginate={false}
                        onFocus={() => {}}
                        onKeyDown={() => {}}
                        onMenuToggle={() => {}}
                        onPaginate={() => {}}
                    >
                        {(state) => (
                            <label
                                className={
                                    state.text !== '' || state.isMenuShown ? 'has-value text-label' : 'text-label'
                                }
                                htmlFor="druglookup"
                            >
                                {t('prescriptionInfoForm.inputs.drugName')}
                            </label>
                        )}
                    </Typeahead>

                    <div>{form.touched.drugName && rest.errors}</div>
                </div>
            </div>
        </>
    );
};

export default DrugLookupAlt;
