import React from 'react';
import { Col, Row } from 'react-bootstrap';
import sanitizeHtml from 'sanitize-html';
import './ResourceIcon.style.scss';

export interface ResourceIconProps {
    icon: JSX.Element;
    titleText: string;
    bodyText: string;
    bodyClassName: string | undefined;
}

export default function ResourceIcon({ icon, titleText, bodyText, bodyClassName }: ResourceIconProps) {
    return (
        <Col className="resource-icon mb-2">
            <Row className="d-flex justify-content-center">
                <Col xs={12} className="resource-icon__icon  my-1">
                    {icon}
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col xs={8} className="text-center my-1">
                    <div className="h4 resource-icon__title m-0">{titleText}</div>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col xs={10} className="text-center">
                    <div
                        className={`resource-icon__body mb-3 ${bodyClassName ? bodyClassName : ''}`}
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(bodyText, {
                                allowedAttributes: {
                                    a: ['id', 'href', 'name', 'rel', 'target', 'data-*'],
                                    '*': ['class']
                                }
                            })
                        }}
                    />
                </Col>
            </Row>
        </Col>
    );
}
