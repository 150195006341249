import React from 'react';
import { graphql } from 'gatsby';

import { WebFormProps } from 'components/web-form/web-form.component';
import WebFormComponent from 'components/web-form/web-form.component';

export default function WebFormProvider({
    paragraph,
    sectionIndex
}: {
    paragraph: GatsbyTypes.WebFormProviderFragment;
    sectionIndex: number;
}) {
    const webFormProps: WebFormProps = {
        backgroundColor: paragraph.field_wf_background_color ? paragraph.field_wf_background_color : 'white',
        eyebrowText: paragraph.field_wf_eyebrow ? paragraph.field_wf_eyebrow : '',
        titleText: paragraph.field_wf_title ? paragraph.field_wf_title : '',
        image: paragraph.relationships?.field_wf_image?.relationships?.field_media_image?.localFile,
        webformObject: paragraph.relationships?.field_wf_form ? paragraph.relationships?.field_wf_form : null,
        sectionIndex: sectionIndex
    };
    return <WebFormComponent {...webFormProps} />;
}

export const query = graphql`
    fragment WebFormProvider on paragraph__webform {
        field_wf_background_color
        field_wf_title
        field_wf_eyebrow
        relationships {
            field_wf_image {
                relationships {
                    field_media_image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(formats: [AUTO])
                            }
                        }
                    }
                }
            }
            field_wf_form {
                drupal_internal__id
                elements {
                    attributes {
                        name
                        value
                    }
                    name
                    type
                }
                title
                status
            }
        }
    }
`;
