import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

import { MedicalConditionsState } from './medical-conditions.reducers';

export const medicalConditionsSelector = (state: RootState): MedicalConditionsState => state.medicalConditionsReducer;

export const membershipHealthConditionsSelector = createSelector(
    medicalConditionsSelector,
    ({ healthConditions }: MedicalConditionsState) => ({
        healthConditions
    })
);

export const isLoadingHealthConditionsSelector = createSelector(
    medicalConditionsSelector,
    (medicalConditions) => medicalConditions.isLoadingHealthConditions
);

export const isLoadingAllergiesSelector = createSelector(
    medicalConditionsSelector,
    (medicalConditions) => medicalConditions.isLoadingAllergies
);

export const medicalConditionsDetailsSelector = createSelector(
    medicalConditionsSelector,
    (medicalConditions) => medicalConditions.healthConditions?.medicalConditions
);

export const medicalConditionsAllergiesDetailsSelector = createSelector(
    medicalConditionsSelector,
    (medicalConditions) => medicalConditions.healthConditions?.allergies
);
