import React, { useCallback, useState, useEffect } from 'react';
import TextSetValue from 'ui-kit/text/textSetValue';
import Button from 'ui-kit/button/button';
import { DrugLookupObjectPayload } from 'state/drug/drug.services';
import { useDispatch, useSelector } from 'react-redux';
import { accountHasInsuranceSelector } from 'state/account/account.selectors';
import classNames from 'classnames';
import { throttle } from 'lodash';
import './drug-lookup.style.scss';

import { drugLookupRoutine } from 'state/drug/drug.routines';
import { drugLookupSelector, drugSelector } from 'state/drug/drug.selectors';
import { setDrugListVisibility } from 'state/drug/drug.reducers';
import { formatDrugName } from 'util/drug';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const DrugLookup = ({ field, form, formError, ...rest }: any) => {
    const [isFocused, setIsFocused] = useState(false);
    const [textHasChanged, setTextHasChanged] = useState(false);
    const [selectedDrugName, setSelectedDrugName] = useState(rest.value);
    const [selectedDrugGPC, setSelectedDrugGPC] = useState('');
    const [activeDrugIndex, setActiveDrugIndex] = useState(-1);
    const drugNameRef = React.useRef(null);
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const dispatch = useDispatch();
    const drugs = useSelector(drugLookupSelector);
    const { showDrugList } = useSelector(drugSelector);

    const lookupText = (searchName?: string) => {
        rest.defaultValue = undefined;

        if (searchName && searchName.length > 2) {
            dispatch(
                drugLookupRoutine.trigger({
                    drugName: searchName,
                    onFailure: () => {
                        // if api fails, reset the drug search form.
                        dispatch(setDrugListVisibility(false));
                        setSelectedDrugName('');
                        setSelectedDrugGPC('');
                        rest.onResetForm(form);
                        rest.onDrugLookupSearchFail(form);
                    }
                })
            );
        }
    };

    const throttleLookupText = useCallback(throttle(lookupText, 250), [accountHasInsurance]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextHasChanged(true);
        const searchName = event.currentTarget.value;
        form.setFieldValue(field.name, searchName);
        setSelectedDrugName(field.value);

        throttleLookupText(searchName);

        // If the user has deleted the entire search value, then hide the
        // dropdown.
        if (!searchName || (searchName && searchName.length === 0)) {
            dispatch(setDrugListVisibility(false));
            setSelectedDrugName('');
            setSelectedDrugGPC('');
            rest.onResetForm(form);
        }
    };

    const handleClick = (drug: DrugLookupObjectPayload, listIndex: number) => {
        form.setFieldValue(field.name, drug.onSaleDrugName);
        dispatch(setDrugListVisibility(false));
        setActiveDrugIndex(listIndex);

        if (selectedDrugName !== drug.onSaleDrugName || selectedDrugGPC !== drug.genericProductCode) {
            setSelectedDrugName(drug.onSaleDrugName);
            setSelectedDrugGPC(drug.genericProductCode);
            // Call the onChange handler from the parent, passing back the Formik
            // bag (ie, the form variable) so that it can be used to reset drugForm
            // and strength dropdowns.
            rest.onDrugChange(drug, form);
        }
    };

    const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        setTextHasChanged(true);
        setIsFocused(true);

        // Perform a fresh lookup.
        const searchName = event.currentTarget.value;
        lookupText(searchName);
    };

    const handleOnBlur = () => {
        setSelectedDrugName(field.value);
        // Hide the drug list.
        setTimeout(() => {
            dispatch(setDrugListVisibility(false));
            setIsFocused(false);
        }, 200);

        rest.onDrugBlur(form);
    };

    const onActiveIndexChange = (newIndex: number) => {
        setActiveDrugIndex(newIndex);
        if (newIndex > -1) {
            const drug = drugs[newIndex];
            form.setFieldValue(field.name, drug.onSaleDrugName);
        }
        rest.onDrugBlur(form); // to reset the form/strength dropdowns
    };

    const handleKeyDown = (keyEvent: React.KeyboardEvent<HTMLInputElement>) => {
        if (drugs.length > 0) {
            let newIndex = -1;
            if (keyEvent.key === 'ArrowUp') {
                dispatch(setDrugListVisibility(true));
                if (activeDrugIndex > 0) {
                    newIndex = activeDrugIndex - 1;
                } else {
                    newIndex = drugs.length - 1;
                }
                onActiveIndexChange(newIndex);
            } else {
                if (keyEvent.key === 'ArrowDown') {
                    dispatch(setDrugListVisibility(true));
                    if (activeDrugIndex < drugs.length - 1) {
                        newIndex = activeDrugIndex + 1;
                    } else {
                        newIndex = 0;
                    }
                    onActiveIndexChange(newIndex);
                } else {
                    if (keyEvent.key === 'Enter') {
                        if (keyEvent.key === 'Enter') {
                            keyEvent.preventDefault();
                        }
                        rest.onDrugBlur(form);
                        const drugIndex = activeDrugIndex > -1 ? activeDrugIndex : 0;
                        handleClick(drugs[drugIndex], activeDrugIndex);
                    } else {
                        if (keyEvent.key === 'Tab') {
                            // Hide druglist immediately to prevent focus being given to list button
                            dispatch(setDrugListVisibility(false));
                            setIsFocused(false);
                            rest.onDrugBlur(form);
                        } else {
                            onActiveIndexChange(-1);
                        }
                    }
                }
            }
        }
    };

    const classes = classNames('drug-lookup', { focused: isFocused });

    useEffect(() => {
        if (selectedDrugName) {
            handleOnBlur();
        }
        return () => {
            setIsFocused(false);
            setSelectedDrugName('');
            setSelectedDrugGPC('');
            setActiveDrugIndex(-1);
            setTextHasChanged(false);
        };
    }, []);

    useEffect(() => {
        if (formError && selectedDrugName) {
            if (drugNameRef.current) {
                drugNameRef.current.focus();
            }
        }
    }, [formError]);

    return (
        <div className={classes}>
            <TextSetValue
                name={rest.name ? rest.name : 'drugName'}
                label={rest.label}
                type="text"
                onChange={handleChange}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                onKeyDown={handleKeyDown}
                value={rest.value ? rest.value : ''}
                ariaExpanded={showDrugList ? 'true' : 'false'}
                autocomplete="off"
                inputRef={drugNameRef}
                {...rest}
            />
            {textHasChanged && drugs.length !== 0 && showDrugList && isFocused ? (
                <DrugList drugs={drugs} handleClick={handleClick} activeIndex={activeDrugIndex} />
            ) : null}
        </div>
    );
};

const DrugList = (props: any) => {
    const { t } = useTranslation();
    if (props?.drugs && typeof props.drugs.map === 'function') {
        return (
            <ul className={'drug-list'} role={'listbox'}>
                {props.drugs.map((drug: DrugLookupObjectPayload, idx: number) => (
                    <li
                        role={'option'}
                        aria-selected={props.activeIndex === idx ? 'true' : 'false'}
                        className={`drug ${props.activeIndex === idx ? 'active' : ''}`}
                        key={idx}
                    >
                        <Button
                            variant="text"
                            label={formatDrugName(t, drug)}
                            type="button"
                            className="my-2 mx-md-2 d-block sm-full d-sm-inline text-dark"
                            onClick={() => props.handleClick(drug, idx)}
                        />
                    </li>
                ))}
            </ul>
        );
    } else {
        return <></>;
    }
};

export default DrugLookup;
