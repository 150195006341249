import { TFunction } from 'gatsby-plugin-react-i18next';
import { DrugLookupObjectPayload } from 'state/drug/drug.services';

export function formatDrugName(t: TFunction<'translation'>, drug: DrugLookupObjectPayload): string {
    if (drug.brandDrugName && drug.brandDrugName !== drug.onSaleDrugName && drug.brandNameCode === 'G') {
        return t('prescriptionInfoForm.drugLookup.hasGeneric', {
            drugName: drug.onSaleDrugName,
            brandName: drug.brandDrugName
        });
    } else {
        return drug.onSaleDrugName ? drug.onSaleDrugName : drug.drugName;
    }
}
