import React, { ReactElement } from 'react';
import { Row, Col } from 'react-bootstrap';

import { PlansPricingProps } from './plans-pricing.props';

import './plans-pricing.style.scss';
import MembershipPlansTabbedOptionsComponent
    from "../membership-plans-tabbed-options/membership-plans-tabbed-options.component";

const PlansPricing = ({ Active, sectionIndex }: PlansPricingProps): ReactElement => {
    return (
        <div className="Plans-Pricing" key={sectionIndex}>
            <Row>
                <Col>
                    <MembershipPlansTabbedOptionsComponent />
                </Col>
            </Row>
        </div>
    );
};

export default PlansPricing;
