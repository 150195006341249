import React from 'react';

import { VisibilityIconProps } from 'ui-kit/icons/visibility-icon/visibility-icon.props';

import withOnKeyDown from 'hoc/withOnKeyDown';

const VisibilityIcon = ({ onClick, onKeyDown, style, toggled, imageAltText }: VisibilityIconProps) => {
    return (
        <div
            className="visibility-icon"
            style={style}
            role="button"
            onClick={onClick}
            onKeyDown={onKeyDown}
            tabIndex={0}
        >
            {toggled ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <g clipPath="url(#clip0_34528_219)">
                        <path
                            d="M23.0632 10.352C23.3443 10.7025 23.5 11.1578 23.5 11.63C23.5 12.1022 23.3443 12.5575 23.0632 12.908C21.2829 15.0635 17.2305 19.26 12.5 19.26C7.76947 19.26 3.71715 15.0635 1.93684 12.908C1.65565 12.5575 1.5 12.1022 1.5 11.63C1.5 11.1578 1.65565 10.7025 1.93684 10.352C3.71715 8.1965 7.76947 4 12.5 4C17.2305 4 21.2829 8.1965 23.0632 10.352Z"
                            stroke="#1A497F"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M12.5001 15.021C14.3729 15.021 15.8911 13.5027 15.8911 11.6299C15.8911 9.75706 14.3729 8.23883 12.5001 8.23883C10.6272 8.23883 9.10899 9.75706 9.10899 11.6299C9.10899 13.5027 10.6272 15.021 12.5001 15.021Z"
                            stroke="#1A497F"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_34528_219">
                            <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                        </clipPath>
                    </defs>
                </svg>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <g clipPath="url(#clip0_34528_221)">
                        <path
                            d="M18.2046 17.7125C16.5245 18.8043 14.5778 19.6154 12.5001 19.6154C7.77855 19.6154 3.73388 15.4269 1.95696 13.2755C1.67637 12.9257 1.52102 12.4711 1.52102 11.9999C1.52102 11.5286 1.67637 11.0741 1.95696 10.7243C2.93647 9.53841 4.60508 7.73355 6.6961 6.35242M9.96162 4.77435C10.778 4.52744 11.6275 4.38444 12.5001 4.38444C17.2216 4.38444 21.2662 8.57292 23.0432 10.7243C23.3238 11.0741 23.4792 11.5286 23.4792 11.9999C23.4792 12.4711 23.3238 12.9257 23.0432 13.2755C22.4802 13.9571 21.6896 14.8432 20.7219 15.7358M10.1067 9.60659C8.78493 10.9284 8.78493 13.0714 10.1067 14.3932C11.4285 15.7149 13.5715 15.7149 14.8933 14.3932M1.5 1.00003L23.5 23"
                            stroke="#1A497F"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_34528_221">
                            <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                        </clipPath>
                    </defs>
                </svg>
            )}
        </div>
    );
};

export default withOnKeyDown(VisibilityIcon);
