import { graphql } from 'gatsby';

import { ContactUsFormProps } from 'components/contact-us-form/contact-us-form.component';
import ContactUsForm from 'components/contact-us-form/contact-us-form.component';

export default function ContactUsFormProvider({
    paragraph,
    sectionIndex
}: {
    paragraph: GatsbyTypes.ContactUsFormProviderFragment;
    sectionIndex: number;
}) {
    const contactUsFormProps: ContactUsFormProps = {
        defaultValues: { firstName: '', lastName: '', email: '', phoneNumber: '', message: '' },
        centerFormSubmit: true,
        eyebrowText: paragraph.field_cu_eyebrow ? paragraph.field_cu_eyebrow : '',
        titleText: paragraph.field_cu_title ? paragraph.field_cu_title : '',
        buttonText: paragraph.field_cu_button_text ? paragraph.field_cu_button_text : '',
        image: paragraph.relationships?.field_cu_image?.relationships?.field_media_image?.localFile,
        sectionIndex: sectionIndex
    };
    return <ContactUsForm {...contactUsFormProps} />;
}

export const query = graphql`
    fragment ContactUsFormProvider on paragraph__contact_us_form {
        field_cu_button_text
        field_cu_eyebrow
        field_cu_title
        relationships {
            field_cu_image {
                relationships {
                    field_media_image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(formats: [AUTO])
                            }
                        }
                    }
                }
            }
        }
    }
`;
