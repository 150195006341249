import React, { ReactElement } from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import { QuoteProps, QuoteTextProps } from './quote.props';

import './quote.style.scss';

const FormattedQuoteText = ({ quote, speaker }: QuoteTextProps): ReactElement => {
    return (
        <>
            <p className="quote-text">“{quote}”</p>
            <p className="quote-speaker">{speaker}</p>
        </>
    );
};

const Quote = ({ quote, speaker, quoteIcon, sectionIndex }: QuoteProps): ReactElement => {
    return (
        <Container
            data-ga-index={`${sectionIndex > -1 ? 1 + sectionIndex : 0}`}
            data-ga-location="Quote"
            fluid
            className="quote"
        >
            <Row className={'mb-3'}>
                <Col xs="12" md="12" lg="12">
                    {quoteIcon}
                </Col>
            </Row>
            <Row>
                <Col xs="12" md="12" lg="8" className="quote-text-container">
                    <FormattedQuoteText quote={quote} speaker={speaker} />
                </Col>
            </Row>
        </Container>
    );
};

export default Quote;
