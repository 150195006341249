import { TFunction } from 'gatsby-plugin-react-i18next';
import { ENABLE_MEMBERSHIP } from 'gatsby-env-variables';

import {
    IntraPageMenuItemProps,
    IntraPageMenuItemContentValue
} from 'components/navigation/intra-page-menu/intra-page-menu.props';
import { Boolean } from 'aws-sdk/clients/appstream';

const intraPageMenuKeys = {
    PERSONAL_INFORMATION: 'personalInformation',
    SHIPPING_ADDRESS: 'shippingAddress',
    SECURITY: 'security',
    HEALTH_PROFILE: 'healthProfile',
    INSURANCE: 'insurance',
    FAMILY_ACCOUNT: 'familyAccount',
    PAYMENT: 'payment',
    PAYMENT_METHODS: 'paymentMethods',
    PAYMENT_HISTORY: 'paymentHistory',
    CONTACT_PREFERENCES: 'contactPreferences',
    MESSAGES: 'messages',
    CART: 'cart',
    AUTO_REFILL: 'autoRefill',
    MEMBERSHIP: 'membership'
};

export const getAllIntraPageMenuItems = (translation: TFunction<'translation'>) => {
    const intraPageMenuItems: Array<IntraPageMenuItemProps<IntraPageMenuItemContentValue>> = [
        {
            key: intraPageMenuKeys.PERSONAL_INFORMATION,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.PERSONAL_INFORMATION}`),
            value: '/secure/profile/personal-info',
            forUserType: 'any'
        },
        {
            key: intraPageMenuKeys.SECURITY,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.SECURITY}`),
            value: '/secure/profile/security',
            forUserType: 'any'
        },
        {
            key: intraPageMenuKeys.SHIPPING_ADDRESS,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.SHIPPING_ADDRESS}`),
            value: '/secure/profile/shipping-address',
            forUserType: 'any'
        },
        {
            key: intraPageMenuKeys.PAYMENT,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.PAYMENT}`),
            value: '',
            forUserType: 'any'
        },
        {
            key: intraPageMenuKeys.PAYMENT_METHODS,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.PAYMENT_METHODS}`),
            value: '/secure/profile/payment',
            isChild: true,
            forUserType: 'any'
        },
        {
            key: intraPageMenuKeys.PAYMENT_HISTORY,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.PAYMENT_HISTORY}`),
            value: '/secure/profile/payment/history',
            isChild: true,
            forUserType: 'any'
        },
        {
            key: intraPageMenuKeys.HEALTH_PROFILE,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.HEALTH_PROFILE}`),
            value: '/secure/profile/health-profile',
            forUserType: 'any'
        },
        {
            key: intraPageMenuKeys.AUTO_REFILL,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.AUTO_REFILL}`),
            value: '/secure/profile/auto-refill',
            forUserType: 'any'
        },
        {
            key: intraPageMenuKeys.INSURANCE,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.INSURANCE}`),
            value: '/secure/profile/insurance',
            forUserType: 'insured'
        },
        {
            key: intraPageMenuKeys.FAMILY_ACCOUNT,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.FAMILY_ACCOUNT}`),
            value: '/secure/profile/family-account',
            forUserType: 'any'
        },
        {
            key: intraPageMenuKeys.MESSAGES,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.MESSAGES}`),
            value: '/secure/profile/messages',
            partiallyActive: true,
            hidden: false,
            forUserType: 'any'
        },
        {
            key: intraPageMenuKeys.MEMBERSHIP,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.MEMBERSHIP}`),
            value: '/secure/profile/membership',
            hidden: false,
            forUserType: 'any'
        },
        {
            key: intraPageMenuKeys.CONTACT_PREFERENCES,
            label: translation(`menus.intraPage.profile.${intraPageMenuKeys.CONTACT_PREFERENCES}`),
            value: '/secure/profile/contact-preferences',
            hidden: false,
            forUserType: 'any'
        }
    ];

    return intraPageMenuItems;
};

export const getFilteredIntraPageMenuItems = (
    translation: TFunction<'translation'>,
    isProfileLoaded: boolean,
    hasInsurance: boolean,
    isCAResident: boolean = false,
    patientPlanAutoRefillFlag: Boolean = false,
    hasMembership: boolean = false
) => {
    const menu = getAllIntraPageMenuItems(translation).filter((item) => {
        // remove auto-refill menu item for CA residents or if plan is not eligible for auto-refill
        if (item.key === intraPageMenuKeys.AUTO_REFILL && (isCAResident || !patientPlanAutoRefillFlag)) {
            return null;
        }

        // remove membership menu item for non-membership users or ENABLE_MEMBERSHIP = false
        if ((!ENABLE_MEMBERSHIP || !hasMembership) && item.key === intraPageMenuKeys.MEMBERSHIP) {
            return null;
        }

        return (
            !item.hidden &&
            (item.forUserType === 'any' ||
                (isProfileLoaded && hasInsurance && item.forUserType === 'insured') ||
                (isProfileLoaded && !hasInsurance && item.forUserType === 'uninsured'))
        );
    });

    return menu;
};
