import React from 'react';
import { graphql } from 'gatsby';
import { FAQProps } from 'components/frequently-asked-questions/frequently-asked-questions.props';
import FrequentlyAskedQuestions from 'components/frequently-asked-questions/frequently-asked-questions.component';

export default function FrequentlyAskedQuestionsProvider({
    paragraph,
    sectionIndex
}: {
    paragraph: GatsbyTypes.FrequentlyAskedQuestionsProviderFragment;
    sectionIndex: number;
}) {
    const fAQQuestionProps: FAQProps = {
        sectionIndex: sectionIndex,
        background: paragraph.field_faq_background_color ? paragraph.field_faq_background_color : 'smoke',
        allQuestions: paragraph.relationships?.field_faq_refs
            ? paragraph.relationships?.field_faq_refs?.map((faq) => ({
                  id: faq?.id,
                  question: faq?.field_question,
                  answer: faq?.field_answer?.processed
              }))
            : []
    };
    return <FrequentlyAskedQuestions {...fAQQuestionProps} />;
}

export const query = graphql`
    fragment FrequentlyAskedQuestionsProvider on paragraph__frequently_asked_questions {
        field_faq_background_color
        relationships {
            field_faq_refs {
                id
                field_question
                field_answer {
                    processed
                }
            }
        }
    }
`;
