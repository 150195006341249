import React from 'react';
import { graphql } from 'gatsby';
import { QuoteProps } from 'components/quote/quote.props';
import Quote from 'components/quote/quote.component';
import QuoteIcon from 'ui-kit/icons/quote/quote-icon';

export default function QuoteComponentProvider({
    paragraph,
    sectionIndex
}: {
    paragraph: GatsbyTypes.QuoteComponentProviderFragment;
    sectionIndex: number;
}) {
    const quoteProps: QuoteProps = {
        sectionIndex: sectionIndex,
        quote: paragraph.field_qc_quote_text ? paragraph.field_qc_quote_text : '',
        speaker: paragraph.field_qc_quote_author ? paragraph.field_qc_quote_author : '',
        quoteIcon: paragraph.relationships?.field_qc_icon ? (
            <img
                src={paragraph.relationships?.field_qc_icon?.relationships?.field_media_svg?.localFile?.publicURL}
                alt={'Quote Icon'}
            />
        ) : (
            //TODO: Need to resolve Core Issue (https://www.drupal.org/project/drupal/issues/3005528) to get the default svg from drupal
            // Fallback of the default icon
            <QuoteIcon />
        )
    };
    return <Quote {...quoteProps} />;
}

export const query = graphql`
    fragment QuoteComponentProvider on paragraph__quote_component {
        id
        field_qc_quote_author
        field_qc_quote_text
        relationships {
            field_qc_icon {
                relationships {
                    field_media_svg {
                        localFile {
                            publicURL
                        }
                    }
                }
            }
        }
    }
`;
