import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'state/store';

export const drugSelector = (state: RootState) => state.drugReducer;

export const drugFormularySelector = createSelector(drugSelector, (drug) => (drug.formularies ? drug.formularies : []));

export const drugLookupSelector = createSelector(drugSelector, (drug) =>
    drug.drugLookupResults ? drug.drugLookupResults : []
);

export const drugDescriptionSelector = createSelector(drugSelector, (drug) =>
    drug.drugDescription ? drug.drugDescription : ''
);

export const drugsWithdisccountSelector = createSelector(drugSelector, (drug) => drug.drugDiscountPrices);
