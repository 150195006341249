import bPillModalSuccessIcon from 'assets/images/b-pill-modal-success.png';
import bPillModalDefaultIcon from 'assets/images/b-pill-modal-default.png';
import AlertIcon from 'ui-kit/icons/alert/alert-icon';
import { BirdiModalIconType } from 'types/birdi-modal-icon';

interface BirdiModalIconProps {
    icon: BirdiModalIconType;
}

const BirdiModalIcon = ({ icon }: BirdiModalIconProps): JSX.Element => {
    switch (icon) {
        case 'alert':
            return <AlertIcon />;
        case 'birdi-price':
            return (
                <svg width="60px" height="60px" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <circle stroke="#039fd3" strokeWidth="3" fill="#039fd3" cx="30" cy="30" r="28.5"></circle>
                        <g transform="translate(4.347826, 14.782609)">
                            <path
                                d="M35.1670043,7.428252 C35.1670043,6.70962476 35.7487396,6.12464803 36.4656381,6.12464803 C37.1825367,6.12464803 37.764272,6.70962476 37.764272,7.428252 C37.764272,8.14703665 37.1825367,8.73201339 36.4656381,8.73201339 C35.7487396,8.73201339 35.1670043,8.14703665 35.1670043,7.428252 Z M39.2381725,4.54006602 C37.9214625,2.81074387 35.827695,1.69212691 33.4691811,1.69212691 C33.3813307,1.69212691 33.2863089,1.69655766 33.1863566,1.70379455 C32.1131775,1.78532037 31.0897504,2.1640019 30.2090055,2.76673174 C30.1861465,2.78150091 30.1640345,2.79730392 30.1417731,2.81310694 C29.9944593,2.91663882 29.8510301,3.0256353 29.7125313,3.14157328 L27.2188055,5.22860474 L6.83214821,22.4274751 C6.04881561,23.0792386 5.94736933,24.2344831 6.60445435,25.0104553 C7.26153938,25.7849506 8.43183201,25.8856764 9.21516462,25.2339129 L11.6298442,22.9420092 C14.2829856,26.4356564 18.507872,28.6994748 23.2686756,28.6994748 C30.4265398,28.6994748 36.373473,23.5888988 37.585898,16.8585879 C37.8872487,15.1843548 38.6148531,13.614392 39.748392,12.3356772 L43.1128526,9.84382277 C43.6000636,9.46647047 43.6000636,8.73746422 43.1128526,8.36011192 C41.6722855,7.24356265 40.3388422,5.98596779 39.2381725,4.54006602 Z"
                                fill="#FFFFFF"
                            ></path>
                        </g>
                        <path
                            d="M20.9692012,17.9266037 C23.7564326,17.9266037 26.0159302,20.1861013 26.0159302,22.9733327 L26.0159302,28.0200617 L26.0159302,28.0200617 L15.9224722,28.0200617 L15.9224722,22.9733327 C15.9224722,20.1861013 18.1819697,17.9266037 20.9692012,17.9266037 Z"
                            fill="#222A3F"
                            transform="translate(20.969201, 22.973333) rotate(-41.000000) translate(-20.969201, -22.973333) "
                        ></path>
                        <path
                            d="M30.0787806,28.3638168 C32.8660121,28.3638168 35.1255096,30.6233143 35.1255096,33.4105458 L35.1255096,38.4572747 L35.1255096,38.4572747 L25.0320517,38.4572747 L25.0320517,33.4105458 C25.0320517,30.6233143 27.2915492,28.3638168 30.0787806,28.3638168 Z"
                            fill="#222A3F"
                            transform="translate(30.078781, 33.410546) rotate(-221.000000) translate(-30.078781, -33.410546) "
                        ></path>
                    </g>
                </svg>
            );
        case 'success':
            return (
                <img width={76} src={bPillModalSuccessIcon} alt={'Success Modal Icon'} title={'Success Modal Icon'} />
            );
        case 'default':
            return (
                <img width={76} src={bPillModalDefaultIcon} alt={'Default Modal Icon'} title={'Default Modal Icon'} />
            );
        default:
            return <></>;
    }
};

export default BirdiModalIcon;
