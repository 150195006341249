import React, { useState } from 'react';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import AcknowledgementInfo from 'components/acknowledgement-Info/acknowledgement-info';
import { Accordion } from 'react-bootstrap';
import { Trans } from 'gatsby-plugin-react-i18next';
import 'components/acknowledgement-Info/acknowledgement-info.style.scss';
import { graphql, useStaticQuery } from 'gatsby';

const AcknowledgementModal = () => {
    const [activePanel, setActivePanel] = useState('termsOfService');

    const pageData = useStaticQuery(graphql`
        query {
            termsOfService: allNodeLandingPage(filter: { path: { alias: { eq: "/website-terms-of-use" } } }) {
                nodes {
                    title
                    id
                    drupal_internal__nid
                    path {
                        langcode
                        alias
                    }
                    drupal_internal__vid
                    relationships {
                        field_landing_page_content {
                            ...supportedParagraphs
                        }
                    }
                }
            }
            websitePrivacyPolicy: allNodeLandingPage(filter: { path: { alias: { eq: "/website-privacy-policy" } } }) {
                nodes {
                    title
                    id
                    drupal_internal__nid
                    path {
                        langcode
                        alias
                    }
                    drupal_internal__vid
                    relationships {
                        field_landing_page_content {
                            ...supportedParagraphs
                        }
                    }
                }
            }
            privacyPolicy: allNodeLandingPage(filter: { path: { alias: { eq: "/notice-of-privacy-practices" } } }) {
                nodes {
                    title
                    id
                    drupal_internal__nid
                    path {
                        langcode
                        alias
                    }
                    drupal_internal__vid
                    relationships {
                        field_landing_page_content {
                            ...supportedParagraphs
                        }
                    }
                }
            }
        }
    `);

    const infoBody = (handlePanelToggle: Function, activePanel: string) => {
        return (
            <>
                <div className={'acknowledgement-info acknowledgement-title'}>
                    <Trans i18nKey="registrationTOSCheckboxLink">
                        {'I have read and agree to the '}
                        <span className="font-weight-bolder">Terms of Use</span>
                        {' and '}
                        <span className="font-weight-bolder">Website Privacy Policy</span>
                        {' and I acknowledge I have received the '}
                        <span className="font-weight-bolder">Notice of Privacy Practices of Birdi</span>
                        {'.'}
                    </Trans>
                </div>
                <div className="mt-3">
                    <Accordion
                        className="acknowledgement-accordion"
                        activeKey={activePanel}
                        defaultActiveKey={'termsOfService'}
                    >
                        <AcknowledgementInfo
                            data={pageData?.termsOfService}
                            eventKey="termsOfService"
                            handleNextPanel={handlePanelToggle}
                            nextRef={'webPrivacy'}
                            activeKey={activePanel}
                        />
                        <AcknowledgementInfo
                            data={pageData?.websitePrivacyPolicy}
                            eventKey="webPrivacy"
                            handleNextPanel={handlePanelToggle}
                            nextRef={'noticeOfPrivacy'}
                            activeKey={activePanel}
                        />
                        <AcknowledgementInfo
                            data={pageData?.privacyPolicy}
                            eventKey="noticeOfPrivacy"
                            handleNextPanel={handlePanelToggle}
                            nextRef={'termsOfService'}
                            activeKey={activePanel}
                        />
                    </Accordion>
                </div>
            </>
        );
    };
    const handlePanelToggle = (nextActivePanel: string) => {
        setActivePanel(nextActivePanel);
    };
    return <BirdiModalContent body={infoBody(handlePanelToggle, activePanel)} icon={'none'} />;
};
export default AcknowledgementModal;
