import { useState } from 'react';
import storageHelper from 'util/storageHelper';
import { ENABLE_FRONTEND_PROTECTION, FE_PROTECTION_USERNAME, FE_PROTECTION_PASSWORD } from 'gatsby-env-variables';
import crypto from 'crypto-browserify';

import BasicAuthSignInForm from 'components/basic-auth-sign-in-form/basic-auth-sign-in-form.component';
import { BasicAuth } from 'types/basic-auth';

//
// --- Constants ---

const BASIC_AUTH_COOKIE_SESSION = crypto
    .createHash('sha1')
    .update(`${FE_PROTECTION_USERNAME}${FE_PROTECTION_PASSWORD}`)
    .digest('hex');

const IS_BASIC_AUTH_ENABLED =
    ENABLE_FRONTEND_PROTECTION === true && FE_PROTECTION_USERNAME !== null && FE_PROTECTION_PASSWORD !== null;

//
// --- Helper Functions ---

const checkCredentials = (username: string, password: string) => {
    return username === FE_PROTECTION_USERNAME && password === FE_PROTECTION_PASSWORD;
};

//
// --- withBasicAuth Component ---

const withBasicAuth = (Component: any) => (props: any) => {
    const [loggedUser, setLoggedUser] = useState<string | null>(storageHelper.cookies.getBasicAuthCookie());
    const [basicAuthAttemptFailed, setBasicAuthAttemptFailed] = useState<boolean>(false);
    const isBasicAuthLoggedUser = loggedUser === BASIC_AUTH_COOKIE_SESSION;

    const handleSubmit = ({ username, password }: BasicAuth) => {
        const isValidCredentials = checkCredentials(username, password);

        if (isValidCredentials) {
            storageHelper.cookies.setBasicAuthCookie(BASIC_AUTH_COOKIE_SESSION);
            setLoggedUser(BASIC_AUTH_COOKIE_SESSION);
            setBasicAuthAttemptFailed(false);
        } else {
            setBasicAuthAttemptFailed(true);
        }
    };

    if (typeof window === 'undefined') {
        return null;
    }

    if (IS_BASIC_AUTH_ENABLED && !isBasicAuthLoggedUser) {
        return (
            <BasicAuthSignInForm
                onSubmit={handleSubmit}
                onBasicAuthAttemptFailedChange={setBasicAuthAttemptFailed}
                basicAuthAttemptFailed={basicAuthAttemptFailed}
            />
        );
    } else {
        return <Component {...props} />;
    }
};

export default withBasicAuth;
