import React, { ReactElement } from 'react';
import { Row, Col } from 'react-bootstrap';
import { DrugFormularyTableProps } from './drug-formulary-table.props';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { formatDrugName } from 'util/drug';
import { DosageFormsObjectPayload, DrugLookupObjectPayload, StrengthsObjectPayload } from 'state/drug/drug.services';

const DrugFormularyTable = ({ formulary }: DrugFormularyTableProps): ReactElement => {
    const { t } = useTranslation();

    if (formulary && formulary.length > 0) {
        let sortableFormulary: DrugLookupObjectPayload[] = [];
        // Need to filter out duplicate strengths (because of packaging) as well.
        Object.keys(formulary).forEach(function (key) {
            let drug: DrugLookupObjectPayload = { ...formulary[key] };
            let cleanDosageForms: DosageFormsObjectPayload[] = [];
            drug.dosageForms?.map((dosage: DosageFormsObjectPayload) => {
                let cleanDosage: DosageFormsObjectPayload = { ...dosage };
                let uniqueGPIs: any[] = [];
                let uniqueStrengths: StrengthsObjectPayload[] = [];
                cleanDosage.strengths.map((strength) => {
                    if (!uniqueGPIs.includes(strength.gpi)) {
                        uniqueGPIs.push(strength.gpi);
                        uniqueStrengths.push(strength);
                    }
                });
                cleanDosage.strengths = uniqueStrengths;
                cleanDosageForms.push(cleanDosage);
            });
            drug.dosageForms = cleanDosageForms;
            sortableFormulary.push(drug);
        });

        return (
            <table className="table table-striped table-bordered table-sticky-head">
                <thead className="thead-dark sticky-top">
                    <tr>
                        <th>
                            <Row>
                                <Col sm={12} lg={6}>
                                    {t('components.drugFormularyTable.drugName')}
                                </Col>
                                <Col sm={12} lg={6}>{`${t('components.drugFormularyTable.formats')} / ${t(
                                    'components.drugFormularyTable.strengths'
                                )}`}</Col>
                            </Row>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sortableFormulary
                        .sort(function (a, b) {
                            const drugNameA = formatDrugName(t, a).toLowerCase();
                            const drugNameB = formatDrugName(t, b).toLowerCase();
                            if (drugNameA < drugNameB) return -1;
                            if (drugNameA > drugNameB) return 1;
                            return 0;
                        })
                        .map((drug: DrugLookupObjectPayload, i) => (
                            <tr key={i}>
                                <td>
                                    <Row>
                                        <Col sm={12} lg={6} className="text-uppercase">
                                            {formatDrugName(t, drug)}
                                        </Col>
                                        <Col sm={12} lg={6}>
                                            {drug.dosageForms?.map((dosage, d) => (
                                                <Row
                                                    key={d}
                                                    className={d !== drug.dosageForms.length - 1 ? 'mb-3' : ''}
                                                >
                                                    <Col sm={12} lg={6}>
                                                        {dosage.dosageForm}
                                                    </Col>
                                                    <Col sm={12} lg={6}>
                                                        {dosage.strengths?.map((strength, s) => (
                                                            <Row key={s}>
                                                                <Col>
                                                                    {strength.strength} {strength.strengthUnit}
                                                                </Col>
                                                            </Row>
                                                        ))}
                                                    </Col>
                                                </Row>
                                            ))}
                                        </Col>
                                    </Row>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        );
    } else {
        return <></>;
    }
};

export default DrugFormularyTable;
