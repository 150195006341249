import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { navigate } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { accountHasInsuranceSelector, accountIsLoggedInSelector } from 'state/account/account.selectors';
import { setDrugLookupStatus, resetDrugLookupResults } from 'state/drug/drug.reducers';
import { setDrugDetailValues } from 'state/add-transfer-prescription/add-transfer-prescription.reducers';
import { drugSelector } from 'state/drug/drug.selectors';
import { DosageFormsObjectPayload, DrugDescriptionObjectPayload } from 'state/drug/drug.services';
import { StrengthOption } from 'state/add-transfer-prescription/add-transfer-prescription.reducers';

import { PrescriptionTypes } from 'types/prescription';

import DashboardCtaBlock from 'components/dashboard-cta-block/DashboardCtaBlock';
import DrugLookupForm, {
    extractPackageOptions, // DRX-1056 enhancement
    extractStrengths
} from 'components/drug-lookup-form/drug-lookup-form.component';
import DrugInfo from 'components/drug-info/drug-info.component';
import { DrugFormWrapper } from 'components/drug-form-wrapper/drug-form-wrapper.component';

import storageHelper, { selectedDrugObject } from 'util/storageHelper';

import './get-birdi-price.style.scss';
import BirdiPrice from 'components/birdi-price/birdi-price.component';
import Link from 'ui-kit/link/link';

export interface GetBirdiPriceProps {
    subTitle: string;
    titleText: string;
    linkTo: string;
    linkLabel: string;
    image: FluidObject;
    sectionIndex: number;
}

const GetBirdiPrice = ({ subTitle, titleText, linkTo, linkLabel, image, sectionIndex }: GetBirdiPriceProps) => {
    const formName = 'GetBirdiPriceSearchForm';
    const { t } = useTranslation();
    const [isControlled, setIsControlled] = useState(false);
    const [isFormValid, setIsFormValid] = useState(true);
    const [showPrice, setShowPrice] = useState(false);
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const isLoggedIn = useSelector(accountIsLoggedInSelector);
    const { drugLookupStatus, drugDetails } = useSelector(drugSelector);
    const [selectedDrug, setSelectedDrug] = useState<selectedDrugObject>({
        drugName: '',
        genericProductCode: '',
        strength: '',
        qty: '',
        drugForm: '',
        price: '',
        description: '',
        packageDisplay: '',
        packageOption: '',
        strengthWithPackage: '',
        gpi: '',
        ndc: ''
    });
    const [formError, setFormError] = useState('');
    const dispatch = useDispatch();
    const resultsRef = useRef(null);

    // Reset the drug lookup status on the very first form load.
    useEffect(() => {
        dispatch(setDrugLookupStatus('IDLE'));
        dispatch(resetDrugLookupResults());
    }, []);

    // Update the drug forms dropdown after drug details have been fetched.
    useEffect(() => {
        // If drug details have been fetched, update the drugforms dropdown.
        switch (drugLookupStatus) {
            case 'FETCHED_DETAILS':
                updateDrugForms();
                break;
            default:
            // Do nothing.
        }
    }, [drugLookupStatus]);

    // Update the drugforms dropdown.
    // This is called from useEffect when the drugLookupStatus indicates that
    // drug details have been fetched.
    const updateDrugForms = () => {
        const drugForms = drugDetails.dosageForms.map((item: DosageFormsObjectPayload) => {
            return {
                key: item.dosageForm,
                label: item.dosageForm,
                value: item.dosageForm,
                strengths: item.strengths,
                gpi: item.gpi // DRX-1056 enhancement
            };
        });

        // If there is only one drug form, go ahead and populate the drug strengths
        // as well.
        let drugStrengths: StrengthOption[] = [];
        if (drugForms.length === 1) {
            drugStrengths = extractStrengths(drugForms[0].strengths);
        }
        // DRX-1056 enhancement
        let pkgOptions = [];
        if (drugStrengths.length === 1) {
            pkgOptions = drugStrengths[0].packageOptions ? extractPackageOptions(drugStrengths[0]) : [];
        }
        dispatch(
            setDrugDetailValues({
                drugFormOptions: drugForms,
                drugStrengthOptions: drugStrengths,
                drugPackageOptions: pkgOptions // DRX-1056 enhancement
            })
        );
    };

    // Reset the form.
    const handleReset = (form: any) => {
        setIsControlled(false);
        setIsFormValid(true);
        setFormError('');
    };

    const resetSelectedDrug = (values: Partial<PrescriptionTypes>) => {
        setSelectedDrug({
            drugName: values.drugName ? values.drugName : '',
            genericProductCode: values.genericProductCode ? values.genericProductCode : '',
            gpi: values.gpi ? values.gpi : '',
            ndc: values.ndc ? values.ndc : '',
            strength: values.strength ? values.strength : '',
            qty: values.qty ? values.qty : '',
            drugForm: values.drugForm ? values.drugForm : '',
            price: '',
            description: '',
            packageDisplay: values.packageDisplay ? values.packageDisplay : '',
            packageOption: '',
            strengthWithPackage: values.strengthWithPackage ? values.strengthWithPackage : ''
        });
    };
    const handlePriceSuccess = (priceValue: string) => {
        setShowPrice(true);
        setSelectedDrug((previousData) => {
            return {
                ...previousData,
                price: priceValue && priceValue !== 'NA' ? priceValue : t('components.getBirdiPrice.priceError')
            };
        });

        // Scroll to the results.
        if (typeof window !== 'undefined') {
            window.scrollTo({ behavior: 'smooth', top: resultsRef?.current?.offsetTop });
        }
    };
    const handleDescriptionSuccess = (response: DrugDescriptionObjectPayload) => {
        setSelectedDrug((previousData) => {
            return {
                ...previousData,
                description: response ? response.htmlDesc : t('components.getBirdiPrice.descrError')
            };
        });
    };
    let RenderFormikFunction = (formik: any) => {
        useEffect(() => {
            formik.validateField('drugName');
        }, [isControlled]);

        return (
            <DrugLookupForm
                isDrugNameEditable={true}
                formName={formName}
                formik={formik}
                isFormValid={isFormValid}
                isControlled={isControlled}
                accountHasInsurance={accountHasInsurance}
                currentFlowType="New"
                setIsControlled={setIsControlled}
                setIsFormValid={setIsFormValid}
                setFormError={setFormError}
                handleReset={handleReset}
                variant="gbp"
                className="has-blue-bg"
            />
        );
    };

    // If a logged-in user tries to access the page that houses this component,
    // redirect them to the medicine cabinet.
    if (isLoggedIn) {
        navigate('/secure/medicine-cabinet');
        return null;
    }

    return (
        <>
            <Container fluid className={`get-birdi-price`} id={'get-birdi-price'}>
                <Row>
                    <Col sm="12" lg={{ span: 10, offset: 1 }}>
                        {formError && (
                            <Row>
                                <Col>
                                    <p className="prescription-error">{formError}</p>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col lg="8" className="get-birdi-pice-form-container">
                                {sectionIndex === 0 && titleText && (
                                    <h1 className="h2 get-birdi-price-title text-center text-lg-left">{titleText}</h1>
                                )}
                                {sectionIndex > 0 && titleText && (
                                    <h2 className="get-birdi-price-title text-center text-lg-left">{titleText}</h2>
                                )}
                                {subTitle && (
                                    <div className="h3 get-birdi-price-subtitle text-center text-lg-left">
                                        {subTitle}
                                    </div>
                                )}
                                {linkTo && linkLabel && (
                                    <Link
                                        to={linkTo}
                                        label={linkLabel}
                                        className={
                                            'h3 get-birdi-price-link font-weight-bold text-center text-lg-left d-block d-lg-inline-block'
                                        }
                                        variant={'underline'}
                                        dataGALocation={'Get Birdi Price'}
                                    />
                                )}
                                <div className="spacer text-center text-lg-left mx-auto mx-lg-0 d-block">&nbsp;</div>
                                <DrugFormWrapper
                                    isControlled={isControlled}
                                    isDrugNameEditable={true}
                                    renderFormikFunction={RenderFormikFunction}
                                    handlePriceSuccess={handlePriceSuccess}
                                    handleDescriptionSuccess={handleDescriptionSuccess}
                                    resetSelectedDrug={resetSelectedDrug}
                                />
                            </Col>
                            <Col
                                className="get-birdi-price-image-container"
                                xs={{ span: 8, offset: 2 }}
                                sm={{ span: 6, offset: 3 }}
                                lg={{ span: 4, offset: 0 }}
                            >
                                {image && <GatsbyImage image={getImage(image)} alt={''} />}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <div ref={resultsRef}>
                {showPrice && (
                    <Container>
                        <Row>
                            <Col sm="12" lg={{ span: 10, offset: 1 }}>
                                <Row className="get-birdi-price-title-container no-gutters">
                                    <Col xs="12" sm="8">
                                        <div className="get-birdi-price-results-title">
                                            <h2 className="h3">{t('components.getBirdiPrice.results')}</h2>
                                        </div>
                                    </Col>
                                    <Col xs="12" sm="4">
                                        <div className="get-birdi-price-results-price-title d-none d-sm-block">
                                            <div className="h3 text-right">
                                                {t('components.getBirdiPrice.theBirdiPrice')}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col xs="12" sm="8" className="mb-4">
                                        <div className="get-birdi-price-results-name">
                                            <div className="h3 text-uppercase">{selectedDrug.drugName}</div>
                                            <div className="get-birdi-price-results-details">
                                                <span>
                                                    {t('components.getBirdiPrice.qty')} {selectedDrug.qty}
                                                </span>
                                                {selectedDrug.packageDisplay !== '' && (
                                                    <span>{selectedDrug.packageDisplay}</span>
                                                )}
                                                <span>{selectedDrug.strength}</span>
                                                <span>{selectedDrug.drugForm}</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs="12" sm="4" className="text-sm-right">
                                        <div className="h3">{selectedDrug.price}</div>
                                        <div className="d-inline-block">
                                            <BirdiPrice accountHasInsurance={accountHasInsurance} />
                                        </div>
                                        <div>{t('components.getBirdiPrice.shipping')}</div>
                                    </Col>
                                </Row>
                                <Row className="mb-5">
                                    <Col sm="12" lg="8">
                                        <p className="large">{t('components.getBirdiPrice.disclaimer')}</p>
                                    </Col>
                                </Row>
                                <Row xs={2} lg={2} xl={2} className="get-birdi-price-ctas mb-5">
                                    <Col className="mb-lg-0 mb-4">
                                        <DashboardCtaBlock
                                            icon={'plane'}
                                            title={t('components.dashboardCtaBlock.titles.transferPre')}
                                            bodyText={t('components.dashboardCtaBlock.body.transferPre')}
                                            ctas={[
                                                {
                                                    label: t('components.dashboardCtaBlock.ctas.transferPre'),
                                                    variant: 'dark',
                                                    dataGALocation: 'GetBirdiPrice',
                                                    onClick: () => {
                                                        storageHelper.session.setSelectedDrug(selectedDrug);
                                                        storageHelper.session.setPrescriptionFlowType('Transfer');
                                                        navigate('/get-started');
                                                    }
                                                }
                                            ]}
                                        />
                                    </Col>
                                    <Col className="mb-lg-0 mb-4">
                                        <DashboardCtaBlock
                                            icon={'pill-bottle'}
                                            title={t('components.dashboardCtaBlock.titles.reqNew')}
                                            bodyText={t('components.dashboardCtaBlock.body.reqNew')}
                                            ctas={[
                                                {
                                                    label: t('components.dashboardCtaBlock.ctas.reqNew'),
                                                    variant: 'dark',
                                                    dataGALocation: 'GetBirdiPrice',
                                                    onClick: () => {
                                                        storageHelper.session.setSelectedDrug(selectedDrug);
                                                        storageHelper.session.setPrescriptionFlowType('New');
                                                        navigate('/get-started');
                                                    }
                                                }
                                            ]}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="get-birdi-price-results-title">
                                            <h2 className="h3">{t('components.getBirdiPrice.drugInformation')}</h2>
                                        </div>
                                        <DrugInfo
                                            details={selectedDrug.description}
                                            className="get-birdi-price-results-description large"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                )}
            </div>
        </>
    );
};

export default GetBirdiPrice;
