import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createRoutine } from 'redux-saga-routines';
import produce from 'immer';
import { AuthenticateUserResponse, UnauthorizedCaregiver, VerifyUserResponse } from 'types/family-account';
import { Caregiver } from 'types/family-management';
import { caregiverStatusMap } from 'state/family-profile/family-profile-helpers';
import { getLastCaregiverUpdateDate } from './family-account.helpers';

export interface FamilyAccountMemberToAdd {
    firstName: string;
    lastName: string;
    age: number;
    accountType: 'Child' | 'Pet' | 'Adult';
    prescriptionNumber: string;
    dobMonth: string;
    dobDay: number;
    dobYear: number;
}

export interface FamilyAccountState {
    firstName: string;
    lastName: string;
    userVerified: 'UserNotVerified' | 'UserVerified' | 'MaxAttemptsReached' | '';
    securityToken: string;
    secretKeyHash: string;
    bearerToken: string;
    error: {
        messageText?: string;
    };
    memberToAdd: FamilyAccountMemberToAdd;
    isCaregiverLoading: boolean;
    caregivers: Caregiver[];
    isExpired: boolean;
}

const initialState: FamilyAccountState = {
    firstName: '',
    lastName: '',
    userVerified: '',
    securityToken: '',
    secretKeyHash: '',
    bearerToken: '',
    isExpired: false,
    error: {
        messageText: undefined
    },
    memberToAdd: {
        firstName: '',
        lastName: '',
        age: 0,
        accountType: 'Child',
        prescriptionNumber: '',
        dobMonth: '',
        dobDay: 0,
        dobYear: 0
    },
    isCaregiverLoading: false,
    caregivers: []
};

// Routines
export const familyAccountVerifyUserRoutine = createRoutine('family-account/VERIFY_USER');
export const familyAccountGetSecretKeyHashRoutine = createRoutine('family-account/GET_SECRET_KEY_HASH');
export const familyAccountAuthenticateUserRoutine = createRoutine('family-account/AUTHENTICATE_USER');

export const familyAccountGetCaregiversRoutine = createRoutine('family-account/GET_CAREGIVERS');
export const familyAccountPostChangeCaregiversAccessRoutine = createRoutine(
    'family-account/POST_CHANGE_CAREGIVERS_ACCESS'
);

const familyAccountSlice = createSlice({
    name: 'family-account',
    initialState,
    reducers: {
        setSecurityToken: (state, action: PayloadAction<{ securityToken: string }>) => {
            state.securityToken = action.payload.securityToken;
        },
        resetBearerToken: (state) => {
            state.bearerToken = initialState.bearerToken;
        },
        setMemberToAddFormValues: (state, action: PayloadAction<FamilyAccountMemberToAdd>) => {
            state.memberToAdd = action.payload;
        },
        setExpired: (state) => {
            state.isExpired = true;
        }
    },
    extraReducers: ({ addCase }) => {
        /**
         * Family Account reducers
         */
        // Verify User
        addCase(familyAccountVerifyUserRoutine.SUCCESS, (state, { payload }: PayloadAction<VerifyUserResponse>) =>
            produce(state, (draftState) => {
                draftState.userVerified = payload.verificationStatus;
                draftState.firstName = payload.firstName ?? 'Hi';
            })
        );
        addCase(familyAccountVerifyUserRoutine.FAILURE, (state, { payload }: PayloadAction<{ messageText: string }>) =>
            produce(state, (draftState) => {
                draftState.error.messageText = payload.messageText;
            })
        );
        // Getting user secret key hash for authentication
        addCase(familyAccountGetSecretKeyHashRoutine.SUCCESS, (state, { payload }: PayloadAction<string>) =>
            produce(state, (draftState) => {
                draftState.secretKeyHash = payload;
            })
        );
        addCase(familyAccountGetSecretKeyHashRoutine.FAILURE, (state, { payload }: PayloadAction<string>) =>
            produce(state, (draftState) => {
                draftState.error.messageText = payload;
            })
        );
        // authenticate user
        addCase(
            familyAccountAuthenticateUserRoutine.SUCCESS,
            (state, { payload }: PayloadAction<AuthenticateUserResponse>) =>
                produce(state, (draftState) => {
                    draftState.bearerToken = payload.bearerToken;
                })
        );
        addCase(
            familyAccountAuthenticateUserRoutine.FAILURE,
            (state, { payload }: PayloadAction<AuthenticateUserResponse>) =>
                produce(state, (draftState) => {
                    draftState.bearerToken = initialState.bearerToken;
                    draftState.error.messageText = payload.messageText;
                })
        );

        // GET Caregivers
        addCase(familyAccountGetCaregiversRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isCaregiverLoading = true;
            })
        );
        addCase(
            familyAccountGetCaregiversRoutine.SUCCESS,
            (state, { payload }: PayloadAction<UnauthorizedCaregiver[]>) =>
                produce(state, (draftState) => {
                    draftState.isCaregiverLoading = false;
                    draftState.caregivers = payload
                        // DRX-1810: If the caregiver has a DateRevokeAccess, it is exclude until
                        // They resend the invite and change the status.
                        .filter((caregiver: UnauthorizedCaregiver) => !caregiver.caregiver.dateRevokeAccess)
                        .map((caregiver: UnauthorizedCaregiver) => ({
                            caregiverName: `${caregiver.caregiver.firstName} ${caregiver.caregiver.lastName}`,
                            firstName: caregiver.caregiver.firstName,
                            lastName: caregiver.caregiver.lastName,
                            accountStatus:
                                caregiverStatusMap.get(caregiver.releventDependentInformation.accountStatus) ??
                                'expired',
                            lastUpdateDate: getLastCaregiverUpdateDate(caregiver),
                            ePostPatientNumber: caregiver.caregiver.epostPatientNum,
                            id: caregiver.releventDependentInformation.id
                        }));
                })
        );
        addCase(familyAccountGetCaregiversRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isCaregiverLoading = false;
                draftState.caregivers = [];
            })
        );
    }
});

export const familyAccountActions = familyAccountSlice.actions;

export default familyAccountSlice.reducer;
