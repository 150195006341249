import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { drupalFetchCSRFRoutine } from 'state/drupal/drupal.routines';
import { baseEffectHandler } from 'util/sagas/sagas';
import DrupalService from './drupal.services';

export default function* drupalSaga() {
    yield takeLatest(drupalFetchCSRFRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        yield baseEffectHandler({
            service: DrupalService.fetchCSRF().get,
            *onResponse(data: any) {
                yield put(drupalFetchCSRFRoutine.success(data));
            },
            *onError(error: any) {
                yield put(drupalFetchCSRFRoutine.failure(error));
            }
        });
    });
}
