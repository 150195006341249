/**
 * No op function that can be used in place of empty anonymous functions
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
export function noop() {}
export type UnknownFunction = (...args: unknown[]) => void;

/**
 * Delay function that can be used in situations where a setTimeout is required
 */
export function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
