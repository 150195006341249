import React, { ReactElement } from 'react';

const DropFilesIcon = ({ className, style }: { className?: string; style?: CSSProperties }): ReactElement => {
    return (
        <div className={`drop-files-icon ${className ?? ''}`} style={style}>
            <svg width="34px" height="34px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <circle fill="#1A497F" cx="15" cy="15" r="15"></circle>
                <g stroke="none" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round">
                    <g stroke="#ffffff" strokeWidth="1.5">
                        <g transform="translate(7.75,8) scale(.8, .8)">
                            <path
                                d="M10.6390597,8.92307692 L13.1005982,8.92307692 L9.10059818,4 L5.10059818,8.92307692 L7.56213664,8.92307692 L7.56213664,11.6923077 C7.56213664,12.5421538 8.25075203,13.2307692 9.10059818,13.2307692 C9.95044433,13.2307692 10.6390597,12.5421538 10.6390597,11.6923077 C10.6390597,11.4696987 10.6390597,11.4716619 10.6390597,11.3194997 C10.6390597,11.1952494 10.6390597,9.19712219 10.6390597,8.92307692 Z"
                                strokeLinejoin="round"
                                transform="translate(9.100598, 8.615385) rotate(-180.000000) translate(-9.100598, -8.615385) "
                            ></path>
                            <g>
                                <polyline points="14.0419097 0 18 0 18 4.00444751"></polyline>
                                <polyline
                                    transform="translate(2.005515, 16.007783) rotate(-180.000000) translate(-2.005515, -16.007783) "
                                    points="0 14.0155663 4.01103032 14.0155663 4.01103032 18"
                                ></polyline>
                                <polyline
                                    transform="translate(2.014664, 2.021732) rotate(-90.000000) translate(-2.014664, -2.021732) "
                                    points="0.0685393691 0.00706816317 3.96078773 0.00706816317 3.96078773 4.03639526"
                                ></polyline>
                                <polyline
                                    transform="translate(15.895915, 15.999426) rotate(-270.000000) translate(-15.895915, -15.999426) "
                                    points="13.9597977 14.022502 17.8320323 14.022502 17.8320323 17.9763493"
                                ></polyline>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default DropFilesIcon;
