import { graphql } from 'gatsby';
import { useMemo } from 'react';

import IconList from 'components/icon-list/IconList';

export default function IconListComponentProvider({
    paragraph,
    sectionIndex
}: {
    paragraph: GatsbyTypes.IconListComponentProviderFragment;
    sectionIndex: number;
}) {
    const data = useMemo(
        () => ({
            eyebrowText: paragraph.field_il_eyebrow,
            title: paragraph.field_il_title,
            subtitle: paragraph.field_il_subtitle,
            image: paragraph.relationships?.field_il_background_image?.relationships?.field_media_image?.localFile,
            tabs: (paragraph.relationships?.field_il_icons_tabs || []).map((tab) => ({
                tabName: tab?.field_ilt_tab_title || '',
                icons: (tab?.relationships?.field_il_icons || []).map((icon) => ({
                    body: icon?.field_ri_html_body?.processed
                        ? icon?.field_ri_html_body?.processed
                        : icon?.field_ri_body,
                    bodyClassName: icon?.field_ri_html_body?.processed ? 'bodyAsHTML' : '',
                    title: icon?.field_ri_title,
                    icon: icon?.relationships?.field_ri_icon?.relationships?.field_media_svg?.localFile,
                    alt: icon?.relationships?.field_ri_icon?.relationships?.field_media_svg?.alt || ''
                }))
            }))
        }),
        [paragraph]
    );

    return (
        <IconList
            eyebrowText={data.eyebrowText}
            title={data.title}
            subtitle={data.subtitle}
            image={data.image}
            sectionIndex={sectionIndex}
            iconsTabs={data.tabs}
        />
    );
}

export const query = graphql`
    fragment IconListComponentProvider on paragraph__icon_list_component {
        id
        field_il_eyebrow
        field_il_title
        field_il_subtitle
        relationships {
            field_il_background_image {
                relationships {
                    field_media_image {
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(formats: [AUTO])
                            }
                        }
                    }
                }
            }
            field_il_icons_tabs {
                field_ilt_tab_title
                relationships {
                    field_il_icons {
                        field_ri_body
                        field_ri_html_body {
                            processed
                        }
                        field_ri_title
                        relationships {
                            field_ri_icon {
                                relationships {
                                    field_media_svg {
                                        localFile {
                                            publicURL
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
