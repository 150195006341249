import AlertIcon from 'ui-kit/icons/alert/alert-icon';
import sanitizeHtml from 'sanitize-html';
import './empty-alert-box.style.scss';

interface EmptyAlertBoxProps {
    text: string;
}
const EmptyAlertBox = ({ text }: EmptyAlertBoxProps) => (
    <div className="empty-alert-box__header">
        <AlertIcon className={'header-icon-container'} />
        <p
            dangerouslySetInnerHTML={{
                __html: sanitizeHtml(text, {
                    allowedTags: ['span', 'strong'],
                    allowedAttributes: {
                        span: ['class']
                    }
                })
            }}
        />
    </div>
);
export default EmptyAlertBox;
