import React from 'react';
import { graphql } from 'gatsby';
import { HowItWorksProps } from 'components/how-it-works/how-it-works.props';
import HowItWorks from 'components/how-it-works/how-it-works.component';

export default function ContentBlocksProvider({
    paragraph,
    sectionIndex
}: {
    paragraph: GatsbyTypes.ContentBlocksProviderFragment;
    sectionIndex: number;
}) {
    const contentBlocksProps: HowItWorksProps = {
        sectionIndex: sectionIndex,
        eyebrowText: paragraph.field_cbs_eyebrow,
        title: paragraph.field_cbs_title,
        ctaText: paragraph.field_cbs_cta_link?.title,
        ctaTo: paragraph.field_cbs_cta_link?.uri,
        backgroundColor:
            paragraph.field_cbs_background_color === 'white' ? undefined : paragraph.field_cbs_background_color,
        blocks: paragraph.relationships?.field_cbs_content_block_refs
            ? paragraph.relationships?.field_cbs_content_block_refs?.map((block) => ({
                  title: block?.field_cb_subtitle,
                  text: block?.field_cb_body,
                  image: block?.relationships?.field_cb_block_image?.localFile,
                  imageAltText: block?.field_cb_block_image?.alt
              }))
            : []
    };
    return <HowItWorks {...contentBlocksProps} />;
}

export const query = graphql`
    fragment ContentBlocksProvider on paragraph__content_blocks {
        field_cbs_title
        field_cbs_eyebrow
        field_cbs_cta_link {
            title
            uri: url
        }
        field_cbs_background_color
        relationships {
            field_cbs_content_block_refs {
                field_cb_body
                field_cb_subtitle
                relationships {
                    field_cb_block_image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(formats: [AUTO, WEBP])
                            }
                        }
                    }
                }
                field_cb_block_image {
                    alt
                }
            }
        }
    }
`;
