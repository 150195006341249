import React, { ReactElement } from 'react';

import { Link as GatsbyLink } from 'gatsby';
import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';

import { CtaStackProps } from 'ui-kit/cta-stack/cta-stack.props';

import './cta-stack.style.scss';

const CtaStack = ({
    disabled,
    eyebrowText,
    iconDirection,
    label,
    onClick,
    to,
    className
}: CtaStackProps): ReactElement => {
    return (
        <div className="cta-stack">
            {eyebrowText && <div className="eyebrow-text">{eyebrowText}</div>}
            <div className="cta">
                {!onClick && to && (
                    <GatsbyLink to={to}>
                        {label}
                        <ChevronIcon
                            direction={iconDirection ?? 'right'}
                            style={{ display: 'inline-block', position: 'relative', top: '.5em', left: '.25em' }}
                        />
                    </GatsbyLink>
                )}
                {onClick && (
                    <button className={className} onClick={onClick} tabIndex={0} disabled={disabled}>
                        {iconDirection && iconDirection === 'left' && (
                            <ChevronIcon
                                direction="left"
                                style={{
                                    display: 'inline-block',
                                    position: 'relative',
                                    bottom: '0',
                                    left: '.25em',
                                    marginRight: '.5rem'
                                }}
                            />
                        )}
                        {label}
                        {(!iconDirection || iconDirection === 'right') && (
                            <ChevronIcon
                                direction="right"
                                style={{
                                    display: 'inline-block',
                                    position: 'relative',
                                    top: '0.8rem',
                                    left: '.25em'
                                }}
                            />
                        )}
                    </button>
                )}
            </div>
        </div>
    );
};

export default CtaStack;
