import { CameraProps } from './camera.props';

import React from 'react';

const Camera = ({ className, style }: CameraProps) => {
    return (
        <div className={className} style={style}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                width="100%"
                height="100%"
                fill="currentColor"
            >
                <path d="M256,157c-68.925,0-125,56.075-125,125s56.075,125,125,125s125-56.075,125-125S324.925,157,256,157z M256,367     c-46.869,0-85-38.131-85-85c0-46.869,38.131-85,85-85s85,38.131,85,85C341,328.869,302.869,367,256,367z" />
                <path d="M452,97h-73.575c-2.329,0-4.428-1.271-5.508-3.327L352.562,50.9c-0.056-0.117-0.112-0.233-0.171-0.349     C344.512,34.791,328.67,25,311.049,25H202.376c-17.621,0-33.463,9.791-41.342,25.552c-0.059,0.116-0.115,0.232-0.171,0.349     l-20.356,42.775C139.427,95.729,137.329,97,135,97H60c-33.084,0-60,26.916-60,60v270c0,33.084,26.916,60,60,60h392     c33.084,0,60-26.916,60-60V157C512,123.916,485.084,97,452,97z M472,426.999c0,11.028-8.972,20-20,20H60     c-11.028,0-20-8.972-20-20V157c0-11.028,8.972-20,20-20h75c17.62,0,33.462-9.79,41.342-25.55c0.059-0.116,0.115-0.233,0.172-0.35     l20.356-42.775c1.079-2.054,3.177-3.325,5.506-3.325h108.673c2.329,0,4.427,1.271,5.506,3.324l20.356,42.775     c0.057,0.117,0.113,0.234,0.172,0.35c7.88,15.76,23.722,25.55,41.342,25.55H452c11.028,0,20,8.972,20,20V426.999z" />
            </svg>
        </div>
    );
};

export default Camera;
