import { graphql } from 'gatsby';
import React from 'react';

import CenteredTextHeroProvider from './CenteredTextHeroProvider';
import ContactUsFormProvider from './ContactUsFormProvider';
import ContentBlocksProvider from './ContentBlocksProvider';
import FeatureHighlightProvider from './FeatureHighlightProvider';
import FrequentlyAskedQuestionsProvider from './FrequentlyAskedQuestionsProvider';
import GetBirdiPriceProvider from './GetBirdiPriceProvider';
import IconGridComponentProvider from './IconGridComponentProvider';
import IconListComponentProvider from './IconListComponentProvider';
import ImageListComponentProvider from './ImageListComponentProvider';
import InternalHeaderComponentProvider from './InternalHeaderComponentProvider';
import MultipleImageHeroProvider from './MultipleImageHeroProvider';
import PageAlertProvider from './PageAlertProvider';
import PlansPricingProvider from './PlansPricingProvider';
import QuoteComponentProvider from './QuoteComponentProvider';
import ResourcesFileListProvider from './ResourcesFileListProvider';
import RichTextComponentProvider from './RichTextComponentProvider';
import RoundedHeroProvider from './RoundedHeroProvider';
import SideBySideProvider from './SideBySideProvider';
import WebFormProvider from './WebFormProvider';

export function paragraphToComponent(
    paragraphKey: string | undefined,
    paragraph: any,
    sectionIndex: number
): JSX.Element {
    const paragraphMap: { [paragraphKey: string]: JSX.Element } = {
        paragraph__rounded_hero_component: <RoundedHeroProvider {...{ paragraph, sectionIndex }} />,
        paragraph__content_blocks: <ContentBlocksProvider {...{ paragraph, sectionIndex }} />,
        paragraph__quote_component: <QuoteComponentProvider {...{ paragraph, sectionIndex }} />,
        paragraph__multiple_image_hero_component: <MultipleImageHeroProvider {...{ paragraph, sectionIndex }} />,
        paragraph__frequently_asked_questions: <FrequentlyAskedQuestionsProvider {...{ paragraph, sectionIndex }} />,
        paragraph__icon_grid_component: <IconGridComponentProvider {...{ paragraph, sectionIndex }} />,
        paragraph__centered_text_hero: <CenteredTextHeroProvider {...{ paragraph, sectionIndex }} />,
        paragraph__resources_file_list: <ResourcesFileListProvider {...{ paragraph, sectionIndex }} />,
        paragraph__feature_highlight_component: <FeatureHighlightProvider {...{ paragraph, sectionIndex }} />,
        paragraph__icon_list_component: <IconListComponentProvider {...{ paragraph, sectionIndex }} />,
        paragraph__rich_text_component: <RichTextComponentProvider {...{ paragraph, sectionIndex }} />,
        paragraph__internal_header_component: <InternalHeaderComponentProvider {...{ paragraph, sectionIndex }} />,
        paragraph__contact_us_form: <ContactUsFormProvider {...{ paragraph, sectionIndex }} />,
        paragraph__get_birdi_price_component: <GetBirdiPriceProvider {...{ paragraph, sectionIndex }} />,
        paragraph__webform: <WebFormProvider {...{ paragraph, sectionIndex }} />,
        paragraph__side_by_side_paragraphs: <SideBySideProvider {...{ paragraph, sectionIndex }} />,
        paragraph__page_alert_banner: <PageAlertProvider {...{ paragraph, sectionIndex }} />,
        paragraph__plans_pricing: <PlansPricingProvider {...{ paragraph, sectionIndex }} />,
        paragraph__image_list_component: <ImageListComponentProvider {...{ paragraph, sectionIndex }} />
    };
    if (paragraphKey === undefined || paragraphMap[paragraphKey] === undefined) {
        return (
            <div>
                <div style={{ color: 'red' }}>Failed to load paragraph {paragraphKey} was not valid</div>
                <div style={{ color: 'red' }}>JSON stringify of paragraphData: {JSON.stringify(paragraph)}</div>
            </div>
        );
    }
    return paragraphMap[paragraphKey];
}

export const query = graphql`
    fragment supportedParagraphs on Node {
        internal {
            type
        }
        ... on paragraph__multiple_image_hero_component {
            ...MultipleImageHeroProvider
        }
        ... on paragraph__content_blocks {
            ...ContentBlocksProvider
        }
        ... on paragraph__rounded_hero_component {
            ...RoundedHeroProvider
        }
        ... on paragraph__quote_component {
            ...QuoteComponentProvider
        }
        ... on paragraph__frequently_asked_questions {
            ...FrequentlyAskedQuestionsProvider
        }
        ... on paragraph__icon_grid_component {
            ...IconGridComponentProvider
        }
        ... on paragraph__centered_text_hero {
            ...CenteredTextHeroProvider
        }
        ... on paragraph__resources_file_list {
            ...ResourcesFileListProvider
        }
        ... on paragraph__feature_highlight_component {
            ...FeatureHighlightProvider
        }
        ... on paragraph__icon_list_component {
            ...IconListComponentProvider
        }
        ... on paragraph__rich_text_component {
            ...RichTextComponentProvider
        }
        ... on paragraph__internal_header_component {
            ...InternalHeaderComponentProvider
        }
        ... on paragraph__contact_us_form {
            ...ContactUsFormProvider
        }
        ... on paragraph__get_birdi_price_component {
            ...GetBirdiPriceProvider
        }
        ... on paragraph__webform {
            ...WebFormProvider
        }
        ... on paragraph__side_by_side_paragraphs {
            ...SideBySideProvider
        }
        ... on paragraph__page_alert_banner {
            ...PageAlertProvider
        }
        ... on paragraph__plans_pricing {
            ...PlansPricingProvider
        }
        ... on paragraph__image_list_component {
            ...ImageListComponentProvider
        }
    }
`;
