import axiosClient from 'util/axiosClient';
// api still in development, the endpoint bellow will be replaced.
import { EASY_REFILL_API_URL } from 'gatsby-env-variables';
import {
    AuthenticateUserRequest,
    AuthenticateUserResponse,
    JoinMembershipPlanInvitationResponse,
    JoinMembershipPlanAcceptOrDeclineInvitePayload,
    JoinMembershipPlanAcceptOrDeclineInviteResponse,
    VerifyUserRequest,
    VerifyUserResponse
} from 'types/join-membership-plan';

export class JoinMembershipPlanService {
    static verifyUser() {
        return {
            post: async (payload: VerifyUserRequest): Promise<VerifyUserResponse> => {
                const response = await axiosClient.post(`${EASY_REFILL_API_URL}/Authentication/VerifyUser`, payload);
                return response.data;
            }
        };
    }
    static authenticateUser() {
        return {
            post: async (payload: AuthenticateUserRequest): Promise<AuthenticateUserResponse> => {
                const response = await axiosClient.post(`${EASY_REFILL_API_URL}/Authentication/Authenticate`, payload);
                return response.data;
            }
        };
    }
    static getSecretKeyHash() {
        return {
            get: async (payload: string): Promise<string> => {
                const response = await axiosClient.get(
                    `${EASY_REFILL_API_URL}/Authentication/CreateSecretKeyHash?securityToken=${payload}`
                );
                return response.data;
            }
        };
    }
    static getInvites() {
        return {
            get: async (bearerToken: string): Promise<JoinMembershipPlanInvitationResponse> => {
                const response = await axiosClient.get(`${EASY_REFILL_API_URL}/EasyMembership/Invitations`, {
                    headers: { Authorization: `Bearer ${bearerToken}` }
                });
                return response.data;
            }
        };
    }
    static acceptOrDeclineInvite() {
        return {
            post: async (
                payload: JoinMembershipPlanAcceptOrDeclineInvitePayload
            ): Promise<JoinMembershipPlanAcceptOrDeclineInviteResponse> => {
                const { bearerToken, ...payloadProperties } = payload;

                const response = await axiosClient.post(
                    `${EASY_REFILL_API_URL}/EasyMembership/Invitations/${payloadProperties.inviteId}/response`,
                    {
                        status: payloadProperties.status
                    },
                    {
                        headers: { Authorization: `Bearer ${bearerToken}` }
                    }
                );

                return response.data;
            }
        };
    }
}
