import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { FeatureCardProps } from './feature-card.props';
import './feature-card.styles.scss';

const FeatureCard = (props: FeatureCardProps): ReactElement => {
    const { image, title, description, isStoryBook = false } = props;
    const { t } = useTranslation();

    return (
        <div className="feature-card">
            <div className="feature-card-image">
                {/* Only for  storybook documentation purpose */}
                {isStoryBook && <img src={image as any} alt={title} />}
                {/* GatsbyImage render is not supported at storybook builder */}
                <GatsbyImage image={getImage(image) as IGatsbyImageData} alt={title} />
            </div>
            <div className="feature-card-content">
                <h3>{t(title)}</h3>
                <p>{t(description)}</p>
            </div>
        </div>
    );
};

export default FeatureCard;
