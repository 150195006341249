import { PayloadAction } from '@reduxjs/toolkit';
import { navigate } from 'gatsby';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
    DrugFormStrengthResponse,
    DrugInfoProps,
    DrugQuantity,
    ScriptSaveGetAutocompleteValuesPayload,
    searchDrugsByNameParams,
    SendSmsEmailCardPayload
} from 'types/discount-card';
import { DrugSearchResponse } from 'types/discount-card';

import { isAxiosError } from 'util/axiosClient';
import { baseEffectHandler } from 'util/sagas/sagas';

import { DiscountCardErrors, formatDrugNameForURL } from './discount-card.helpers';
import {
    discountCardSearchFilterRoutine,
    discountCardSearchRoutine,
    discountCardSendSmsEmailRoutine,
    generateDiscountCardRoutine,
    getAutocompleteValues
} from './discount-card.routines';
import { discountCardCurrentDrugSelector } from './discount-card.selector';
import DiscountCardService from './discount-card.services';

type PostSendSmsEmailSaga = {
    data: SendSmsEmailCardPayload;
    onSuccess?: (response: unknown) => void;
    onFailure?: (error: unknown) => void;
};

type GenerateDiscountCardSaga = {
    data: {
        groupId: string;
    };
    onSuccess?: (response: unknown) => void;
    onFailure?: (error: unknown) => void;
};

type SearchDrugsSaga = {
    data: {
        searchPayload: searchDrugsByNameParams;
        loadQuantitiesData?: boolean;
    };
    onSuccess?: (response: unknown) => void;
    onFailure?: (error: unknown) => void;
};

function* getAutocompleteValuesSaga(action: PayloadAction<Omit<ScriptSaveGetAutocompleteValuesPayload, 'authToken'>>) {
    const { search, count } = action.payload;

    yield baseEffectHandler({
        service: DiscountCardService.getAutocompleteValues().get,
        data: {
            search,
            count
        },
        *onResponse(data) {
            yield put(getAutocompleteValues.success(data));
        },
        *onError(err) {
            yield put(getAutocompleteValues.failure(err));
        }
    });
}

function* searchDrugsByNameSaga(action: PayloadAction<SearchDrugsSaga>) {
    const { data, onSuccess, onFailure } = action.payload;

    yield baseEffectHandler({
        service: DiscountCardService.searchDrugByNameAndZip().get,
        data,
        *onResponse(response: DrugSearchResponse) {
            if (!response.Drugs?.length && !response.DrugInfo) {
                yield put(discountCardSearchRoutine.failure({ messageText: DiscountCardErrors.drugNotFound }));
                if (onFailure) onFailure({ messageText: DiscountCardErrors.drugNotFound });
            }

            if (!response.Drugs?.length) {
                yield put(discountCardSearchRoutine.failure({ messageText: DiscountCardErrors.pharmaciesNotFound }));
                if (onFailure) onFailure({ messageText: DiscountCardErrors.pharmaciesNotFound });
            }

            if (response.Drugs.length > 0) {
                yield put(discountCardSearchRoutine.success(response));
                navigate(
                    `/discount-card/results?drugName=${formatDrugNameForURL(action.payload.data.drugName)}&zip=${
                        action.payload.data.zipCode
                    }`
                );
                if (onSuccess) onSuccess(response);
            }
        },
        *onError(error) {
            if (isAxiosError(error.response)) {
                navigate(`/discount-card/error`);
                yield put(discountCardSearchRoutine.failure(error.response));
            } else {
                navigate(`/discount-card/error`);
                yield put(discountCardSearchRoutine.failure(error));
            }
        }
    });
}

function* searchDrugsByEditFilter(action: PayloadAction<SearchDrugsSaga>) {
    const { data, onSuccess, onFailure } = action.payload;

    const { searchPayload, loadQuantitiesData } = data;
    let quantities: DrugQuantity[] | null = null;

    // When receiving loadQuantitiesData as true, and having a GSN available we want to load
    // quantities data from /PricingAPI/api/PricingEngineExternal/DrugFormStrength
    // this will ensure that quantities are updated and corresponds to our corresponding selection
    if (loadQuantitiesData && searchPayload.GSN) {
        const DrugStrength: DrugFormStrengthResponse = yield call(DiscountCardService.searchDrugFormStrength().get, {
            GSN: searchPayload.GSN
        });

        quantities = DrugStrength.Quantities.filter((quantity) => quantity.GSN === searchPayload.GSN);
    }

    const currentDrug: DrugInfoProps = yield select(discountCardCurrentDrugSelector);

    if (!searchPayload.brandIndicator && currentDrug) {
        searchPayload.brandIndicator = currentDrug.brandGeneric;
    }

    yield baseEffectHandler({
        service: DiscountCardService.searchDrugByEditFilters().get,
        data: searchPayload,
        *onResponse(response: DrugSearchResponse) {
            // Pharmacies not found
            if (response.Drugs.length === 0) {
                yield put(
                    discountCardSearchFilterRoutine.failure({ messageText: DiscountCardErrors.pharmaciesNotFound })
                );
                onFailure && onFailure({ messageText: DiscountCardErrors.pharmaciesNotFound });
                return;
            }
            if (response.Drugs.length > 1) {
                const searchResults = { ...response };

                // Take quantities from /DrugFormStrength if available
                if (quantities) {
                    searchResults.Quantities = [...quantities];
                }

                // Sometimes (no pattern detected yet) scriptSave returns with all the values of strengths
                // and quantities with as IsSelected in false. The logic below will allow us to ensure
                // that if we have no item selected we will select the first one by default.

                // Ensure there is always a strength selected.
                const anyStrengthsSelected = searchResults.Strengths.some((s) => s.IsSelected);
                if (!anyStrengthsSelected) {
                    searchResults.Strengths[0].IsSelected = true;
                }

                // Ensure there is always a quantity selected.
                const anyQuantitiesSelected = searchResults.Quantities.some((q) => q.IsSelected);
                if (!anyQuantitiesSelected) {
                    searchResults.Quantities[0].IsSelected = true;
                }

                yield put(discountCardSearchFilterRoutine.success(searchResults));
                onSuccess && onSuccess(searchResults);
            }
        },
        *onError() {
            // API Error
            yield put(discountCardSearchFilterRoutine.failure({ messageText: DiscountCardErrors.somethingWentWrong }));
            onFailure && onFailure({ messageText: DiscountCardErrors.somethingWentWrong });
        }
    });
}

function* generateDiscountCardSaga(action: PayloadAction<GenerateDiscountCardSaga>) {
    const { data, onSuccess, onFailure } = action.payload;

    yield baseEffectHandler({
        service: DiscountCardService.generateDiscountCard().get,
        data,
        *onResponse(response) {
            yield put(generateDiscountCardRoutine.success(response));
            onSuccess && onSuccess(response);
        },
        *onError(error) {
            yield put(generateDiscountCardRoutine.failure(error));
            onFailure && onFailure(error);
        }
    });
}

function* sendSmsEmailCardSaga(action: PayloadAction<PostSendSmsEmailSaga>) {
    const { onSuccess, onFailure } = action.payload;

    yield baseEffectHandler({
        service: DiscountCardService.sendCard().post,
        data: action.payload.data,
        *onResponse(response: unknown) {
            yield put(discountCardSendSmsEmailRoutine.success(response));
            onSuccess && onSuccess(response);
        },
        *onError(error) {
            yield put(discountCardSendSmsEmailRoutine.failure(error));
            onFailure && onFailure(error);
        }
    });
}

function* discountCardSaga() {
    yield takeLatest(getAutocompleteValues.TRIGGER, getAutocompleteValuesSaga);
    yield takeLatest(discountCardSearchRoutine.TRIGGER, searchDrugsByNameSaga);
    yield takeLatest(generateDiscountCardRoutine.TRIGGER, generateDiscountCardSaga);
    yield takeLatest(discountCardSearchFilterRoutine.TRIGGER, searchDrugsByEditFilter);
    yield takeLatest(discountCardSendSmsEmailRoutine.TRIGGER, sendSmsEmailCardSaga);
}

export default discountCardSaga;
