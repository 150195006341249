import { put, takeLatest, call } from 'redux-saga/effects';
import { AWS_CONFIG_REGION, AWS_CONFIG_POOLID } from 'gatsby-env-variables';
import { PayloadAction } from '@reduxjs/toolkit';
import { baseEffectHandler } from 'util/sagas/sagas';
import AccountService from '../account/account.services';
import PharmacyService, {
    PharmacyLookupPayload,
    PharmacySearchPayload,
    PharmacyGroupByPayload
} from './pharmacy.service';
import { pharmacyGroupByRoutine, pharmacyLookUpRoutine, pharmacySearchRoutine } from './pharmacy.routines';
import { PharmacyGroupSearchResponse, PharmacySearchGroup } from 'types/pharmacy';

/**
 * Format the pharmacy data we get back from the API to be more UI friendly
 */
const formatPharmacySearchResults = (response: PharmacyGroupSearchResponse): PharmacySearchGroup[] => {
    return (
        (response.groupPharmacies || [])
            // sometimes the API sends an empty object when no results - they are working on fixing
            .filter((group) => !!group.groupPharmacyName)
            .map((group) => {
                return {
                    ...group,
                    pharmacies: group.pharmacies.map((pharmacy) => ({
                        ...pharmacy,
                        // add GroupName to each pharmacy - this is what we use for display purposes and sending
                        // to the transfer API.
                        GroupName: group.groupPharmacyName
                    }))
                };
            })
    );
};

export default function* PharmacySaga() {
    yield takeLatest(pharmacyLookUpRoutine.TRIGGER, function* (action: PayloadAction<PharmacyLookupPayload>) {
        yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

        yield baseEffectHandler({
            service: PharmacyService.pharmacyLookup().get,
            data: action.payload,
            *onResponse(response) {
                yield put(pharmacyLookUpRoutine.success(response));
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess(response);
            },
            *onError() {
                yield put(pharmacyLookUpRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });

    yield takeLatest(pharmacySearchRoutine.TRIGGER, function* (action: PayloadAction<PharmacySearchPayload>) {
        yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

        yield baseEffectHandler({
            service: PharmacyService.pharmacySearch().get,
            data: action.payload,
            *onResponse(response) {
                yield put(pharmacySearchRoutine.success(response));
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess(response);
            },
            *onError() {
                yield put(pharmacySearchRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });

    yield takeLatest(pharmacyGroupByRoutine.TRIGGER, function* (action: PayloadAction<PharmacyGroupByPayload>) {
        yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

        yield baseEffectHandler<PharmacyGroupByPayload, PharmacyGroupSearchResponse>({
            service: PharmacyService.pharmacySearch().groupBy,
            data: action.payload,
            *onResponse(response) {
                const formattedResults = formatPharmacySearchResults(response);
                yield put(pharmacyGroupByRoutine.success(formattedResults));
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess(formattedResults);
            },
            *onError() {
                yield put(pharmacyGroupByRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });
}
