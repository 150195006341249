import { MembershipPlanStatusType, MembershipDetails } from 'types/membership';

export enum MembershipPlanStatusEnum {
    PaymentDue = 'PAYMENT_DUE',
    Active = 'ACTIVE',
    OnHold = 'ONHOLD',
    Canceled = 'CANCELED'
}

export const definePlanStatus = (planStatus: MembershipDetails['planStatus']) => {
    const _planStatus = planStatus as MembershipPlanStatusType;
    const statusMap = {
        PAYMENT_DUE: 'PAYMENT DUE',
        ACTIVE: 'ACTIVE',
        ONHOLD: 'ON HOLD',
        CANCELED: 'CANCELED'
    };
    return statusMap[_planStatus];
};

export function extractIdFromGenderField(text: string): number | null {
    if (!text) return null;
    const match = text.match(/gender-(\d+)/);
    return match ? parseInt(match[1], 10) : null;
}

export const joinMembershipInvitationPlanStatusMap: Map<number, string> = new Map([
    [0, 'pending'],
    [1, 'active'],
    [2, 'expired'],
    [3, 'declined']
]);
