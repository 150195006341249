import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';
// Bootstrap
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

// UI Kit
import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';
import Link from 'ui-kit/link/link';
import CoBrandingLogo from 'ui-kit/logo/co-branding-logo/co-branding-logo';
import Logo from 'ui-kit/logo/logo';
import PillLogo from 'ui-kit/logo/pill-logo/pill-logo';

// Components
import {
    IntraPageMenuItemContentValue,
    IntraPageMenuItemProps
} from 'components/navigation/intra-page-menu/intra-page-menu.props';
import { MenuProps, NavPath } from 'components/navigation/navigation.props';

// Hooks
import { useGlobalLink } from 'hooks/useGlobalLink';
import useMediaQuery from 'hooks/useMediaQuery';

import './desktop-menu.style.scss';

const DesktopMenu = ({
    isScrolled,
    userName,
    profileMenu,
    isLoggedIn,
    cartItemsTotal,
    rightMenuPaths = [],
    leftMenuPaths = []
}: MenuProps) => {
    const { t } = useTranslation();
    const globalLink = useGlobalLink();
    const [rightNavPaths, setRightNavPaths] = useState<NavPath[]>([]);
    const [leftNavPaths, setLeftNavPaths] = useState<NavPath[]>([]);
    const longMenuTest = useMediaQuery('(max-width: 1128px)'); // DRX-1254: Use pill logo to prevent wrapping menus (need to allow for co-branding logo)
    const { trackFormAbandonment, handleSignOut } = globalLink;
    const getCoBrandingDataQuery = CoBrandingFileQuery();

    const setNavButtonVariant = (buttonVariant: 'primary' | 'outline' | undefined) => {
        if (!buttonVariant) return 'nav-button-outline';
        if (buttonVariant === 'primary') return 'nav-button-primary';

        return 'nav-button-outline';
    };

    // Updating the menu items when the user logs in
    useEffect(() => {
        setRightNavPaths(rightMenuPaths);
    }, [rightMenuPaths, isLoggedIn]);

    useEffect(() => {
        setLeftNavPaths(leftMenuPaths);
    }, [leftMenuPaths, isLoggedIn]);

    return (
        <Container fluid="xl" className="desktop-menu d-none d-lg-flex">
            <Navbar.Brand>
                {getCoBrandingDataQuery && (
                    <CoBrandingLogo
                        nodes={getCoBrandingDataQuery?.nodes}
                        linkClassName="desktop-menu-header--coBranding-logo"
                    />
                )}
                <Link
                    onClick={trackFormAbandonment}
                    to={isLoggedIn ? '/secure/medicine-cabinet' : '/'}
                    label={
                        <>
                            <div className="co-brand-logo d-flex"></div>
                            {isScrolled || (rightNavPaths && rightNavPaths?.length > 5 && longMenuTest) ? (
                                <PillLogo variant="primary" />
                            ) : (
                                <Logo variant="primary" />
                            )}
                        </>
                    }
                    variant="site"
                    dataGALocation="HeaderLogo"
                    ariaLabel="Birdi Homepage"
                />
            </Navbar.Brand>

            {leftNavPaths && leftNavPaths.length > 0 && (
                <Navbar.Collapse id="site-navbar-paths">
                    <Nav className="left-main-menu">
                        {leftNavPaths.map(({ label, id, children }) => (
                            <React.Fragment key={`left-menu-item-${id}`}>
                                {children && children.length > 0 && (
                                    <DropdownButton id="dropdown-basic-button" title={label}>
                                        <>
                                            {children
                                                .filter((f) => !f.isMobile)
                                                .map(({ field_menu_body, label, link, id, isButton }) => (
                                                    <React.Fragment key={`left-menu-item-child-${id}`}>
                                                        {link && link.url ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    as={Link}
                                                                    to={link.url}
                                                                    label={label}
                                                                    data-tagtype="anchorlink"
                                                                    dataGALocation="HeaderLeftNav"
                                                                    onClick={trackFormAbandonment}
                                                                    className={isButton ? 'btn' : ''}
                                                                />
                                                            </>
                                                        ) : (
                                                            <Dropdown.ItemText>
                                                                {field_menu_body && field_menu_body.processed
                                                                    ? field_menu_body.processed
                                                                    : label}
                                                            </Dropdown.ItemText>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                        </>
                                    </DropdownButton>
                                )}
                            </React.Fragment>
                        ))}
                    </Nav>
                </Navbar.Collapse>
            )}

            {rightNavPaths && rightNavPaths.length > 0 && (
                <Navbar.Collapse id="site-navbar-paths" className="justify-content-end">
                    <Nav>
                        {rightNavPaths &&
                            rightNavPaths
                                .filter((f) => !f.isMobile)
                                .map(({ link, label, isButton, buttonVariant }: NavPath) => (
                                    <React.Fragment key={`site-nav-${label}`}>
                                        {link && (
                                            <Link
                                                onClick={trackFormAbandonment}
                                                to={link.url}
                                                label={label}
                                                variant={
                                                    Boolean(isButton) === true
                                                        ? setNavButtonVariant(buttonVariant)
                                                        : 'nav'
                                                }
                                                dataGALocation="HeaderNav"
                                            />
                                        )}
                                    </React.Fragment>
                                ))}
                        {profileMenu && (
                            <>
                                <div className="menu-links-profile">
                                    <DropdownButton id="dropdown-basic-button" title={userName}>
                                        {profileMenu.map(
                                            ({
                                                key,
                                                value,
                                                label,
                                                isChild
                                            }: IntraPageMenuItemProps<IntraPageMenuItemContentValue>) => (
                                                <React.Fragment key={`profile-item-${key}`}>
                                                    {value && (
                                                        <Dropdown.Item
                                                            as={Link}
                                                            to={value}
                                                            label={label}
                                                            data-tagtype="anchorlink"
                                                            dataGALocation="HeaderNav"
                                                            className={isChild ? 'dropdown-item-child' : ''}
                                                            onClick={trackFormAbandonment}
                                                        />
                                                    )}
                                                    {!value && <Dropdown.ItemText>{label}</Dropdown.ItemText>}
                                                </React.Fragment>
                                            )
                                        )}
                                        <Dropdown.Item
                                            className="menu-links-profile__logout"
                                            key={'profile-item-logout'}
                                            onClick={handleSignOut}
                                            data-tagtype="anchorlink"
                                            data-ga-location="HeaderNav"
                                        >
                                            {t('menus.intraPage.profile.signOut')}
                                        </Dropdown.Item>
                                    </DropdownButton>
                                </div>
                                <Link
                                    to={'/secure/cart/review/'}
                                    label={<CartIcon itemCount={cartItemsTotal} className={'border-white'} />}
                                    variant={'nav'}
                                    className={'no-active-state'}
                                    dataGALocation="HeaderNav"
                                />
                            </>
                        )}
                    </Nav>
                </Navbar.Collapse>
            )}
        </Container>
    );
};

export default DesktopMenu;

export const CoBrandingFileQuery = () => {
    const query = useStaticQuery(graphql`
        {
            allSiteSettingEntityEnvironmentSettings {
                nodes {
                    field_co_branding_logo {
                        description
                    }
                    relationships {
                        field_co_branding_logo {
                            filename
                            localFile {
                                publicURL
                            }
                        }
                    }
                }
            }
        }
    `);

    if (!query?.allSiteSettingEntityEnvironmentSettings) return undefined;

    return query?.allSiteSettingEntityEnvironmentSettings;
};
