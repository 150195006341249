import React, { CSSProperties } from 'react';
import { FluidObject } from 'gatsby-image';
import BackgroundImage from 'ui-kit/background-image/background-image';

export interface WithBackgroundImageProps {
    backgroundImage: FluidObject;
    style?: CSSProperties;
    useRoundedCorners?: boolean;
}
const withBackgroundImage = <P extends object>(Component: React.ComponentType<P>, className: string) =>
    class WithBackgroundImage extends React.Component<P & WithBackgroundImageProps> {
        render() {
            const { backgroundImage, style, useRoundedCorners } = this.props;
            if (!backgroundImage) {
                return <Component {...(this.props as P)} />;
            }
            return (
                <BackgroundImage
                    image={backgroundImage}
                    style={style ?? {}}
                    useRoundedCorners={useRoundedCorners}
                    className={className}
                >
                    <Component {...(this.props as P)} />
                </BackgroundImage>
            );
        }
    };

export default withBackgroundImage;
