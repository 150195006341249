import React from 'react';
import classNames from 'classnames';
import './birdi-price-icon.style.scss';
import { BirdiPriceIconProps } from './birdi-price-icon.props';

const BirdiPriceIcon = ({ className, style }: BirdiPriceIconProps) => {
    const classes = classNames('birdi-price-icon', className);

    return (
        <div className={classes} style={style}>
            <svg width="30px" height="28px" viewBox="0 0 30 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    transform="translate(-7.000000, 0.000000)"
                >
                    <path
                        d="M31.4345064,6.52551304 C31.4345064,5.89421898 31.9544984,5.38033316 32.5953079,5.38033316 C33.2361175,5.38033316 33.7561095,5.89421898 33.7561095,6.52551304 C33.7561095,7.15694539 33.2361175,7.67083121 32.5953079,7.67083121 C31.9544984,7.67083121 31.4345064,7.15694539 31.4345064,6.52551304 Z M35.0735755,3.98832189 C33.8966164,2.46916041 32.0250738,1.48648649 29.9168839,1.48648649 C29.8383577,1.48648649 29.7534211,1.49037878 29.6640774,1.49673619 C28.7048016,1.56835436 27.7899974,1.90101556 27.0027315,2.43049698 C26.9822986,2.44347129 26.9625335,2.45735379 26.9426349,2.4712363 C26.8109565,2.56218619 26.6827503,2.65793656 26.5589513,2.75978486 L24.3299004,4.59318403 L6.10700884,19.7019139 C5.40681631,20.27447 5.31613717,21.2893202 5.90348158,21.9709903 C6.49082599,22.6513629 7.53690802,22.7398476 8.23710055,22.1672915 L10.3954948,20.1539178 C12.7670415,23.2229898 16.5435138,25.2116914 20.799023,25.2116914 C27.1971775,25.2116914 32.5129248,20.7221924 33.5966674,14.8098012 C33.8660339,13.3390339 34.516413,11.9598652 35.5296422,10.8365498 L38.5370112,8.64752487 C38.9725114,8.31603135 38.9725114,7.67561961 38.5370112,7.3441261 C37.2493407,6.36326858 36.0574242,5.25850643 35.0735755,3.98832189 Z"
                        fill="#039fd3"
                    ></path>
                    <path
                        d="M14.4632988,2.43904249 C16.951663,2.45378792 18.9652476,4.4673526 18.9800176,6.9557166 L19.0065802,11.4308267 L19.0065802,11.4308267 L10.0264456,11.3776127 L9.99988292,6.90250254 C9.98533731,4.45194738 11.9601132,2.45358836 14.4106684,2.43904275 C14.4282117,2.43893862 14.4457555,2.43893853 14.4632988,2.43904249 Z"
                        fill="#222A3F"
                        transform="translate(14.489906, 6.921631) rotate(-41.000000) translate(-14.489906, -6.921631) "
                    ></path>
                    <path
                        d="M22.6098275,11.661715 C25.0981916,11.6764604 27.1117762,13.6900251 27.1265462,16.1783891 L27.1531089,20.6534992 L27.1531089,20.6534992 L18.1729742,20.6002851 L18.1464115,16.125175 C18.1318659,13.6746199 20.1066418,11.6762608 22.557197,11.6617152 C22.5747403,11.6616111 22.5922841,11.661611 22.6098275,11.661715 Z"
                        fill="#222A3F"
                        transform="translate(22.636434, 16.144304) rotate(-221.000000) translate(-22.636434, -16.144304) "
                    ></path>
                </g>
            </svg>
        </div>
    );
};

export default BirdiPriceIcon;
