const shallowCompare = (obj1: any, obj2: any) =>
    Object.keys(obj1).length === Object.keys(obj2).length &&
    // eslint-disable-next-line no-prototype-builtins
    Object.keys(obj1).every((key) => obj2.hasOwnProperty(key) && obj1[key] === obj2[key]);

const buildQueryParams = <T extends Record<string, any>>(params: T): string => {
    return Object.entries(params)
        .filter(([_, value]) => value !== undefined)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as any)}`)
        .join('&');
};

const isNullOrEmptyObject = (variable: any): boolean => {
    if (variable === null) {
        return true;
    }

    if (typeof variable === 'string') {
        try {
            const parsedVariable = JSON.parse(variable);
            if (typeof parsedVariable === 'object' && Object.keys(parsedVariable).length === 0) {
                return true;
            }
        } catch (e) {
            return false;
        }
    }

    if (typeof variable === 'object' && Object.keys(variable).length === 0) {
        return true;
    }

    return false;
};

export { shallowCompare, buildQueryParams, isNullOrEmptyObject };
