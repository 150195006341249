import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';
import format from 'date-fns/format';
import { getAge } from 'const/options';

import {
    familyProfileAddDependentRoutine,
    familyProfileGetCaregiversRoutine,
    familyProfileGetDependentsRoutine,
    familyProfileRemoveDependentRoutine,
    familyProfileVerifyDependentRoutine
} from './family-profile.routines';
import {
    FamilyAccountDependentData,
    FamilyAccountGetDependentsPayload,
    FamilyAccountAddDependentPayload,
    FamilyAccountVerifyDependentSuccessResponse,
    FamilyAccountVerifyDependentResponse
} from 'types/family-management';
import { FamilyManagementStatus, FamilyMemberAccountType, Caregiver, CaregiverProfile } from 'types/family-management';
import {
    accountStatusMap,
    accountTypeMap,
    caregiverStatusMap,
    getLastUpdateDate,
    removeDependentFromState
} from './family-profile-helpers';

export interface Dependent {
    familyMemberName: string;
    familyMemberFirstName: string;
    familyMemberAge?: number;
    familyMemberLastName?: string;
    dob: string;
    accountStatus: Extract<
        FamilyManagementStatus,
        'invitePending' | 'fullAccess' | 'partialAccess' | 'declined' | 'expired'
    >;
    accountType: FamilyMemberAccountType;
    insuranceId?: string;
    ePostPatientNum: string;
    id: number;
    dateRevokeAccess: string | null;
    dateRequested: string;
    dateGrantedAccess: string;
    dateInviteExpiration: string;
    planAlias?: string;
}

export interface FamilyProfileState {
    dependents: Dependent[];
    caregivers: Caregiver[];
    isFamilyAccountLoading: boolean;
    isCaregiverLoading: boolean;
    dependentToAdd: FamilyAccountVerifyDependentSuccessResponse | null;
}

const initialState: FamilyProfileState = {
    dependents: [],
    caregivers: [],
    isFamilyAccountLoading: false,
    isCaregiverLoading: false,
    dependentToAdd: null
};

const familyAccountSlice = createSlice({
    name: 'family-profile',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        /**
         * Family Profile reducers
         */

        // GET Dependents
        addCase(familyProfileGetDependentsRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isFamilyAccountLoading = true;
            })
        );
        addCase(
            familyProfileGetDependentsRoutine.SUCCESS,
            (state, { payload }: PayloadAction<FamilyAccountGetDependentsPayload>) =>
                produce(state, (draftState) => {
                    draftState.isFamilyAccountLoading = false;
                    draftState.dependents = payload.Data.map((dependent: FamilyAccountDependentData) => {
                        const dateOfBirth = new Date(dependent.DOB);
                        const dateRequested = new Date(dependent.DateRequested);
                        const dateInviteExpiration = new Date();
                        const dateGrantedAccess = new Date(dependent.DateGrantedAccess);

                        dateInviteExpiration.setDate(dateRequested.getDate() + 7);
                        return {
                            familyMemberName: `${dependent.FirstName} ${dependent.LastName}`,
                            familyMemberFirstName: dependent.FirstName,
                            familyMemberLastName: dependent.LastName,
                            familyMemberAge: getAge(dateOfBirth),
                            dob: format(dateOfBirth, 'MM/dd/yyyy'),
                            accountStatus: accountStatusMap.get(dependent.AccountStatus) ?? 'expired',
                            accountType: accountTypeMap.get(dependent.AccountType) ?? 'adult',
                            insuranceId: dependent.InsuranceMemberId,
                            ePostPatientNum: dependent.EPostPatientNum,
                            id: dependent.ID,
                            dateRevokeAccess: dependent.DateRevokeAccess,
                            dateRequested: format(dateRequested, 'MM/dd/yyyy'),
                            dateInviteExpiration: format(dateInviteExpiration, 'MM/dd/yyyy'),
                            dateGrantedAccess: format(dateGrantedAccess, 'MM/dd/yyyy'),
                            planAlias: dependent.PlanAlias,
                            phoneNumber: '1' + dependent.PhoneAreaCode + dependent.PhoneNumber
                        };
                    });
                })
        );
        addCase(
            familyProfileGetDependentsRoutine.FAILURE,
            (state, { payload }: PayloadAction<FamilyAccountGetDependentsPayload>) =>
                produce(state, (draftState) => {
                    draftState.isFamilyAccountLoading = false;
                })
        );

        // Verify dependent
        addCase(familyProfileVerifyDependentRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isFamilyAccountLoading = true;
            })
        );
        addCase(
            familyProfileVerifyDependentRoutine.SUCCESS,
            (state, { payload }: PayloadAction<FamilyAccountVerifyDependentSuccessResponse>) =>
                produce(state, (draftState) => {
                    draftState.isFamilyAccountLoading = false;
                    draftState.dependentToAdd = payload;
                })
        );
        addCase(
            familyProfileVerifyDependentRoutine.FAILURE,
            (state, { payload }: PayloadAction<FamilyAccountVerifyDependentResponse>) =>
                produce(state, (draftState) => {
                    draftState.isFamilyAccountLoading = false;
                })
        );

        // Add dependent
        addCase(familyProfileAddDependentRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isFamilyAccountLoading = true;
            })
        );
        addCase(
            familyProfileAddDependentRoutine.SUCCESS,
            (state, { payload }: PayloadAction<FamilyAccountAddDependentPayload>) => {
                produce(state, (draftState) => {
                    draftState.isFamilyAccountLoading = false;
                });
            }
        );
        addCase(
            familyProfileAddDependentRoutine.FAILURE,
            (state, { payload }: PayloadAction<FamilyAccountAddDependentPayload>) => {
                produce(state, (draftState) => {
                    draftState.isFamilyAccountLoading = false;
                });
            }
        );

        // GET Caregivers
        addCase(familyProfileGetCaregiversRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isCaregiverLoading = true;
            })
        );
        addCase(familyProfileGetCaregiversRoutine.SUCCESS, (state, { payload }: PayloadAction<CaregiverProfile[]>) =>
            produce(state, (draftState) => {
                draftState.isCaregiverLoading = false;
                draftState.caregivers = payload
                    // DRX-1874: If the caregiver has a DateRevokeAccess, it is exclude until
                    // They resend the invite and change the status.
                    .filter((caregiver: CaregiverProfile) => !caregiver.DateRevokeAccess)
                    .map((caregiver: CaregiverProfile) => ({
                        caregiverName: `${caregiver.FirstName} ${caregiver.LastName}`,
                        caregiverFirstName: caregiver.FirstName,
                        accountStatus: caregiverStatusMap.get(caregiver.AccessLevel.AccountStatus) ?? 'expired',
                        lastUpdateDate: getLastUpdateDate(caregiver),
                        ePostPatientNumber: caregiver.EPostPatientNum
                    }));
            })
        );
        addCase(familyProfileGetCaregiversRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isCaregiverLoading = false;
                draftState.caregivers = [];
            })
        );
        // Remove Dependent
        addCase(familyProfileRemoveDependentRoutine.TRIGGER, (state) =>
            produce(state, (drafState) => {
                drafState.isFamilyAccountLoading = true;
            })
        );
        addCase(familyProfileRemoveDependentRoutine.SUCCESS, (state, { payload }: PayloadAction<Dependent>) =>
            produce(state, (drafState) => {
                drafState.dependents = removeDependentFromState(payload.ePostPatientNum!, drafState.dependents);
            })
        );
        addCase(familyProfileRemoveDependentRoutine.FAILURE, (state) =>
            produce(state, (drafState) => {
                drafState.isFamilyAccountLoading = false;
            })
        );
    }
});

export const familyAccountActions = familyAccountSlice.actions;

export default familyAccountSlice.reducer;
