import classNames from 'classnames';
import React from 'react';

import { ModalCloseIconProps } from './modal-close-icon.props';

const ModalCloseIcon = ({ className, style, colorHex = '#1A497F' }: ModalCloseIconProps) => {
    const classes = classNames('modal-close-icon', className);

    return (
        <div className={classes} style={style}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M19 5L5 19M5 5L19 19"
                    stroke={colorHex}
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default ModalCloseIcon;
