import React, { ReactElement } from 'react';

import AppStore from 'ui-kit/icons/badges/app-store';
import PlayStore from 'ui-kit/icons/badges/play-store';

import { StoreBadgeProps } from './badge-store.props';
import './badge-store.style.scss';

function getSvg(badgeType: string, label: string) {
    const badgeComponents: { [key: string]: React.ReactNode } = {
        app_store: <AppStore label={label} />,
        play_store: <PlayStore label={label} />
    };

    return badgeComponents[badgeType] || null;
}

const StoreBadge = ({ className, label, to, dataGALocation, badgeType }: StoreBadgeProps): ReactElement => {
    const svgImage = getSvg(badgeType, label);

    return (
        <div className={`badge ${className ?? ''}`}>
            <a
                href={to}
                rel="noopener noreferrer"
                target="_blank"
                data-tagtype="anchorlink"
                data-ga-location={dataGALocation}
            >
                {svgImage}
            </a>
        </div>
    );
};

export default StoreBadge;
