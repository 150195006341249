import { Col } from 'react-bootstrap';

import DownloadIcon from 'ui-kit/icons/download/download-icon';
import './FileListFile.style.scss';

export interface FileListFileProp {
    file: string;
    title: string;
}

export default function FileListFile({ file, title }: FileListFileProp) {
    return (
        <Col xs={12} md={6} lg={4} className="resource-download">
            <a href={file} download>
                <div className="download-link">
                    <DownloadIcon />
                    <div className="download-title">{title}</div>
                </div>
            </a>
        </Col>
    );
}
