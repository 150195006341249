import { createRoutine } from 'redux-saga-routines';

export const membershipVerifyDependentRoutine = createRoutine('MEMBERSHIP/VERIFY_DEPENDENT');
export const membershipDetailsRoutine = createRoutine('MEMBERSHIP/GET_MEMBERSHIP_DETAILS');
export const membershipPlansDetailsRoutine = createRoutine('MEMBERSHIP/GET_MEMBERSHIP_PLANS_DETAILS');
export const membershipRequestVisitMemberIdRoutine = createRoutine('MEMBERSHIP/SET_REQUEST_VISIT_MEMBER_ID');
export const membershipAddMemberRoutine = createRoutine('MEMBERSHIP/POST_ADD_MEMBER');
export const membershipPaymentMethodsRoutine = createRoutine('MEMBERSHIP/GET_PAYMENT_METHODS');
export const membershipSendInviteRoutine = createRoutine('MEMBERSHIP/POST_SEND_INVITE');
export const membershiGenerateScheduleLinkRoutine = createRoutine('MEMBERSHIP/POST_GENERATE_SCHEDULE_LINK');
export const membershipGetVisitsLinkRoutine = createRoutine('MEMBERSHIP/POST_GET_PREVIOUS_VISITS_LINK');
export const membershipBirdiSelectFormularyRoutine = createRoutine('MEMBERSHIP/GET_BIRDI_SELECT_FORMULARY');
export const membershipGetTelehealthVisitsRoutine = createRoutine('MEMBERSHIP/GET_TELEHEALTH_VISITS');
export const membershipUpdateDefaultPaymentMethodRoutine = createRoutine('MEMBERSHIP/UPDATE_DEFAULT_PAYMENT');
export const membershipGetUpgradablePlansRoutine = createRoutine('MEMBERSHIP/GET_UPGRADABLE_PLANS');
export const membershipRemoveFromPlanRoutine = createRoutine('MEMBERSHIP/DELETE_REMOVE_FROM_PLAN');
export const membershipLeavePlanRoutine = createRoutine('MEMBERSHIP/DELETE_LEAVE_PLAN');
export const membershipRemoveInviteeRoutine = createRoutine('MEMBERSHIP/DELETE_REMOVE_INVITE');
export const membershipUpgradePlanRoutine = createRoutine('MEMBERSHIP/PUT_UPGRADE_PLAN');
export const membershipPreAuthorizePaymentRoutine = createRoutine('MEMBERSHIP/POST_PREAUTHORIZE_PAYMENT');
export const membershipApplyPromoCodeRoutine = createRoutine('MEMBERSHIP/GET_PLANS_PROMOCODE');
export const membershipGetInvitationsRoutine = createRoutine('MEMBERSHIP/GET_INVITATIONS');
export const membershipAcceptOrDeclineInvitationsRoutine = createRoutine('MEMBERSHIP/ACCEPT_OR_DECLINE_INVITATIONS');
