import axiosClient from 'util/axiosClient';
// api still in development, the endpoint bellow will be replaced.
import { EASY_REFILL_API_URL } from 'gatsby-env-variables';
import {
    AuthenticateUserRequest,
    AuthenticateUserResponse,
    CaregiversAcceptOrDeclinePayload,
    EasyCaregiverGetCaregivers,
    VerifyUserRequest,
    VerifyUserResponse
} from 'types/family-account';
import { CaregiversChangeAccessResponse } from 'types/family-management';

export class FamilyAccountService {
    static verifyUser() {
        return {
            post: async (payload: VerifyUserRequest): Promise<VerifyUserResponse> => {
                const response = await axiosClient.post(`${EASY_REFILL_API_URL}/Authentication/VerifyUser`, payload);
                return response.data;
            }
        };
    }
    static authenticateUser() {
        return {
            post: async (payload: AuthenticateUserRequest): Promise<AuthenticateUserResponse> => {
                const response = await axiosClient.post(`${EASY_REFILL_API_URL}/Authentication/Authenticate`, payload);
                return response.data;
            }
        };
    }
    static getSecretKeyHash() {
        return {
            get: async (payload: string): Promise<string> => {
                const response = await axiosClient.get(
                    `${EASY_REFILL_API_URL}/Authentication/CreateSecretKeyHash?securityToken=${payload}`
                );
                return response.data;
            }
        };
    }

    static getCaregivers() {
        return {
            get: async (bearerToken: string): Promise<EasyCaregiverGetCaregivers> => {
                const response = await axiosClient.get(`${EASY_REFILL_API_URL}/EasyCaregiver/GetCaregivers`, {
                    headers: { Authorization: `Bearer ${bearerToken}` }
                });
                return response.data;
            }
        };
    }

    static changeCaregiversAccess() {
        return {
            post: async (
                payload: CaregiversAcceptOrDeclinePayload & { bearerToken: string }
            ): Promise<CaregiversChangeAccessResponse> => {
                const { bearerToken } = payload;
                const response = await axiosClient.post(
                    `${EASY_REFILL_API_URL}/EasyCaregiver/AcceptOrDeclineCaregiver?dependentId=${payload.dependentId}&accountStatus=${payload.accountStatus}`,
                    null,
                    {
                        headers: { Authorization: `Bearer ${bearerToken}` }
                    }
                );
                return response.data;
            }
        };
    }
}
