import React from 'react';
import { graphql } from 'gatsby';

import GetBirdiPrice, { GetBirdiPriceProps } from 'components/get-birdi-price/get-birdi-price.component';

export default function GetBirdiPriceProvider({
    paragraph,
    sectionIndex
}: {
    paragraph: GatsbyTypes.GetBirdiPriceProviderFragment;
    sectionIndex: number;
}) {
    const getBirdiPriceProps: GetBirdiPriceProps = {
        defaultValues: {},
        subTitle: paragraph.field_gbp_subtitle ? paragraph.field_gbp_subtitle : '',
        titleText: paragraph.field_gbp_title ? paragraph.field_gbp_title : '',
        linkTo: paragraph.field_gbp_link?.uri ? paragraph.field_gbp_link?.uri : '',
        linkLabel: paragraph.field_gbp_link?.title ? paragraph.field_gbp_link?.title : '',
        image: paragraph.relationships?.field_gbp_image?.relationships?.field_media_image?.localFile,
        sectionIndex: sectionIndex
    };
    return <GetBirdiPrice {...getBirdiPriceProps} />;
}

export const query = graphql`
    fragment GetBirdiPriceProvider on paragraph__get_birdi_price_component {
        field_gbp_subtitle
        field_gbp_title
        field_gbp_link {
            title
            uri: url
        }
        relationships {
            field_gbp_image {
                relationships {
                    field_media_image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(formats: [AUTO])
                            }
                        }
                    }
                }
            }
        }
    }
`;
