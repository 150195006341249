import { put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import {
    joinMembershipPlanAuthenticateUserRoutine,
    joinMembershipPlanVerifyUserRoutine,
    joinMembershipPlanGetSecretKeyHashRoutine,
    joinMembershipPlanGetInvitesRoutine,
    joinMembershipPlanAcceptOrDeclineInviteRoutine
} from './join-membership-plan.routines';
import {
    AuthenticateUserRequest,
    AuthenticateUserResponse,
    JoinMembershipPlanInvitationResponse,
    JoinMembershipPlanAcceptOrDeclineInviteResponse,
    VerifyUserRequest,
    VerifyUserResponse
} from 'types/join-membership-plan';
import { JoinMembershipPlanService } from './join-membership-plan.services';
import { baseEffectHandler } from 'util/sagas/sagas';
import { TrackError } from 'util/google_optimize/optimize_helper';
import {
    joinMembershipPlanSecretKeyHashSelector,
    joinMembershipPlanSecurityTokenSelector,
    joinMembershipPlanUserBearerTokenSelector
} from './join-membership-plan.selectors';

const AUTHENTICATION_TYPE = 'EasyMembership';

function* generateSecretKeySaga(
    action: PayloadAction<{
        onSuccess?: () => void;
        onFailure?: (response: string) => void;
    }>
) {
    try {
        const securityToken: string = yield select(joinMembershipPlanSecurityTokenSelector);

        yield baseEffectHandler({
            service: JoinMembershipPlanService.getSecretKeyHash().get,
            isAuthenticatedService: false,
            data: securityToken,
            *onResponse(response: AuthenticateUserResponse) {
                yield put(joinMembershipPlanGetSecretKeyHashRoutine.success(response));
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
            },
            *onError() {
                yield put(joinMembershipPlanGetSecretKeyHashRoutine.failure('The security token is invalid'));
                const { onFailure } = action.payload;
                if (onFailure) {
                    onFailure('The security token is invalid');
                }
            }
        });
    } catch (error) {
        yield put(joinMembershipPlanGetSecretKeyHashRoutine.failure(error));
    }
}

function* authenticateUserSaga() {
    try {
        const securityToken: string = yield select(joinMembershipPlanSecurityTokenSelector);
        const secretKeyHash: string = yield select(joinMembershipPlanSecretKeyHashSelector);
        // if successful verification - authenticate user
        const authenticateData: AuthenticateUserRequest = {
            securityToken,
            secretKeyHash,
            authenticationType: AUTHENTICATION_TYPE
        };

        yield baseEffectHandler({
            service: JoinMembershipPlanService.authenticateUser().post,
            isAuthenticatedService: false,
            data: authenticateData,
            *onResponse(response: AuthenticateUserResponse) {
                if (response && response.messageText !== 'Authenticated') {
                    yield put(joinMembershipPlanAuthenticateUserRoutine.failure(response));
                    TrackError('family-account.sagas.ts', 'authenticateUserSaga', response.messageText);
                } else {
                    yield put(joinMembershipPlanAuthenticateUserRoutine.success(response));
                }
            },
            *onError(error) {
                yield put(joinMembershipPlanAuthenticateUserRoutine.failure({ messageText: 'An error has occurred' }));
            }
        });
    } catch (_) {
        yield put(joinMembershipPlanAuthenticateUserRoutine.failure({ messageText: 'An error has occurred' }));
    }
}

function* verifyUserSaga(
    action: PayloadAction<{
        dateOfBirth: string;
        zipcode: string;
        onSuccess?: () => void;
        onFailure?: (response: VerifyUserResponse) => void;
        onMaxNumAttemptsReached?: (response: VerifyUserResponse) => void;
    }>
) {
    try {
        const securityToken: string = yield select(joinMembershipPlanSecurityTokenSelector);
        const { dateOfBirth, zipcode, onFailure, onMaxNumAttemptsReached, onSuccess } = action.payload;
        const data: VerifyUserRequest = {
            dateOfBirth,
            zipcode,
            securityToken,
            authenticationType: AUTHENTICATION_TYPE
        };

        yield baseEffectHandler({
            service: JoinMembershipPlanService.verifyUser().post,
            isAuthenticatedService: false,
            data: data,
            *onResponse(response: VerifyUserResponse) {
                if (response && response.verificationStatus === 'MaxAttemptsReached') {
                    yield put(joinMembershipPlanVerifyUserRoutine.failure(response));
                    if (onMaxNumAttemptsReached) onMaxNumAttemptsReached(response);
                    if (onFailure) onFailure(response);
                } else if (
                    response &&
                    response.verificationStatus !== 'UserVerified' &&
                    response.verificationStatus !== 'TokenValid'
                ) {
                    yield put(joinMembershipPlanVerifyUserRoutine.failure(response));
                    TrackError('family-account.sagas.ts', 'verifyUserSaga', response.messageText);
                    if (onFailure) onFailure(response);
                } else {
                    yield put(joinMembershipPlanVerifyUserRoutine.success(response));
                    yield put(joinMembershipPlanAuthenticateUserRoutine.trigger());
                    if (onSuccess) onSuccess();
                }
            },
            *onError(error) {
                yield put(joinMembershipPlanVerifyUserRoutine.failure({ messageText: 'An error has occurred' }));
                if (onFailure)
                    onFailure({
                        messageText: 'An error has occurred',
                        verificationStatus: 'UserNotVerified',
                        firstName: '',
                        lastName: '',
                        epostPatientNum: '',
                        messageStatus: false
                    });
            }
        });
    } catch (error: any) {
        const { onFailure } = action.payload;
        yield put(joinMembershipPlanVerifyUserRoutine.failure({ messageText: 'An error has occurred' }));
        if (onFailure)
            onFailure({
                messageText: 'An error has occurred',
                verificationStatus: 'UserNotVerified',
                firstName: '',
                lastName: '',
                epostPatientNum: '',
                messageStatus: false
            });
    }
}

function* getInvitesSaga(
    action: PayloadAction<{
        onSuccess?: (response?: JoinMembershipPlanInvitationResponse) => void;
        onFailure?: (errors?: JoinMembershipPlanInvitationResponse) => void;
    }>
) {
    try {
        const { onSuccess, onFailure } = action.payload || {};
        const bearerToken: string = yield select(joinMembershipPlanUserBearerTokenSelector);

        yield baseEffectHandler({
            service: JoinMembershipPlanService.getInvites().get,
            isAuthenticatedService: false,
            data: bearerToken,
            *onResponse(response: JoinMembershipPlanInvitationResponse) {
                if (response.messageStatus === true) {
                    yield put(joinMembershipPlanGetInvitesRoutine.success(response));
                    if (onSuccess) onSuccess(response);
                } else if (response.messageStatus === false) {
                    yield put(joinMembershipPlanGetInvitesRoutine.failure({ messageText: 'An error has occurred' }));
                    if (onFailure) onFailure(response);
                }
            },
            *onError(error) {
                yield put(joinMembershipPlanGetInvitesRoutine.failure({ messageText: 'An error has occurred' }));
                if (onFailure) onFailure();
            }
        });
    } catch (error) {
        const { onFailure } = action.payload;
        yield put(joinMembershipPlanGetInvitesRoutine.failure({ messageText: 'An error has occurred' }));
        if (onFailure) onFailure();
    }
}

function* acceptOrDeclineInviteSaga(
    action: PayloadAction<{
        invitationId: string;
        status: string;
        onSuccess?: () => void;
        onFailure?: () => void;
    }>
) {
    try {
        const bearerToken: string = yield select(joinMembershipPlanUserBearerTokenSelector);
        const { onSuccess, onFailure } = action.payload || {};

        yield baseEffectHandler({
            service: JoinMembershipPlanService.acceptOrDeclineInvite().post,
            isAuthenticatedService: false,
            data: { bearerToken, ...action.payload },
            *onResponse(response: JoinMembershipPlanAcceptOrDeclineInviteResponse) {
                yield put(joinMembershipPlanAcceptOrDeclineInviteRoutine.success());
                if (onSuccess) onSuccess();
            },
            *onError(error) {
                yield put(joinMembershipPlanAcceptOrDeclineInviteRoutine.failure());
                if (onFailure) onFailure();
            }
        });
    } catch (error) {
        const { onFailure } = action.payload;
        yield put(joinMembershipPlanAcceptOrDeclineInviteRoutine.failure());
        if (onFailure) onFailure();
    }
}

function* joinMembershipPlanSaga() {
    yield takeLatest(joinMembershipPlanVerifyUserRoutine.TRIGGER, verifyUserSaga);
    yield takeLatest(joinMembershipPlanAuthenticateUserRoutine.TRIGGER, authenticateUserSaga);
    yield takeLatest(joinMembershipPlanGetSecretKeyHashRoutine.TRIGGER, generateSecretKeySaga);
    yield takeLatest(joinMembershipPlanGetInvitesRoutine.TRIGGER, getInvitesSaga);
    yield takeLatest(joinMembershipPlanAcceptOrDeclineInviteRoutine.TRIGGER, acceptOrDeclineInviteSaga);
}

export default joinMembershipPlanSaga;
