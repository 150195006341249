import { createRoutine } from 'redux-saga-routines';

export const addPrescriptionRoutine = createRoutine('ADD_TRANSFER_PRESCRIPTION/ADD_PRESCRIPTION');
export const transferPrescriptionRoutine = createRoutine('ADD_TRANSFER_PRESCRIPTION/TRANSFER_PRESCRIPTION');
export const addTransferPrescriptionRoutine = createRoutine('ADD_TRANSFER_PRESCRIPTION/ADD_TRANSFER_PRESCRIPTION');
export const photoUploadRoutine = createRoutine('ADD_TRANSFER_PRESCRIPTION/UPLOAD_PHOTO');
export const getFilePrescriptionRoutine = createRoutine('ADD_TRANSFER_PRESCRIPTION/GET_FILE_PRESCRIPTION');
export const getFilePharmacyRoutine = createRoutine('ADD_TRANSFER_PRESCRIPTION/GET_FILE_PHARMACY');
export const getFilePrescriberRoutine = createRoutine('ADD_TRANSFER_PRESCRIPTION/GET_FILE_PRESCRIBER');
export const cancelPrescriptionRoutine = createRoutine('ADD_TRANSFER_PRESCRIPTION/CANCEL_PRESCRIPTION');
