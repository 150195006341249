import classNames from 'classnames';
import React, { ReactElement } from 'react';

import AlertIcon from 'ui-kit/icons/alert/alert-icon';
import CheckCircle from 'ui-kit/icons/check-circle/check-circle';
import CircleInfo from 'ui-kit/icons/info/circle-info-icon';

import UIStatuses from 'types/ui-statuses';

import './toast-box.style.scss';

interface InfoBoxProps {
    variant?: UIStatuses | 'descriptive';
    icon?: UIStatuses;
    children: React.ReactNode | React.ReactNode[];
}
// This component will be used for creating the styles for the Info/Toast component variants
// https://www.figma.com/file/DCSivY7w2M4MI6nnmgZexX/Design-System---Source?type=design&node-id=606-21912&mode=dev
const ToastBox = ({ children, variant, icon }: InfoBoxProps): ReactElement => {
    const ToastClasses = classNames('toast-box', `toast-box__${variant}`);

    const selectIcon = (i: UIStatuses) => {
        switch (i) {
            case 'info':
                return <CircleInfo className="toastbox-icon info" />;

            case 'success':
                return <CheckCircle variant="success" className="toastbox-icon success" />;

            case 'danger':
                return <AlertIcon className="toastbox-icon danger" variant="alert" />;

            case 'warning':
                return <AlertIcon variant="warning" className="toastbox-icon warning" />;

            case 'default':
                return <CircleInfo className="toastbox-icon default" />;

            // add more cases as needed;
            default:
                return null;
        }
    };
    return (
        <div className={ToastClasses}>
            <div className="toast-box__text">
                {icon && selectIcon(icon)}
                {children}
            </div>
        </div>
    );
};
export default ToastBox;
