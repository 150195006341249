import React from 'react';
import { graphql } from 'gatsby';

import { PlansPricingProps } from 'components/plans-pricing/plans-pricing.props';
import PlansPricing from 'components/plans-pricing/plans-pricing.component';

export default function PlansPricingProvider({
    paragraph,
    sectionIndex
}: {
    paragraph: GatsbyTypes.PlansPricingProviderFragment;
    sectionIndex: number;
}) {
    const plansPricingProps: PlansPricingProps = {
        Active: true,
        sectionIndex: sectionIndex
    };
    return <PlansPricing {...plansPricingProps} />;
}

export const query = graphql`
    fragment PlansPricingProvider on paragraph__plans_pricing {
        id
    }
`;
