import React from 'react';

import { LogoProps } from 'ui-kit/logo/logo.props';

const Logo = ({ variant }: LogoProps) => {
    const getVariantStyle = (variant: string | undefined) => {
        switch (variant) {
            case 'light': {
                return { fill: '#FFFFFF', height: '48', width: '160' };
            }
            case 'light-large': {
                return { fill: '#FFFFFF', height: '167', width: '500' };
            }
            case 'primary-large': {
                return { fill: '#03a4dc', height: '167', width: '500' };
            }
            case 'primary': {
                return { fill: '#03a4dc', height: '48', width: '160' };
            }
            default: {
                return { fill: '#03a4dc', height: '100%', width: '100%' };
            }
        }
    };

    const variantStyle = getVariantStyle(variant);

    return (
        <svg
            width={variantStyle.width}
            height={variantStyle.height}
            viewBox="0 0 161 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill={variantStyle.fill}
                d="M160.021 46.9791C160.021 48.6898 158.911 50 157.029 50C155.147 50 154.021 48.6898 154.021 46.9791C154.021 45.2768 155.147 44 157.029 44C158.911 44 160.021 45.2768 160.021 46.9791ZM154.817 46.9791C154.817 48.4061 155.686 49.3241 157.029 49.3241C158.429 49.3241 159.233 48.4061 159.233 46.9791C159.233 45.5522 158.429 44.6759 157.029 44.6759C155.718 44.6759 154.817 45.5438 154.817 46.9791ZM155.847 48.3227V45.7274C155.847 45.4937 155.895 45.4353 156.12 45.4353H157.045C157.834 45.4353 158.276 45.7441 158.276 46.4701C158.276 46.9458 158.075 47.2462 157.713 47.3964L158.324 48.3727C158.413 48.5146 158.332 48.6147 158.196 48.6147H157.938C157.721 48.6147 157.641 48.573 157.528 48.3644L156.989 47.4965H156.547V48.3227C156.547 48.5563 156.49 48.6147 156.273 48.6147H156.12C155.895 48.6147 155.847 48.5563 155.847 48.3227ZM156.997 46.1113H156.547V46.8289H156.997C157.367 46.8289 157.536 46.7371 157.536 46.4701C157.536 46.1947 157.367 46.1113 156.997 46.1113Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill={variantStyle.fill}
                d="M135.912 23.3042H135.908V23.3818C135.908 23.3902 135.907 23.398 135.907 23.4066C135.907 23.4147 135.908 23.4224 135.908 23.4308V42.9516C135.908 42.9597 135.907 42.968 135.907 42.9761C135.907 42.9844 135.908 42.9922 135.908 43.0003V43.3663H135.928C136.131 47.0643 139.194 50.0025 142.954 50.0025C146.714 50.0025 149.776 47.0643 149.981 43.3663H150V23.3042H149.995C149.94 19.4712 146.81 16.3799 142.954 16.3799C139.097 16.3799 135.968 19.4712 135.912 23.3042Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill={variantStyle.fill}
                d="M91.8835 32.3048C91.8835 42.0282 99.7881 49.9103 109.539 49.9103C112.415 49.9103 115.128 49.2203 117.527 48.0039C118.794 49.2373 120.524 49.9999 122.436 49.9999C126.196 49.9999 129.259 47.062 129.463 43.3639H129.482V42.9979C129.482 42.9895 129.483 42.9818 129.483 42.9737C129.483 42.9654 129.482 42.9573 129.482 42.9492V7.05092C129.482 7.04256 129.483 7.0351 129.483 7.02674C129.483 7.01808 129.482 7.01032 129.482 7.00226V6.92433H129.478C129.422 3.09135 126.293 0 122.436 0C118.579 0 115.45 3.09135 115.395 6.92433H115.39V15.6935C113.558 15.0519 111.59 14.6993 109.539 14.6993C99.7881 14.6993 91.8835 22.5814 91.8835 32.3048ZM103.611 32.3048C103.611 29.04 106.265 26.3935 109.539 26.3935C112.813 26.3935 115.467 29.04 115.467 32.3048C115.467 35.5696 112.813 38.2161 109.539 38.2161C106.265 38.2161 103.611 35.5696 103.611 32.3048Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill={variantStyle.fill}
                d="M82.8502 16.4685C80.0611 16.5151 77.335 17.0459 74.7753 17.9998C73.5555 16.988 71.9895 16.3774 70.2784 16.3774C66.4213 16.3774 63.2921 19.4688 63.2367 23.3018H63.2325V23.3797C63.2325 23.3878 63.2319 23.3955 63.2319 23.4042C63.2319 23.4125 63.2325 23.4203 63.2325 23.4284V42.9492C63.2325 42.9575 63.2319 42.9656 63.2319 42.9736C63.2319 42.982 63.2325 42.9898 63.2325 42.9978V43.3642H63.252C63.4556 47.0619 66.5189 50.0001 70.2784 50.0001C74.0381 50.0001 77.1008 47.0619 77.3053 43.3642H77.3248V34.2012C77.3248 32.5113 78.403 30.9892 80.018 30.4769C81.0534 30.1485 82.143 29.9762 83.2526 29.9762C87.045 29.9762 90.1095 26.8687 90.0257 23.068C89.9427 19.3246 86.6045 16.4055 82.8502 16.4685Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill={variantStyle.fill}
                d="M43.1132 35.8501V42.9494C43.1132 42.9574 43.1123 42.9655 43.1123 42.9739C43.1123 42.9819 43.1132 42.9897 43.1132 42.998V43.3641H43.1324C43.336 47.0618 46.3996 50 50.1587 50C53.9188 50 56.9812 47.0618 57.186 43.3641H57.2052V35.8501H43.1132Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill={variantStyle.fill}
                d="M43.1132 30.5274H57.2052V23.3018H57.2001C57.1447 19.4685 54.0155 16.3774 50.1587 16.3774C46.3017 16.3774 43.1728 19.4685 43.1174 23.3018H43.1132V23.3794C43.1132 23.3878 43.1123 23.3955 43.1123 23.4042C43.1123 23.4122 43.1132 23.42 43.1132 23.4284V30.5274Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill={variantStyle.fill}
                d="M19.9434 14.6993C17.8918 14.6993 15.9241 15.0519 14.0926 15.6935V6.92433H14.0875C14.0324 3.09135 10.9032 0 7.04643 0C3.18907 0 0.0601824 3.09135 0.00449122 6.92433H0.00059883V7.00226C0.00059883 7.01032 0 7.01808 0 7.02674C0 7.0351 0.00059883 7.04256 0.00059883 7.05092V42.9492C0.00059883 42.9573 0 42.9654 0 42.9737C0 42.9818 0.00059883 42.9895 0.00059883 42.9979V43.3639H0.0197614C0.223663 47.062 3.28698 49.9999 7.04643 49.9999C8.95849 49.9999 10.6882 49.2373 11.955 48.0039C14.3545 49.2203 17.0675 49.9103 19.9434 49.9103C29.6942 49.9103 37.599 42.0282 37.599 32.3048C37.599 22.5814 29.6942 14.6993 19.9434 14.6993ZM19.9434 38.2161C16.6693 38.2161 14.0153 35.5696 14.0153 32.3048C14.0153 29.04 16.6693 26.3935 19.9434 26.3935C23.2175 26.3935 25.8715 29.04 25.8715 32.3048C25.8715 35.5696 23.2175 38.2161 19.9434 38.2161Z"
            />
        </svg>
    );
};

export default Logo;
