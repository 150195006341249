import React, { ReactElement } from 'react';

import { Row, Col } from 'react-bootstrap';
import sanitizeHtml from 'sanitize-html';

import { RichTextProps } from './rich-text.props';

import './rich-text.style.scss';

const RichText = ({ richText, desktopFullWidth, sectionIndex }: RichTextProps): ReactElement => {
    return (
        <div className="rich-text" key={sectionIndex}>
            <Row className={desktopFullWidth ? 'no-gutters' : ''}>
                <Col xs="12" sm="12" lg={desktopFullWidth ? '12' : { span: 8, offset: 2 }}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(richText, {
                                allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
                                allowedAttributes: {
                                    a: ['id', 'href', 'name', 'rel', 'target', 'data-*'],
                                    img: ['src', 'alt', 'border', 'width', 'height'],
                                    '*': ['class', 'data-*']
                                }
                            })
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default RichText;
