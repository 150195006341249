import React from 'react';

const Twitter = ({ label }: { label: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <title>{label}</title>
            <path
                d="M18.6595 1.9039H22.033L14.663 10.3274L23.3333 21.7899H16.5445L11.2273 14.8379L5.1432 21.7899H1.76768L9.65069 12.78L1.33325 1.9039H8.29436L13.1006 8.25823L18.6595 1.9039ZM17.4756 19.7707H19.3448L7.27865 3.81703H5.27271L17.4756 19.7707Z"
                fill="#1A497F"
            />
        </svg>
    );
};

export default Twitter;
