import { graphql } from 'gatsby';
import { useMemo } from 'react';

import HowItWorksSection from 'display-components/birdi-discount/how-it-works-section';

import { FeatureCardProps } from 'components/feature-card/feature-card.props';

export default function ImageListComponentProvider({
    paragraph
}: {
    paragraph: GatsbyTypes.ImageListComponentProviderFragment;
}) {
    const title = paragraph.field_image_list_component_title || '';
    const subtitle = paragraph.field_image_list_comp_subtitle?.processed || '';
    const imageComponents = useMemo(() => paragraph.relationships?.field_image_list_component_image || [], [paragraph]);

    const featureCardItems: FeatureCardProps[] = useMemo(
        () =>
            imageComponents.map((imageComponent) => {
                const image =
                    imageComponent?.relationships?.field_image_component_image?.relationships?.field_media_image;
                return {
                    title: imageComponent?.field_image_component_title || '',
                    description: imageComponent?.field_image_component_subtitle?.processed || '',
                    image: image?.localFile?.childImageSharp
                };
            }),
        [imageComponents]
    );

    return <HowItWorksSection title={title} subtitle={subtitle} featureCards={featureCardItems} />;
}

export const query = graphql`
    fragment ImageListComponentProvider on paragraph__image_list_component {
        id
        field_image_list_component_title
        field_image_list_comp_subtitle {
            processed
        }
        relationships {
            field_image_list_component_image {
                field_image_component_title
                field_image_component_subtitle {
                    processed
                }
                relationships {
                    field_image_component_image {
                        ... on media__image {
                            id
                            name
                            relationships {
                                field_media_image {
                                    localFile {
                                        childImageSharp {
                                            gatsbyImageData(formats: PNG)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
