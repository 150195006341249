import { Link as GatsbyLink } from 'gatsby';
import React, { ReactElement } from 'react';

import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';
import ExternalIcon from 'ui-kit/icons/external-icon/external-icon';

import { LinkProps } from './link.props';
import './link.style.scss';

const Link = ({
    className,
    anchorClassName,
    label,
    to,
    variant,
    partiallyActive,
    dataGALocation,
    external,
    ariaLabel,
    children,
    isQuickLink = false,
    hasExternalIcon = true,
    onClick
}: LinkProps): ReactElement => {
    if (external === null || external === undefined) {
        if (to.substring(0, 8) === 'https://' || to.substring(0, 7) === 'http://') {
            external = true;
        }
    }
    return (
        <div className={`link ${className ?? ''}`} data-variant={variant ?? ''}>
            {external || to.substring(0, 7) === 'mailto:' || to.substring(0, 4) === 'tel:' ? (
                <a
                    href={to}
                    rel="noopener noreferrer"
                    target={external ? '_blank' : ''}
                    data-tagtype="anchorlink"
                    data-ga-location={dataGALocation}
                    className={`${anchorClassName ?? ''}`}
                    aria-label={ariaLabel}
                >
                    {!isQuickLink && label}
                    {variant === 'arrow' && <ChevronIcon direction={'right'} />}
                    {!isQuickLink && external && hasExternalIcon && variant !== 'arrow' && <ExternalIcon />}
                    {isQuickLink && children}
                </a>
            ) : (
                <GatsbyLink
                    to={to}
                    activeClassName="active"
                    partiallyActive={partiallyActive ? partiallyActive : false}
                    data-tagtype="anchorlink"
                    data-ga-location={dataGALocation}
                    className={`${anchorClassName ?? ''}`}
                    aria-label={ariaLabel}
                    onClick={onClick}
                >
                    {label}
                    {variant === 'arrow' && <ChevronIcon direction={'right'} />}
                    {children}
                </GatsbyLink>
            )}
        </div>
    );
};

export default Link;
