import * as yup from 'yup';

export const MESSAGE_SCHEMA = yup.object().shape({
    messageType: yup.string().required(),
    subject: yup.string().required(),
    message: yup.string().required()
});

export const MESSAGE_REPLY_SCHEMA = yup.object().shape(
    {
        messageType: yup.string().required(),
        subject: yup.string().required(),
        message: yup.string().when('attachedFiles', {
            is: (attachedFiles: any[]) => attachedFiles === undefined || attachedFiles?.length === 0,
            then: yup.string().required(),
            otherwise: yup.string()
        }),
        attachedFiles: yup.array().when('message', {
            is: '',
            then: yup.array().min(1).of(yup.number().required()),
            otherwise: yup.array()
        })
    },
    [['message', 'attachedFiles']]
);
