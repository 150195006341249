import React, { useState } from 'react';
import classNames from 'classnames';
import { Col, Container, Row } from 'react-bootstrap';

import withOnKeyDown from 'hoc/withOnKeyDown';

import { AccordionProps, AcccordionCtaProps, AccordionToggleProps } from 'ui-kit/accordion/accordion.props';

import './accordion.style.scss';

const AccordionCta = withOnKeyDown(({ onClick, onKeyDown, text, icon }: AcccordionCtaProps) => {
    const classes = classNames('birdi-accordion-toggle-cta mb-sm-0');

    return (
        <div className={classes} onClick={onClick} onKeyDown={onKeyDown} role="button" tabIndex={0}>
            {icon && <div className="birdi-accordion-toggle-cta-icon ml-0 ml-sm-2">{icon}</div>}
            {text && <div className="birdi-accordion-toggle-cta-text">{text}</div>}
        </div>
    );
});

const Toggle = ({
    alwaysOpenContent,
    children,
    customCta,
    includeTitleIcon: includeIcon = true,
    onToggle,
    startToggled,
    title,
    titleIcon,
    toggleIcon,
    toggleText,
    variant
}: AccordionToggleProps) => {
    const [isToggled, setIsToggled] = useState(startToggled);

    const classes = classNames('birdi-accordion-toggle', { toggled: isToggled });
    const titleClasses = classNames({ 'm-0': includeIcon, 'ml-2': includeIcon });

    const handleClick = () => {
        if (onToggle) {
            onToggle();
        } else {
            setIsToggled(!isToggled);
        }
    };

    return (
        <div className={classes}>
            <Row>
                <Col>
                    {variant === 'full-cta' ? (
                        <Row className="birdi-accordion-toggle-header">
                            <Col>{customCta && customCta({ onClick: handleClick, isToggled: Boolean(isToggled) })}</Col>
                        </Row>
                    ) : (
                        <Row className="birdi-accordion-toggle-header">
                            <Col xs={{ span: 12, order: 1 }} sm={{ span: 6, order: 1 }} md={6}>
                                <div className="birdi-accordion-toggle-title-wrapper">
                                    {includeIcon && !titleIcon && (
                                        <div className="blue-half-pill" style={{ height: '0.8rem', width: '0.8rem' }} />
                                    )}
                                    {includeIcon && titleIcon}
                                    <div className={titleClasses}>{title}</div>
                                </div>
                            </Col>
                            <Col
                                xs={{ span: 12, order: 3 }}
                                sm={{ span: 6, order: 2 }}
                                md={6}
                                className="d-flex align-items-center justify-content-start justify-content-sm-end"
                            >
                                {!customCta && (
                                    <AccordionCta
                                        text={toggleText}
                                        icon={toggleIcon ?? (isToggled ? '-' : '+')}
                                        onClick={handleClick}
                                    />
                                )}
                                {customCta && customCta({ onClick: handleClick, isToggled: Boolean(isToggled) })}
                            </Col>
                            <Col xs={{ span: 12, order: 2 }} sm={{ span: 12, order: 3 }}>
                                {alwaysOpenContent && (
                                    <div className="birdi-accordion-always-open-content">{alwaysOpenContent}</div>
                                )}
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="birdi-accordion-toggle-content">{children}</div>
                </Col>
            </Row>
        </div>
    );
};

const Spacer = () => <div className="birdi-accordion-spacer" />;

const Accordion = ({ children, className, style }: AccordionProps) => {
    const classes = classNames('birdi-accordion', className);

    return (
        <Container fluid className={classes} style={style}>
            <Row>
                <Col className="mb-3">
                    <div className="birdi-accordion-content">{children}</div>
                </Col>
            </Row>
        </Container>
    );
};

Accordion.Toggle = Toggle;
Accordion.Spacer = Spacer;

export default Accordion;
