import React from 'react';
import './sidebar-close-icon.style.scss';

const SidebarCloseIcon = ({ ...props }: Omit<React.HTMLAttributes<HTMLDivElement>, 'ref'>) => {
    return (
        <div {...props} className="sidebar-close-icon-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                    d="M0.585786 0.585786C1.33288 -0.161304 2.52397 -0.193786 3.30967 0.48834L3.41421 0.585786L15.4142 12.5858C16.1953 13.3668 16.1953 14.6332 15.4142 15.4142C14.6671 16.1613 13.476 16.1938 12.6903 15.5117L12.5858 15.4142L0.585786 3.41421C-0.195262 2.63317 -0.195262 1.36683 0.585786 0.585786Z"
                    fill="white"
                />
                <path
                    d="M12.5858 0.585786C13.3668 -0.195262 14.6332 -0.195262 15.4142 0.585786C16.1613 1.33288 16.1938 2.52397 15.5117 3.30967L15.4142 3.41421L3.41421 15.4142C2.63317 16.1953 1.36683 16.1953 0.585786 15.4142C-0.161304 14.6671 -0.193786 13.476 0.48834 12.6903L0.585786 12.5858L12.5858 0.585786Z"
                    fill="white"
                />
            </svg>
        </div>
    );
};
export default SidebarCloseIcon;
