import React from 'react';
import { graphql } from 'gatsby';
import SideBySide from 'components/side-by-side-paragraphs/side-by-side.component';
import { SideBySideProps } from 'components/side-by-side-paragraphs/side-by-side.props';
export default function SideBySideProvider({
    paragraph,
    sectionIndex
}: {
    paragraph: GatsbyTypes.SideBySideProviderFragment;
    sectionIndex: number;
}) {
    const sideBySideProps: SideBySideProps = {
        paragraphs: paragraph.relationships?.field_nested_paragraphs,
        sectionIndex: sectionIndex
    };
    return <SideBySide {...sideBySideProps} />;
}

export const query = graphql`
    fragment SideBySideProvider on paragraph__side_by_side_paragraphs {
        id
    }
`;
