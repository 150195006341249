import classNames from 'classnames';

import { ChevronProps } from './chevron-thin-icon.props';
import './chevron-thin-icon.style.scss';

const ChevronIcon = ({ direction, style }: ChevronProps) => {
    const containerClasses = classNames('chevron-icon', { [`${direction}`]: direction });

    return (
        <div className={containerClasses} style={style}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M13 6L7 11.9697M13 18L7 12.0303" stroke="#03A4DC" strokeWidth="2" strokeLinecap="round" />
            </svg>
        </div>
    );
};

export default ChevronIcon;
